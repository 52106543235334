import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Layout } from 'antd';
import { history } from '../Helpers';
import { menuService, authenticationService } from '../Services';
import { System_User_Service } from '../../components/System_User/Service/System_User.Service';
import jsonQuery from 'json-query';
import { MPS_LANGUAGE_STATE } from '../../commons';
import { LoginPage } from '../../authenticate/LoginPage';
import { LANGUAGE_STATE } from './Language/ln';
const { Sider } = Layout;


class Mps_SubMenu extends Component {
	constructor(props) {
		super(props);
		const URLpage = this.getURLChildCurrentPage();
		const chooseLanguage = menuService.getLanguage();
		const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
		this.state = {
			subMenuMainFunction: [],
			language,
			subMenuReport: [],
			module: this.props.module,
			isOpen: false,
			UserLabel: '',
			URLpage,
			collapsed: localStorage.getItem('collapsedSubmenuStatus') === 'true' ? true : false

		};
	}

	componentDidMount() {
		if (authenticationService.checkInfor()) {
			this.getInfor();
		}
	}

	/**
	* @desc Handle sự kiện lấy thông tin đường dẫn của thằng con nhỏ nhất (Ví dụ: http://a/b thì sẽ return b)
	* @param
	*   var url: địa chỉ trang hiện tại
	*   Number page: địa chỉ con cuối cùng
	* @return địa chỉ thằng con cuối cùng
	*/
	getURLChildCurrentPage = () => {
		let url = window.location.href;
		url = url.split('#').pop().split('?').pop();
		const page = url.substring(url.lastIndexOf('/') + 1);
		return page;
	}

	componentDidUpdate() {
		if (localStorage.getItem('UpdateUserLabel')) {
			const userName = localStorage.getItem('UpdateUserLabel');
			this.setState({
				UserLabel: userName
			});
			localStorage.removeItem('UpdateUserLabel');
		}
	}
	getInfor() {
		Promise.all([menuService.getSubMenu(this.props.module)]).then(result => {
			const subMenuMainFunction = jsonQuery(['[*System_ScreenGroup=?]', 2], { data: result[0] }).value;
			const subMenuReport = jsonQuery(['[*System_ScreenGroup=?]', 3], { data: result[0] }).value;
			const subMenuMaster = jsonQuery(['[*System_ScreenGroup=?]', 4], { data: result[0] }).value;
			this.setState({
				subMenuMainFunction: [subMenuMainFunction],
				subMenuReport: [subMenuReport],
				subMenuMaster: [subMenuMaster]
			});
		});
		const userinfor = JSON.parse(localStorage.getItem('userinfor'));
		const userId = jsonQuery('[**]User[_id]', { data: userinfor }).value;

		Promise.all([System_User_Service.getUserProfile(userId)]).then(result => {
			const data = jsonQuery('[0]', { data: result }).value;
			const subMenuMainFunction = data.result.docs[0];
			if (!!subMenuMainFunction) {
				this.setState({
					UserLabel: subMenuMainFunction.System_User_DisplayName,
					collapsed: localStorage.getItem('collapsedSubmenuStatus') === 'true' ? true : false
				});
			} else {
				this.logout();
			}
		});
	}
	logout() {
		authenticationService.logout();
		history.push('/login');
	}



	/**
	* @desc Handle sự kiện thay đổi trạng thái thanh submenu
	* @param
	*   key: 
	*   Number page: địa chỉ con cuối cùng
	* @return none
	*/
	onCollapse = collapsed => {
		localStorage.setItem('collapsedSubmenuStatus', collapsed);
		this.setState({ collapsed });
	};

	render() {
		const userinfor = localStorage.getItem('userinfor');
		if (!userinfor) {
			history.push('/login');
			return <div>{LoginPage}</div>;
		}
		const { subMenuMainFunction, subMenuReport,subMenuMaster, URLpage } = this.state;
		const { SubMenu } = Menu;
		const chooseLanguage = this.props.language[0];
		const language = jsonQuery([chooseLanguage], { data: MPS_LANGUAGE_STATE }).value;
		//Lấy tên địa chỉ con nhỏ nhất làm key để hiển thị giá trị được chọn trong Submenu
		const getDefault = [URLpage];
		const iconStyle = this.state.collapsed ? { 'marginLeft': '0px' } : { 'left': '0px' };
		let MenuLayout = {};
		if (this.state.collapsed === false) {
			MenuLayout.defaultSelectedKeys = getDefault;
			MenuLayout.defaultOpenKeys = ['sub2', 'sub3', 'sub4'];
		}
		return (
			<Sider theme='light' trigger={this.state.collapsed ?
				<span className='mps-display-options'><Icon type='double-right' style={{ float: 'right', fontSize: '25px', paddingTop: '12px' }} /> </span>
				:
				<span className='mps-display-options'><Icon type='double-left' style={{ float: 'right', fontSize: '25px', paddingTop: '12px' }} /></span>}
				width='250' style={{ background: '#fff' }} collapsedWidth='80' collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
				<Menu mode='inline'
					{...MenuLayout}
					style={{ height: '100%', borderRight: 0 }}
				>
					<SubMenu
						key='sub1'
						style={iconStyle}
						title={<span> <Icon type='user' style={{ fontSize: 18 }} /> {this.state.collapsed ? <p>&emsp;</p> : ''}  {language.SYSTEM_USER_INFO}</span>}
					>
						<Menu.Item key='1'>

							<Link to='/UserInfor'>
								<h5>{this.state.UserLabel}</h5>
							</Link>

						</Menu.Item>
						<Menu.Item key='2'>
							<Link to='/ChangePassword' >
								{jsonQuery(['[ChangePassword]'], { data: language }).value}
							</Link>
						</Menu.Item>
						<Menu.Item key='3'>
							<Link to='' onClick={this.logout}>
								{jsonQuery(['[Logout]'], { data: language }).value}
							</Link>
						</Menu.Item>
					</SubMenu>
					<SubMenu key='sub2'
						style={iconStyle}
						title={<span><Icon type='desktop' style={{ fontSize: 18 }} /> {this.state.collapsed ? <p>&emsp;</p> : ''} {language.SYSTEM_MAIN_FUNCTION}</span>}
					>
						{
							Object.keys(subMenuMainFunction).map(function (key) {
								const navModel = subMenuMainFunction[key];
								if (navModel === null) {
									return '';
								}
								const subMenuList = navModel.map(navChild =>
									<Menu.Item key={navChild.Screen_Code} >
										<Link to={{pathname:`/${navChild.Screen_Code}`}}>
											{jsonQuery(['[?]', navChild.Screen_Code], { data: language }).value}
										</Link>
									</Menu.Item>);
								return subMenuList;
							})
						}
					</SubMenu>
					{
						!!subMenuReport && !!subMenuReport[0] && subMenuReport[0].length > 0 ?
							<SubMenu key='sub3'
								style={iconStyle}
								title={<span><Icon type='pie-chart' style={{ fontSize: 18 }} /> {this.state.collapsed ? <p>&emsp;</p> : ''} Báo cáo</span>}
							>
								{
									Object.keys(subMenuReport).map(function (key) {
										const navModel = subMenuReport[key];
										if (navModel === null) {
											return '';
										}
										const subMenuList = navModel.map(navChild =>
											<Menu.Item key={navChild.Screen_Code} >
												<Link to={navChild.Screen_Code}>
													{jsonQuery(['[?]', navChild.Screen_Code], { data: language }).value}
												</Link>
											</Menu.Item>);
										return subMenuList;
									})
								}
							</SubMenu> : ''
					}
					{
						!!subMenuMaster && !!subMenuMaster[0] && subMenuMaster[0].length > 0 ?
							<SubMenu key='sub4'
								style={iconStyle}
								title={<span><Icon type='desktop' style={{ fontSize: 18 }} /> {this.state.collapsed ? <p>&emsp;</p> : ''}{this.state.language.CATALOGUE}</span>}
							>
								{
									Object.keys(subMenuMaster).map(function (key) {
										const navModel = subMenuMaster[key];
										if (navModel === null) {
											return '';
										}
								
										const subMenuList = navModel.map(navChild =>
											<Menu.Item key={navChild.Screen_Code} >
												<Link to={navChild.Screen_Code}>
													{jsonQuery(['[?]', navChild.Screen_Code], { data: language }).value}
												</Link>
											</Menu.Item>);
										return subMenuList;
									})
								}
							</SubMenu> : ''
					}

				</Menu>
			</Sider>
		);
	}
}

export { Mps_SubMenu };

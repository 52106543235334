import React from 'react';


const System_UserList = React.lazy(() => import('../../../components/System_User/System_UserList'));
const System_User_Profile = React.lazy(() => import('../../../components/System_User/System_User_Profile'));
const System_CompanyProfile = React.lazy(() => import('../../../components/System_Company/System_CompanyProfile'));
const System_User_ChangePassword = React.lazy(() => import('../../../components/System_User/WrappedSystem_User_ChangePassword'));
const System_RoleList = React.lazy(() => import('../../../components/System_Role/System_RoleList'));
const System_Permission = React.lazy(() => import('../../../components/System_Permission/WrappedSystem_Permission'));
const System_Setting = React.lazy(() => import('../../../components/System_Setting/System_Setting'));
const ResetPassword = React.lazy(() => import('../../ForgotPassword/ResetPassword'));
const System_ParameterList = React.lazy(() => import('../../../components/System_Parameter/System_ParameterList'));

export const systemroutes = [
    { path: '/User', component: System_UserList },
    { path: '/UserInfor', component: System_User_Profile },
    { path: '/CompanyInfor', component: System_CompanyProfile },
    { path: '/ChangePassword', component: System_User_ChangePassword },
    { path: '/Role', component: System_RoleList },
    { path: '/Permission', component: System_Permission },
    { path: '/System_Setting', component: System_Setting },
    { path: '/reset', component: ResetPassword },
    { path: '/System_Parameter', component: System_ParameterList },
];

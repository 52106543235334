export const LANGUAGE_STATE ={
    'vn':
        {
            PAGE_TITLE: 'Quản lý bình lọc nước',
            SIPCO_WATERFILTER_SEARCH: 'Quản lý bình lọc nước',
            SIPCO_WATERFILTER_NEW: 'Thêm mới bình lọc nước',
            SIPCO_WATERFILTER_COPY: 'Sao chép thông tin bình lọc nước',
            SIPCO_WATERFILTER_EDIT: 'Chỉnh sửa thông tin bình lọc nước',
            SIPCO_WATERFILTER_VIEW: 'Xem thông tin bình lọc nước',
            SIPCO_WATERFILTER_TITLE_GENERAL: 'Thông tin chung',
            SIPCO_WATERFILTER_TITLE_ADDITIONAL: 'Thông tin thêm',
            CPA_CODE: 'Mã CPA',
            SERIAL_CODE: 'Mã số bình lọc nước',
            OWNER:'Tên hộ nhận BLN',
            PHONE:'Số điện thoại',
            PROVIDE_DATE:'Ngày cấp',
            SIPCO_WATERFILTER_DESCRIPTION:'Mô tả',
            DOCUMENT_UPLOAD:'Tài liệu',
            STATUS:'Trạng thái',
            CREATED_DATE: 'Ngày tạo',
            CREATED_BY: 'Người tạo',
            UPDATED_DATE: 'Ngày cập nhật',
            UPDATED_BY: 'Người cập nhật',
            SEARCH_INFOR:'Thông tin tìm kiếm',
            SAVE: 'Lưu',
            SAVEANDCLOSE:'Lưu và đóng',
            SAVEANDNEXT: 'Lưu và tiếp tục',
            CANCEL:'Hủy',
            CLOSE:'Đóng',
            SEARCH:'Tìm kiếm',
            NEW: 'Thêm mới',
            VIEW: 'Xem',
            EDIT: 'Chỉnh sửa',
            DELETE: 'Xóa',
            COPY:'Sao chép',
            EXPORT_CPA:'Tải dữ liệu theo CPA',
            IMPORT:'Nhập dữ liệu',
            DOWNLOADDATA:'Tải dữ liệu (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import mẫu',
            SIPCO_WATERFILTER_DATA_FILE: 'File data SIPCO_WATERFILTER',
            MONITORING_DATE: 'Ngày giám sát',
            DESCRIPTION_OF_MONITORING: 'Mô tả giám sát',
            FILTER_REPLACEMENT_DATE: 'Ngày thay lõi',
            NUMBER_OF_TIMES_TO_REPLACE: 'Lần thay lõi',
            HETHAN:'Quản lý bình lọc nước sắp hết hạn',
            ERROR_DATA: 'Dữ liệu lỗi',
            PROVINCE: 'Tỉnh',
            DISTRICT: 'Huyện',
            WARD: 'Xã',
            VILLAGE: 'Thôn/Ấp',
            ERROR_IMPORT_ROW: 'sản phẩm import không thành công.',
            SUCCESS_IMPORT_ROW: 'sản phẩm import thành công.',
            SUM_SERRIAL_PER_CPA: 'Tổng bình lọc/CPA',
            SUM_SERIAL_PER_PROVINCE: 'Tổng bình lọc/Tỉnh',
            SUM_SERIAL_PER_DISTRICT: 'Tổng bình lọc/Huyện',
            SUM_WATERFILTER_PER_PROVINCE: 'Tổng bình lọc/tỉnh',
            SUM_WATERFILTER_PER_WARD: 'Tổng bình lọc/Xã',
            SUM_CPA: 'Tổng CPA',
            SUM: 'Tổng',
            ADDDOCUMENT: 'Thêm tài liệu',
            UPLOAD: 'File đính kèm',
            DAYLEFT: 'Ngày còn lại',
            WATERFILTER_EPIRED: 'Bình lọc nước đã hết hạn',
            DAY: 'ngày',
            EXPORT_BY_CPA: 'Tải dữ liệu bình lọc nước theo CPA',
            DOWNLOAD_CLOSE: 'Tải và đóng',
            DOWNLOAD_CONTINUE: 'Tải và tiếp tục',
            CHOOSE_ONLY_ONE_ROW: 'Vui lòng chỉ chọn một dòng dữ liệu',
            CHOOSE_DATA_COPY: 'Vui lòng chọn dòng dữ liệu để tải',
            SIPCO_CPA_DATA_FILE: 'File data SIPCO_CPA',
        },
    'en':
        {
            PAGE_TITLE: 'Household management',
            SIPCO_WATERFILTER_SEARCH: 'Household',
            SIPCO_WATERFILTER_NEW: 'Add new Household information',
            SIPCO_WATERFILTER_COPY: 'Copy Household information',
            SIPCO_WATERFILTER_EDIT: 'Edit Household information',
            SIPCO_WATERFILTER_VIEW: 'View Household information',
            SIPCO_WATERFILTER_TITLE_GENERAL: 'General  information',
            SIPCO_WATERFILTER_TITLE_ADDITIONAL: 'Additional information',
            CPA_CODE: 'CPA code',
            SERIAL_CODE: 'Household',
            OWNER:'Name of Household',
            PHONE:'Phone',
            PROVIDE_DATE:'Distribution date',
            SIPCO_WATERFILTER_DESCRIPTION:'Description',
            DOCUMENT_UPLOAD:'Upload document',
            STATUS:'Status',
            CREATED_DATE: 'Created date',
            CREATED_BY: 'Created by',
            UPDATED_DATE: 'Updated date',
            UPDATED_BY: 'Updated by',
            SEARCH_INFOR:'Search Information',
            SAVE: 'Save',
            SAVEANDCLOSE:'Save and close',
            SAVEANDNEXT: 'Save and continue',
            CANCEL:'Cancel',
            CLOSE:'Close',
            SEARCH:'Search',
            NEW: 'New',
            EDIT: 'Edit',
            VIEW: 'View',
            DELETE: 'Delete',
            COPY:'Copy',
            EXPORT_CPA: 'Download data by CPA',
            IMPORT:'Import data',
            DOWNLOADDATA:'Download Data (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import example',
            SIPCO_WATERFILTER_DATA_FILE: 'File data SIPCO_WATERFILTER',
            MONITORING_DATE: 'Monitoring date',
            DESCRIPTION_OF_MONITORING: 'Description of Monitoring',
            FILTER_REPLACEMENT_DATE: 'Filter replacement date',
            NUMBER_OF_TIMES_TO_REPLACE: 'Number of times to replace',
            HETHAN:'Household management',
            ERROR_DATA: 'Data error',
            PROVINCE: 'Province',
            DISTRICT: 'District',
            WARD: 'Ward',
            VILLAGE: 'Village',
            ERROR_IMPORT_ROW: 'row error.',
            SUCCESS_IMPORT_ROW: 'row success.',
            SUM_SERIAL_PER_CPA: 'Total Series/CPA',
            SUM_SERIAL_PER_PROVINCE: 'Total Series/Province',
            SUM_SERIAL_PER_DISTRICT: 'Total Series/District',
            SUM_WATERFILTER_PER_PROVINCE: 'Total Household/province',
            SUM_WATERFILTER_PER_WARD: 'Total Household/Ward',
            SUM_CPA: 'Total CPA',
            SUM: 'Sum',
            ADDDOCUMENT: 'Additional document',
            UPLOAD: 'Upload file',
            DAYLEFT: 'Day left',
            WATERFILTER_EPIRED: 'Expired',
            DAY: 'day',
            EXPORT_BY_CPA: 'Household export by CPA',
            DOWNLOAD_CLOSE: 'Download and close',
            DOWNLOAD_CONTINUE: 'Download and continue',
            CHOOSE_DATA_DOWNLOAD: 'Please choose row to download',
            CHOOSE_ONLY_ONE_ROW: 'Plesea choose only data download',
            SIPCO_CPA_DATA_FILE: 'File data SIPCO_CPA',

        }
    };

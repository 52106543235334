import { LogglyTracker } from 'loggly-jslogger';
import jsonQuery from 'json-query';
var userinfor = JSON.parse(localStorage.getItem('userinfor'));

var userId = jsonQuery('[**]User[_id]', { data: userinfor }).value;

const logger = new LogglyTracker();

logger.push({ 'logglyKey': 'f388b137-e99e-49c3-ab5e-bc90cfb09f65' });
logger.push({ userId});
export default logger;
export const MESSAGE_STATE = {
    'vn':
    {
        ERROR_PRODCUCT_IS_EXIST:'Sản phẩm đã tồn tại',
        ERROR_PRODCUCT_CODE_IS_EXIST:'Mã sản phẩm đã tồn tại',
        ERROR_PRODCUCT_NAME_IS_EXIST:'Tên sản phẩm đã tồn tại',
        ERROR_PRODCUCT_BARCODE_IS_EXIST:'Mã barcode đã tồn tại'
    },
    'en':
    {
        ERROR_PRODCUCT_IS_EXIST:'Error product code is exist',
        ERROR_PRODCUCT_NAME_IS_EXIST:'Error product name is exist',
        ERROR_PRODCUCT_BARCODE_IS_EXIST:'Error product barcode is exist',
    }
};

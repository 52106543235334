import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Col, Layout, Modal, Row, Spin,Tabs } from 'antd';
import jsonQuery from 'json-query';
// import React, { Component } from 'react';
import { Mps_HeaderMaster, Mps_SubMenu, Mps_Footer, Mps_Breadcum } from '../../authenticate/Layout';
//Khai báo common component
import { menuService } from '../../authenticate/Services';
import moment from 'moment';

import {
    MESSAGE_STATE,
    MPS_MESSAGE_STATE, Mps_MethodCommon, MPS_MODE, MPS_SCREEN_NO, MPS_SCREEN_ACTION, MPS_RESULTS
} from '../../commons';
import {
    getPageSize, Mps_LabelFormat, Mps_ErrorPermission,
    Mps_Download, Mps_ImportExcel, Mps_ResultTable, Mps_DisplayColumnResultTable
} from '../Mps_Control';
//Khai báo thêm component trên page
import { LANGUAGE_STATE } from './Language/ln';
import {
    initResultTable, getDisplaySortFields, setDisplaySortFields
    , getDisplaySearchConditions, setDisplaySearchConditions, getDisplayColumnSearchResults
    , setDisplayColumnSearchResults, searchModel, headers, headerExample, getAllColumn, initResultTableExpired
} from './Models/Sipco_WaterFilter.Model';
import { Sipco_WaterFilterSearch } from './NotificalSearch';
import { Sipco_WaterFilterView_Notifical } from './Sipco_WaterFilterView_Notifical';
import { Sipco_WaterFilter_Service } from '../Sipco_WaterFilter/Service/Sipco_WaterFilter.Service';
import { Sipco_Parameter_Service } from '../../components/Sipco_Parameter/Service/Sipco_Parameter.Service'
//import { Log_Service } from '../../authenticate/Helpers/Log.Service';
const {TabPane} = Tabs
const screenNo = MPS_SCREEN_NO.Notify;
let permission = [];
const confirm = Modal.confirm;

 class Notifical extends Component {

 /**
    * @desc Hàm khởi tạo component
    * @param
    *   props - properties của component.
    * @return Nothing
    */
  constructor(props) {

    super(props);
    const chooseLanguage = menuService.getLanguage();
    const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
    const messageValid = jsonQuery([chooseLanguage[0]], { data: MESSAGE_STATE }).value;
    const commonMessage = jsonQuery([chooseLanguage[0]], { data: MPS_MESSAGE_STATE }).value;
    document.title = language.PAGE_TITLE;
    this.state = {
        chooseLanguage,
        language,
        messageValid,
        commonMessage,
        searchModel,
        dataSource: [],
        selectedModel: [],
        searchOptions: {
            page: 1,
            limit: getPageSize(),
            language: chooseLanguage,
            sort: getDisplaySortFields(),
            sortAscending: true
        },
        importModel: [],
        visible: false,
        mode: '',
        isLoading: false,
        alertMesage: {
            show: false,
            message: '',
            description: ''
        },
        dataNotifical: [],
        dataExpired:[],
        totalExpirationSoon:'',
        totalExpired:'',
        dataForDownload: [],
        key: 1,
        page: 1,
    };
}

componentWillMount() {
    permission = menuService.showPermission(screenNo);
    const today = [moment(Date.now()), moment(Date.now())]
    const searchObjectModel = this.state.searchModel;
    const searchOptions = this.state.searchOptions;
    searchObjectModel.Notifical = today
    Promise.all([Sipco_Parameter_Service.search(searchObjectModel, searchOptions)]).then((result) => {   
        var data = jsonQuery('[**]result[*]docs', { data: result }).value
        const Expire_Duration = data[0].Expire_Duration
        const Notify_Before = data[0].Notify_Before
        searchObjectModel.Expire_Duration = Expire_Duration
        searchObjectModel.Notify_Before = Notify_Before 
        Promise.all([Sipco_WaterFilter_Service.search1(searchObjectModel, searchOptions)]).then((result) => {
            var data = jsonQuery('result', { data: result }).value;
            /*demo mẫu logger*/
            //Log_Service.create("Search", data);
            // const dataReturn = [{docs: [...data]}]

            this.setState({
                dataNotifical: data,
                totalExpirationSoon: data[0].total,
                dataSource: data
            });
            const tempdata = [...data]
            const data1 = JSON.parse(JSON.stringify(tempdata));
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
            this.handleDownloadExcel (tempDataForDownload)
            const dataForDownload = [{
                docs: tempDataForDownload
            }];
            this.setState({
                dataForDownload: dataForDownload,
            }); 
        }).catch(reason => {
            Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
            this.setState({
                isLoading: false,
            });
        });
    })
    Promise.all([Sipco_Parameter_Service.search(searchObjectModel, searchOptions)]).then((result) => {   
        var data = jsonQuery('[**]result[*]docs', { data: result }).value
        const Expire_Duration = data[0].Expire_Duration
        const Notify_Before = data[0].Notify_Before
        searchObjectModel.Expire_Duration = Expire_Duration
        searchObjectModel.Notify_Before = Notify_Before
        Promise.all([Sipco_WaterFilter_Service.searchWaterFilterExpired(searchObjectModel, searchOptions)]).then((result) => {
            var data = jsonQuery('result', { data: result }).value;
            /*demo mẫu logger*/
            //Log_Service.create("Search", data);
            // const dataReturn = [{docs: [...data]}]

            this.setState({
                dataExpired: data,
                totalExpired:  data[0].total,
                visible: false,
                isLoading: false
            });
        }).catch (err => console.log("err",err))
    })
}

componentWillUnmount() {
    localStorage.removeItem('page_Notifical_Expired');
    localStorage.removeItem('page_Notifical_Soon');
    localStorage.removeItem('pageSize_Notifical_Expired');
    localStorage.removeItem('pageSize_Notifical_Soon');
    this.setState({
        dataNotifical: [],
        dataSource: [],
        dataExpired: []
    });
}

//#region Handle event (Search, add, copy, edit, view, delete)
/**
* @desc Handle sự kiện tìm kiếm dữ liệu
* @param
* @return Nothing (Hiển thị dữ liệu được lọc theo điều kiện tìm kiếm),
*/
handleSearchAfterAction = () => {
    this.setState({
        isLoading: true
    });
    const today = [moment(Date.now()), moment(Date.now())]
    var searchObjectModel = this.state.searchModel;
    searchObjectModel.Provide_Date = Mps_MethodCommon.handleChangeDate(searchObjectModel.Provide_Date)
    searchObjectModel.Filter_Replacement_Date = Mps_MethodCommon.handleChangeDate(searchObjectModel.Filter_Replacement_Date)
    searchObjectModel.Monitoring_Date = Mps_MethodCommon.handleChangeDate(searchObjectModel.Monitoring_Date)
    searchObjectModel.CreatedDate = Mps_MethodCommon.handleChangeDate(searchObjectModel.CreatedDate)
    searchObjectModel.UpdatedDate = Mps_MethodCommon.handleChangeDate(searchObjectModel.UpdatedDate)
    var searchOptions = this.state.searchOptions;
    searchObjectModel.Notifical = today
    searchOptions.page = 1
    Promise.all([Sipco_WaterFilter_Service.search1(searchObjectModel, searchOptions)]).then((result) => {
        var data = jsonQuery('result', { data: result }).value;
        /*demo mẫu logger*/
        //Log_Service.create("Search", data);
        // const dataReturn = [{docs: [...data]}]

        this.setState({
            dataSource: data,
            visible: false,
            isLoading: false,
            selectedModel: []
        });
        const tempdata = [...data]
        const data1 = JSON.parse(JSON.stringify(tempdata));
        const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
        this.handleDownloadExcel (tempDataForDownload)
        const dataForDownload = [{
            docs: tempDataForDownload
        }];
        this.setState({
            dataForDownload: dataForDownload,
        }); 
    }).catch(reason => {
        Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
        this.setState({
            isLoading: false,

        });
    });
    Promise.all([Sipco_WaterFilter_Service.searchWaterFilterExpired(searchObjectModel, searchOptions)]).then((result) => {
        var data = jsonQuery('result', { data: result }).value;
        /*demo mẫu logger*/
        //Log_Service.create("Search", data);

        this.setState({
            dataExpired: data,
            visible: false,
            isLoading: false,
            selectedModel: []
        });
        const tempdata = [...data]
            const data1 = JSON.parse(JSON.stringify(tempdata));
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
            this.handleDownloadExcel (tempDataForDownload)
            const dataForDownload = [{
                docs: tempDataForDownload
            }];
            this.setState({
                dataForDownload: dataForDownload,
            }); 
    })
};

handleDayLeft (provideDay) {
    const Provide_Date =  new Date(provideDay)
    const endDate = new Date(Provide_Date.getFullYear()+3, Provide_Date.getMonth(), Provide_Date.getDate(),0,0,0);
    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate()
    const now = new Date(year,month,day ,0,0,0)
    var Difference_In_Time = endDate.getTime() - now.getTime()
    var Difference_In_Days = Difference_In_Time /(1000 * 60 * 60 * 24);
    return Difference_In_Days
}

handleDownloadExcel (data, key) {
        data.forEach(d => {
            d.Serial_Code = (!!d.Serial_Code ? ( d.Serial_Code) : '');
            d.Owner = (!!d.Owner ? (d.Owner) : '');
            d.Phone = (!!d.Phone ? ("'"+d.Phone) : '');
            d.DayLeft = (key == 1 || key == undefined) ?this.handleDayLeft (d.Provide_Date): ''
            d.Provide_Date = !Mps_LabelFormat.formatDate(d.Provide_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Provide_Date));
            d.Sipco_WaterFilter_Description = (!!d.Sipco_WaterFilter_Description ? (d.Sipco_WaterFilter_Description) : '');
            d.UpdatedBy = (!!d.UpdatedByObject ? d.UpdatedByObject.System_User_DisplayName : '');
            d.UpdatedDate = !Mps_LabelFormat.formatDate(d.UpdatedDate) ? '' : ("'"+Mps_LabelFormat.formatDate(d.UpdatedDate));
            d.CreatedBy = (!!d.CreatedByObject ? d.CreatedByObject.System_User_DisplayName : '');
            d.CreatedDate = "'"+Mps_LabelFormat.formatDate(d.CreatedDate);
            d.Status = (!!d.StatusObject ? d.StatusObject.System_Parameter_Name : '');
            d.Monitoring_Date = !Mps_LabelFormat.formatDate(d.Monitoring_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Monitoring_Date));
            d.Filter_Replacement_Date = !Mps_LabelFormat.formatDate(d.Filter_Replacement_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Filter_Replacement_Date));
        });
        return data
}

convertToDownload = (data, key) => {
    if (!!data && data.length > 0) {
        data.forEach(d => {
            d.Serial_Code = (!!d.Serial_Code ? ( d.Serial_Code) : '');
            d.Owner = (!!d.Owner ? (d.Owner) : '');
            d.Phone = (!!d.Phone ? ("'"+d.Phone) : '');
            d.DayLeft = (key == 1) ?this.handleDayLeft (d.Provide_Date): ''
            d.Provide_Date = !Mps_LabelFormat.formatDate(d.Provide_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Provide_Date));
            d.Sipco_WaterFilter_Description = (!!d.Sipco_WaterFilter_Description ? (d.Sipco_WaterFilter_Description) : '');
            d.UpdatedBy = (!!d.UpdatedByObject ? d.UpdatedByObject.System_User_DisplayName : '');
            d.UpdatedDate = !Mps_LabelFormat.formatDate(d.UpdatedDate) ? '' : ("'"+Mps_LabelFormat.formatDate(d.UpdatedDate));
            d.CreatedBy = (!!d.CreatedByObject ? d.CreatedByObject.System_User_DisplayName : '');
            d.CreatedDate = "'"+Mps_LabelFormat.formatDate(d.CreatedDate);
            d.Status = (!!d.StatusObject ? d.StatusObject.System_Parameter_Name : '');
            d.Monitoring_Date = !Mps_LabelFormat.formatDate(d.Monitoring_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Monitoring_Date));
            d.Filter_Replacement_Date = !Mps_LabelFormat.formatDate(d.Filter_Replacement_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Filter_Replacement_Date));
        });
        const dataForDownload = [{
            docs: data
        }];
        this.setState({ dataForDownload });
    }
    // else {
    //     const tempData = JSON.parse(JSON.stringify(this.state.dataSource));
    //     const tempDataForDownload = jsonQuery('[**]docs[**]', { data: tempData }).value;
    //     this.convertToDownload(tempDataForDownload);
    // }
};

//#endregion

//#region functions xử lý event trên componet Mps_ResultTable
/**
* @desc Handle sự kiện chọn record trên table.
* @param
*   Object selectedRow: records được chọn trên table.
* @return Nothing (Cập nhật giá trị mới cho state selectedModel).
*/
handleSelectRow = selectedRow => {
    const data = JSON.parse(JSON.stringify(selectedRow));
    this.convertToDownload(data,this.state.key);
    this.setState({ 
        detailModel: selectedRow[0],
        selectedModel: selectedRow 
    });
};

/**
* @desc Handle sự kiện page thay đổi.
* @param
*   Number page: trang thay đổi.
*   Number pageSizes: Số record trên mỗi page
* @return Nothing (Cập nhật giá trị mới cho state searchOptions, và hiển thị data trên table theo điều kiện tìm kiếm).
*/
handlePagination = (page, pageSizes) => {
    const model = this.state.searchModel;
    const searchOptions = this.state.searchOptions;
    searchOptions.page = page;
    searchOptions.limit = pageSizes;
    localStorage.setItem('page_Notifical_Expired',JSON.stringify(page))
    /*demo mẫu logger*/
    //Log_Service.create("searchOptions", searchOptions);
    this.setState({
        searchOptions,
        isLoading: true,
        page
    });
    Promise.all([Sipco_WaterFilter_Service.searchWaterFilterExpired(model, searchOptions)]).then((result) => {
        var data = jsonQuery('result', { data: result }).value;
        /*demo mẫu logger*/
        //Log_Service.create("search", data);
        this.setState({
            dataExpired: data,
            visible: false,
            isLoading: false,
        });
        const tempdata = [...data]
        const data1 = JSON.parse(JSON.stringify(tempdata));
        const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
        this.handleDownloadExcel (tempDataForDownload)
        const dataForDownload = [{
            docs: tempDataForDownload
        }];
        this.setState({
            dataForDownload: dataForDownload,
        }); 
    });
};

handlePagination_Epiration_soon = (page, pageSizes) => {
    const model = this.state.searchModel;
    const searchOptions = this.state.searchOptions;
    const today = [moment(Date.now()), moment(Date.now())]
    searchOptions.page = page;
    searchOptions.limit = pageSizes;
    localStorage.setItem('page_Notifical_Soon',JSON.stringify(page))
    model.Notifical = today
    /*demo mẫu logger*/
    //Log_Service.create("searchOptions", searchOptions);
    this.setState({
        searchOptions,
        isLoading: true,
        page
    });
    Promise.all([Sipco_WaterFilter_Service.search1(model, searchOptions)]).then((result) => {
        var data = jsonQuery('result', { data: result }).value;
        /*demo mẫu logger*/
        //Log_Service.create("search", data);
        this.setState({
            dataSource: data,
            visible: false,
            isLoading: false,
        });
        const tempdata = [...data]
        const data1 = JSON.parse(JSON.stringify(tempdata));
        const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
        this.handleDownloadExcel (tempDataForDownload)
        const dataForDownload = [{
            docs: tempDataForDownload
        }];
        this.setState({
            dataForDownload: dataForDownload,
        });
    });
};

/**
* @desc Handle sự kiện thay đổi thiết lập số records hiển thị trên table.
* @param
*   Number currentPage: trang hiện tại.
*   Number pageSizes: Số record sẽ hiển thị trên mỗi page
* @return Nothing (Cập nhật pageSizes hiển thị theo thiết lập mới),
*/
handleShowSizeChange = (currentPage, pageSizes) => {
    const searchOptions = this.state.searchOptions;
    searchOptions.page = currentPage;
    searchOptions.limit = pageSizes;
    /*demo mẫu logger*/
    //Log_Service.create("searchOptions", searchOptions);
    this.setState({
        searchOptions,
        dataExpired:[],
        page: currentPage
    });
    const model = this.state.searchModel;
    this.setState({
        isLoading: true,
    });
    Promise.all([Sipco_WaterFilter_Service.searchWaterFilterExpired(model, searchOptions)]).then((result) => {
        var data = jsonQuery('result', { data: result }).value;
        /*demo mẫu logger*/
        //Log_Service.create("search", data);
        this.setState({
            dataExpired: data,
            visible: false,
            isLoading: false,
        }); 
        // const data = this.state.dataExpired
        const tempdata = [...data]
        const data1 = JSON.parse(JSON.stringify(tempdata));
        const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
        this.handleDownloadExcel (tempDataForDownload)
        const dataForDownload = [{
            docs: tempDataForDownload
        }];
        this.setState({
            dataForDownload: dataForDownload,
        }); 
    })
};

handleShowSizeChange_soon = (currentPage, pageSizes) => {
    const searchOptions = this.state.searchOptions;
    searchOptions.page = currentPage;
    searchOptions.limit = pageSizes;
    /*demo mẫu logger*/
    //Log_Service.create("searchOptions", searchOptions);
    this.setState({
        searchOptions,
        page: currentPage
    });
    const model = this.state.searchModel;
    this.setState({
        isLoading: true,
    });
    Promise.all([Sipco_WaterFilter_Service.search1(model, searchOptions)]).then((result) => {
        var data = jsonQuery('result', { data: result }).value;
        /*demo mẫu logger*/
        //Log_Service.create("search", data);
        this.setState({
            dataSource: data,
            visible: false,
            isLoading: false,
        });
            
        // const data = this.state.dataSource
        const tempdata = [...data]
        const data1 = JSON.parse(JSON.stringify(tempdata));
        const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
        this.handleDownloadExcel (tempDataForDownload)
        const dataForDownload = [{
            docs: tempDataForDownload
        }];
        this.setState({
            dataForDownload: dataForDownload,
        }); 
    })
};

handleTabChange = (key) => {
    const pageSize_Notifical_Soon = JSON.parse(localStorage.getItem('pageSize_Notifical_Soon'))
    const pageSize_Notifical_Expired = JSON.parse(localStorage.getItem('pageSize_Notifical_Expired'))
    const pageSize = JSON.parse(localStorage.getItem('pageSize'))
    if (key == 1 ) {
        localStorage.setItem('pageSize_Notifical_Soon', JSON.stringify(this.state.searchOptions.limit))
        if (pageSize_Notifical_Soon !== pageSize) {
            localStorage.removeItem('page_Notifical_Soon');
            const model = this.state.searchModel;
            const searchOptions = this.state.searchOptions;
            searchOptions.page = 1
            this.setState({
                isLoading: true,
            });
            Promise.all([Sipco_WaterFilter_Service.search1(model, searchOptions)]).then((result) => {
                var data = jsonQuery('result', { data: result }).value;
                /*demo mẫu logger*/
                //Log_Service.create("search", data);
                this.setState({
                    dataSource: data,
                    visible: false,
                    isLoading: false,
                });
                const tempdata = [...data]
                const data1 = JSON.parse(JSON.stringify(tempdata));
                const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
                this.handleDownloadExcel (tempDataForDownload, key)
                const dataReturn = [{ docs: tempDataForDownload }]
                this.setState({
                    dataForDownload: dataReturn,
                })
            })
        }
        const page_Notifical_Soon = JSON.parse(localStorage.getItem('page_Notifical_Soon'))
        this.setState({
            page: (page_Notifical_Soon === null) ? 1 : page_Notifical_Soon,
            key,
        })

    }
    if (key == 2) {
        localStorage.setItem('pageSize_Notifical_Expired', JSON.stringify(this.state.searchOptions.limit))
        if (pageSize_Notifical_Expired !== pageSize) {
            localStorage.removeItem('page_Notifical_Expired');
            const model = this.state.searchModel;
            const searchOptions = this.state.searchOptions;
            searchOptions.page = 1
            this.setState({
                isLoading: true,
            });
            Promise.all([Sipco_WaterFilter_Service.searchWaterFilterExpired(model, searchOptions)]).then((result) => {
                var data = jsonQuery('result', { data: result }).value;
                /*demo mẫu logger*/
                //Log_Service.create("search", data);
                this.setState({
                    dataExpired: data,
                    visible: false,
                    isLoading: false,
                });
                const tempdata = [...data]
                const data1 = JSON.parse(JSON.stringify(tempdata));
                const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
                this.handleDownloadExcel (tempDataForDownload,key)
                const dataReturn = [{ docs: tempDataForDownload }]
                this.setState({
                    dataForDownload: dataReturn,
                })
            })
        }
        const page_Notifical_Expired = JSON.parse(localStorage.getItem('page_Notifical_Expired'))
        this.setState({
            page: (page_Notifical_Expired === null) ? 1 : page_Notifical_Expired,
            key,
        })
    }
}

//#endregion

//#region Handle sự kiện component Mps_DisplayColumnResultTable
/**
* @desc Handle sự kiện component Search thay đổi giá trị
* @param
*   Object value: Model điều kiện tìm kiếm.
* @return Nothing (value State được cập nhật giá trị mới)
*/
handleSearchChange = value => {
    this.setState({
        searchModel: value
    });

}
/**
   * @desc Handle thiết lập hiển thị Field điều kiện tìm kiếm.
   * @param
   *   array value: Danh sách Field được chọn hiển thị .
   * @return Nothing (Cập nhật setting),
   */
handleDisplaySearchConditionsChange = value => {
    setDisplaySearchConditions(value);
    this.setState({
        displaySearchConditions: value
    });
};

//Handle thiết lập sắp xếp hiển thị kết quả tìm kiếm
/**
* @desc Handle thiết lập sắp xếp hiển thị record trên table kết quả tìm kiếm.
* @param
*   array value: Danh sách Field được chọn sort.
* @return Nothing (Cập nhật setting),
*/
handleDisplaySortFieldsChange = value => {
    setDisplaySortFields(value);
    const searchOptions = this.state.searchOptions;
    searchOptions.sort = value;
    this.setState({
        displaySortFields: value,
        searchOptions
    });
};

/**
* @desc Handle thiết lập sắp xếp hiển thị record trên table kết quả tìm kiếm.
* @param
*   array value: Danh sách Field được chọn hiển thị trên table.
* @return Nothing (Cập nhật setting),
*/
handleDisplayColumnSearchResultsChange = value => {
    setDisplayColumnSearchResults(value);
    this.setState({
        displayColumnSearchResults: value
    });
};

/**
* @desc Handle thiết lập kết quả hiển thị sắp xếp theo bảng chữ cái.
* @param
*   bool value: kiểu sắp xếp.
* @return Nothing (Cập nhật setting: true (sắp xếp tăng), false: sắp xếp giảm),
*/
handleSortTypeChange = value => {
    const searchOptions = this.state.searchOptions;
    searchOptions.sortAscending = value;
    this.setState({
        sortAscending: value,
        searchOptions
    });
};
//#endregion

render() {
    const { Content } = Layout;
    const { language, totalExpirationSoon, totalExpired, key } = this.state;
    //#region kiểm tra quyền truy cập
    const actionView = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.View));
    // Hiển thị thông báo Nếu ko có quyền truy cập.
    if (!actionView) {
        //Hiển thị thông báo Nếu ko có quyền truy cập.
        return <Redirect to='/login' />;
    }
    const actionExport = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.Export));
    //#region giá trị khởi tạo cho component Mps_DisplayColumnResultTable, Mps_ResultTable
    const displaySearchConditions = getDisplaySearchConditions(this.state.displaySearchConditions);
    const displaySortFields = getDisplaySortFields(displaySortFields);
    const displayColumnSearchResults = getDisplayColumnSearchResults(this.state.displayColumnSearchResults);
    const columnResultTable = initResultTable(language, displayColumnSearchResults);
    const columnResultTableExpired = initResultTableExpired(language, displayColumnSearchResults);

    //#endregion

    const headersExport = headers(columnResultTable);

    return (
        <Layout>
            <Mps_HeaderMaster language={this.state.chooseLanguage} />
            <Layout>
                <Mps_SubMenu module="CPATransaction" language={this.state.chooseLanguage} />
                <Layout className='mps-content'>
                    <Mps_Breadcum module="CPATransaction" screen={language.HETHAN} language={this.state.chooseLanguage} />
                    <Content className='mps-sub-content' >
                        <Spin spinning={this.state.isLoading} delay={500}>
                            <Sipco_WaterFilterSearch
                                value={this.state.searchModel}
                                displaySearchConditions={displaySearchConditions}
                                language={this.state.chooseLanguage}
                                onChange={this.handleSearchChange} />

                            <Row>
                                <Col>
                                    <div className='button-area'>
                                        {(actionView) ? <Button type="primary" onClick={this.handleSearchAfterAction}>{language.SEARCH}</Button> : ''}

                                        {(actionView) ? <Sipco_WaterFilterView_Notifical allowAccess={actionView} actionName={language.VIEW} mode={MPS_MODE.ReadOnly}
                                            onhandleSave={this.handleSearchAfterAction} language={this.state.chooseLanguage}
                                            value={this.state.selectedModel} chooseKey = {key} /> : ''}

                                        {(actionExport) ? <Mps_Download value={this.state.dataForDownload}
                                            language={language.DOWNLOADDATA} name={language.SIPCO_WATERFILTER_DATA_FILE}
                                            headers={headersExport} /> : ''}

                                        <Mps_DisplayColumnResultTable columns={columnResultTable}
                                            onDisplaySearchConditionsChange={this.handleDisplaySearchConditionsChange}
                                            onDisplaySortFieldsChange={this.handleDisplaySortFieldsChange}
                                            onDisplayColumnSearchResultsChange={this.handleDisplayColumnSearchResultsChange}
                                            onSortTypeChange={this.handleSortTypeChange}
                                            displaySearchConditions={displaySearchConditions}
                                            displaySortFields={displaySortFields}
                                            displayColumnSearchResults={displayColumnSearchResults}
                                            sortAscending={this.state.searchOptions.sortAscending}
                                            language={this.state.chooseLanguage} />
                                    </div>
                                </Col>
                            </Row>
                        </Spin>
                            <div style={{ margin: '1rem', fontWeight: 'bold' }}>
                                <Row>
                                    <Col span={24}></Col>
                                    <Col span={8}> <span>{language.TOTAL_WATERFILTER_EPIRATION_SOON}:</span> 
                                    <span style={{ color: '#1c21ca'}}> {(totalExpirationSoon === undefined) ? 0 : totalExpirationSoon}</span></Col>
                                    {/* <Col style={{ color: '#1c21ca', marginLeft: '-2%' }} span={2}></Col> */}
                                </Row>
                                <Row>
                                    <Col span={24}></Col>
                                    <Col span={8}> <span>{language.TOTAL_WATERFILTER_EPIRED}:</span> 
                                    <span style={{ color: '#1c21ca'}}> {(totalExpired === undefined) ? 0 :totalExpired}</span></Col>
                                    {/* <Col style={{ color: '#1c21ca', marginLeft: '-5px' }} span={2}>{totalExpired}</Col> */}
                                </Row>
                            </div>
                        <Tabs defaultActiveKey="1" onTabClick={this.handleTabChange}>
                                <TabPane tab={language.WATERFILTER_EPIRATION_SOON} key="1">
                                    <Mps_ResultTable onChange={this.handleSelectRow}
                                        onPagination={this.handlePagination_Epiration_soon}
                                        onShowSizeChange={this.handleShowSizeChange_soon}
                                        messageValid={this.state.commonMessage}
                                        columns={columnResultTable}
                                        dataSource={this.state.dataSource}
                                        key = {key}
                                        current={this.state.page}
                                         />
                                </TabPane>
                                <TabPane tab={language.WATERFILTER_EPIRED} key="2">
                                <Mps_ResultTable onChange={this.handleSelectRow}
                                        onPagination={this.handlePagination}
                                        onShowSizeChange={this.handleShowSizeChange}
                                        messageValid={this.state.commonMessage}
                                        columns={columnResultTableExpired}
                                        dataSource={this.state.dataExpired} 
                                        key = {key}
                                        current={this.state.page}
                                        />
                                </TabPane>
                        </Tabs>
                    </Content>
                </Layout>
            </Layout>
            <Mps_Footer />
        </Layout>
    );
}
}

export { Notifical };


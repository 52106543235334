import moment from 'moment';
const displayDateHourFormat = 'DD-MM-YYYY HH:mm';
const displayDateTimeFormat = 'DD-MM-YYYY HH:mm:ss';
const displayDateFormat = 'DD-MM-YYYY';
const displayMonthFormat = 'MM-YYYY';
const displayYearFormat = 'YYYY-MM-DD';
const displayTimeHourFormat = 'HH:mm:ss';
const displayTimeMinuteFormat = 'HH:mm';
const displayCurrencyFormat = /(\d)(?=(\d{3})+(?!\d))/g;
export const Mps_LayoutFormat = {
    ColLabel: 4,
    ColInput: 8,
    ColCardLabel: 8,
    ColCardInput: 16,
};

export const Mps_LabelFormat = {
    fotmatDateTimeHour,
    formatDateTime,
    formatDate,
    formatMonth,
    formatCurrency,
    formatPercent,
    formatPhoneNumber,
    formatTimeHour,
    formatTimeMinute,
    displayDateFormat,
    displayMonthFormat,
    displayYearFormat,
    displayTimeMinuteFormat,
    displayTimeHourFormat,
    displayDateHourFormat,
    displayDateTimeFormat,
    displayCurrencyFormat
};
function formatTimeHour(value) {
    return (!!value) ? moment(value).format(displayTimeHourFormat) : '';
}
function formatTimeMinute(value) {
    return (!!value) ? moment(value).format(displayTimeMinuteFormat) : '';
}
function fotmatDateTimeHour(value) {
    return (!!value) ? moment(value).format(displayDateHourFormat) : '';
}
function formatDateTime(value) {
    return (!!value) ? moment(value).format(displayDateTimeFormat) : '';
}
function formatDate(value) {
    return (!!value) ? moment(value).format(displayDateFormat) : '';
}
function formatMonth(value) {
    return (!!value) ? moment(value).format(displayMonthFormat) : '';
}
function formatPercent(value) {
    let amount = !!value ? (value) : 0;
    return amount + '%';
}
function formatCurrency(value, delimiter, currency) {
    let amount = !!value ? value.toString().replace(displayCurrencyFormat, (!!delimiter ? `$1${delimiter}` : "$1,")) : 0;
    return !!currency ? `${amount} ${currency}` : amount;
}
function formatPhoneNumber(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '-');
}

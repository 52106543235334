import React from 'react';
import './index.css';
import {App} from './authenticate/App/App';
//import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ReactDOM,{ render } from 'react-dom';

ReactDOM.render(<App />, document.getElementById('root'));

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Layout, Row, Col, Icon, Button } from 'antd';
import jsonQuery from 'json-query';
import { menuService, authenticationService } from '../Services';
import { LANGUAGE_STATE as LANGUAGE_STATE_TEXT_MESS } from '../Layout/Language/ln';
import {
    getPageSize
} from '../../components/Mps_Control';
import { MPS_LANGUAGE_STATE, Mps_MethodCommon, MPS_SCREEN_NO, MPS_SCREEN_ACTION } from '../../commons';
import moment from 'moment';
import { Notify } from '../../components/Notifical/Bell'
// import { history } from '../../../src/authenticate/Helpers';
import { Sipco_WaterFilter_Service } from '../../components/Sipco_WaterFilter/Service/Sipco_WaterFilter.Service'
import { Sipco_Parameter_Service } from '../../components/Sipco_Parameter/Service/Sipco_Parameter.Service'
import { searchModel,getDisplaySortFields } from '../../components/Sipco_WaterFilter/Models/Sipco_WaterFilter.Model'
// import { Log_Service } from '../../authenticate/Helpers/Log.Service';
// import { Mps_ErrorPermission } from '../../components/Mps_Control'

function getURLChildCurrentPage() {
    let url = window.location.href;
    url = url.split('#').pop().split('?').pop();
    const page = url.substring(url.lastIndexOf('/') + 1);
    return page;
}


class Mps_HeaderMaster extends Component {
    constructor(props) {
        super(props);
        const URLpage = getURLChildCurrentPage();
        const UserInfor = authenticationService.getUserInfor();
        const userModule = (!!UserInfor) ? authenticationService.getUserInfor()[1].ModuleAllow : [];
        const ModuleScreen = this.getModuleURL(userModule, URLpage);
        const chooseLanguage = menuService.getLanguage();
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE_TEXT_MESS }).value;
        this.state = {
            language: language,
            isOpen: true,
            dataSource: [],
            ModuleScreen,
            searchModel,
            data_notify: {
                date: "",
                Todo_Task: []
            },
            searchOptions: {
                page: 1,
                limit: getPageSize(),
                language: chooseLanguage,
                sort: getDisplaySortFields(),
                sortAscending: true
            },
            Notifical: '',
            Notify_Before: 20,
            Expire_Duration: 3
        };
    }

    componentWillMount() {
        const today = [moment(Date.now()), moment(Date.now())]
        var searchObjectModel = this.state.searchModel;
        var searchOptions = this.state.searchOptions;
        Promise.all([Sipco_Parameter_Service.search(searchObjectModel, searchOptions)]).then((result) => {
            var data = jsonQuery('[**]result[*]docs', { data: result }).value
            const Expire_Duration = data[0].Expire_Duration
            const Notify_Before = data[0].Notify_Before
            searchObjectModel.Notifical = today
            searchObjectModel.Expire_Duration = Expire_Duration
            searchObjectModel.Notify_Before = Notify_Before
            Promise.all([Sipco_WaterFilter_Service.search1(searchObjectModel, searchOptions)]).then(result => {
                const data = jsonQuery('result', { data: result }).value;
                /*demo mẫu logger*/
                //Log_Service.create("Search", data);
                this.setState({
                    Notifical: data[0].total,
                });
            }).catch(reason => {
                console.log("reason",reason);
                // Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
                this.setState({
                    isLoading: false,

                });
            });
        })
        // searchObjectModel.Notifical = today
        // Promise.all([Sipco_WaterFilter_Service.searchWaterFilterExpired(searchObjectModel, searchOptions)]).then((result) => {
        //     var data = jsonQuery('result', { data: result }).value;
        //     /*demo mẫu logger*/
        //     Log_Service.create("Search", data);
        //     this.setState({
        //         Notifical: data[0].docs,
        //     });
        // }).catch(reason => {
        //     // Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
        //     this.setState({
        //         isLoading: false,

        //     });
        // });
    }

    componentDidMount() {
        Promise.all([menuService.show()]).then(result => {

            this.setState({
                dataSource: result
            })
            //this.loadNotify();   
        });
    }


    getModuleURL = (userModule, URLpage) => {
        const foundModuleName = userModule.find(Module => Module.System_Screens.find(Screen => Screen.Screen_Code === URLpage));
        return !!foundModuleName ? foundModuleName.System_Module_Code : URLpage
    };



    render() {
        const screenNo = MPS_SCREEN_NO.Notify;
        const permission = menuService.showPermission(screenNo);
        const actionView = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.View));
        const { Header } = Layout;
        const menuList = this.state.dataSource;
        const chooseLanguage = this.props.language[0];
        const language = jsonQuery([chooseLanguage], { data: MPS_LANGUAGE_STATE }).value;

        const language_todo = menuService.getLanguage();
        var message_notify = []

        if (this.state.data_notify.Todo_Task.length > 0) {
            var text_mess = this.state.language.TEXT_MESS;
            const fullname =
                <p>
                    <Icon type='caret-down' style={{ marginRight: "5px" }} />
                    {text_mess.concat(this.state.data_notify.date.slice(1, -1))}
                </p>
            message_notify.push(fullname)
        }
        if (!!this.props.isUpdate_Status_Notify && this.props.isUpdate_Status_Notify === true) {
            this.loadNotify()
        }
        return (
            <Header className='mps-header' >
                <div >
                    <Row>
                        {/* Vùng hiển thị thông tin logo */}
                        <Col span={1}></Col>
                        <Col span={22}>
                            <Menu className='mps-header-menu'
                                mode="horizontal"
                                onClick={this.handleMenuClick}
                                defaultSelectedKeys={[this.state.ModuleScreen]}
                                overflowedIndicator={<Icon
                                    style={{ fontSize: 30, color: 'white', right: '0', top: '20px' }} type="menu" />}
                            >
                                {
                                    Object.keys(menuList).map(function (key) {
                                        const navModel = menuList[key];
                                        const menulistv1 = navModel.map((navChild) =>

                                            <Menu.Item key={navChild.System_Module_Code} className={Mps_MethodCommon.getDisplayTheme()}>
                                                <Link to={{ pathname: `/${navChild.System_Module_Name}` }}>
                                                    {jsonQuery(['[?]', navChild.System_Module_Name], { data: language }).value}
                                                </Link>
                                            </Menu.Item>
                                        );
                                        return menulistv1;
                                    })
                                }
                            </Menu>

                        </Col>
                        {(actionView) ?
                            <Col span={1}>
                                <Notify
                                    language={this.state.language}
                                    Notifical={this.state.Notifical}
                                />
                            </Col> : ''}
                    </Row>
                </div>
            </Header>
        );
    }
}

export { Mps_HeaderMaster };

export const LANGUAGE_STATE ={
    'vn':
        {
            TITLE_HELLO: 'Ngày mới vui vẻ!',
            TITLE_TODAY: 'Hôm nay',
            TITLE_THIS_WEEK: 'Tuần này',
            TITLE_LAST_WEEK: 'Tuần trước',
            TITLE_THIS_MONTH: 'Tháng này',
            TITLE_LAST_MONTH: 'Tháng trước',
           
        },
    'en':
        {
            TITLE_HELLO: 'Have a nice day!',
            TITLE: 'Display options',
            TITLE_TODAY: 'Today',
            TITLE_THIS_WEEK: 'This week',
            TITLE_LAST_WEEK: 'Last week',
            TITLE_THIS_MONTH: 'This month',
            TITLE_LAST_MONTH: 'Last month',
        }    
    }

import React, {Component} from 'react';
import ReactExport from 'react-data-export';
import { LANGUAGE_STATE } from './Language/ln';
import {  MPS_RESULTS }  from '../../../commons';
import jsonQuery from 'json-query';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let multiDataSet = [];
let note = [];
const color = {
    red:'FFBB0000',
    orange: 'FFFF6600',
    brown: 'FF993300'
}

class Mps_ImportError extends Component {

    constructor(props) {
        super(props);
        const chooseLanguage = this.props.chooseLanguage;
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
        const data = this.props.data;
        const column = this.props.column;
        this.state = {
            language,
            data,
            column
        };
        this.setNote();
    }

    setNote() {
        const columns = this.state.language.NOTE_COLUMN.map(m => {return ({title: m, width: {wpx: m.length*10}})});
        const data = this.state.language.ERROR_NAME.map(m =>{
            return([
                {value: '', style: {fill: {patternType: "solid", fgColor: {rgb: color[m.color]}}}},
                {value: m.value}              
            ])
        })
        note = [{columns, data}];
    }

    setMultiData() {
        if(this.props.data.length > 0){
        const columns = this.props.column.map(m => { return ({title: m, width: {wpx: m.length*10}})});
        const dataSheet= [];
        this.props.data.forEach(d => {
            const row = [];
            this.props.column.forEach(c => {
                let value, style;
                if(d[c] === undefined || d[c] === null || d[c] === 'undefined'){
                    value = '';
                    style = {};
                }
                else if(d[c].value === undefined && d[c].status === undefined){
                    value = d[c];
                    style = {};
                }
                else{
                    if(d[c].value === undefined || !d[c].value || d[c].value === 'undefined'){
                        value = '';
                    }
                    else{
                        value = d[c].value;
                    }
                    switch (d[c].status) {
                        case MPS_RESULTS.DATA_EXIST :
                            style = {font: { color: {rgb: color.red}}};
                            break;
                        case MPS_RESULTS.DATA_NOT_EXIST:
                            style = {font: {color: {rgb: color.orange}}};
                            break;
                        case MPS_RESULTS.CLEAR:
                            value = this.state.language.NO_VALUE;
                            style = {font: {color: {rgb: color.brown}}};
                            break;
                        case MPS_RESULTS.DATE_NOT_CORRECT:
                        case MPS_RESULTS.LOGIC_REPEATTIME_WITH_STARTDATE_ENDDATE:
                        default:
                            style = {font: {color: {rgb: color.red}}};
                    }
                }

                row.push({ value , style });
            })
            dataSheet.push(row);
        })
        multiDataSet = [{columns: [], data: []},{ columns, data: dataSheet }];
    }
    }


    render() {
        this.setMultiData();
        return (
            <ExcelFile filename={this.state.language.FILE_NAME} element={<a>{this.state.language.DOWNLOAD_ERROR_IMPORT}</a>}>
                <ExcelSheet dataSet={multiDataSet} name={this.state.language.SHEET_NAME_ERROR}/>
                <ExcelSheet dataSet={note} name={this.state.language.NOTE}/>
            </ExcelFile>
        );
    }
}
export { Mps_ImportError };
import React, { Component } from 'react';

import { Layout } from 'antd';

class Mps_Footer extends Component {
   
    render() 
    {   
        const { Footer } = Layout;
        return (    
           <Footer style={{ textAlign: 'center' }}>Company Name Co.Ltd @2019 ver 1.0.0 </Footer>    
    );
  }
}

export { Mps_Footer };

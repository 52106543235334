import React, { Component } from 'react';
import {
    Form, Row, Col, Card, Collapse, Input, Checkbox
} from 'antd';
import jsonQuery from 'json-query';
import {
    Mps_LayoutFormat
    , Mps_InputRangePicker
} from '../Mps_Control';
import { MPS_SYSTEM_PARAMETER_TYPE, Mps_MethodCommon } from '../../commons';
import { System_User_SelectMultiple } from '../System_User/Controls/System_User_SelectMultiple';
import { LANGUAGE_STATE } from './Language/ln';
import { Sipco_Parameter_AutoComplete } from './Controls/Sipco_Parameter_AutoComplete';
import { Mps_SelectMultipleParameterSystem } from '../Mps_Control/Mps_SelectMultipleParameterSystem/Mps_SelectMultipleParameterSystem';
import logger from '../../commons/Mps_Logger';
const { Panel } = Collapse;
class Sipco_ParameterSearch extends Component {
    /**
    * @desc Hàm khởi tạo component
    * @param
    *   props - properties của component.
    * @return Nothing
    */
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
        this.state = {
            language,
            chooseLanguage,
        };
    }
    /**
     * @desc Handle thông tin lỗi
     * @param
     *   Nothing: nothing
     * @return Nothing
     */
    componentDidCatch(error, info) {
        // log the error to loggly
        //logger.push({ error, info });
    }
    /**
    * @desc Handle sự kiện textbox Code thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (Code được cập nhật giá trị mới).
    */
    handleCodeChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Parameter_Code = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện textbox Name thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (Name được cập nhật giá trị mới).
    */
    handleNameChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Sipco_Parameter_Name = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    handleSMS_FlagChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.SMS_Flag = value.target.checked;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    handleSMSContentChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.SMS_Content = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    handleEmail_FlagChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Email_Flag = value.target.checked;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    handleEmailContentChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Email_Content = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện textbox Description thay đổi
    * @param
    *  Object e - sự kiện thay đổi.
    * @return Nothing (Description được cập nhật giá trị mới).
    */
    handleDescriptionChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Description = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
     * @desc Handle sự kiện select Status thay đổi
     * @param
     *  String alue - Giá trị mới.
     * @return Nothing (Status được cập nhật giá trị mới).
     */
    handleStatusChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Status = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện select CreatedBy thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedBy được cập nhật giá trị mới).
    */
    handleCreatedByChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.CreatedBy = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
    handleCreatedDateChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.CreatedDate = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện select UpdatedBy thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (UpdatedBy được cập nhật giá trị mới).
    */
    handleUpdatedByChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.UpdatedBy = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện select UpdatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (UpdatedDate được cập nhật giá trị mới).
    */
     handleExpireChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Expire_Duration = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    handleUpdatedDateChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.UpdatedDate = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 22 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 22 },
            },
        };
        const gridStyle = {
            width: '50%',
        };
        const searchModel = this.props.value;
        const { chooseLanguage, language } = this.state;
        const labelFieldName = language.MODULENAMET_SCREENT_CODE;
        const displaySearchConditions = this.props.displaySearchConditions;

        return (
            <Form  {...formItemLayout} >
                <Collapse
                    defaultActiveKey={['1']}
                    expandIconPosition={'right'}
                    className='mps-search-header-collapse'
                >
                    <Panel header={<span className='mps-search-header-panel-title'>  {language.SEARCH_INFOR}</span>} key="1" >
                        <Card style={{ border: 'none' }} >
                            <Sipco_Parameter_AutoComplete placeholder={labelFieldName}
                                className={Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'Parameter_Code')}
                                value={searchModel.Parameter_Code}
                                language={chooseLanguage}
                                style={gridStyle}
                                onChange={this.handleCodeChange} />



                            <Card.Grid style={gridStyle}
                                className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'Expire_Duration')}`}>
                                <Row>
                                    <Col span={Mps_LayoutFormat.ColCardLabel} >
                                        <Form.Item >
                                            {language.EXPIRE_DURATION}
                                        </Form.Item>
                                    </Col>
                                    <Col span={Mps_LayoutFormat.ColCardInput}>
                                        <Form.Item>
                                            <Input onChange={this.handleExpireChange}
                                                value={searchModel.Expire_Duration}
                                                allowClear
                                                id='Expire_Duration'
                                                name='Expire_Duration' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}
                                className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'SMS_Flag')}`}>
                                <Row>
                                    <Col span={Mps_LayoutFormat.ColCardLabel} >
                                        <Form.Item >
                                            {language.SMS_FLAG}
                                        </Form.Item>
                                    </Col>
                                    <Col span={Mps_LayoutFormat.ColCardInput}>
                                        <Form.Item>
                                            <Checkbox style={{ fontWeight: 'bold' }}
                                                checked={(searchModel.SMS_Flag === true) ? searchModel.SMS_Flag : false}
                                                id='SMS_Flag'
                                                onChange={this.handleSMS_FlagChange} >
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}
                                className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'SMS_Content')}`}>
                                <Row>
                                    <Col span={Mps_LayoutFormat.ColCardLabel} >
                                        <Form.Item >
                                            {language.SMS_CONTENT}
                                        </Form.Item>
                                    </Col>
                                    <Col span={Mps_LayoutFormat.ColCardInput}>
                                        <Form.Item>
                                            <Input onChange={this.handleSMSContentChange}
                                                value={searchModel.SMS_Content}
                                                allowClear
                                                id='SMS_Content'
                                                name='SMS_Content' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}
                                className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'Email_Flag')}`}>
                                <Row>
                                    <Col span={Mps_LayoutFormat.ColCardLabel} >
                                        <Form.Item >
                                            {language.EMAIL_FLAG}
                                        </Form.Item>
                                    </Col>
                                    <Col span={Mps_LayoutFormat.ColCardInput}>
                                        <Form.Item>
                                            <Checkbox style={{ fontWeight: 'bold' }}
                                                checked={(searchModel.Email_Flag === true) ? searchModel.Email_Flag : false}
                                                id='Email_Flag'
                                                onChange={this.handleEmail_FlagChange} >
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}
                                className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'Email_Content')}`}>
                                <Row>
                                    <Col span={Mps_LayoutFormat.ColCardLabel} >
                                        <Form.Item >
                                            {language.EMAIL_CONTENT}
                                        </Form.Item>
                                    </Col>
                                    <Col span={Mps_LayoutFormat.ColCardInput}>
                                        <Form.Item>
                                            <Input onChange={this.handleEmailContentChange}
                                                value={searchModel.Email_Content}
                                                allowClear
                                                id='Email_Content'
                                                name='Email_Content' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}
                                className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'Description')}`}>
                                <Row>
                                    <Col span={Mps_LayoutFormat.ColCardLabel} >
                                        <Form.Item >
                                            {language.DESCRIPTION}
                                        </Form.Item>
                                    </Col>
                                    <Col span={Mps_LayoutFormat.ColCardInput}>
                                        <Form.Item>
                                            <Input onChange={this.handleDescriptionChange}
                                                value={searchModel.Description}
                                                allowClear
                                                id='Description'
                                                name='Description' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card.Grid>

                            <Card.Grid style={gridStyle}
                                className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'Status')}`}>
                                <Row>
                                    <Col span={Mps_LayoutFormat.ColCardLabel} >
                                        <Form.Item > {language.STATUS} </Form.Item>
                                    </Col>
                                    <Col span={Mps_LayoutFormat.ColCardInput}>
                                        <Mps_SelectMultipleParameterSystem type={MPS_SYSTEM_PARAMETER_TYPE.Status}
                                            allowClear
                                            value={searchModel.Status} onChange={this.handleStatusChange} />
                                    </Col>
                                </Row>
                            </Card.Grid>

                            <System_User_SelectMultiple
                                label={language.CREATED_BY}
                                className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'CreatedBy')}`}
                                onChange={this.handleCreatedByChange}
                                value={searchModel.CreatedBy}
                                style={gridStyle} />
                            <Card.Grid style={gridStyle}
                                className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'CreatedDate')}`}>
                                <Row>
                                    <Col span={Mps_LayoutFormat.ColCardLabel} >
                                        <Form.Item > {language.CREATED_DATE} </Form.Item>
                                    </Col>
                                    <Col span={Mps_LayoutFormat.ColCardInput}>
                                        <Mps_InputRangePicker onChange={this.handleCreatedDateChange}
                                            value={searchModel.CreatedDate} />
                                    </Col>
                                </Row>
                            </Card.Grid>

                            <System_User_SelectMultiple
                                label={language.UPDATED_BY}
                                className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'UpdatedBy')}`}
                                onChange={this.handleUpdatedByChange}
                                value={searchModel.UpdatedBy}
                                style={gridStyle} />

                            <Card.Grid style={gridStyle}
                                className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'UpdatedDate')}`}>
                                <Row>
                                    <Col span={Mps_LayoutFormat.ColCardLabel} >
                                        <Form.Item > {language.UPDATED_DATE} </Form.Item>
                                    </Col>
                                    <Col span={Mps_LayoutFormat.ColCardInput}>
                                        <Mps_InputRangePicker onChange={this.handleUpdatedDateChange}
                                            value={searchModel.UpdatedDate} />
                                    </Col>
                                </Row>
                            </Card.Grid>
                        </Card>
                    </Panel>
                </Collapse>
            </Form>
        );
    }
}

const WrappedSipco_ParameterSearch = Form.create({ name: 'Sipco_ParameterSearch' })(Sipco_ParameterSearch);
export { WrappedSipco_ParameterSearch as Sipco_ParameterSearch };

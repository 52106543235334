import React, { Component } from 'react';
import jsonQuery from 'json-query';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { LANGUAGE_STATE } from './Language/ln';
import { menuService } from '../../../authenticate/Services';

class Mps_ErrorPermission extends Component {
    constructor(props) {

        super(props);
        const chooseLanguage = menuService.getLanguage().length != 0 ? menuService.getLanguage() : ["en"];
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;

        this.state = {
            language
        };
    }
    render() {
        return (
            <Result
            status="403"
            title= '403'
            subTitle= {this.state.language.ERROR_403} 
            extra={<Link to="/"><Button type="primary">{this.state.language.BACK_TO_HOME}</Button></Link>}
        />
        );
    }
}
export { Mps_ErrorPermission };

import React, {Component} from 'react';
import ReactExport from 'react-data-export';
import { LANGUAGE_STATE } from './Language/ln';
import jsonQuery from 'json-query';
import { System_Parameter_Service } from '../../System_Parameter/Service/System_Parameter.Service';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let multiDataSet = [];
const color = {
    red:'FFBB0000',
    orange: 'FFFF6600',
    white: 'FFFFFFFF',
    brown: 'FF993300'
};

class Mps_ImportExample extends Component {

    constructor(props) {
        super(props);
        const chooseLanguage = this.props.chooseLanguage;
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
        this.state = {
            language,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setNote();
        }
    }
    
    componentDidMount() {
        this.setNote();  
    }

    setNote(){
        const headers = this.props.headers;
        const parameterHeader = [];
        const parameters = [];
        const note = JSON.parse(JSON.stringify(this.state.language.NOTE_COLUMN));
        note[0].data[0][0].style = {fill: {patternType: "solid", fgColor: {rgb: color[note[0].data[0][0].style]}}};
        note[0].data[1][0].style = {fill: {patternType: "solid", fgColor: {rgb: color[note[0].data[1][0].style]}}};
        note[0].data[2][0].style = {fill: {patternType: "solid", fgColor: {rgb: color[note[0].data[2][0].style]}}};
        headers.forEach(header => {
            if(header.type !== null){
                parameterHeader.push(header);
                const searchModel = {};
                searchModel.System_Parameter_Type = header.type;
                parameters.push(System_Parameter_Service.getDataFilterControl(searchModel));
            }
        })
        Promise.all(parameters).then(results => {
            for(let i = 0; i < parameterHeader.length; i++){
                const title = this.props.chooseLanguage[0] === 'vn' ? this.state.language.CODE + parameterHeader[i].label : parameterHeader[i].label + this.state.language.CODE;
                const col = {title, width: {wpx: title.length*10}};
                const columns = [{title: parameterHeader[i].label , width: {wpx: parameterHeader[i].label.length*10}}, col];
                const data = [];
                var dataSource = jsonQuery('docs[**]', { data: results[i].result }).value;
                dataSource.forEach(d => {
                    data.push([d.System_Parameter_Name, d.System_Parameter_Code]);
                });
                data.push(['','']);
                note.push({columns, data});
            }
            this.setState({
                note
            });
        })
    }

    setMultiData() {
        const headers = this.props.headers;
        if(this.props.headers.length > 0){
            const columns = [];
            const row = [];
            headers.forEach(header =>{
                columns.push({
                    title: header.label + this.state.language.REQUIRE[header.require], 
                    width: {wpx: header.label.length*10},
                    style: {
                        fill: {patternType: "solid", fgColor: {rgb: color[this.state.language.COLOR[header.require]]}},
                        border: {bottom: {style: "thin", color: {rgb: 'FF000000'}}, right: {style: "thin", color: {rgb: 'FF000000'}}},
                        alignment: { vertical: "top" },
                    }
                });
                row.push({
                    value: header.key, 
                    style: {
                        border: {bottom: { style: "medium", color: {rgb: 'FF000000'} }, top: { style: "thin", color: {rgb: 'FF000000'} }},
                        fill: {patternType: "solid", fgColor: {rgb: color[this.state.language.COLOR[header.require]]}}
                }});
            })
            multiDataSet = [{columns, data: [row]}];
        }
    }


    render() {
        this.setMultiData();
        return (
            <div>
                <ExcelFile filename={this.props.label} element={<span>{this.props.language}</span>}>
                    <ExcelSheet dataSet={multiDataSet} name={this.state.language.SHEET_NAME}/>
                    {this.state.note !== undefined ? <ExcelSheet dataSet={this.state.note} name={this.state.language.NOTE}/> : ''}
                </ExcelFile>
            </div>
        );
    }
}
export { Mps_ImportExample };
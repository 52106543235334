import React from 'react';
import jsonQuery from 'json-query';
import {
  Radio, Form
} from 'antd';
import {System_Parameter_Service} from '../../System_Parameter/Service/System_Parameter.Service';

class Mps_RadioParameterSystem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {            
          dataSource:[],
        }; 
    }

    componentWillMount()
    {     
      const searchModel = {}
      searchModel.System_Parameter_Type = this.props.type;
      Promise.all([System_Parameter_Service.getDataFilterControl(searchModel)]).then((result) => {   
        var data = jsonQuery('docs[**]', {data:result[0].result}).value;      
          this.setState({
            dataSource:data,
          });
      }); 
    }

    onChange = (e) =>{
      this.props.onChange(e);  
    }
    
    render() {     
      const dataSource= jsonQuery('[**]', {data: this.state.dataSource}).value;        
      return (
        <Form.Item>
            <Radio.Group disabled={(!!this.props.disabled)? this.props.disabled : false} name={this.props.name} onChange={this.onChange} value={this.props.value}>
                 
                   {dataSource.map((radio) => <Radio  key={radio.System_Parameter_Code} value={radio.System_Parameter_Code} >{radio.System_Parameter_Name}</Radio>) }
            </Radio.Group>
        </Form.Item>
      );
    }
}

export {  Mps_RadioParameterSystem };

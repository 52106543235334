import React from 'react';
import jsonQuery from 'json-query';
import {
    Form, Row, Col,
    Checkbox, Card,
} from 'antd';
import { Mps_Tooltip } from '../index';
import { LANGUAGE_STATE } from './ln';
import './Mps_RadioMultipleDay.css';

const CheckboxGroup = Checkbox.Group;

class Mps_RadioMultipleDay extends React.Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
        this.state = {
            language,
            options: [
                { label: language.SUNDAY, value: 0 },
                { label: language.MONDAY, value: 1 },
                { label: language.TUESDAY, value: 2 },
                { label: language.WEDNESDAY, value: 3 },
                { label: language.THURSDAY, value: 4 },
                { label: language.FRIDAY, value: 5 },
                { label: language.SATURDAY, value: 6 },

            ]
        };
    }

    onChange = value => {
        value.sort();
        this.props.onChange(value);
    };

    getName = name => {
        if (!!name) {
            return name;
        } else {
            return '';
        }
    };

    getDisabled = disabled => {
        if (!!disabled) {
            return disabled;
        } else {
            return false;
        }
    };

    getPlaceHolder = placeholder => {
        if (!!placeholder) {
            return placeholder;
        } else {
            return '';
        }
    };

    render() {
        const chooseLanguage = this.props.language;
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
        const gridStyle = this.props.style;
        const getFieldDecoratorName = this.getName(this.props.name);
        return (
            <Card.Grid style={gridStyle}
                className={`edit-hasFeedback mps-card-items ${this.props.className}`}>
                <Row>
                    <Col span={4} >
                        <Form.Item>
                            {(!!this.props.label) ? this.props.label : language.CLASS}
                        </Form.Item>
                    </Col>
                    <Col span={20}>
                        <Form.Item
                            help={
                                (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
                            }
                            hasFeedback
                        >
                            {(!!this.props.getFieldDecorator) ?
                                (this.props.getFieldDecorator(getFieldDecoratorName, {
                                    initialValue: this.props.value ? this.props.value : null,
                                    rules: [
                                        {
                                            required: (!!this.props.required) ? this.props.required : false,
                                            message: this.props.message,
                                        },
                                    ],
                                })(
                                    <CheckboxGroup
                                        disabled={!!this.props.disabled ? this.props.disabled : false}
                                        options={this.state.options}
                                        onChange={this.onChange}
                                    />
                                )) : (
                                    <CheckboxGroup
                                        disabled={!!this.props.disabled ? this.props.disabled : false}
                                        options={this.state.options}
                                        value={this.props.value}
                                        onChange={this.onChange}
                                    />
                                )}
                        </Form.Item>
                    </Col>
                </Row>
            </Card.Grid>
        );
    }
}

export { Mps_RadioMultipleDay };


import React from 'react';
export const LANGUAGE_STATE = {
    'vn':
    {
        REPORT_ADDRESS: 'Địa chỉ: ',
        REPORT_TEL: 'Số điện thoại: ',
        REPORT_FAX: 'Fax: ',
        REPORT_CREATED_DATE: 'Ngày lập: ',
        REPORT_DATE: 'Thời gian: ',
       
    },
    'en':
    {
        REPORT_ADDRESS: 'Address: ',
        REPORT_TEL: 'Tel: ',
        REPORT_FAX: 'Fax: ',
        REPORT_CREATED_DATE: 'Created date: ',
        REPORT_DATE: 'Time: ',
        
    }
};


import React, { Component } from 'react';
import jsonQuery from 'json-query';
import { System_Company_Service } from '../../System_Company/Service/System_Company.Service';
import { initModel } from '../../System_Company/Models/System_Company.Model';
import { Mps_LabelFormat } from '../../Mps_Control/Mps_LabelFormat/Mps_LabelFormat';
import { LANGUAGE_STATE } from './Language/ln';
import { authenticationService, menuService } from '../../../authenticate/Services';
import moment from 'moment';

class Mps_HeaderInfor extends Component {
    constructor(props) {
        super(props);
        const chooseLanguage = (!!menuService.getLanguage() && !!menuService.getLanguage().lenght > 0) ? menuService.getLanguage() : ['vn'];
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;

        this.state = {
            companyModel: initModel,
            language
        };
    }

    componentDidMount() {
        const userInfo = authenticationService.getUserInfor();
        const user = jsonQuery('[**]User[0]', { data: userInfo }).value;
        const UniqueCode = user.System_UniqueCode;
        Promise.all([System_Company_Service.getByCode(UniqueCode)]).then(result => {
            const datasource = jsonQuery('result[0]', { data: result }).value;
            this.setState({
                companyModel: datasource
            });
        });
    }

    render() {
        const companyModel = this.state.companyModel;
        var address =[] ;
        if(!!companyModel.Address){
            address.push(companyModel.Address) 
        }
        if(!!companyModel.Ward){
            address.push(companyModel.Ward) 
        }
        if(!!companyModel.District){
            address.push(companyModel.District) 
        }
        if(!!companyModel.City){
            address.push(companyModel.City) 
        }
        return (
            <div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                    <div>
                        {
                            (!!companyModel.System_Company_Logo) ?
                                <img 
                                    height='65px'
                                    src={!!(companyModel.System_Company_Logo) ?
                                        (companyModel.System_Company_Logo[0] ? companyModel.System_Company_Logo[0].thumbUrl
                                            : initModel.System_Company_Logo[0].thumbUrl)
                                        : initModel.System_Company_Logo[0].thumbUrl
                                    } />
                                : <div></div>
                        }
                    </div>
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <div style={{ margin: 'auto', textAlign: 'center', fontSize: '16pt' }}><b>
                            {!!companyModel.System_Company_Name ? companyModel.System_Company_Name : ''}</b></div>
                        
                        <div >
                            {this.state.language.REPORT_ADDRESS  + address.join (', ')}
                        </div>

                        <div>
                            {this.state.language.REPORT_TEL + `${Mps_LabelFormat.formatPhoneNumber(
                                !!companyModel.System_Company_PhoneNumber ? companyModel.System_Company_PhoneNumber : '')}`}
                                    &nbsp;&nbsp;&nbsp;
                            {this.state.language.REPORT_FAX + `${Mps_LabelFormat.formatPhoneNumber(!!companyModel.System_Company_Fax ? companyModel.System_Company_Fax : '')}`}
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: '30px', marginTop: '30px', textAlign: 'center', fontSize: '16pt' }} >
                    <b>{this.props.label}</b>
                </div>
                <br />
                <div className='mps-font-size'>
                    <div ><b>{this.state.language.REPORT_CREATED_DATE}</b>{Mps_LabelFormat.formatDate(moment(Date.now()))}</div>
                    <div><b>{this.state.language.REPORT_DATE}</b>{!!this.props.changeDate ? this.props.changeDate : ''}</div>
                </div>
                <br />
            </div>
        );
    }
}

export { Mps_HeaderInfor };

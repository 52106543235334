export const MESSAGE_STATE = {
    'vn':
    {
        END_DATE_WRONG:'Ngày kết thúc cấp phát không được nhỏ hơn ngày bắt đầu cấp phát.'
    },
    'en':
    {
        END_DATE_WRONG:'The Distribution end date should not be less than the Distribution start date.',
        CAN_NOT_DELETE: 'Can not delete active CPA code'
    }
};
import React from 'react';
import jsonQuery from 'json-query';

import {
  Input, Select, Row, Col, Card,
  Form
} from 'antd';
import {
  Mps_LayoutFormat, Mps_Tooltip
} from '../../Mps_Control';
import { Mps_MethodCommon } from '../../../commons';
import { Address_Service } from './Service/Mps_GroupAddress.Service';
import { LANGUAGE_STATE } from './language/ln';


class Mps_GroupAddress extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      city: [],
      addressModel: {
        City: '',
        Address: '',
        District: '',
        Ward: ''
      },
      district: [],
      ward: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const addressModel = this.props.value;
      this.setState({
        addressModel
      })
      Promise.all([Address_Service.getCity()]).then(result => {
        if(result[0] === undefined){
          return;
        }
        const city = jsonQuery('LtsItem[**]', { data: result }).value;
        city.sort(function (a, b) {
          if (a.Title < b.Title) { return -1; }
          if (a.Title > b.Title) { return 1; }
          return 0;
        });
        this.setState({ city });
        if (!addressModel.City) {
          return;
        }
        const cityID = jsonQuery(['[Title=?][ID]', addressModel.City], { data: this.state.city }).value;
        if (!!cityID && cityID != null) {
          Promise.all([Address_Service.getDistrict(cityID)]).then(result1 => {
            const district = jsonQuery('[**]', { data: result1 }).value;
            district.sort(function (a, b) {
              if (a.Title < b.Title) { return -1; }
              if (a.Title > b.Title) { return 1; }
              return 0;
            });
            this.setState({ district });
            if (!addressModel.District) {
              return;
            }
            const districtSelect = this.state.district.find(m => m.Title === addressModel.District);
            if (!!districtSelect && districtSelect != null) {
              Promise.all([Address_Service.getWard(districtSelect.ID)]).then(result2 => {
                const ward = jsonQuery('[**]', { data: result2 }).value;
                ward.sort(function (a, b) {
                  if (a.Title < b.Title) { return -1; }
                  if (a.Title > b.Title) { return 1; }
                  return 0;
                });
                this.setState({ ward });
              });
            }
          });
        }
      });
    }
  }


  componentDidMount() {
    const addressModel = this.props.value;
    Promise.all([Address_Service.getCity()]).then(cityList => {
      if(cityList[0] === undefined){
        return;
      }
      const city = jsonQuery('LtsItem[**]', { data: cityList }).value;
      city.sort(function (a, b) {
        if (a.Title < b.Title) { return -1; }
        if (a.Title > b.Title) { return 1; }
        return 0;
      });
      this.setState({ city });
      if (!addressModel.City) {
        return;
      }
      const cityID = jsonQuery(['[Title=?][ID]', addressModel.City], { data: this.state.city }).value;
      if (!!cityID && cityID != null) {
        Promise.all([Address_Service.getDistrict(cityID)]).then(districtList => {
          const district = jsonQuery('[**]', { data: districtList }).value;
          district.sort(function (a, b) {
            if (a.Title < b.Title) { return -1; }
            if (a.Title > b.Title) { return 1; }
            return 0;
          });
          this.setState({ district });
          if (!addressModel.District) {
            return;
          }
          const districtSelect = this.state.district.find(m => m.Title === addressModel.District);
          if (!!districtSelect && districtSelect != null) {
            Promise.all([Address_Service.getWard(districtSelect.ID)]).then(wardList => {
              const ward = jsonQuery('[**]', { data: wardList }).value;
              ward.sort(function (a, b) {
                if (a.Title < b.Title) { return -1; }
                if (a.Title > b.Title) { return 1; }
                return 0;
              });
              this.setState({ ward });
            });
          }
        });
      }
    });
  }

  handleCityChange = value => {
    if (value === undefined) {
      const addressModel = this.props.value;
      addressModel.City = '';
      addressModel.District = '';
      addressModel.Ward = '';
      this.setState({
        district: [],
        ward: []
      });
      this.props.onChange(addressModel);
    } else {
      const city = value.split('-');
      if (city.length < 2) {
        const addressModel = this.props.value;
        addressModel.City = '';
        addressModel.District = '';
        addressModel.Ward = '';
        this.setState({
          district: [],
          ward: []
        });
        this.props.onChange(addressModel);
        return;
      }
      const citySelect = jsonQuery(['[ID=?][Title]', city[0]], { data: this.state.city }).value;

      Promise.all([Address_Service.getDistrict(city[0])]).then(result => {
        const district = jsonQuery('[**]', { data: result }).value;
        district.sort(function (a, b) {
          if (a.Title < b.Title) { return -1; }
          if (a.Title > b.Title) { return 1; }
          return 0;
        });
        const addressModel = this.props.value;
        addressModel.City = citySelect;
        addressModel.District = '';
        addressModel.Ward = '';
        this.setState({
          district,
          ward: []
        });
        if (!!this.props.onChange) {
          this.props.onChange(addressModel);
        }
      });
    }
  };

  handleDistrictChange = value => {
    if (value === undefined) {
      const addressModel = this.props.value;
      addressModel.District = '';
      addressModel.Ward = '';
      this.setState({ ward: [] });
      this.props.onChange(addressModel);
    } else {
      const district = value.split('-');
      if (district.length < 2) {
        addressModel = this.props.value;
        addressModel.District = '';
        addressModel.Ward = '';
        this.setState({
          ward: []
        });
        this.props.onChange(addressModel);
        return;
      }
      const districtSelect = this.state.district.find(m => m.Title === district[1]);
      Promise.all([Address_Service.getWard(districtSelect.ID)]).then(result => {
        const ward = jsonQuery('[**]', { data: result }).value;
        ward.sort(function (a, b) {
          if (a.Title < b.Title) { return -1; }
          if (a.Title > b.Title) { return 1; }
          return 0;
        });
        this.setState({
          ward
        });
      });
      let addressModel = this.props.value;
      addressModel.District = districtSelect.Title;
      addressModel.Ward = '';
      this.setState({
        addressModel
      });
      if (!!this.props.onChange) {
        this.props.onChange(addressModel);
      }
    }
  };

  handleWardChange = value => {
    if (value === undefined) {
      var ward = '';
      let addressModel = this.props.value;
      addressModel.Ward = '';
      this.props.onChange(addressModel);
    } else {
      var ward = value.split('-');
      if (ward.length < 2) {
        let addressModel = this.props.value;
        addressModel.Ward = '';
        this.props.onChange(addressModel);
        return;
      }
      const wardSelect = ward[1];

      let addressModel = this.props.value;
      addressModel.Ward = wardSelect;
      if (!!this.props.onChange) {
        this.props.onChange(addressModel);
      }
    }
  };

  onBlur = () => {
    this.props.onChange(this.state.addressModel);
  }

  handleInputChange = event => {
    const addressModel = this.props.value;
    addressModel[event.target.id] = event.target.value;
    this.setState({
      addressModel
    });
  };

  render() {

    const { Option } = Select;
    const chooselanguage = this.props.language;
    const language = jsonQuery([chooselanguage[0]], { data: LANGUAGE_STATE }).value;

    //city
    const { city, district, ward } = this.state;
    const city_DD = city.map((city) => <Option key={city.ID + '-' + city.Title}>{city.Title}</Option>);
    //district
    const district_DD = district.map((district) => <Option key={district.TinhThanhID + '-' + district.Title}>{district.Title}</Option>);
    //ward
    const ward_DD = ward.map((ward) => <Option key={ward.QuanHuyenID + '-' + ward.Title}>{ward.Title}</Option>);

    const gridStyle = this.props.style;
    return (
      <span>
        <Card.Grid style={gridStyle}
          className={'mps-card-items ' + Mps_MethodCommon.checkFieldAvailable(this.props.displaySearchConditions, 'Address')}>
          <Row>
            <Col span={Mps_LayoutFormat.ColCardLabel} >
              <Form.Item>
                {(!!this.props.labelAddress) ? this.props.label : language.ADDRESS}
              </Form.Item>
            </Col>
            <Col span={Mps_LayoutFormat.ColCardInput}>
              <Form.Item
                help={(!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''}
                hasFeedback  >
                <Input disabled={(!!this.props.disabled) ? this.props.disabled : false} id={'Address'}
                  value={this.state.addressModel.Address ? this.state.addressModel.Address : this.props.value.Address}
                  defaultValue={this.props.value.Address} onBlur={this.onBlur}
                  onChange={this.handleInputChange} />
              </Form.Item>
            </Col>
          </Row>
        </Card.Grid>
        <Card.Grid style={gridStyle}
          className={'mps-card-items ' + Mps_MethodCommon.checkFieldAvailable(this.props.displaySearchConditions, 'City')}>
          <Row>
            <Col span={Mps_LayoutFormat.ColCardLabel} >
              <Form.Item>
                {(!!this.props.labelCity) ? this.props.label : language.CITY}
              </Form.Item>
            </Col>
            <Col span={Mps_LayoutFormat.ColCardInput}>
              <Form.Item
                help={(!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''}
                hasFeedback  >
                <Select allowClear={true} disabled={(!!this.props.disabled) ? this.props.disabled : false} value={this.props.value.City}
                  placeholder='Please select'
                  onChange={this.handleCityChange}>
                  <Option key='default'> </Option>
                  {city_DD}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card.Grid>
        <Card.Grid style={gridStyle}
          className={'mps-card-items ' + Mps_MethodCommon.checkFieldAvailable(this.props.displaySearchConditions, 'District')}>
          <Row>
            <Col span={Mps_LayoutFormat.ColCardLabel} >
              <Form.Item>
                {(!!this.props.labelDistrict) ? this.props.label : language.DISTRICT}
              </Form.Item>
            </Col>
            <Col span={Mps_LayoutFormat.ColCardInput}>
              <Form.Item
                help={(!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''}
                hasFeedback  >
                <Select allowClear={true} disabled={(!!this.props.disabled) ? this.props.disabled : false} value={this.props.value.District}
                  placeholder='Please select'
                  onChange={this.handleDistrictChange}>
                  <Option key='default'> </Option>
                  {district_DD}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card.Grid>

        <Card.Grid style={gridStyle}
          className={'mps-card-items ' + Mps_MethodCommon.checkFieldAvailable(this.props.displaySearchConditions, 'Ward')}>
          <Row>
            <Col span={Mps_LayoutFormat.ColCardLabel} >
              <Form.Item>
                {(!!this.props.labelWard) ? this.props.label : language.WARD}
              </Form.Item>
            </Col>
            <Col span={Mps_LayoutFormat.ColCardInput}>
              <Form.Item
                help={(!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''}
                hasFeedback  >
                <Select allowClear={true} disabled={(!!this.props.disabled) ? this.props.disabled : false} value={this.props.value.Ward}
                  placeholder='Please select'
                  onChange={this.handleWardChange}>
                  <Option key='default'> </Option>
                  {ward_DD}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card.Grid>
      </span>
    );
  }
}

export { Mps_GroupAddress };

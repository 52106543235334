import React, { Component } from 'react';
import jsonQuery from 'json-query';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { LANGUAGE_STATE } from './Language/ln';
import { menuService } from '../../../authenticate/Services';

class Mps_ErrorAccountExpired extends Component {
    constructor(props) {

        super(props);
        const chooseLanguage = (!!menuService.getLanguage() && !!menuService.getLanguage().lenght > 0) ? menuService.getLanguage() : ['vn'];
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;

        this.state = {
            language          
        };
    }
    render() {
        return (
            <Result
            status="403"
            title= {this.state.language.ERROR_ACCOUNT_EXPIRED} 
            subTitle= {this.state.language.ERROR_ACCOUNT_EXPIRED_MESAGE }
            extra={
                <div>
                    <Link to="/"><Button type="primary">{this.state.language.LOGIN_WITH_OTHER_ACCOUNT}</Button></Link>
                    <Link style={{marginLeft: 10}} to= {{ pathname : "http://minhphusolutions.vn" }} target="_blank" ><Button type="primary">{this.state.language.CONTACT_US}</Button></Link>
                
                </div>
               
            }
        />
        );
    }
}
export { Mps_ErrorAccountExpired };

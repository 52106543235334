export const LANGUAGE_STATE ={
    'vn':
        {
            PAGE_TITLE: 'Quản lý tham số',
            SIPCO_PARAMETER_SEARCH: 'Quản lý tham số',
            SIPCO_PARAMETER_NEW: 'Thêm mới tham số',
            SIPCO_PARAMETER_COPY: 'Sao chép thông tin tham số',
            SIPCO_PARAMETER_EDIT: 'Chỉnh sửa thông tin tham số',
            SIPCO_PARAMETER_VIEW: 'Xem thông tin tham số',
            SIPCO_PARAMETER_TITLE_GENERAL: 'Thông tin chung',
            SIPCO_PARAMETER_TITLE_ADDITIONAL: 'Thông tin thêm',
            PARAMETER_CODE: 'Mã thông số',
            EXPIRE_DURATION: 'Thông báo trước ngày hết hạn (ngày)',
            SMS_FLAG:'Gởi SMS',
            SMS_CONTENT: 'Nội dung SMS',
            EMAIL_FLAG:'Gởi Email',
            EMAIL_CONTENT:'Nội dung Email',
            DESCRIPTION:'Mô tả',
            STATUS:'Trạng thái',
            CREATED_DATE: 'Ngày tạo',
            CREATED_BY: 'Người tạo',
            UPDATED_DATE: 'Ngày cập nhật',
            UPDATED_BY: 'Người cập nhật',
            SEARCH_INFOR:'Thông tin tìm kiếm',
            SAVE: 'Lưu',
            SAVEANDCLOSE:'Lưu và đóng',
            SAVEANDNEXT: 'Lưu và tiếp tục',
            CANCEL:'Hủy',
            CLOSE:'Đóng',
            SEARCH:'Tìm kiếm',
            NEW: 'Thêm mới',
            VIEW: 'Xem',
            EDIT: 'Chỉnh sửa',
            DELETE: 'Xóa',
            COPY:'Sao chép',
            IMPORT:'Nhập dữ liệu',
            DOWNLOADDATA:'Tải dữ liệu (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import mẫu',
            SIPCO_PARAMETER_DATA_FILE: 'File data Sipco parameter',
        },
    'en':
        {
            PAGE_TITLE: 'Parameter management',
            SIPCO_PARAMETER_SEARCH: 'Alert Setting',
            SIPCO_PARAMETER_NEW: 'Add new Parameter information',
            SIPCO_PARAMETER_COPY: 'Add new Parameter information',
            SIPCO_PARAMETER_EDIT: 'Edit Parameter information',
            SIPCO_PARAMETER_VIEW: 'View Parameter information',
            SIPCO_PARAMETER_TITLE_GENERAL: 'General  information',
            SIPCO_PARAMETER_TITLE_ADDITIONAL: 'Additional information',
            PARAMETER_CODE: 'Parameter Code',
            EXPIRE_DURATION: 'Expire Duration',
            SMS_FLAG:'SMS',
            SMS_CONTENT: 'SMS Content',
            EMAIL_FLAG:'Email',
            EMAIL_CONTENT:'Email Content',
            DESCRIPTION:'Description',
            STATUS:'Status',
            CREATED_DATE: 'Created date',
            CREATED_BY: 'Created by',
            UPDATED_DATE: 'Updated date',
            UPDATED_BY: 'Updated by',
            SEARCH_INFOR:'Search Information',
            SAVE: 'Save',
            SAVEANDCLOSE:'Save and close',
            SAVEANDNEXT: 'Save and continue',
            CANCEL:'Cancel',
            CLOSE:'Close',
            SEARCH:'Search',
            NEW: 'New',
            EDIT: 'Edit',
            VIEW: 'View',
            DELETE: 'Delete',
            COPY:'Copy',
            IMPORT:'Import data',
            DOWNLOADDATA:'Download Data (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import example',
            SIPCO_PARAMETER_DATA_FILE: 'File data Sipco parameter',
        }
    };

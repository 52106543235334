import React from 'react';
export const GUIDE_LINE ={
    'vn':
        {
            CPA_Code: <span>Mã trường thông tin lấy từ CPA</span>,
            Serial_Code: <span>Mã số Serial gắn với CPA</span>,
            Sipco_WaterFilter_Description: <span>Mô tả ý nghĩa của "Mô tả"</span>,
            Status: <span>Mô tả ý nghĩa của "Trạng thái"</span>,
        },
    'en':
        {
            CPA_Code: <span>CPA Cod from CPA master</span>,
            Serial_Code: <span>Serial Code is unique follow CPA Code </span>,
            Sipco_WaterFilter_Description: <span>Describe the meaning of "Description"</span>,
            Status: <span>Describe the meaning of "Status"</span>,
        }

}

import { authHeader } from '../../../authenticate/Helpers';
import { API_URL } from '../../../conf.js';
import { Mps_MethodCommon } from '../../../commons';
import {authenticationService} from '../../../authenticate/Services';
export const System_User_Service = {
    search,
    getById,
    create,
    update,
    updateUserProfile,
    deleteModel,
    getDataFilter,
    importModel,
    getUserProfile,
    changePasswordUser,
    getUserIsAdmin
};
const content = 'Content-Type';
const Type = 'application/json';
//Lấy thông tin chi tiết của System_User theo điều kiện tìm kiếm được nhập.
function search(searchModel, searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };

    return fetch(`${API_URL}` + `/System_User/search`, requestOptions).then(handleResponse);
}

//Lấy thông tin chi tiết của một System_User theo Id.
function getById(_id) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'GET'

    };
    return fetch(`${API_URL}` + `/System_User/getById/${_id}`, requestOptions).then(handleResponse);
}

function getUserProfile(_id) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'GET'

    };
    return fetch(`${API_URL}` + `/System_User/getUserProfile/${_id}`, requestOptions).then(handleResponse);
}
//Lấy dữ liệu hiển thị cho autocomplete.
function getDataFilter(searchModel, page = 1, limit = Mps_MethodCommon.paginationLimitMaximum) {
    const value = {
        searchModel,
        page,
        limit
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(`${API_URL}` + `/System_User/getDataFilter`, requestOptions).then(handleResponse);
}

//Tạo mới một System_User
function create(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(`${API_URL}` + `/System_User/create`, requestOptions).then(handleResponse);
}

//Import data
function importModel(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify({ model })
    };

    return fetch(`${API_URL}` + `/System_User/import`, requestOptions).then(handleResponse);
}

//Cập nhật thông tin một System_User
function update(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };

    return fetch(`${API_URL}` + `/System_User/update`, requestOptions).then(handleResponse);
}

function updateUserProfile(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };

    return fetch(`${API_URL}` + `/System_User/updateUserProfile`, requestOptions).then(handleResponse);
}

//Xóa thông tin một/danh sách System_User
function deleteModel(ids) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify({ ids })
    };
    return fetch(`${API_URL}` + `/System_User/delete`, requestOptions).then(handleResponse);

}


function changePasswordUser(userModel) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify({ userModel })
    };

    return fetch(`${API_URL}` + `/System_User/changePasswordUser`, requestOptions).then(handleResponse);
}

function getUserIsAdmin(searchModel, page = 1, limit = Mps_MethodCommon.paginationLimitMaximum) {
    const value = {
        searchModel,
        page,
        limit
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(`${API_URL}` + `/System_User/getUserIsAdmin`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                authenticationService.logout(); 
                //return response.json();               
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}


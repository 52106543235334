//import config from 'config';
import { React } from 'react';
import { history, authHeader } from '../Helpers';
import { MPS_RESULTS } from '../../commons';
import { API_URL } from '../../conf.js';
import jsonQuery from 'json-query';
import { LoginPage } from '../../authenticate/LoginPage';

export const authenticationService = {
    login,
    logout,
    getUser,
    getAuthorizeUser,
    getUserInfor,
    getCurrentLanguage,
    checkLogout,
    checkInfor,
    verifyUserEmail,
    verifyCodeEmail,
    updatePassword,
};

function login(userName, password, uniqueCode) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userName, password, uniqueCode })
    };

    return fetch(API_URL + `/Authentication/signIn`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.returnCode === MPS_RESULTS.SUCCESS ) {
                // login successful if there's a jwt token in the response
                if (user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user.token));
                }
            }
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('userinfor');
}
function checkLogout() {
    if(!localStorage.getItem('userinfor')) {
        history.push('/login');
        return true;
    }
    return false;
}
function checkInfor() {
    return localStorage.getItem('userinfor') ? true : false;
}
function getUser(model, language) {
    model.language = language
    const requestOptions = {
        headers: { 'Authorization': authHeader(), 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(API_URL + `/Authentication/getAuthorizeUser`, requestOptions).then(handleResponse)
        .then(userinfor => {
            // login successful if there's a jwt token in the response
            if (userinfor) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                if (!!language) {
                    userinfor[0].User.System_User_Language = language;
                }
                localStorage.setItem('userinfor', JSON.stringify(userinfor));
            }
        });

}

async function getAuthorizeUser(model, language) {
    await getUser(model, language);
    return JSON.parse(localStorage.getItem('userinfor'));
}

function getUserInfor() {
    return JSON.parse(localStorage.getItem('userinfor'));
}
function getCurrentLanguage() {
    var module = JSON.parse(localStorage.getItem('userinfor'));
    var languageAvailable = jsonQuery('[**]User[System_User_Language]', { data: module }).value[0];
    return languageAvailable;
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

async function verifyUserEmail(model, language) {
    model.language = language;
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    };
    return fetch(API_URL + `/Authentication/verifyUserEmail`, requestOptions).then(handleResponse)
        .then(result => result);
}

async function verifyCodeEmail(model, _language) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    };
    return fetch(API_URL + `/Authentication/verifyCodeEmail`, requestOptions).then(handleResponse)
        .then(result => result);
}
async function updatePassword(model) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    };
    return fetch(API_URL + `/Authentication/updatePassword`, requestOptions).then(handleResponse)
        .then(result => result);
}
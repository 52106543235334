import React, { Component } from 'react';
import { Mps_HeaderInfor } from './Mps_HeaderInfor';
import { Mps_ReportByList } from './Mps_ReportByList';
import Mps_BarChart from '../Mps_Chart/Mps_BarChart/Mps_BarChart';
import { Mps_LabelFormat } from '../Mps_LabelFormat/Mps_LabelFormat';
import { Text } from 'recharts';


class Mps_Report extends Component {
    tickFormatter = value => {
        return `${value} Đ`;
    };

    xAxisTick = ({ x, y, payload }) => {
        return <Text x={x} y={y + 5} width={100} textAnchor='middle' lineHeight='1rem'
            fontSize={12} fontWeight='bold' verticalAnchor='start'
        >
            {payload.value}
        </Text>;
    };

    toolTipFormatter = value => {
        return `${Mps_LabelFormat.formatCurrency(value)}Đ`;
    };

    render() {
        const { reportType, chartData, xAxisTick, tickFormatter, toolTipFormatter, xAxisDatakey, dataKeys } = this.props;

        let x = 'month';
        let y = 'revenue';
        if (!!this.props.x) {
            x = this.props.x;
        }
        if (!!this.props.y) {
            y = this.props.y;
        }

        const propsBarChart = {
            data: chartData,
            height: 350,
            xAxisDatakey: xAxisDatakey || x,
            xAxisTick: this.xAxisTick,
            dataKeys: dataKeys || [y],
            tickFormatter: this.tickFormatter,
            toolTipFormatter: this.toolTipFormatter,
            hasLegend: false,
        };

        if (!!xAxisTick) {
            propsBarChart.xAxisTick = xAxisTick;
        }

        if (!!tickFormatter) {
            propsBarChart.tickFormatter = tickFormatter;
        }

        if (!!toolTipFormatter) {
            propsBarChart.toolTipFormatter = toolTipFormatter;
        }

        return (
            <div className='mps-layout-report'>
                <Mps_HeaderInfor label={this.props.headerLabel} changeDate={this.props.changeDate} />
                {reportType.typeDisplay === 2 ?
                    <Mps_ReportByList
                        rowKey={this.props.rowKey || 'OrderNo'}
                        dataSource={this.props.dataSource}
                        title={this.props.title}
                        columns={this.props.columns} />
                    :
                    <Mps_BarChart {...propsBarChart} />
                }
            </div>
        );
    }
}

export { Mps_Report };

import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Badge, Popover} from 'antd';
import jsonQuery from 'json-query';
import { LANGUAGE_STATE } from './Language/ln';
import {searchModel,getDisplaySortFields } from '../Sipco_WaterFilter/Models/Sipco_WaterFilter.Model'
import {getPageSize} from '../Mps_Control'
import './Notifical.css'

function renderMenu(ln, cn) {
    return (
        (cn) ?
        <div style={{ borderRight: '0px' }}>
        <h6 style={{display: 'block'}}>{ln.YOUR_NOTIFY}</h6>
            <p>
                {cn}&nbsp;{ln.NOTIFICAL} <Link to={{pathname:`/Notify`}}>&nbsp; {ln.DETAIL} </Link>
            </p>
        </div>:
        <div style={{ borderRight: '0px' }}>
        <h6 style={{display: 'block'}}>{ln.YOUR_NOTIFY}</h6>
        <hr/>
        <p>{ln.NO_NOTIFY}</p>
        </div>
            
    );

        
}


class Notify extends React.Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
        this.state = {
            visible: false,
            language,
            searchModel,
            searchOptions: {
                page: 1,
                limit: getPageSize(),
                language: chooseLanguage,
                sort: getDisplaySortFields(),
                sortAscending: true
            },
            dataNotifical: []
        };
    }

    hide = () => {
        this.setState({
          visible: false,
        });
    };
    handleVisibleChange = visible => {
        this.setState({ visible });
    };
    

    render() {
        const count = this.props.Notifical
        const {language} =this.props
        const List_mess_notify = renderMenu(language, count);
        return (

            <Badge count={count} style={{ marginRight: 30, zIndex: 1, display: 'inline-block' }}>
                <Popover className="mess_notify"placement='bottom' title={null} content={List_mess_notify} trigger='click'
                         visible={this.state.visible} onVisibleChange={this.handleVisibleChange}>
                      <Icon type="bell" style={{ fontSize: '30px', color: 'white', marginRight: 30 }} />
                </Popover>
            </Badge>
        );
    }
}
export { Notify };

import React from 'react';
import {
    Form, Input, Button, Tabs, Row, Col, Modal
    , Card
} from 'antd';
import jsonQuery from 'json-query';
import {
    MPS_MESSAGE_STATE, MPS_RESULTS
    , MPS_MODE
    , MPS_SYSTEM_PARAMETER_TYPE
    , MPS_SYSTEM_PARAMETER
    , Mps_MethodCommon,
    MPS_DISPLAY_AREA_STORAGE
} from '../../commons';
import { authenticationService } from '../../authenticate/Services';
//Import MPS common control here...
import {
    Mps_LayoutFormat
    , Mps_SelectParameterSystem, Mps_InputDatePicker
    , Mps_Tooltip, Mps_DisplayColumnResultTable, Mps_GenerateField
} from '../Mps_Control';
import { System_User_Select } from '../System_User/Controls/System_User_Select';
import { Sipco_WaterFilter_Service } from './Service/Sipco_WaterFilter.Service';
import { LANGUAGE_STATE } from './Language/ln';
import { GUIDE_LINE } from './Language/Guideline';
import { MESSAGE_STATE } from './Language/ms';

import {
    initModel, getDisplayFieldOnEditForm, setDisplayFieldOnEditForm
    , initFieldControl, initFielDisplayOnEditForm
} from './Models/Sipco_WaterFilter.Model';
import moment from 'moment';
//import { Log_Service } from '../../authenticate/Helpers/Log.Service';
import { Sipco_WaterFilterHistory_Service } from '../Sipco_WaterFilter_History/Service/Sipco_WaterFilterHistory.Service';
import Mps_UploadPictureV2 from '../Mps_Control/Mps_UploadPicture/Mps_UploadPictureV2';
import './Language/Css.css'
const { TabPane } = Tabs;

class Sipco_WaterFilterAdd extends React.Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([this.props.language[0]], { data: LANGUAGE_STATE }).value;
        const commonMessage = jsonQuery([this.props.language[0]], { data: MPS_MESSAGE_STATE }).value;
        const guideLine = jsonQuery([this.props.language[0]], { data: GUIDE_LINE }).value;
        const validMessage = jsonQuery([this.props.language[0]], { data: MESSAGE_STATE }).value;

        this.state = {
            file: [],
            language,
            commonMessage,
            guideLine,
            initModel,
            chooseLanguage,
            visible: false,
            validMessage,
        };
    }
    /**
     * @desc Khởi tạo data khi load component
     * @param Nothing
     * @return Nothing
    */
    componentWillMount() {
        const selectedModel = this.props.value;
        //Xử lí thêm field
        this.setState({
            selectedModel,
        });

    }
    /**
    * @desc Handle thiết lập sắp xếp hiển thị field trên form.
    * @param
    *   array value: Danh sách Field được chọn hiển thị trên form.
    * @return Nothing (Cập nhật setting),
    */
    handleDisplayFieldOnEditFormChange = value => {
        setDisplayFieldOnEditForm(value);
        this.setState({
            displayDefaultFieldOnEditForm: value
        });
    };

    /**
     * @desc Handle sự kiện hiển thị màn hình View/Add/Copy/Edit
     * @param event
     *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
     *   object data - dữ liệu hiển thị
     * @return Nothing
     */
    handleShowForm = e => {
        e.preventDefault();
        const mode = this.props.mode;
        let selectedModel = {};
        const userInfo = authenticationService.getUserInfor();
        const user = jsonQuery('[**]User[0]', { data: userInfo }).value;
        const userId = user._id;

        if (mode === MPS_MODE.Create) {
            selectedModel = { ...initModel };
            selectedModel.Status = MPS_SYSTEM_PARAMETER.Status.Active;
            selectedModel.CreatedBy = userId;
            selectedModel.WaterFilter_File = [];
            this.setState({
                selectedModel,
                mode,
                visible: true,
            });
        } else {
            selectedModel = this.props.value;
            if (selectedModel.length === 0) {
                Mps_MethodCommon.openWarningNotification(this.state.commonMessage.CHOOSE_DATA_COPY, '');
                return;
            } else if (selectedModel.length > 1) {
                Mps_MethodCommon.openWarningNotification(this.state.commonMessage.CHOOSE_ONLY_DATA_COPY, '');
                return;
            } else {
                Promise.all([Sipco_WaterFilter_Service.getById(selectedModel[0]._id)]).then(result => {
                    const data = jsonQuery('result[0]', { data: result }).value;
                    data.CreatedBy = userId;
                    if (result[0].returnCode === '1') {
                        data.Serial_Code = '';
                        data.CreatedBy = userId;
                        data.UpdatedDate = '';
                        data.WaterFilter_Files = [];
                        data.Phone = '';
                        data.Status = MPS_SYSTEM_PARAMETER.Status.Active;
                        this.setState({
                            selectedModel: data,
                            visible: true
                        });
                    } else {
                        Mps_MethodCommon.openNotification('warning', this.state.commonMessage.DATANOTFOUND, '');
                    }
                });
            }
        }
    };

    /**
    * @desc Handle sự kiện xác nhận lưu thông tin
    * @param event
    *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
    *   object data - dữ liệu hiển thị
    * @return bool - kết quả: true = thành công/ false = thất bại
    */
    handleSave = e => {
        e.preventDefault();
        this.handleOk('Save');
    };

    handleSaveNext = e => {
        e.preventDefault();
        this.handleOk('SaveNext');
    }

    

    handleOk = (e) => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const objectModel = {
                    _id: values._id,
                    Serial_Code: values.Serial_Code,
                    Owner: values.Owner,
                    Phone: values.Phone,
                    Provide_Date: values.Provide_Date,
                    Sipco_WaterFilter_Description: values.Sipco_WaterFilter_Description,
                    Status: this.state.selectedModel.Status,
                    Filter_Replacement_Date: this.state.selectedModel.Filter_Replacement_Date,
                    Number_Of_Times_To_Replace: values.Number_Of_Times_To_Replace,
                    CreatedDate: Date.now(),
                    WaterFilter_File: this.state.selectedModel.WaterFilter_File,
                    Province: values.Province,
                    District: values.District,
                    Ward: values.Ward, 
                    Village: values.Village,
                    UpdatedDate: '',
                    Monitoring_Date: this.state.selectedModel.Monitoring_Date,
                    Description_Of_Monitoring: values.Description_Of_Monitoring,
                };
                /*demo mẫu logger*/
                //Log_Service.create('handleOk', objectModel);
                Promise.all([Sipco_WaterFilter_Service.create(objectModel)]).then(result => {
                    if (result && result[0].returnCode === MPS_RESULTS.DATA_EXIST) throw Mps_MethodCommon.openNotification('error',
                    Mps_MethodCommon.returnMessageApi(MPS_RESULTS.DATA_EXIST, this.state.chooseLanguage));

                    if (result && result[0].returnCode === MPS_RESULTS.ERROR) throw new Error(MPS_RESULTS.ERROR);

                    const formData = new FormData();
                    // Append images to form data
                    this.state.selectedModel.WaterFilter_Files.forEach(file => {
                        formData.append('WaterFilter_New_Files', file.originFileObj);
                    });
                    formData.append('Serial_Code', result[0].data.Serial_Code);

                    Promise.all([Sipco_WaterFilterHistory_Service.create(objectModel)]).then((result) => {
                        if (!!result && result[0].returnCode === MPS_RESULTS.SUCCESS) { }
                    });
                    return Sipco_WaterFilter_Service.uploadWaterFilterFiles(formData);


                }).then(result => {
                    if (result && result.returnCode === MPS_RESULTS.ERROR) throw new Error(MPS_RESULTS.ERROR);

                    this.setState({
                        selectedModel: { ...initModel },
                        visible: false
                    });
                    this.handleReset();
                    this.props.onhandleSave();
                    return Mps_MethodCommon.openSuccessNotification(
                        this.state.commonMessage.INFORM_TITLE,
                        Mps_MethodCommon.returnMessageApi(result.returnCode, this.state.chooseLanguage)
                    );
                }).catch(error => {
                    if (error === MPS_RESULTS.DATA_EXIST) {
                        //console.log('error')
                        return Mps_MethodCommon.openErrorNotification(this.state.commonMessage.ERROR_TITLE,
                            Mps_MethodCommon.returnMessageApi(MPS_RESULTS.DATA_EXIST, this.state.chooseLanguage));
                    }
                    if (error === MPS_RESULTS.ERROR) {
                        return Mps_MethodCommon.openNotification('error', this.state.validMessage.ERROR_PRODCUCT_IS_EXIST, '');
                    }
                });
            }
        });
    };
    handleSubmitContinute = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            const objectModel = {
                _id: values._id,
                Serial_Code: values.Serial_Code,
                Owner: values.Owner,
                Phone: values.Phone,
                Provide_Date: values.Provide_Date,
                Sipco_WaterFilter_Description: values.Sipco_WaterFilter_Description,
                Status: this.state.selectedModel.Status,
                Filter_Replacement_Date: this.state.selectedModel.Filter_Replacement_Date,
                Number_Of_Times_To_Replace: values.Number_Of_Times_To_Replace,
                CreatedDate: Date.now(),
                WaterFilter_File: this.state.selectedModel.WaterFilter_File,
                Province: values.Province,
                District: values.District,
                Ward: values.Ward,
                UpdatedDate: '',
                Monitoring_Date: this.state.selectedModel.Monitoring_Date,
                Description_Of_Monitoring: values.Description_Of_Monitoring,
            };
            /*demo mẫu logger*/
           // Log_Service.create('handleOk', objectModel);
            Promise.all([Sipco_WaterFilter_Service.create(objectModel)]).then(result => {
                if (result && result[0].returnCode === MPS_RESULTS.DATA_EXIST) throw Mps_MethodCommon.openNotification('error',
                Mps_MethodCommon.returnMessageApi(MPS_RESULTS.DATA_EXIST, this.state.chooseLanguage));

                if (result && result[0].returnCode === MPS_RESULTS.ERROR) throw new Error(MPS_RESULTS.ERROR);

                const formData = new FormData();
                // Append images to form data
                this.state.selectedModel.WaterFilter_Files.forEach(file => {
                formData.append('WaterFilter_New_Files', file.originFileObj);
                });
                formData.append('Serial_Code', result[0].data.Serial_Code);

                Promise.all([Sipco_WaterFilterHistory_Service.create(objectModel)]).then((result) => {
                    if (!!result && result[0].returnCode === MPS_RESULTS.SUCCESS) { }
                });
                return Sipco_WaterFilter_Service.uploadWaterFilterFiles(formData);


            }).then(result => {
                if (result && result.returnCode === MPS_RESULTS.ERROR) throw new Error(MPS_RESULTS.ERROR);

                this.setState({
                    selectedModel: { ...initModel, WaterFilter_Files: [] },
                    visible: true
                });
                this.handleReset();
                this.props.onhandleSave();
                return Mps_MethodCommon.openSuccessNotification(
                    this.state.commonMessage.INFORM_TITLE,
                    Mps_MethodCommon.returnMessageApi(result.returnCode, this.state.chooseLanguage)
                );
            }).catch(error => {
                if (error === MPS_RESULTS.DATA_EXIST) {
                    //console.log('error')
                    return Mps_MethodCommon.openErrorNotification(this.state.commonMessage.ERROR_TITLE,
                        Mps_MethodCommon.returnMessageApi(MPS_RESULTS.DATA_EXIST, this.state.chooseLanguage));
                }
                if (error === MPS_RESULTS.ERROR) {
                    return Mps_MethodCommon.openNotification('error', this.state.validMessage.ERROR_PRODCUCT_IS_EXIST, '');
                }
            });
          }
        });
      };

    /**
    * @desc Handle sự kiện tắt màn hình
    * @param event
    *   Nothing
    * @return Nothing
    */
    handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.handleReset()
    };

    /**
    * @desc Handle sự kiện reset thông tin trên lưới
    * @param event
    *   Nothing
    * @return Nothing
    */
    handleReset = () => {
        this.props.form.resetFields();
    };

    /**
    * @desc Handle sự kiện trường thông tin trạng thái thay đổi giá trị.
    * @param event
    *   string value: giá trị mới
    * @return Nothing
    */
    handleStatusChange = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.Status = value;
        this.setState({
            selectedModel
        });
    };
    handleProvide_DateChange = value => {
        const selectedModel = this.state.selectedModel;
        if (value !== null) {
            const dateTime_in = value._d
            const dateTime = new Date(dateTime_in.getFullYear(), dateTime_in.getMonth(), dateTime_in.getDate(), 0, 0, 0);
            const dateTime_after = dateTime.setHours(dateTime.getHours() + 8)
            const pick_time = new Date(dateTime_after);
            value._d = pick_time
        } else {
            value = ""
        }
        selectedModel.Provide_Date = value;
        this.setState({ selectedModel });
    };

    handleReplaceDateChange = value => {
        const selectedModel = this.state.selectedModel;
        if (value !== null) {
            const dateTime_in = value._d
            const dateTime = new Date(dateTime_in.getFullYear(), dateTime_in.getMonth(), dateTime_in.getDate(), 0, 0, 0);
            const dateTime_after = dateTime.setHours(dateTime.getHours() + 8)
            const pick_time = new Date(dateTime_after);
            value._d = pick_time
        } else {
            value = ""
        }
        selectedModel.Filter_Replacement_Date = value;
        this.setState({
            selectedModel
        });
    }
    handleUploadDocument = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.WaterFilter_File = value;
        selectedModel.WaterFilter_Files = value;
        this.setState({
            selectedModel,
            file: value
        });
    };


    /**
   * @desc Handle tùy chọn hiển thị
   * @param
   *   Nothing: nothing
   * @return Nothing
   */
    handleDisplayChange = (type, value) => {
        if (!value && type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
            this.setState({ isReset: this.state.isReset ? false : true });
        }
        if (type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
            if (type === MPS_DISPLAY_AREA_STORAGE.sortField) {
                const { searchOptions } = this.state;
                searchOptions.sort = value;
                this.setState({
                    searchOptions
                });
                return;
            }
            this.setState({ [type]: value });
        } else {
            const { searchOptions } = this.state;
            searchOptions.sortAscending = value;
            this.setState({
                sortAscending: value,
                searchOptions
            });
        }
    };

    handleSurveyDateChange = value => {
        const selectedModel = this.state.selectedModel;
        if (value !== null) {
            const dateTime_in = value._d
            const dateTime = new Date(dateTime_in.getFullYear(), dateTime_in.getMonth(), dateTime_in.getDate(), 0, 0, 0);
            const dateTime_after = dateTime.setHours(dateTime.getHours() + 8)
            const pick_time = new Date(dateTime_after);
            value._d = pick_time
        } else {
            value = ""
        }
        selectedModel.Monitoring_Date = value;
        this.setState({
            selectedModel
        });
    }

    render() {
        if (this.props.allowAccess === false) {
            return '';
        }
        const mode = this.props.mode;
        const { language, guideLine, chooseLanguage } = this.state;
        const { getFieldDecorator } = this.props.form;
        let screenName = '';
        if (mode === MPS_MODE.Copy) {
            screenName = language.SIPCO_WATERFILTER_COPY;
        }
        if (mode === MPS_MODE.Create) {
            screenName = language.SIPCO_WATERFILTER_NEW;
        }
        const selectedModel = this.state.selectedModel;
        const displayDefaultFieldOnEditForm = getDisplayFieldOnEditForm();
        const displayFieldOnEditForm = initFielDisplayOnEditForm(language);
        const displaySearchConditions = this.props.displaySearchConditions;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };

        const gridStyle = {
            width: '50%',
        };

        return (
            <span >
                {this.props.allowAccess === true ? <Button type="primary" onClick={this.handleShowForm}>{this.props.actionName}</Button> : ' '}
                <Modal
                    title={screenName}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="saveClose" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleSave}>
                            {language.SAVEANDCLOSE}
                        </Button>,
                        <Button key='submit_and_continute' className={Mps_MethodCommon.getDisplayTheme()} type='primary' onClick={this.handleSubmitContinute}>
                        {language.SAVEANDNEXT}
                        </Button>,
                        <Button key="back" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleCancel}>
                            {language.CANCEL}
                        </Button>
                    ]}
                >
                    <Form {...formItemLayout} onSubmit={this.handleSave} id={Mps_MethodCommon.getDisplayTheme()}>
                        <Mps_DisplayColumnResultTable columns={displayFieldOnEditForm}
                            onDisplayFieldOnEditFormChange={this.handleDisplayFieldOnEditFormChange}
                            displayFieldOnEditForm={displayDefaultFieldOnEditForm}
                            language={this.state.chooseLanguage} />

                        <Tabs defaultActiveKey="1" >
                            <TabPane tab={language.SIPCO_WATERFILTER_TITLE_GENERAL} key="1">
                                <Card style={{ border: 'none' }} >

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Serial_Code')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SERIAL_CODE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={
                                                        <Mps_Tooltip title={guideLine.Serial_Code} />
                                                    }
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Serial_Code', {
                                                        initialValue: selectedModel.Serial_Code,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Serial_Code.Required,
                                                            }
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Owner')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.OWNER}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Owner', {
                                                        initialValue: selectedModel.Owner,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Owner.Required,
                                                            }
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Phone')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.PHONE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Phone', {
                                                        initialValue: selectedModel.Phone,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Phone.Required,
                                                            }
                                                        ],
                                                    })(<Input type='number'/>)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Province')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.PROVINCE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Province', {
                                                        initialValue: selectedModel.Province,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Province.Required,
                                                            }
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'District')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.DISTRICT}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('District', {
                                                        initialValue: selectedModel.District,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.District.Required,
                                                            }
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Ward')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.WARD}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Ward', {
                                                        initialValue: selectedModel.Ward,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Ward.Required,
                                                            }
                                                        ],
                                                    })(
                                                        <Input />

                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Village')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.VILLAGE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Village', {
                                                        initialValue: selectedModel.Village,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Village.Required,
                                                            }
                                                        ],
                                                    })(
                                                        <Input />

                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Provide_Date')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.PROVIDE_DATE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker
                                                    style={{ width: '100%' }}
                                                    value={selectedModel.Provide_Date}
                                                    name='Provide_Date'
                                                    required={initFieldControl.Provide_Date.Required}
                                                    getFieldDecorator={getFieldDecorator}
                                                    onChange={this.handleProvide_DateChange} />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Sipco_WaterFilter_Description')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_WATERFILTER_DESCRIPTION}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Sipco_WaterFilter_Description', {
                                                        initialValue: selectedModel.Sipco_WaterFilter_Description,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Sipco_WaterFilter_Description.Required,
                                                            }
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Status')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.STATUS}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_SelectParameterSystem name='Status' id='Status' type={MPS_SYSTEM_PARAMETER_TYPE.Status}

                                                    getFieldDecorator={getFieldDecorator}
                                                    required={initFieldControl.Status.Required}
                                                    value={this.state.selectedModel.Status} onChange={this.handleStatusChange} />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.CREATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedBy')}`} name="CreatedBy"
                                        help={guideLine.CreatedBy}
                                        value={this.state.selectedModel.CreatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.CREATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}
                                                    name="CreatedDate"
                                                    help={guideLine.CreatedDate}
                                                    style={{ width: '100%' }}
                                                    value={moment(Date.now())}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Filter_Replacement_Date')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.FILTER_REPLACEMENT_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={false}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Filter_Replacement_Date')}`}
                                                    name="Filter_Replacement_Date"
                                                    style={{ width: '100%' }}
                                                    onChange={this.handleReplaceDateChange}
                                                    value={!!selectedModel.Filter_Replacement_Date ? moment(selectedModel.Filter_Replacement_Date) : null}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Number_Of_Times_To_Replace')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.NUMBER_OF_TIMES_TO_REPLACE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Number_Of_Times_To_Replace', {
                                                        initialValue: selectedModel.Number_Of_Times_To_Replace,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Number_Of_Times_To_Replace.Required,
                                                            }
                                                        ],
                                                    })(<Input type = 'number'/>)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.UPDATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedBy')}`} name="UpdatedBy"
                                        help={guideLine.UpdatedBy}
                                        value={this.state.selectedModel.UpdatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.UPDATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}
                                                    name="UpdatedDate"
                                                    help={guideLine.UpdatedDate}
                                                    style={{ width: '100%' }}
                                                    value=''
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Monitoring_Date')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.MONITORING_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={false}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Monitoring_Date')}`}
                                                    name="Monitoring_Date"
                                                    style={{width:'100%'}}
                                                    onChange={this.handleSurveyDateChange}
                                                    value={!!selectedModel.Monitoring_Date ? moment(selectedModel.Monitoring_Date) : null}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Description_Of_Monitoring')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.DESCRIPTION_OF_MONITORING}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Description_Of_Monitoring', {
                                                        initialValue: selectedModel.Description_Of_Monitoring,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Description_Of_Monitoring.Required,
                                                            }
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                </Card>
                            </TabPane>
                            <TabPane tab={language.DOCUMENT_UPLOAD} key='2'>
                                <Mps_UploadPictureV2 disabled={false}
                                    language={language}
                                    value={this.state.selectedModel.WaterFilter_Files}
                                    onChange={this.handleUploadDocument} />
                            </TabPane>
                        </Tabs>
                    </Form>
                </Modal>
            </span>
        );
    }
}
const WrappedSipco_WaterFilterAdd = Form.create({ name: 'Sipco_WaterFilterAdd' })(Sipco_WaterFilterAdd);
export { WrappedSipco_WaterFilterAdd as Sipco_WaterFilterAdd };

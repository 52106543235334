import React from 'react';
import { InputNumber, Form } from 'antd';
import { Mps_Tooltip } from '../Mps_Tooltip/Mps_Tooltip';
import isNumber from 'lodash/isNumber';
class Mps_InputAmount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
        };
    }

    onChange = value => {
        this.setState({ value });
    };

    onBlur = () => {
        if (!!this.props.form) {
            const changedValue = {};
            changedValue[!!this.props.name ? this.props.name : 'undefinded'] = this.state.value;
            this.props.form.setFieldsValue(changedValue);
        }
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(this.state.value);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (!isNaN(this.props.value) && prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            if (this.props.form) {
                const changeValue = [];
                changeValue[this.props.name] = this.props.value;
                this.props.form.setFieldDecorator(changeValue);
            }
            this.setState({
                value: this.props.value
            });
        }
    }

    render() {
        const currency = this.props.currency;
        return (
            <Form.Item
                help={
                    (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
                }
                hasFeedback
            >
                {
                    (!this.props.required) ?
                        <InputNumber
                            className={this.props.className}
                            style={{ width: '100%' }}
                            className={this.props.className}
                            defaultValue={this.props.value}
                            value={this.state.value}
                            min={isNumber(this.props.min) ? this.props.min : -9999999999}
                            max={isNumber(this.props.max) ? this.props.max : 9999999999}
                            disabled={(!!this.props.disabled) ? this.props.disabled : false}
                            formatter={value => this.props.minus === false ? `${currency ? currency + '' : ''}${value}${currency ? '' : 'đ'}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/-/g, '') :
                                `${currency ? currency + '' : ''}${value}${currency ? '' : 'đ'}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => this.props.minus === false ? value.replace(/\$\s?|(,*)/g, '').replace(/-/g, '').replace('đ', '').replace(`${currency} `, '').replace(`${currency}`, '')
                                : value.replace(/\$\s?|(,*)/g, '').replace('đ', '').replace(`}${currency} `, '').replace(`${currency}`, '')}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                        />
                        : (this.props.getFieldDecorator(this.props.name, {
                            initialValue: this.props.value,
                            rules: [
                                {
                                    required: this.props.required
                                }
                            ]
                        })(
                            <InputNumber
                                className={this.props.className}
                                style={{ width: '100%' }}
                                className={this.props.className}
                                min={isNumber(this.props.min) ? this.props.min : -9999999999}
                                max={isNumber(this.props.max) ? this.props.max : 9999999999}
                                disabled={(!!this.props.disabled) ? this.props.disabled : false}
                                formatter={value => this.props.minus === false ? `${currency ? currency + '' : ''}${value}${currency ? '' : 'đ'}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/-/g, '') :
                                    `${currency ? currency + '' : ''}${value}${currency ? '' : 'đ'}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => this.props.minus === false ? value.replace(/\$\s?|(,*)/g, '').replace(/-/g, '').replace('đ', '').replace(`${currency} `, '').replace(`${currency}`, '')
                                    : value.replace(/\$\s?|(,*)/g, '').replace('đ', '').replace(`}${currency} `, '').replace(`${currency}`, '')}
                                onChange={this.onChange}
                                onBlur={this.onBlur}
                            />
                        ))}
            </Form.Item>
        );
    }
}
export { Mps_InputAmount };

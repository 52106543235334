import React from "react";
import jsonQuery from 'json-query';
import { Table, Button } from "antd";
import * as excelJS from "exceljs";
import { saveAs } from "file-saver";
import { Fragment } from "react";
import { System_Company_Service } from '../../System_Company/Service/System_Company.Service';
import { Mps_MethodCommon } from '../../../commons';
import { Mps_LabelFormat } from '../../Mps_Control/Mps_LabelFormat/Mps_LabelFormat';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

class Mps_ExportExcelReport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			label: this.props.headerLabel ? this.props.headerLabel : 'unknow',
			changeDate: this.props.changeDate ? this.props.changeDate : null,
			selectedRowKeys: [],
			dataSourceTotal: [],
			width: 300,
			tempstr: "This is temp text.",
		};
	}

	componentDidMount() {
		const companyStoreID = 1;
		Promise.all([System_Company_Service.getCompanyProfile(companyStoreID)]).then(result => {
			const data = jsonQuery('[0]', { data: result }).value;
			const datasource = data.result.docs[0];
			this.setState({
				companyStoreModel: datasource
			});
		});
	}


	handleExport = () => {
		const workbook = new excelJS.Workbook({ useStyles: true });
		let worksheet = workbook.addWorksheet(Mps_LabelFormat.formatDate(moment(Date.now())));
		const companyStoreModel = this.state.companyStoreModel;
		workbook.creator = "Binance";
		workbook.lastModifiedBy = "Binance";
		workbook.created = new Date();
		workbook.modified = new Date();

		// Tạo khoảng trống, kiểu cho icon
		worksheet.mergeCells('A2:B4');
		if('System_Company_Logo' in companyStoreModel
		&& '0' in companyStoreModel.System_Company_Logo
		&&  'thumbUrl' in companyStoreModel.System_Company_Logo[0]){
			const imageId2 = workbook.addImage({
				base64: !!(companyStoreModel.System_Company_Logo) ?
					(companyStoreModel.System_Company_Logo[0] ? companyStoreModel.System_Company_Logo[0].thumbUrl : null) : null,
				extension: 'png',
				editAs: 'oneCell'
			});
			worksheet.addImage(imageId2, 'A2:B4');
		}

		// Tạo khoảng trống, kiểu và giá trị cho tên công ty
		const beginChar = 'C';
		worksheet.mergeCells(`C2:${this.props.columns && this.props.columns.length > 2
			? String.fromCharCode(beginChar.charCodeAt(0) - 3 + this.props.columns.length) : 'F'}2'`);
		const CompanyCell = worksheet.getCell("C2");
		CompanyCell.value = 'System_Company_Name' in companyStoreModel ? companyStoreModel.System_Company_Name : `'`;

		// Tạo khoảng trống, kiểu và giá trị cho tên địa chỉ
		worksheet.mergeCells(`C3:${this.props.columns && this.props.columns.length > 2
			? String.fromCharCode(beginChar.charCodeAt(0) - 3 + this.props.columns.length) : 'F'}3'`);
		const AddressCell = worksheet.getCell("C3");
		AddressCell.value = 'Địa chỉ: ' + `${!!companyStoreModel.System_Company_Address ? companyStoreModel.System_Company_Address : ''}` + ', '
			+ `${!!companyStoreModel.System_Company_Ward ? companyStoreModel.System_Company_Ward : ''}` + ', ' +
			`${!!companyStoreModel.System_Company_District ? companyStoreModel.System_Company_District : ''}` + ', '
			+ `${!!companyStoreModel.System_Company_City ? companyStoreModel.System_Company_City : ''}`;

		// Tạo khoảng trống, kiểu và giá trị cho liên hệ
		worksheet.mergeCells(`C4:${this.props.columns && this.props.columns.length > 2
			? String.fromCharCode(beginChar.charCodeAt(0) - 3 + this.props.columns.length) : 'F'}4'`);
		const ContactCell = worksheet.getCell("C4");
		ContactCell.value = `SĐT: ${companyStoreModel.System_Company_PhoneNumber} , FAX: ${companyStoreModel.System_Company_Fax}`;

		// Tạo khoảng trống, kiểu và giá trị ngày lập
		const DateCell = worksheet.getCell("A5");
		DateCell.value = `Ngày lập: `;
		const DateDataCell = worksheet.getCell("B5");
		DateDataCell.value = Mps_LabelFormat.formatDate(moment(Date.now()));

		// Tạo khoảng trống, kiểu và giá trị cho thời gian báo cáo
		const ReportDateCell = worksheet.getCell("A6");
		ReportDateCell.value = `Thời gian: `;
		const ReportDateDataCell = worksheet.getCell("B6");
		ReportDateDataCell.value = 'changeDate' in this.props ? this.props.changeDate : `'`;

		// Tạo khoảng trống, kiểu và giá trị cho Nội dung báo cáo
		worksheet.mergeCells(`C5:${this.props.columns && this.props.columns.length > 2
			? String.fromCharCode(beginChar.charCodeAt(0) - 3 + this.props.columns.length) : 'F'}6'`);
		const DescriptionCell = worksheet.getCell("C5");
		DescriptionCell.value = !!this.props.headerLabel ? this.props.headerLabel : `'`;


		// add header row
		worksheet.getRow(7).values = this.getColumnName(this.props.columns);
		// Xác định từng thuộc tính cột dữ liệu
		worksheet.columns = this.prepareColumn();

		// Thêm dữ liệu ứng với từng thuộc tính
		worksheet.addRows(this.prepareData());

		worksheet.eachRow(function (Row, rowNum) {
			Row.eachCell(function (Cell, cellNum) {
				if (Cell._value.model.type === 2) {
					Cell.alignment = {
						vertical: "middle",
						horizontal: "right"
					};
				} else if (Cell._value.model.type === 3) {
					Cell.alignment = {
						vertical: "middle",
						horizontal: "left"
					};
				} else Cell.alignment = {
					vertical: "middle",
					horizontal: "center"
				};
				Cell.border = {
					top: { style: 'thin' },
					left: { style: 'thin' },
					bottom: { style: 'thin' },
					right: { style: 'thin' }
				};
			});
		});

		worksheet.getRow(7).font = { bold: true, size: 11, name: "Calibri" };

		//Chỉnh lại font, style cuối cùng tránh bị ghi đè
		CompanyCell.alignment = {
			vertical: "middle",
			horizontal: "center"
		};
		CompanyCell.font = {
			name: "Calibri",
			family: 4,
			size: 24,
			bold: true
		};
		AddressCell.alignment = {
			vertical: "middle",
			horizontal: "center"
		};
		AddressCell.font = {
			name: "Calibri",
			family: 4,
			size: 16,
		};
		ContactCell.alignment = {
			vertical: "middle",
			horizontal: "center"
		};
		ContactCell.font = {
			name: "Calibri",
			family: 4,
			size: 16,
		};
		DateCell.font = {
			name: "Calibri",
			family: 4,
			size: 11,
			bold: true
		};
		DateDataCell.font = {
			name: "Calibri",
			family: 4,
			size: 11,
		};
		ReportDateCell.font = {
			name: "Calibri",
			family: 4,
			size: 11,
			bold: true
		};
		ReportDateDataCell.font = {
			name: "Calibri",
			family: 4,
			size: 11,
		};
		DescriptionCell.alignment = {
			vertical: "middle",
			horizontal: "center"
		};
		DescriptionCell.font = {
			name: "Calibri",
			family: 4,
			size: 28,
			bold: true
		};


		const ExpotName = ('name' in this.props && !!this.props.name ? this.props.name : 'Report_Data') + " " + Mps_LabelFormat.fotmatDateTimeHour(moment(Date.now())) + ".xlsx";

		workbook.xlsx.writeBuffer().then(function (buffer) {
			// done
			const blob = new Blob([buffer], { type: "applicationi/xlsx" });
			saveAs(blob, (ExpotName));
		});
	}

	selectRow = record => {
		const selectedRowKeys = [...this.state.selectedRowKeys];
		if (selectedRowKeys.indexOf(record.key) >= 0) {
			selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
		} else {
			selectedRowKeys.push(record.key);
		}
		this.setState({ selectedRowKeys });
	};

	onSelectedRowKeysChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	getColumnName = (columns) => {
		const columnName = [];
		columns.forEach(element => {
			columnName.push(element.title)
		});
		return columnName
	}

	prepareColumn = () => {
		const columns = cloneDeep(this.props.columns);
		let width = 0;
		let n = 1;
		columns.forEach((element) => {
			element.width = element.width / 4;
			width = width + element.width;
		});
		while (width < 200) {
			columns.forEach((element, index) => {
				if (index > 1) {
					const plusWidth = element.width * (n / 10)
					element.width = element.width + plusWidth;
					width = width + plusWidth;
				}
			});
		}
		return columns
	}

	prepareData = () => {
		const columns = cloneDeep(this.props.columns);
		const dataForDownload = cloneDeep(jsonQuery('[0]docs', { data: this.props.dataForDownload }).value);
		dataForDownload.forEach(detailData => {
			columns.forEach(element => {
				detailData[element.key] = jsonQuery(element.key, { data: detailData }).value;
			});
		})
		return dataForDownload
	}

	render() {
		return (
			<Button type='primary' onClick={this.handleExport}>Tải bản báo cáo</Button>
		);
	}
}

export { Mps_ExportExcelReport }
import { Button, Card, Col, Form, Icon, Input, Row } from 'antd';
import jsonQuery from 'json-query';
import React from 'react';
import { ReCaptcha } from 'react-recaptcha-google';
import { Link } from 'react-router-dom';
import { MPS_MESSAGE_STATE, Mps_MethodCommon } from '../../commons';
import { Mps_ErrorAccountExpired, Mps_Tooltip } from '../../components/Mps_Control';
import { history } from '../Helpers';
import { authenticationService } from '../Services';
import { LANGUAGE_STATE } from './Language/ln';
import { MESSAGE_STATE } from './Language/ms';
import { initModel } from './Models/LoginModel';
import"./Css/ForgotPassword.css"

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.username = React.createRef();
        this.email = React.createRef();
        this.system_unique = React.createRef();
        this.code_email = React.createRef();
        this.password = React.createRef();
        this.repassword = React.createRef();
        this.btn_submit = React.createRef();

        this.handleClick = this.handleClick.bind(this);
        // this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        // this.verifyCallback = this.verifyCallback.bind(this);
        const defaultLanguage = localStorage.getItem('language');
        const chooseLanguage = (!!defaultLanguage) ? JSON.parse(defaultLanguage) : 'en';
        localStorage.setItem("language", JSON.stringify(chooseLanguage));
        localStorage.setItem("uniqueCode", JSON.stringify(initModel.System_UniqueCode));
        const language = jsonQuery([chooseLanguage], { data: LANGUAGE_STATE }).value;
        const message = jsonQuery([chooseLanguage], { data: MESSAGE_STATE }).value;
        const commonMessage = jsonQuery([chooseLanguage[0]], { data: MPS_MESSAGE_STATE }).value;

        authenticationService.logout();
        this.state = {
            chooseLanguage,
            language,
            message,
            loginModel: initModel,
            commonMessage,

            messageReturn: '',
            //recaptchaToken: '',
            isExpiredPage: false,
            Step: 1,
            loading: false,
        };
    }
    componentDidMount() {
        document.title = this.state.language.LOGINSCREEN;
        //this.onLoadRecaptcha();
        const uniqueCode = localStorage.getItem('uniqueCode');
        const newUniqueCode = window.location.search.substring(12);
        const loginModel = this.state.loginModel;
        loginModel.System_UniqueCode = (!newUniqueCode) ? JSON.parse(uniqueCode) : newUniqueCode;
        loginModel.System_User_Language = this.state.chooseLanguage;
        this.setState({ loginModel });
        window.addEventListener("keydown", (e) => {
            if (e.key === "Enter") {
                if (this?.btn_submit?.current?.buttonNode) {
                    this.btn_submit.current.buttonNode.click();
                }
            }
        })
    }
    // onLoadRecaptcha() {
    //     if (this.captchaDemo) {
    //         try {
    //             this.captchaDemo.reset();
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }

    // verifyCallback(recaptchaTokens) {
    //     // Here you will get the final recaptchaToken!!!
    //     this.setState({
    //         recaptchaToken: recaptchaTokens
    //     });
    // }

    handleClick = e => {
        e.preventDefault();
        const { Step } = this.state;
        if (Step === 1 && !this.username?.current?.input?.value) {
            this.username.current.input.focus();
            return;
        }
        if (Step === 1 && !this.email?.current?.input?.value) {
            this.email.current.input.focus();
            return;
        }
        if (Step === 1 && !this.system_unique?.current?.input?.value) {
            this.system_unique.current.input.focus();
            return;
        }
        if (Step === 2 && !this.code_email?.current?.input?.value) {
            this.code_email.current.input.focus();
            return;
        }
        if (Step === 3 && !this.password?.current?.input?.value) {
            this.password.current.input.focus();
            return;
        }
        if (Step === 3 && !this.repassword?.current?.input?.value) {
            this.repassword.current.input.focus();
            return;
        }
        const { language } = this.state;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { Step } = this.state;
                this.setState({ loading: true })
                if (Step === 1) {
                    const model = {};
                    model.SystemUserCode = values.System_User_Code;
                    model.System_UniqueCode = values.System_UniqueCode;
                    model.System_User_Email = values.System_User_Email;
                    const systemUserLanguage = this.state.loginModel.System_User_Language;
                    authenticationService.verifyUserEmail(model, systemUserLanguage).then(result => {
                        if (!!result?.code) {
                            this.setState({ Step: 2 });
                        } else {
                            Mps_MethodCommon.openErrorNotification(language.EMAIL_NOT_MATCH, "");
                        }
                        this.setState({ loading: false })
                    })
                } else if (Step === 2) {
                    const model = {};
                    model.SystemUserCode = values.System_User_Code;
                    model.System_UniqueCode = values.System_UniqueCode;
                    model.System_User_Email = values.System_User_Email;
                    model.Code_Verify_Name = values.Code_Verify_Name;
                    authenticationService.verifyCodeEmail(model)
                        .then(result => {
                            if (result?.code === 1) {
                                this.setState({ Step: 3 });
                            } else if (result?.code === -1) {
                                Mps_MethodCommon.openErrorNotification(language.CODE_EMAIL_EXPIRED, "");
                            } else {
                                Mps_MethodCommon.openErrorNotification(language.CODE_EMAIL_NOT_MATCH, "");
                            }
                            this.setState({ loading: false })
                        })
                } else { //Step === 3
                    const model = {};
                    model.System_User_Password = values.System_User_Password;
                    model.SystemUserCode = values.System_User_Code;
                    model.System_UniqueCode = values.System_UniqueCode;
                    model.Code_Verify_Name = values.Code_Verify_Name;
                    authenticationService.updatePassword(model)
                        .then(result => {
                            if (result?.code === 1) {
                                Mps_MethodCommon.openSuccessNotification(language.REPASSWORD_SUCCESS, "");
                                history.push("/login");
                            } else if (result?.code === -1) {
                                Mps_MethodCommon.openErrorNotification(language.CODE_EMAIL_EXPIRED, "");
                            } else {
                                Mps_MethodCommon.openErrorNotification(language.REPASSWORD_ERROR, "");
                            }
                            this.setState({ loading: false })
                        })
                }
            }
        });
    };
    handleChangeLanguage = e => {
        const loginModel = this.state.loginModel;
        const chooseLanguage = e.target.id;
        localStorage.setItem("language", JSON.stringify(chooseLanguage));
        loginModel.System_User_Language = chooseLanguage;
        const language = jsonQuery([chooseLanguage], { data: LANGUAGE_STATE }).value;
        this.setState({
            loginModel,
            language
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 }
            }
        };
        if (this.state.isExpiredPage) {
            return <Mps_ErrorAccountExpired />;
        }
        const { language, message, loginModel, Step, loading } = this.state;
        const styleNotStep1 = Step !== 1 ? "c-hide" : "";
        const styleNotStep2 = Step !== 2 ? "c-hide" : "";
        const styleNotStep3 = Step !== 3 ? "c-hide" : "";
        return (
            <div className='mps-login-contain' style={{ backgroundImage: `url(${''})`, backgroundSize: 'cover' }}>

                <Card bordered={true} style={{ width: 365, margin: '0px auto', padding: '20px 20px 0px 20px', borderRadius: 10 }}>
                    <center>
                        
                        <img src={require('../../assets/Layout/logo.jpg')} style={{ width: 250, margin: '20px 10px 20px 0px' }} />
                        <h3 style={{ marginBottom: 26, fontSize: 20 }}>{language.FORGOT_PASSWORD}</h3>
                    </center>
                    <Form {...formItemLayout} onSubmit={this.handleClick}>
                        <Row className={styleNotStep1}>
                            <Col  >

                                <Form.Item hasFeedback >
                                    {getFieldDecorator('System_User_Code', {
                                        rules: [
                                            {
                                                required: !!styleNotStep1,
                                                message: <Mps_Tooltip title={message.USERNAMENOTNULL} />
                                            }
                                        ]
                                    })(<Input
                                        ref={this.username}
                                        prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder={language.USER_NAME} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className={styleNotStep1}>
                            <Col>
                                <Form.Item hasFeedback>
                                    {getFieldDecorator('System_User_Email', {
                                        rules: [
                                            {
                                                required: !!styleNotStep1,
                                                message: ' '
                                            },
                                            {
                                                type: 'email',
                                                message: ' '
                                            }
                                        ],
                                    })(<Input
                                        ref={this.email}
                                        prefix={<Icon type='email' style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder={language.USER_EMAIL} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className={styleNotStep1} hidden = {true}>
                            <Col   >
                                <Form.Item hasFeedback >
                                    {getFieldDecorator('System_UniqueCode', {
                                        initialValue: loginModel.System_UniqueCode,
                                        rules: [
                                            {
                                                required: !!styleNotStep1,
                                                message: ' '
                                            },
                                        ],
                                    })(<Input
                                        ref={this.system_unique}
                                        prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder={language.COMPANY_CODE} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className={styleNotStep2}>
                            <Col   >
                                <Form.Item hasFeedback>
                                    {getFieldDecorator('Code_Verify_Name', {
                                        rules: [
                                            { require: !!styleNotStep2 },

                                        ],
                                    })(<Input
                                        ref={this.code_email}
                                        placeholder={language.EMAIL_CODE} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className={styleNotStep3}>
                            <Col>
                                <Form.Item hasFeedback name="System_User_Password">
                                    {getFieldDecorator('System_User_Password', {
                                        rules: [
                                            { require: !!styleNotStep3 }
                                        ],
                                    })(<Input.Password
                                        ref={this.password}
                                        prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder={language.PASSWORD} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className={styleNotStep3}>
                            <Col   >
                                <Form.Item
                                    hasFeedback
                                >
                                    {getFieldDecorator('System_User_Repassword', {
                                        rules: [
                                            { require: !!styleNotStep3 },
                                            {
                                                validator: (_rule, value, callback) => {
                                                    const { form } = this.props;
                                                    if (!styleNotStep3 && value && value !== form.getFieldValue('System_User_Password')) {
                                                        callback(<></>);
                                                    } else {
                                                        callback();
                                                    }
                                                }
                                            },
                                        ],
                                    })(<Input.Password
                                        ref={this.repassword}
                                        prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder={language.REPASSWORD} />)}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ textAlign: 'right' }} hidden = {true}>
                            <img onClick={this.handleChangeLanguage} id='vn' style={{ width: 40 }} src={require('../../assets/Layout/vietnam.png')} />
                            <img onClick={this.handleChangeLanguage} id='en' style={{ width: 40, marginLeft: 10 }} src={require('../../assets/Layout/english.png')} />
                        </Row>
                        {/* <Row>
                            <Col span={24} >
                                <Form.Item >
                                    <center>
                                        <ReCaptcha ref={el => { this.captchaDemo = el; }}
                                            style={{ width: '100%' }}
                                            size='normal'
                                            data-theme='dark'
                                            render='explicit'
                                            sitekey='6Lda50McAAAAAM4NsJ5vUoz_TpxtqbQzz--85UPL'
                                            onloadCallback={this.onLoadRecaptcha}
                                            verifyCallback={this.verifyCallback}
                                            hl='vi' />
                                    </center>
                                </Form.Item>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col span={12} style={{ paddingRight: 10, float: 'right' }}>
                                <Button
                                    loading={loading}
                                    ref={this.btn_submit} htmlType="submit" style={{ width: '100%', height: 33, fontSize: 15, marginTop: 5 }}
                                    type='primary' id='submit_login' onClick={this.handleClick} className='login-form-button' >
                                    {language.CONTINUTE}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Link to='/login'>
                                <Button className='mps-link-forgot-password' type='link' size='small'
                                    style={{ marginTop: 10 }} >{language.SIGNIN}</Button>
                            </Link>
                        </Row>
                    </Form>
                </Card>
            </div>
        );
    }
}
const WrappedForgotPassword = Form.create({ name: 'ForgotPassword' })(ForgotPassword);
export { WrappedForgotPassword as ForgotPassword };


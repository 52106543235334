import {Mps_LabelFormat} from '../../Mps_Control/Mps_LabelFormat/Mps_LabelFormat';
import {MPS_DISPLAY_AREA_KEY, MPS_SCREEN_NO,MPS_SYSTEM_PARAMETER_TYPE} from '../../../commons';
import { number } from 'prop-types';

export function initResultTable(language, defaultColumn) {
    const cell = 'cell-ellipsis';
    return  [
        {
            width: 150,
            title:language.SIPCO_CPA_CODE,
            dataIndex: 'SIPCO_CPA_Code',
            key: 'SIPCO_CPA_Code',
            className: defaultColumn.includes('SIPCO_CPA_Code') ? cell : 'c-hide'
        },
        {
            width: 200,
            title:language.SIPCO_CPA_PROVINCE,
            dataIndex: 'SIPCO_CPA_ProvinceObject',
            key: 'SIPCO_CPA_Province',
            className: defaultColumn.includes('SIPCO_CPA_Province')? cell : 'c-hide'
        },
        {
            width: 200,
            title:language.SIPCO_CPA_START,
            dataIndex: 'SIPCO_CPA_Start',
            key: 'SIPCO_CPA_Start',
            align: 'right',
            className: defaultColumn.includes('SIPCO_CPA_Start')? cell : 'c-hide'
        },
        {
            width: 200,
            title:language.SIPCO_CPA_END,
            dataIndex: 'SIPCO_CPA_End',
            key: 'SIPCO_CPA_End',
            align: 'right',
            className: defaultColumn.includes('SIPCO_CPA_End')? cell : 'c-hide'
        },
        {
            width: 200,
            title:language.SIPCO_CPA_COLOR,
            dataIndex: 'SIPCO_CPA_ColorObject',
            key: 'SIPCO_CPA_Color',
            className: defaultColumn.includes('SIPCO_CPA_Color')? cell : 'c-hide'
        },
        {
            width: 300,
            title:language.SIPCO_CPA_DESCRIPTION,
            dataIndex: 'SIPCO_CPA_Description',
            key: 'SIPCO_CPA_Description',
            className: defaultColumn.includes('SIPCO_CPA_Description')? cell : 'c-hide'
        },
        {
            width: 150,
            title:language.STATUS,
            dataIndex: 'StatusObject',
            key: 'Status',
            className: defaultColumn.includes('Status') ? cell : 'c-hide'
        },
        {
            width: 200,
            title:language.CREATED_BY,
            dataIndex: 'CreatedByObject',
            key: 'CreatedBy',
            className: defaultColumn.includes('CreatedBy') ? cell : 'c-hide'
        },
        {
            width: 200,
            title:language.START_DATE,
            dataIndex: 'Start_Date',
            key: 'Start_Date',
            className: defaultColumn.includes('Start_Date') ? 'display-date' : 'c-hide display-date',
            render: date => (
                Mps_LabelFormat.formatDate(date)
            ),
        },
        {
            width: 200,
            title:language.END_DATE,
            dataIndex: 'End_Date',
            key: 'End_Date',
            className: defaultColumn.includes('End_Date') ? 'display-date' : 'c-hide display-date',
            render: date => (
                Mps_LabelFormat.formatDate(date)
            ),
        },
        {
            width: 150,
            title:language.CREATED_DATE,
            dataIndex: 'CreatedDate',
            key: 'CreatedDate',
            className: defaultColumn.includes('CreatedDate') ? 'display-date' : 'c-hide display-date',
            render: date => (
                Mps_LabelFormat.formatDate(date)
            ),
        },
        {
            width: 200,
            title:language.UPDATED_BY,
            dataIndex: 'UpdatedByObject',
            key: 'UpdatedBy',
            className: defaultColumn.includes('UpdatedBy') ? cell : 'c-hide'
        },
        {
            width: 150,
            title:language.UPDATED_DATE,
            dataIndex: 'UpdatedDate',
            key: 'UpdatedDate',
            className: defaultColumn.includes('UpdatedDate') ? 'display-date' : 'c-hide display-date',
            render: date => (
                Mps_LabelFormat.formatDate(date)
            ),
        },
    ];
}
export function initFieldsDisplayOnSearchForm(language) {

    return  [
        {
            width: 100,
            title:language.SIPCO_CPA_CODE,
            dataIndex: 'SIPCO_CPA_Code',
            key: 'SIPCO_CPA_Code',
        },
        {
            width: 100,
            title:language.SIPCO_CPA_DESCRIPTION,
            dataIndex: 'SIPCO_CPA_Description',
            key: 'SIPCO_CPA_Description',
        },
        {
            width: 100,
            title:language.STATUS,
            dataIndex: 'StatusObject',
            key: 'Status',
        },
        {
            width: 100,
            title:language.CREATED_BY,
            dataIndex: 'CreatedByObject',
            key: 'CreatedBy',
        },
        {
            width: 100,
            title:language.CREATED_DATE,
            dataIndex: 'CreatedDate',
            key: 'CreatedDate',
        },
        {
            width: 100,
            title:language.UPDATED_BY,
            dataIndex: 'UpdatedByObject',
            key: 'UpdatedBy',
        },
        {
            width: 100,
            title:language.UPDATED_DATE,
            dataIndex: 'UpdatedDate',
            key: 'UpdatedDate',
        },
    ];
}
export function  headers (model){
    const columns = model;

    const displayDefaultColums = getDisplayColumnSearchResults();

    const headersExport = [];
    columns.forEach(element=>{
        if(displayDefaultColums.includes(element.key)){
            const colum = {
                label: element.title,
                key: element.key
            };
            headersExport.push(colum);
        }
    });
  return headersExport;
}
// export function headerExample(columnDisplay) {
//     const columns = columnDisplay;
//     const headersExport = [];
//     const displayDefaultColums = getDisplayColumnSearchResults();
//     columns.forEach(element => {
//         if (displayDefaultColums.includes(element.key)) {
//             const colum = {
//                 label: '',
//                 key: ''
//             };
//             colum.label = element.title;
//             colum.key = element.key;
//             headersExport.push(colum);
//         }

//     });
//     return headersExport;
// }

export function headerExample(columnDisplay) {
    const columns = columnDisplay;
    const headersExport = [];
    const col = ['CreatedBy', 'UpdatedBy', 'CreatedDate', 'UpdatedDate'];
    const onlyOnceCol = [];
    const requireCol = ['SIPCO_CPA_Province', 'SIPCO_CPA_Start', 'SIPCO_CPA_End'];
    const requireOnlyOnceCol = ['SIPCO_CPA_Code'];
    const parameter = [
        {key: 'Status', type : MPS_SYSTEM_PARAMETER_TYPE.Status},
        {key: 'SIPCO_CPA_Province', type : MPS_SYSTEM_PARAMETER_TYPE.Province},
        {key: 'SIPCO_CPA_Color', type : MPS_SYSTEM_PARAMETER_TYPE.ClassifyColor},
    ];
    columns.forEach(element => {
        if (!col.includes(element.key)) {
            const colum = {
                label: null,
                key: null,
                type: null,
                require: 0
            };
            const par = parameter.find(par => par.key === element.key);
            if(par !== undefined){
                colum.type = par.type;
            }
            if(onlyOnceCol.includes(element.key)){
                colum.require = 1;
            }
            if(requireCol.includes(element.key)){
                colum.require = 2;
            }
            if(requireOnlyOnceCol.includes(element.key)){
                colum.require = 3;
            }
            colum.label = element.title;
            colum.key = element.key;
            headersExport.push(colum);
        }

    });
    return headersExport;
}

export const searchModel={
    SIPCO_CPA_Code:'',
    SIPCO_CPA_Color:[],
    SIPCO_CPA_Province:[],
    CreatedBy:[],
    CreatedDate:[],
    Status:[],
    Start_Date:[],
    End_Date:[],
    UpdatedBy:[],
    UpdatedDate:[],
    SIPCO_CPA_Start:'',
    SIPCO_CPA_End: '',
    CPA_File: [],

};

export const initModel={
    _id:'',
    SIPCO_CPA_Code:'',
    // SIPCO_CPA_Name:'',
    SIPCO_CPA_Description:'',
    CreatedBy:'',
    CreatedDate:'',
    Start_Date:'',
    End_Date:'',
    UpdatedBy:'',
    UpdatedDate:'',
    Status:'',
    CPA_File: [],
    CPA_Files: [],
};

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySearchConditionsKey = MPS_SCREEN_NO.CPA + MPS_DISPLAY_AREA_KEY.DisplaySearchConditions;
export function setDisplaySearchConditions(values){
    localStorage.setItem(displaySearchConditionsKey,JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplaySearchConditions(){
    const column = JSON.parse(localStorage.getItem(displaySearchConditionsKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if(column === null){
        return ['SIPCO_CPA_Code', 'Status','SIPCO_CPA_Province', 'SIPCO_CPA_Color',
        'CreatedBy','CreatedDate',];
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySortFieldsKey = MPS_SCREEN_NO.CPA + MPS_DISPLAY_AREA_KEY.DisplaySortFields;
export function setDisplaySortFields(values){
    localStorage.setItem(displaySortFieldsKey,JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplaySortFields(){
    const column = JSON.parse(localStorage.getItem(displaySortFieldsKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if(column === null){
        return [ 'SIPCO_CPA_Code'];
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySearchResultsKey = MPS_SCREEN_NO.CPA + MPS_DISPLAY_AREA_KEY.DisplaySearchResults;
export function setDisplayColumnSearchResults(values){
    localStorage.setItem(displaySearchResultsKey,JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplayColumnSearchResults(){
    const column = JSON.parse(localStorage.getItem(displaySearchResultsKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if(column === null){
        return ['SIPCO_CPA_Code','Status','SIPCO_CPA_Province', 'SIPCO_CPA_Color','CreatedBy',
        'CreatedDate','UpdatedDate','UpdatedBy','Start_Date','End_Date','SIPCO_CPA_Start',
        'SIPCO_CPA_End','SIPCO_CPA_Description',];
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình add/edit/view.
export function initFielDisplayOnEditForm(language) {

    return  [
        {
            title:language.SIPCO_CPA_CODE,
            key: 'SIPCO_CPA_Code',
        },
        // {
        //     title:language.SIPCO_CPA_NAME,
        //     key: 'SIPCO_CPA_Name',
        // },
        {
            title:language.SIPCO_CPA_PROVINCE,
            key: 'SIPCO_CPA_Province',
        },
        {
            title:language.SIPCO_CPA_START,
            key: 'SIPCO_CPA_Start',
        },
        {
            title:language.SIPCO_CPA_END,
            key: 'SIPCO_CPA_End',
        },
        {
            title:language.SIPCO_CPA_COLOR,
            key: 'SIPCO_CPA_Color',
        },
        {
            title:language.SIPCO_CPA_DESCRIPTION,
            key: 'SIPCO_CPA_Description',
        },
        {
            title:language.STATUS,
            key: 'Status',
        },
        {
            title:language.CREATED_BY,
            key: 'CreatedBy',
        },
        {
            title:language.CREATED_DATE,
            key: 'CreatedDate',
        },
        {
            title:language.START_DATE,
            key: 'Start_Date',
        },
        {
            title:language.END_DATE,
            key: 'End_Date',
        },
        {
            title:language.UPDATED_BY,
            key: 'UpdatedBy',
        },
        {
            title:language.UPDATED_DATE,
            key: 'UpdatedDate',
        },
    ];
}
const displayEditFormKey = MPS_SCREEN_NO.CPA + MPS_DISPLAY_AREA_KEY.EditForm;
export function setDisplayFieldOnEditForm(values){
    localStorage.setItem(displayEditFormKey,JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình add/edit/view.
export function getDisplayFieldOnEditForm(){
    const column = JSON.parse(localStorage.getItem(displayEditFormKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if(column === null){
        return ['SIPCO_CPA_Code','Status','SIPCO_CPA_Province', 'SIPCO_CPA_Color','CreatedBy',
        'CreatedDate','UpdatedDate','UpdatedBy','Start_Date','End_Date','SIPCO_CPA_Start',
        'SIPCO_CPA_End','SIPCO_CPA_Description',];
    }
    return column;
}

export function getAllColumn() {
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    return [
        'SIPCO_CPA_Code','SIPCO_CPA_Province', 'SIPCO_CPA_Color','Start_Date','End_Date','SIPCO_CPA_Start',
        'SIPCO_CPA_End','SIPCO_CPA_Description',,'Status','CreatedBy',
        'CreatedDate','UpdatedDate','UpdatedBy'
    ];
}


//Thiết lập trường thông tin hiển thị.
export const initFieldControl = {
    SIPCO_CPA_Code:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    // SIPCO_CPA_Name:{
    //     // true: hiển thị ; false: không hiển thị
    //     Available: true,
    //     // true: bắt buộc nhập ; false: không bắt buộc nhập
    //     Required: true,
    //     Pattern: '',
    // },
    SIPCO_CPA_Province:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    SIPCO_CPA_Start:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    SIPCO_CPA_End:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    SIPCO_CPA_Color:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
    },
    Status:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
        Message:''
    },
    SIPCO_CPA_Description:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
    },
    Start_Date:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
    },
    End_Date:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
    },
};

export const productLisst=[
    {
        _id:1,
        Product_Code:'A',
        Product_Name:'A',
        CreatedBy:'',
        CreatedDate:'',
        UpdatedBy:'',
        UpdatedDate:'',
        Status:'',
    },
    {
        _id:2,
        Product_Code:'A',
        Product_Name:'A',
        CreatedBy:'',
        CreatedDate:'',
        UpdatedBy:'',
        UpdatedDate:'',
        Status:'',
    }
];
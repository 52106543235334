import React, { Fragment } from "react";
import { InputNumber, Form, Row, Col } from "antd";
import { Mps_Tooltip } from "../Mps_Tooltip/Mps_Tooltip";
import { MPS_VALUE_SCOPE } from "../../../commons";
import isNumber from "lodash/isNumber";

class Mps_InputRangeAmount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            min: null,
            max: null,
        };
    }

    //data có dạng như [12,13];
    onChangeMin = (value) => {
        const max = this.state.max === "" ? null : this.state.max;
        let data = [];
        if (value !== "" && value < this.props.min) {
            value = this.props.min;
        } else if (value !== "" && value > this.props.max) {
            value = this.props.max;
        }

        if (value > max && !!max && value !== "") {
            data = [null, max];
            this.props.onChange(data);
            this.setState({ max: null, min: value });
            return;
        } else {
            data = [value === "" ? null : value, max];
            this.props.onChange(data);
            this.setState({ max, min: value });
        }
    };

    onChangeMax = (value) => {
        const min = this.state.min === "" ? null : this.state.min;
        let data = [];
        if (value !== "" && value < this.props.min) {
            value = this.props.min;
        } else if (value !== "" && value > this.props.max) {
            value = this.props.max;
        }
        data = [min, value === "" ? null : value];
        this.props.onChange(data);
        this.setState({ min, max: value });
    };

    onBlur = () => {
        if (this.state.min > this.state.max) {
            this.setState({ max: null });
            const data = [this.state.min, null];
            if (!!this.props.form) {
                const changedValue = {};
                changedValue[
                    !!this.props.minName ? this.props.minName : "undefinded"
                ] = this.state.min;
                changedValue[
                    !!this.props.maxName ? this.props.maxName : "undefinded"
                ] = this.state.max;
                this.props.form.setFieldsValue(changedValue);
            }
            if (typeof this.props.onChange === "function") {
                this.props.onChange(data);
            }
        } else {
            if (!!this.props.form) {
                const changedValue = {};
                changedValue[
                    !!this.props.minName ? this.props.minName : "undefinded"
                ] = this.state.min;
                changedValue[
                    !!this.props.maxName ? this.props.maxName : "undefinded"
                ] = this.state.max;
                this.props.form.setFieldsValue(changedValue);
            }
            if (typeof this.props.onChange === "function") {
                const range = [
                    this.state.min === "" ? null : this.state.min,
                    this.state.max === "" ? null : this.state.max,
                ];
                this.props.onChange(range);
            }
        }
    };

    // onBlur = () => {
    //     if (!!this.props.form) {
    //         const changedValue = {};
    //         changedValue[!!this.props.name ? this.props.name : 'undefinded'] = this.state.value;
    //         this.props.form.setFieldsValue(changedValue);
    //     }
    //     if (typeof this.props.onChange === 'function') {
    //         this.props.onChange(this.state.value);
    //     }
    // };

    componentDidUpdate(prevProps, prevState) {
        if (
            !isNaN(this.props.value) &&
            prevProps.value !== this.props.value &&
            this.props.value !== this.state.value
        ) {
            if (this.props.form) {
                const changeValue = [];
                changeValue[this.props.name] = this.props.value;
                this.props.form.setFieldDecorator(changeValue);
            }
            this.setState({
                value: this.props.value,
            });
        }
    }

    render() {
        const data = this.props.value ? this.props.value : ["", ""];
        const currency = this.props.currency;
        return (
            <Row>
                <Col span={11}>
                    <Form.Item
                        help={
                            !!this.props.help ? (
                                <Mps_Tooltip title={this.props.help} />
                            ) : (
                                ""
                            )
                        }
                        hasFeedback
                    >
                        {!this.props.required ? (
                            <InputNumber
                                value={data[0]}
                                style={{ width: "100%", textAlign: "right" }}
                                className={this.props.className + " input-min"}
                                disabled={this.props.disabled}
                                onBlur={this.onBlur}
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, "")
                                }
                                onChange={this.onChangeMin}
                                min={
                                    isNumber(this.props.min)
                                        ? this.props.min
                                        : -9999999999
                                }
                                max={
                                    isNumber(this.props.max)
                                        ? this.props.max
                                        : 9999999999
                                }
                                formatter={(value) =>
                                    this.props.minus === false
                                        ? `${
                                              currency ? currency + "" : ""
                                          }${value}${currency ? "" : "đ"}`
                                              .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                              )
                                              .replace(/-/g, "")
                                        : `${
                                              currency ? currency + "" : ""
                                          }${value}${
                                              currency ? "" : "đ"
                                          }`.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                          )
                                }
                                parser={(value) =>
                                    this.props.minus === false
                                        ? value
                                              .replace(/\$\s?|(,*)/g, "")
                                              .replace(/-/g, "")
                                              .replace("đ", "")
                                              .replace(`${currency} `, "")
                                              .replace(`${currency}`, "")
                                        : value
                                              .replace(/\$\s?|(,*)/g, "")
                                              .replace("đ", "")
                                              .replace(`}${currency} `, "")
                                              .replace(`${currency}`, "")
                                }
                                onChange={this.onChangeMin}
                                onBlur={this.onBlur}
                            />
                        ) : (
                            this.props.getFieldDecorator(this.props.name, {
                                initialValue: this.props.value,
                                rules: [
                                    {
                                        required: this.props.required,
                                    },
                                ],
                            })(
                                <InputNumber
                                    value={data[0]}
                                    style={{
                                        width: "100%",
                                        textAlign: "right",
                                    }}
                                    className={
                                        this.props.className + " input-min"
                                    }
                                    onBlur={this.onBlur}
                                    onChange={this.onChangeMin}
                                    min={
                                        isNumber(this.props.min)
                                            ? this.props.min
                                            : -9999999999
                                    }
                                    max={
                                        isNumber(this.props.max)
                                            ? this.props.max
                                            : 9999999999
                                    }
                                    disabled={
                                        !!this.props.disabled
                                            ? this.props.disabled
                                            : false
                                    }
                                    formatter={(value) =>
                                        this.props.minus === false
                                            ? `${
                                                  currency ? currency + "" : ""
                                              }${value}${currency ? "" : "đ"}`
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  )
                                                  .replace(/-/g, "")
                                            : `${
                                                  currency ? currency + "" : ""
                                              }${value}${
                                                  currency ? "" : "đ"
                                              }`.replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                              )
                                    }
                                    parser={(value) =>
                                        this.props.minus === false
                                            ? value
                                                  .replace(/\$\s?|(,*)/g, "")
                                                  .replace(/-/g, "")
                                                  .replace("đ", "")
                                                  .replace(`${currency} `, "")
                                                  .replace(`${currency}`, "")
                                            : value
                                                  .replace(/\$\s?|(,*)/g, "")
                                                  .replace("đ", "")
                                                  .replace(`}${currency} `, "")
                                                  .replace(`${currency}`, "")
                                    }
                                    onBlur={this.onBlur}
                                />
                            )
                        )}
                    </Form.Item>
                </Col>
                <Col span={2} style={{ textAlign: "center" }}>
                    ~
                </Col>
                <Col span={11}>
                    <Form.Item
                        help={
                            !!this.props.help ? (
                                <Mps_Tooltip title={this.props.help} />
                            ) : (
                                ""
                            )
                        }
                        hasFeedback
                    >
                        {!this.props.required ? (
                            <InputNumber
                                value={data[1]}
                                style={{ width: "100%", textAlign: "right" }}
                                className={this.props.className + " input-min"}
                                disabled={this.props.disabled}
                                onBlur={this.onBlur}
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, "")
                                }
                                onChange={this.onChangeMax}
                                min={
                                    isNumber(this.props.min)
                                        ? this.props.min
                                        : -9999999999
                                }
                                max={
                                    isNumber(this.props.max)
                                        ? this.props.max
                                        : 9999999999
                                }
                                formatter={(value) =>
                                    this.props.minus === false
                                        ? `${
                                              currency ? currency + "" : ""
                                          }${value}${currency ? "" : "đ"}`
                                              .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                              )
                                              .replace(/-/g, "")
                                        : `${
                                              currency ? currency + "" : ""
                                          }${value}${
                                              currency ? "" : "đ"
                                          }`.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                          )
                                }
                                parser={(value) =>
                                    this.props.minus === false
                                        ? value
                                              .replace(/\$\s?|(,*)/g, "")
                                              .replace(/-/g, "")
                                              .replace("đ", "")
                                              .replace(`${currency} `, "")
                                              .replace(`${currency}`, "")
                                        : value
                                              .replace(/\$\s?|(,*)/g, "")
                                              .replace("đ", "")
                                              .replace(`}${currency} `, "")
                                              .replace(`${currency}`, "")
                                }
                                onChange={this.onChangeMax}
                                onBlur={this.onBlur}
                            />
                        ) : (
                            this.props.getFieldDecorator(this.props.name, {
                                initialValue: this.props.value,
                                rules: [
                                    {
                                        required: this.props.required,
                                    },
                                ],
                            })(
                                <InputNumber
                                    value={data[1]}
                                    style={{
                                        width: "100%",
                                        textAlign: "right",
                                    }}
                                    className={
                                        this.props.className + " input-min"
                                    }
                                    onBlur={this.onBlur}
                                    onChange={this.onChangeMax}
                                    min={
                                        isNumber(this.props.min)
                                            ? this.props.min
                                            : -9999999999
                                    }
                                    max={
                                        isNumber(this.props.max)
                                            ? this.props.max
                                            : 9999999999
                                    }
                                    disabled={
                                        !!this.props.disabled
                                            ? this.props.disabled
                                            : false
                                    }
                                    formatter={(value) =>
                                        this.props.minus === false
                                            ? `${
                                                  currency ? currency + "" : ""
                                              }${value}${currency ? "" : "đ"}`
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  )
                                                  .replace(/-/g, "")
                                            : `${
                                                  currency ? currency + "" : ""
                                              }${value}${
                                                  currency ? "" : "đ"
                                              }`.replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                              )
                                    }
                                    parser={(value) =>
                                        this.props.minus === false
                                            ? value
                                                  .replace(/\$\s?|(,*)/g, "")
                                                  .replace(/-/g, "")
                                                  .replace("đ", "")
                                                  .replace(`${currency} `, "")
                                                  .replace(`${currency}`, "")
                                            : value
                                                  .replace(/\$\s?|(,*)/g, "")
                                                  .replace("đ", "")
                                                  .replace(`}${currency} `, "")
                                                  .replace(`${currency}`, "")
                                    }
                                    onBlur={this.onBlur}
                                />
                            )
                        )}
                    </Form.Item>
                </Col>
            </Row>
        );
    }
}
export { Mps_InputRangeAmount };

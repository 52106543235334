import { authHeader } from '../../../authenticate/Helpers';
import { API_URL } from '../../../conf.js';
import { isNullOrUndefined } from 'util';
import { Mps_MethodCommon } from '../../../commons';

export const System_Company_Service = {
    getCompanyProfile,
    create,
    updateCompanyProfile,
    getDataFilter,
    createMany,
    search,
    getById,
    deleteModel,
    getByCode,
    generateToken
};

function search(searchModel, searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };

    return fetch(`${API_URL}`+ `/System_Company/search`, requestOptions).then(handleResponse);
}

function getDataFilter(searchModel, page = 1, limit = Mps_MethodCommon.paginationLimitMaximum) {
    const value = {
        searchModel,
        page,
        limit
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(`${API_URL}` + `/System_Company/getDataFilter`, requestOptions).then(handleResponse);
}

function getCompanyProfile(_id) {
    let auth = authHeader();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${API_URL}` + `/System_Company/getCompanyProfile/${_id}`, requestOptions).then(handleResponse);
}

//Lấy thông tin chi tiết của một System_User theo Id.
function getById(_id) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'GET'

    };
    return fetch(`${API_URL}` + `/System_Company/getById/${_id}`, requestOptions).then(handleResponse);
}

function getByCode(code) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'GET'

    };
    return fetch(`${API_URL}` + `/System_Company/getByCode/${code}`, requestOptions).then(handleResponse);
}

function updateCompanyProfile(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(model)
    };
    return fetch(`${API_URL}` + `/System_Company/updateCompanyProfile`, requestOptions).then(handleResponse);
}

function generateToken(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(model)
    };
    return fetch(`${API_URL}` + `/System_Company/generateToken`, requestOptions).then(handleResponse);
}

function create(model) {
    console.log(model)
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(model)
    };
    return fetch(`${API_URL}` + `/System_Company/create`, requestOptions).then(handleResponse);
}

function createMany(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(model)
    };
    return fetch(`${API_URL}` + `/System_Company/createMany`, requestOptions).then(handleResponse);
}

//Xóa thông tin một/danh sách System_Company
function deleteModel(model) {
    if (!isNullOrUndefined(model)) {
        if (model.length > 0) {
            const auth = authHeader();
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', auth);
            const requestOptions = {
                headers,
                method: 'POST',
                body: JSON.stringify({ model })
            };
            return fetch(`${API_URL}` + `/System_Company/delete`, requestOptions).then(handleResponse);
        }
    } else {
        return false;
    }
    return null;

}
function handleResponse(response) {
    const responseStatusCode = 401;
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === responseStatusCode) {
                // auto logout if 401 response returned from api
                //logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

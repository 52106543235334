import React from 'react';
import { Drawer, Button, Form, Checkbox, Row, Col, Icon, Tooltip, Select } from 'antd';
import jsonQuery from 'json-query';
import { LANGUAGE_STATE } from './Language/ln';
import { MPS_DISPLAY_AREA_KEY, MPS_DISPLAY_AREA_STORAGE, Mps_MethodCommon } from '../../../commons';
const { Option } = Select;
class Mps_DisplayField extends React.Component {

    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language[0];
        const language = jsonQuery([chooseLanguage], { data: LANGUAGE_STATE }).value;
        this.state = {
            language,
            visible: false,
            checkAll: false
        };
    }
    showDrawer = () => {
        this.setState({
            visible: true
        });
    };
    onReset = () => {
        const displaySearchConditionsKey = this.props.screenNo + MPS_DISPLAY_AREA_KEY.DisplaySearchConditions;
        const displaySortFieldsKey = this.props.screenNo + MPS_DISPLAY_AREA_KEY.DisplaySortFields;
        const displaySearchResultsKey = this.props.screenNo + MPS_DISPLAY_AREA_KEY.DisplaySearchResults;
        const displayEditFormKey = this.props.screenNo + MPS_DISPLAY_AREA_KEY.EditForm;
        const displayEditFormDetail = this.props.screenNo + MPS_DISPLAY_AREA_KEY.EditFormDetail;
        localStorage.removeItem(displaySearchConditionsKey);
        localStorage.removeItem(displaySortFieldsKey);
        localStorage.removeItem(displaySearchResultsKey);
        localStorage.removeItem(displayEditFormKey);
        localStorage.removeItem(displayEditFormDetail);
        if (this.props.onDisplayChange) {
            this.props.onDisplayChange(null);
        }
    };
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    handleDisplaySearchConditionsChange = values => {
        const displaySearchConditionsKey = this.props.screenNo + MPS_DISPLAY_AREA_KEY.DisplaySearchConditions;
        localStorage.setItem(displaySearchConditionsKey, JSON.stringify(values));
        this.props.onDisplayChange(MPS_DISPLAY_AREA_STORAGE.searchField, values);
    };
    handleDisplaySortFieldsChange = values => {
        const displaySortFieldsKey = this.props.screenNo + MPS_DISPLAY_AREA_KEY.DisplaySortFields;
        localStorage.setItem(displaySortFieldsKey, JSON.stringify(values));
        this.props.onDisplayChange(MPS_DISPLAY_AREA_STORAGE.sortField, values);
    };
    handleDisplayColumnOnEditTableChange = values => {
        const displayEditFormDetail = this.props.screenNo + MPS_DISPLAY_AREA_KEY.EditFormDetail;
        localStorage.setItem(displayEditFormDetail, JSON.stringify(values));
        this.props.onDisplayChange(MPS_DISPLAY_AREA_STORAGE.editResult, values);
    };
    handleDisplayColumnSearchResultsChange = values => {
        const displaySearchResultsKey = this.props.screenNo + MPS_DISPLAY_AREA_KEY.DisplaySearchResults;
        const displaySortFieldsKey = this.props.screenNo + MPS_DISPLAY_AREA_KEY.DisplaySortFields;
        if (localStorage.getItem(displaySortFieldsKey)) {
            const string = [];
            const sortFields = JSON.parse(localStorage.getItem(displaySortFieldsKey));
            for (let i = 0; i < sortFields.length; i++) {
                if (values.includes(sortFields[i])) {
                    string.push(sortFields[i]);
                }
            }
            if (string.length > 0) {
                localStorage.setItem(displaySortFieldsKey, JSON.stringify(string));
                this.props.onDisplayChange(MPS_DISPLAY_AREA_STORAGE.sortField, string);
            } else {
                localStorage.removeItem(displaySortFieldsKey);
            }
        }
        localStorage.setItem(displaySearchResultsKey, JSON.stringify(values));
        this.props.onDisplayChange(MPS_DISPLAY_AREA_STORAGE.searchResult, values);
    };
    handleSortTypeChange = () => {
        const { sortField } = this.props;
        const sortAscending = !sortField.sortAscending;
        this.props.onDisplayChange(MPS_DISPLAY_AREA_STORAGE.sortType, sortAscending);
    };

    handleDisplayFieldOnEditFormChange = values => {
        const displayEditFormKey = this.props.screenNo + MPS_DISPLAY_AREA_KEY.EditForm;
        localStorage.setItem(displayEditFormKey, JSON.stringify(values));
        this.props.onDisplayChange(MPS_DISPLAY_AREA_STORAGE.editField, values);
    };

    render() {
        const language = this.state.language;
        const { sortField } = this.props;
        const areas = this.props.columns;
        return (
            <span className={'mps-display-options ' + `${this.props.className} `} style={this.props.style}>
                <Tooltip id={Mps_MethodCommon.getDisplayTheme()} placement='leftBottom' title={language.TITLE} >
                    <Icon type='setting' onClick={this.showDrawer}
                        style={{ float: 'right', fontSize: '25px', marginTop: 10, marginRight: 10, color: 'seagreen' }} />
                </Tooltip>
                <Drawer
                    title={language.TITLE}
                    width={420}
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}>
                    {
                        !!sortField ? (
                            <div className=' mps-display-sort-field '>
                                <Form.Item >
                                    <span style={{ fontWeight: 'bold' }}>{language.TITLE_DISPLAY_SORT_RESULT_AREA}
                                    </span>
                                    <Icon type={(sortField.sortAscending) ? 'sort-ascending' : 'sort-descending'}
                                        onClick={this.handleSortTypeChange}
                                        style={{ fontSize: 20, fontWeight: 'bold' }} />
                                    <Select
                                        style={{ width: '100%' }}
                                        mode='multiple'
                                        allowClear={true}
                                        defaultValue={sortField.data}
                                        onChange={this.handleDisplaySortFieldsChange}
                                    >
                                        {sortField.fields.map(select => select.className === ''
                                            || select.className === 'cell-ellipsis'
                                            || select.className === 'display-date'
                                            || select.className === 'cell-ellipsis display-date' ? <Option key={select.key} value={select.key}>{select.title}</Option> : '')}
                                    </Select>
                                </Form.Item>
                            </div>
                        ) : ''
                    }
                    {
                        areas.map((area, index) =>
                            area.type === MPS_DISPLAY_AREA_STORAGE.searchField
                                ? (
                                    <div className='mps-display-column-result' key={index} >
                                        <span style={{ fontWeight: 'bold', fontSize: 15 }}>{language.TITLE_SEARCH_CONDITION_AREA}</span>
                                        <Checkbox.Group style={{ width: '100%' }} onChange={this.handleDisplaySearchConditionsChange}
                                            value={area.data}>
                                            <Row>
                                                {area.fields.map(column =>
                                                    <Col key={column.key + Math.random()} span={12}>
                                                        <Checkbox key={column.key} value={column.key}>{column.title}</Checkbox>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                ) :
                                (

                                    area.type === MPS_DISPLAY_AREA_STORAGE.searchResult
                                        ? (
                                            <div className='mps-display-column-result' key={index}>
                                                <span style={{ fontWeight: 'bold', fontSize: 15 }}>{language.TITLE_SEARCH_RESULT_AREA}</span>
                                                <Checkbox.Group style={{ width: '100%' }} onChange={this.handleDisplayColumnSearchResultsChange}
                                                    value={area.data}>
                                                    <Row>
                                                        {area.fields.map(column =>
                                                            <Col key={column.key + Math.random()} span={12}>
                                                                <Checkbox key={column.key} value={column.key}>{column.title}</Checkbox>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Checkbox.Group>
                                            </div>
                                        ) :
                                        area.type === 'displayFieldOnEditForm'
                                            ?
                                            (
                                                <div className='mps-display-column-result' key={index}>
                                                    <span style={{ fontWeight: 'bold', fontSize: 15 }}>{language.TITLE_EDIT_FORM}</span>
                                                    <Checkbox.Group style={{ width: '100%' }} onChange={this.handleDisplayFieldOnEditFormChange}
                                                        value={area.data}>
                                                        <Row>
                                                            {area.fields.map(column =>
                                                                <Col key={column.key + Math.random()} span={12}>
                                                                    <Checkbox key={column.key} value={column.key}>{column.title}</Checkbox>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </Checkbox.Group>
                                                </div>
                                            )
                                            :
                                            (
                                                <div className='mps-display-column-result' key={index}>
                                                    <span style={{ fontWeight: 'bold', fontSize: 15 }}>{language.TITLE_DISPLAY_RESULT_AREA}</span>
                                                    <Checkbox.Group style={{ width: '100%' }} onChange={this.handleDisplayColumnOnEditTableChange}
                                                        value={area.data}>
                                                        <Row>
                                                            {area.fields.map(columnresult =>
                                                                <Col key={columnresult.key + Math.random()} span={12}>
                                                                    <Checkbox key={columnresult.key} value={columnresult.key}>{columnresult.title}</Checkbox>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </Checkbox.Group>
                                                </div>
                                            )
                                )
                        )
                    }

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px'
                        }} >
                        <Button style={{ marginRight: 8 }} className={Mps_MethodCommon.getDisplayTheme()} onClick={this.onReset} type='primary'>
                            {language.RESET}
                        </Button>
                        <Button style={{ marginRight: 8 }} className={Mps_MethodCommon.getDisplayTheme()} onClick={this.onClose} type='primary'>
                            {language.CLOSE}
                        </Button>
                    </div>
                </Drawer>
            </span>
        );
    }
}
export { Mps_DisplayField };


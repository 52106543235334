export const MESSAGE_STATE = {
    'vn':
    {
        USERNAMENOTNULL: 'Hộp thư hoặc số điện thoại không để trống',
        DATANOTFOUND: 'Dữ liệu không tìm thấy',
        PASSWORD_NOT_CORRECT: 'Mật mã cũ không chính xác',
        PASSWORD_NULL: 'Mật mã bị trống',
        PASSWORD_FORMAT: 'Mật mã tối thiểu 6 kí tự',
        PASSWORD_NOT_MAP: 'Mật mã không khớp',
        USERNAME_IN_CORRECT: 'Thông tin hộp thư, số điện thoại chưa chính xác',
        COMPANY_CODE_INCORRECT: 'Mã công ty không chính xác',
    },
    'en':
    {
        USERNAMENOTNULL: 'User name is required',
        DATANOTFOUND: 'Data cannot find',
        PASSWORD_NOT_CORRECT: 'Old password is not correct',
        PASSWORD_NULL: 'User name and password are required',
        PASSWORD_NOT_NULL: 'Password is required',
        PASSWORD_FORMAT: 'Password is required at least 6 characters',
        PASSWORD_NOT_MAP: 'Password confirm is not map',
        USERNAME_NOT_NULL: 'User name is required',
        USERNAME_IN_CORRECT: 'Password is incorrect, please check your user name and password',
        COMPANY_CODE_INCORRECT: 'Company code is incorrect',
    }
};


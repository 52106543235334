import React from 'react';
import { Tooltip, Icon } from 'antd';
import { Mps_MethodCommon } from '../../../commons';

class Mps_Tooltip extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.title) {
            return '';
        }
        var requiredStyle = this.props.required ? { "color": "red" } : {};

        return (
            <Tooltip title={this.props.title} id={Mps_MethodCommon.getDisplayTheme()}  >
                <Icon id={Mps_MethodCommon.getDisplayTheme()} type="question-circle-o" style={requiredStyle} />
            </Tooltip>
        );
    }
}
export { Mps_Tooltip };
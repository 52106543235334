import React from 'react';
import { Mps_LabelFormat } from '../../Mps_Control/Mps_LabelFormat/Mps_LabelFormat';
import { CSVLink } from 'react-csv';
import { Button } from 'antd';
import jsonQuery from 'json-query';
import { Mps_MethodCommon } from '../../../commons';
import moment from 'moment';
class Mps_Download extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { value, language, headers } = this.props;
    const modelListExport = jsonQuery('[**]docs[**]', { data: value }).value;
    const row = {};
    headers.forEach(element => {
      const arrayObject = String(element.key);
      Object.defineProperty(row, arrayObject, {
        value: arrayObject,
        writable: true,
        enumerable: true,
        configurable: true
      });
    });
    const dataSource = [];
    dataSource.push(row);
    if (!!modelListExport) {
      modelListExport.forEach(element => {
        dataSource.push(element);
      });
    }
    const filename = this.props.label ? this.props.label + '.csv' : `${this.props.name} ${Mps_LabelFormat.fotmatDateTimeHour(moment(Date.now()))}.csv`;
    return (
      <Button type='primary' className={Mps_MethodCommon.getDisplayTheme()} disabled = {this.props.disabled}>
        <CSVLink data={dataSource} target='_blank' filename={filename} headers={headers} > {language} </CSVLink>
      </Button>
    );
  }
}
export { Mps_Download };

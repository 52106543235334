export const LANGUAGE_STATE = {
    'vn':
    {
        LOGINSCREEN: 'CÔNG TY CỔ PHẦN XÚC TIẾN ĐẦU TƯ VÀ PHÁT TRIỂN BỀN VỮNG',
        LANGUAGE: 'Ngôn ngữ',
        USER_Id: 'Tên đăng nhập',
        USER_NAME: 'Hộp thư hoặc số điện thoại',
        PASSWORD: 'Mật mã',
        LOGIN: 'Đăng nhập',
        COMPANY_CODE: 'Mã công ty',
        FORGOT_PASSWORD: 'Quên mật khẩu?'
    },
    'en':
    {
        LOGINSCREEN: 'CÔNG TY CỔ PHẦN XÚC TIẾN ĐẦU TƯ VÀ PHÁT TRIỂN BỀN VỮNG',
        LANGUAGE: 'Language',
        USER_Id: 'User name',
        USER_NAME: 'Email or phone number',
        PASSWORD: 'Password',
        LOGIN: 'Login',
        COMPANY_CODE: 'Company code',
        FORGOT_PASSWORD: 'Forgot password?'
    }
};


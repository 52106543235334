export const LANGUAGE_STATE = {
    'vn':
    {
        IMPORT: 'Nhập file',
        SAVE: 'Lưu',
        CANCEL: 'Hủy',
        FILE_EXAMPLE: 'Tải file mẫu'
    },
    'en':
    {
        IMPORT: 'Import',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        FILE_EXAMPLE: 'Dowload file example'
    }
};

import React from 'react';
import {
    Form, Input, Button, Tabs, Row, Col, Modal
    , Card
} from 'antd';
import jsonQuery from 'json-query';
import {
    MPS_MESSAGE_STATE
    , Mps_MethodCommon,
    MPS_DISPLAY_AREA_STORAGE
} from '../../commons';
import { authenticationService } from '../../authenticate/Services';
//Import MPS common control here...
import {Mps_LabelFormat,
} from '../Mps_Control';
import { Sipco_WaterFilter_Service } from './Service/Sipco_WaterFilter.Service';
import { LANGUAGE_STATE } from './Language/ln';
import { GUIDE_LINE } from './Language/Guideline';
import { MESSAGE_STATE } from './Language/ms';
import {
    initModel, setDisplayFieldOnEditForm
} from './Models/Sipco_WaterFilter.Model';
import { Log_Service } from '../../authenticate/Helpers/Log.Service';
import { Mps_Download} from '../Mps_Control';
import {headers,  getDisplayColumnSearchResults
    , setDisplayColumnSearchResults,initResultTable} from './Models/Sipco_WaterFilter.Model'
    import { SIPCO_CPA_AutoComplete } from '../SIPCO_CPA/Controls/SIPCO_CPA_AutoComplete';

class Sipco_WaterFilter_ExportCPA extends React.Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([this.props.language[0]], { data: LANGUAGE_STATE }).value;
        const commonMessage = jsonQuery([this.props.language[0]], { data: MPS_MESSAGE_STATE }).value;
        const guideLine = jsonQuery([this.props.language[0]], { data: GUIDE_LINE }).value;
        const validMessage = jsonQuery([this.props.language[0]], { data: MESSAGE_STATE }).value;

        this.state = {
            file: [],
            language,
            commonMessage,
            guideLine,
            initModel,
            chooseLanguage,
            visible: false,
            validMessage,
            data: [],
            searchOptions: {
                page: 1,
                language: chooseLanguage,
                sortAscending: true
            },
            searchModel:{},
            disabled: true

        };
    }
    /**
     * @desc Khởi tạo data khi load component
     * @param Nothing
     * @return Nothing
    */

    componentWillMount() {
        const selectedModel = this.props.value;
        this.setState({
            selectedModel,
        });

    }

    handleCodeChange = value => {
        var searchObjectModel = this.state.searchModel;
        var searchOptions = this.state.searchOptions;
            
            searchObjectModel.SIPCO_CPA_Code = value
            Promise.all([Sipco_WaterFilter_Service.searchForDownload(searchObjectModel, searchOptions)]).then((result) => {
                var data = jsonQuery('result', { data: result }).value;
                const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
                const dataReturn = [{ docs: tempDataForDownload }]
                /*demo mẫu logger*/
                //Log_Service.create("Search", data);
                //console.log(result)
                
                this.convertToDownload(data)
                this.setState({
                    dataSource: data,
                    isLoading: false,
                    dataForDownload: data,
                    searchModel:searchObjectModel

                });
            }).catch(reason => {
                Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
                this.setState({
                    isLoading: false,
    
                });
            });
    };

    /**
    * @desc Handle thiết lập sắp xếp hiển thị field trên form.
    * @param
    *   array value: Danh sách Field được chọn hiển thị trên form.
    * @return Nothing (Cập nhật setting),
    */
    handleDisplayFieldOnEditFormChange = value => {
        setDisplayFieldOnEditForm(value);
        this.setState({
            displayDefaultFieldOnEditForm: value
        });
    };

    /**
     * @desc Handle sự kiện hiển thị màn hình View/Add/Copy/Edit
     * @param event
     *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
     *   object data - dữ liệu hiển thị
     * @return Nothing
     */
    handleShowForm = e => {
        e.preventDefault();
        const userInfo = authenticationService.getUserInfor();
        const user = jsonQuery('[**]User[0]', { data: userInfo }).value;
        //console.log(localStorage.getItem('user'))
        const userId = user._id;
        this.setState({
            visible: true
        })

       
        
    };

    convertToDownload = (data) => {
        if (!!data && data.length > 0) {
            data.forEach(d => {
                d.Serial_Code = (!!d.Serial_Code ? (d.Serial_Code) : '');
                d.Owner = (!!d.Owner ? (d.Owner) : '');
                d.Sipco_WaterFilter_Description = (!!d.Sipco_WaterFilter_Description ? (+ d.Sipco_WaterFilter_Description) : '');
                d.Province = (!!d.Province ? (d.Province) : '');
                d.District = (!!d.District ? (d.District) : '');
                d.Ward = (!!d.Ward ? (d.Ward) : '');
                d.Phone = (!!d.Phone ? (d.Phone) : '');
                d.Provide_Date = !Mps_LabelFormat.formatDateTime(d.Provide_Date) ? '' : (+ Mps_LabelFormat.formatDateTime(d.Provide_Date));
                d.Sipco_WaterFilter_Description = (!!d.Sipco_WaterFilter_Description ? (+ d.Sipco_WaterFilter_Description) : '');
                // d.UpdatedBy = (!!d.UpdatedBy ? d.UpdatedByObject.System_User_DisplayName : '');
                d.UpdatedDate = !Mps_LabelFormat.formatDateTime(d.UpdatedDate) ? '' : (+ Mps_LabelFormat.formatDateTime(d.UpdatedDate));
                // d.CreatedBy = (!!d.CreatedBy ? d.CreatedByObject.System_User_DisplayName : '');
                d.CreatedDate = Mps_LabelFormat.formatDateTime(d.CreatedDate);
                // d.Status = (!!d.StatusObject ? d.StatusObject.System_Parameter_Name : '');
            });
            const dataForDownload = [{
                docs: data
            }];
            this.setState({ dataForDownload });
        }
        else {
            const tempData = JSON.parse(JSON.stringify(this.state.dataSource));
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: tempData }).value;
            this.convertToDownload(tempDataForDownload);
        }
    };

    /**
    * @desc Handle sự kiện tắt màn hình
    * @param event
    *   Nothing
    * @return Nothing
    */
    handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.handleReset()
    };

    /**
    * @desc Handle sự kiện reset thông tin trên lưới
    * @param event
    *   Nothing
    * @return Nothing
    */
    handleReset = () => {
        this.props.form.resetFields();
    };

    /**
   * @desc Handle tùy chọn hiển thị
   * @param
   *   Nothing: nothing
   * @return Nothing
   */
    handleDisplayChange = (type, value) => {
        if (!value && type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
            this.setState({ isReset: this.state.isReset ? false : true });
        }
        if (type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
            if (type === MPS_DISPLAY_AREA_STORAGE.sortField) {
                const { searchOptions } = this.state;
                searchOptions.sort = value;
                this.setState({
                    searchOptions
                });
                return;
            }
            this.setState({ [type]: value });
        } else {
            const { searchOptions } = this.state;
            searchOptions.sortAscending = value;
            this.setState({
                sortAscending: value,
                searchOptions
            });
        }
    };

    handleDisplayColumnSearchResultsChange = value => {
        setDisplayColumnSearchResults(value);
        this.setState({
            displayColumnSearchResults: value
        });
    };

    render() {
        if (this.props.allowAccess === false) {
            return '';
        }
        const { language, chooseLanguage } = this.state;
        let screenName = language.EXPORT_BY_CPA

        const gridStyle = {
            width: '50%',
        };
        const displayColumnSearchResults = getDisplayColumnSearchResults(this.state.displayColumnSearchResults);
        const columnResultTable = initResultTable(language, displayColumnSearchResults);
        const displaySearchConditions = this.props.displaySearchConditions;

        const headersExport = headers(columnResultTable);
        //console.log(this.state.dataForDownload)
        return (
            <span >
                {this.props.allowAccess === true ? <Button type="primary" onClick={this.handleShowForm}>{this.props.actionName}</Button> : ' '}
                <Modal
                    title={screenName}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <><Mps_Download value={this.state.dataForDownload}
                                                language={language.DOWNLOADDATA} name={language.SIPCO_CPA_DATA_FILE}
                                                headers={headersExport}  /></>,
                        <Button key="back" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleCancel}>
                            {language.CLOSE}
                        </Button>,
                        
                    ]}
                >
                <Card>

                <SIPCO_CPA_AutoComplete 
                                className={Mps_MethodCommon.checkFieldAvailable(displaySearchConditions, 'SIPCO_CPA_Code')}
                                value={this.state.searchModel.SIPCO_CPA_Code}
                                language={chooseLanguage}
                                style={gridStyle}
                                onChange={this.handleCodeChange} />
                </Card>

                </Modal>
            </span>
        );
    }
}
const WrappedSipco_WaterFilter_ExportCPA = Form.create({ name: 'Sipco_WaterFilter_ExportCPA' })(Sipco_WaterFilter_ExportCPA);
export { WrappedSipco_WaterFilter_ExportCPA as Sipco_WaterFilter_ExportCPA };

import React from 'react';
import jsonQuery from 'json-query';
import { Mps_LabelFormat } from '../Mps_LabelFormat/Mps_LabelFormat';
import { Mps_Tooltip } from '../Mps_Tooltip/Mps_Tooltip';
import { Mps_LayoutFormat } from '../../Mps_Control';
import { LANGUAGE_STATE } from './Language/ln';
import moment from 'moment';
import { DatePicker, Form, TimePicker } from 'antd';
class Mps_InputRangePicker extends React.Component {

    constructor(props) {
        super(props);
        const chooseLanguage = (!!this.props.language) ? this.props.language : 'vn';
        const language = jsonQuery([chooseLanguage], { data: LANGUAGE_STATE }).value;
        this.state = {
            language
        };
    }

    handleDateChange = (dates, dateStrings) => {
        this.props.onChange(dates);
    };

    handleCalendarChange = dates => {
        this.props.onChange(dates);
    };

    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.value !== this.props.value && nextProps.value !== undefined && nextProps !== null);
    }
    getName = name => {
        if (!!name) {
            return name;
        } else {
            return '';
        }
    };
    render() {
        const startDate = (!!this.props.value) ? this.props.value[0] : '';
        const endDate = (!!this.props.value) ? this.props.value[1] : '';
        const language = this.state.language;
        const format = this.props.format ? this.props.format : Mps_LabelFormat.displayDateFormat;
        const getFieldDecoratorName = this.getName(this.props.name);
        const thisWeek = language.TITLE_THIS_WEEK;
        return (
            <Form.Item
                help={
                    (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
                }
                hasFeedback
            >
                {
                    this.props.typeTime === true ? <TimePicker.RangePicker
                        style={{ width: '100%', textAlign: 'center' }}
                        disabled={this.props.disabled}
                        defaultValue={[startDate, endDate]}
                        value={[startDate, endDate]}
                        format={format}
                        onCalendarChange={this.handleCalendarChange}
                        onChange={this.handleDateChange} />
                        : (<DatePicker.RangePicker renderExtraFooter={() => 'Have a nice day!'}
                            ranges={{
                                Today: [moment(), moment()],
                                'This Week': [moment().startOf('week').add(1, 'days'), moment().endOf('week').add(1, 'days')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Week': [moment().startOf('week').add(-1, 'weeks').add(1, 'days'), moment().endOf('week').add(-1, 'weeks').add(1, 'days')],
                                'Last Month': [moment().add(-1, 'months').startOf('month'), moment().add(-1, 'months').endOf('month')]
                            }}
                            allowClear={this.props.allowClear}
                            style={{ width: '100%' }}
                            value={[startDate, endDate]}
                            disabled={this.props.disabled}
                            // defaultValue={[startDate, endDate]}
                            format={format}
                            onCalendarChange={this.handleCalendarChange}
                            onChange={this.handleDateChange} />
                        )}
            </Form.Item>
        );
    }
}
export { Mps_InputRangePicker };

import { authHeader } from '../../../authenticate/Helpers';
import {API_URL} from '../../../conf.js';
import {Mps_MethodCommon} from '../../../commons';
import {authenticationService} from '../../../authenticate/Services';

export const Sipco_UserFeedback_Service = {
    search,
    getById,
    create,
    update,
    updateMany,
    deleteModel,
    getDataFilter,
    importModel
};

const content = 'Content-Type';
const Type = 'application/json';
//Lấy thông tin chi tiết của Sipco_UserFeedback theo điều kiện tìm kiếm được nhập.
function search(searchModel,searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };

    return fetch(`${API_URL}/Sipco_UserFeedback/search`, requestOptions).then(handleResponse);
}

//Lấy thông tin chi tiết của một Sipco_UserFeedback theo Id.
function getById(_id) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'GET',
        headers
    };
    return fetch(`${API_URL}/Sipco_UserFeedback/getById/${_id}`, requestOptions).then(handleResponse);
}

//Lấy dữ liệu hiển thị cho autocomplete.
function getDataFilter(searchModel,  page = 1, limit = Mps_MethodCommon.paginationLimitMaximum) {
    const value = {
        searchModel,
        page,
        limit,
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(`${API_URL}/Sipco_UserFeedback/getDataFilter`, requestOptions).then(handleResponse);
}

//Tạo mới một Sipco_UserFeedback
function create(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(`${API_URL}/Sipco_UserFeedback/create`, requestOptions).then(handleResponse);
}

//Import data
function importModel(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify({model})
    };
    return fetch(`${API_URL}/Sipco_UserFeedback/import`, requestOptions).then(handleResponse);
}

//Cập nhật thông tin một Sipco_UserFeedback
function update(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(`${API_URL}/Sipco_UserFeedback/update`, requestOptions).then(handleResponse);
}

//Cập nhật thông tin một Sipco_UserFeedback
function updateMany(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(`${API_URL}/Sipco_UserFeedback/updateMany`, requestOptions).then(handleResponse);
}

//Xóa thông tin một/danh sách Sipco_UserFeedback
function deleteModel(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify({model})
    };
    return fetch(`${API_URL}/Sipco_UserFeedback/delete`, requestOptions).then(handleResponse);

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                //authenticationService.logout();    
                window.location.reload(true);            
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

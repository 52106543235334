export const LANGUAGE_STATE ={
    'vn':
        {
            TITLE: 'Tùy chọn hiển thị',
            TITLE_EDIT_FORM: 'Tùy chọn hiển thị trường dữ liệu',
            TITLE_SEARCH_CONDITION_AREA: 'Chọn hiển thị điều kiện tìm kiếm',
            TITLE_SEARCH_RESULT_AREA: 'Chọn hiển thị kết quả tìm kiếm',
            TITLE_DISPLAY_RESULT_AREA: 'Chọn hiển thị trường dữ liệu bảng',
            TITLE_DISPLAY_SORT_RESULT_AREA: 'Sắp xếp hiển thị theo thứ tự bảng chữ cái',
            TITLE_DISPLAY_SORT_ASCENDING: 'tăng',
            TITLE_DISPLAY_SORT_DESCENDING: 'giảm',
            RESET: 'Làm mới',
            CLOSE: 'Đóng',
        },
    'en':
        {
            TITLE: 'Display options',
            TITLE_EDIT_FORM: 'Display optional fields',
            TITLE_SEARCH_CONDITION_AREA: 'Choose display search conditions',
            TITLE_SEARCH_RESULT_AREA: 'Choose display search result',
            TITLE_DISPLAY_SORT_RESULT_AREA: 'Sort display by alphabet',
            TITLE_DISPLAY_RESULT_AREA: 'Choose display table fields',
            TITLE_DISPLAY_SORT_ASCENDING: 'ascending',
            TITLE_DISPLAY_SORT_DESCENDING: 'descending',
            RESET: 'Reset',
            CLOSE: 'Close',
        }    
    }

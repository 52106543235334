import React, { Fragment } from 'react';
import { InputNumber, Form, Row, Col } from 'antd';
import { Mps_Tooltip } from '../Mps_Tooltip/Mps_Tooltip';
import { MPS_VALUE_SCOPE } from '../../../commons/'

class Mps_InputRangeQuantity extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            min: null,
            max: null
        });
    }

    //data có dạng như [12,13];
    onChangeMin = value => {
        const max = this.state.max === '' ? null : this.state.max;
        let data = [];
        if(value !== '' && value < this.props.min) {
            value = this.props.min;
        }
        else if(value !== '' && value > this.props.max) {
            value = this.props.max;
        }

        if (value > max && !!max && value !== '') {
            data = [null, max];
            this.props.onChange(data);
            this.setState({ max: null, min: value });
            return;
        } else {
            data = [value === '' ? null : value, max];
            this.props.onChange(data);
            this.setState({ max, min: value });
        }
    };

    onChangeMax = value => {
        const min = this.state.min === '' ? null : this.state.min;
        let data = [];
        if(value !== '' && value < this.props.min) {
            value = this.props.min;
        }
        else if(value !== '' && value > this.props.max) {
            value = this.props.max;
        }
        data = [min, value === '' ? null : value];
        this.props.onChange(data);
        this.setState({ min, max: value });
    };

    onBlur = () => {
        if (this.state.min > this.state.max) {
            this.setState({ max: null });
            const data = [this.state.min, null];
            if (!!this.props.form) {
                const changedValue = {};
                changedValue[!!this.props.minName ? this.props.minName : 'undefinded'] = this.state.min;
                changedValue[!!this.props.maxName ? this.props.maxName : 'undefinded'] = this.state.max;
                this.props.form.setFieldsValue(changedValue);
            }
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(data);
            }
        } else {
            if (!!this.props.form) {
                const changedValue = {};
                changedValue[!!this.props.minName ? this.props.minName : 'undefinded'] = this.state.min;
                changedValue[!!this.props.maxName ? this.props.maxName : 'undefinded'] = this.state.max;
                this.props.form.setFieldsValue(changedValue);
            }
            if (typeof this.props.onChange === 'function') {
                const range = [this.state.min === '' ? null : this.state.min, this.state.max === '' ? null : this.state.max];
                this.props.onChange(range);
            }
        }
    }

    render() {
        const data = this.props.value ? this.props.value : ['', ''];
        return (
            <Row>
                <Col span={11} >
                    <Form.Item
                        help={
                            (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
                        }
                        hasFeedback
                    >
                        {
                            (!this.props.required) ?
                                (
                                    <InputNumber
                                        value={data[0]}
                                        style={{ width: '100%', textAlign: 'right' }}
                                        className={this.props.className + ' input-min'}
                                        min={!!this.props.min ? this.props.min : MPS_VALUE_SCOPE.Min}
                                        max={!!this.props.max ? this.props.max : MPS_VALUE_SCOPE.Max}
                                        disabled={this.props.disabled}
                                        onBlur={this.onBlur}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={this.onChangeMin}
                                    />
                                )
                                : (this.props.getFieldDecorator(this.props.minName, {
                                    rules: [
                                        {
                                            required: this.props.required,
                                        }
                                    ],
                                })(
                                    <InputNumber
                                        value={data[0]}
                                        style={{ width: '100%', textAlign: 'right' }}
                                        className={this.props.className + ' input-min'}
                                        min={!!this.props.min ? this.props.min : MPS_VALUE_SCOPE.Min}
                                        max={!!this.props.max ? this.props.max : MPS_VALUE_SCOPE.Max}
                                        disabled={this.props.disabled}
                                        onBlur={this.onBlur}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={this.onChangeMin}
                                    />
                                ))}
                    </Form.Item>
                </Col>
                <Col span={2} style={{ textAlign: 'center' }}>~</Col>
                <Col span={11}>
                    <Form.Item
                        help={
                            (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
                        }
                        hasFeedback
                    >
                        {
                            (!this.props.required) ?
                                (
                                    <Fragment>
                                        <InputNumber
                                            value={data[1]}
                                            style={{ width: '100%', textAlign: 'right' }}
                                            className={this.props.className + 'input-max'}
                                            min={!!this.props.min ? this.props.min : MPS_VALUE_SCOPE.Min}
                                            max={!!this.props.max ? this.props.max : MPS_VALUE_SCOPE.Max}
                                            onBlur={this.onBlur}
                                            disabled={this.props.disabled}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={this.onChangeMax}
                                        />
                                    </Fragment>
                                ) : (this.props.getFieldDecorator(this.props.maxName, {
                                    rules: [
                                        {
                                            required: this.props.required,
                                        }
                                    ],
                                })(
                                    <InputNumber
                                        value={data[1]}
                                        style={{ width: '100%', textAlign: 'right' }}
                                        className={this.props.className + ' input-max'}
                                        min={!!this.props.min ? this.props.min : MPS_VALUE_SCOPE.Min}
                                        max={!!this.props.max ? this.props.max : MPS_VALUE_SCOPE.Max}
                                        disabled={this.props.disabled}
                                        onBlur={this.onBlur}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={this.onChangeMax}
                                    />
                                ))}
                    </Form.Item>
                </Col>
            </Row>
        );
    }
}
export { Mps_InputRangeQuantity };

import React from 'react';
import { Form, Input, Button, Row, Col, Icon, Card } from 'antd';
import { history } from '../Helpers';
import { authenticationService } from '../Services';
import { Mps_ErrorAccountExpired, Mps_Tooltip } from '../../components/Mps_Control';
import { System_Setting_Service } from '../../components/System_Setting/Service/System_Setting.Service';
import { LANGUAGE_STATE } from './Language/ln';
import { MESSAGE_STATE } from './Language/ms';
import jsonQuery from 'json-query';
import { initModel } from './Models/LoginModel';
import { ReCaptcha } from 'react-recaptcha-google';
import { Link } from 'react-router-dom';
import { MPS_RESULTS, Mps_MethodCommon } from '../../commons';
import backgroundImage from '../../assets/Layout/background-login.jpg';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.password = React.createRef();
    this.username = React.createRef();
    this.btn_submit = React.createRef();
    // this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    // this.verifyCallback = this.verifyCallback.bind(this);
    const defaultLanguage = localStorage.getItem('language');
    const chooseLanguage = 'en';// (!!defaultLanguage) ? JSON.parse(defaultLanguage) : 'vn';
    const language = jsonQuery([chooseLanguage], { data: LANGUAGE_STATE }).value;
    const message = jsonQuery([chooseLanguage], { data: MESSAGE_STATE }).value;
    authenticationService.logout();
    this.state = {
      chooseLanguage,
      language:language,
      message,
      loginModel: initModel,

      messageReturn: '',
      //recaptchaToken: '',
      isExpiredPage: false
    };
  }
  componentDidMount() {
    this.username.current.focus();
    document.title = this.state.language.LOGINSCREEN;
    // this.onLoadRecaptcha();
    // const uniqueCode = localStorage.getItem('uniqueCode');
    // const newUniqueCode = window.location.search.substring(12);
    const loginModel = this.state.loginModel;
    loginModel.System_UniqueCode = '315893540';//(!newUniqueCode) ? JSON.parse(uniqueCode) : newUniqueCode;
    loginModel.System_User_Language = this.state.chooseLanguage;
    this.setState({ loginModel });
    window.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
          if (this?.btn_submit?.current?.buttonNode) {
              this.btn_submit.current.buttonNode.click();
          }
      }
  })
  }
  // onLoadRecaptcha() {
  //   if (this.captchaDemo) {
  //     this.captchaDemo.reset();
  //   }
  // }

  // verifyCallback(recaptchaTokens) {
  //   // Here you will get the final recaptchaToken!!!
  //   this.setState({
  //     recaptchaToken: recaptchaTokens
  //   });
  // }

  handleClick = e => {
    const id = e.target.id;
    e.preventDefault();
    //const recaptchaToken = this.state.recaptchaToken;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const model = {};
        model.systemUserCode = values.System_User_Code;
        model.System_UniqueCode = values.System_UniqueCode;
        const systemUserLanguage = this.state.loginModel.System_User_Language;
        //const systemUserLanguage = 'en';
        Promise.all([authenticationService.getAuthorizeUser(model, systemUserLanguage)]).then((result) => {         
          localStorage.setItem('uniqueCode', JSON.stringify(model.System_UniqueCode));
          localStorage.setItem('language', JSON.stringify(systemUserLanguage));
          if (result.length !== 0) {
         
                history.push('/');
          }
        });
      }
    });
  };

  checkExist = (rule, value, callback) => {
    const { form } = this.props;
    const systemUserCode = form.getFieldValue('System_User_Code');
    const password = value;
    const uniqueCode = form.getFieldValue('System_UniqueCode');
    if (systemUserCode !== undefined && password !== undefined) {
      Promise.all([authenticationService.login(systemUserCode, password, uniqueCode)]).then(result => {
        const returnCode = jsonQuery('[returnCode][0]', { data: result }).value;
        if (returnCode === MPS_RESULTS.ACCOUNT_EXPIRED) {
          this.setState({ isExpiredPage: true });
        }
        if (returnCode === MPS_RESULTS.ACCOUNT_INCORECT) {
          callback(<Mps_Tooltip title={this.state.message.USERNAME_IN_CORRECT} />);
        } else {
          callback();
        }
      });
    } else
    if (systemUserCode === undefined && password === undefined) {
      Mps_MethodCommon.openWarningNotification(this.state.message.PASSWORD_NULL, '');
      this.username.current.focus();
    } else
    if (systemUserCode === undefined) {
      Mps_MethodCommon.openWarningNotification(this.state.message.USERNAME_NOT_NULL, '');
      this.username.current.focus();
    } else
    if (password === undefined) {
      Mps_MethodCommon.openWarningNotification(this.state.message.PASSWORD_NOT_NULL, '');
      this.password.current.focus();
    }

  };

  checkValidCompanyCode = (rule, value, callback) => {
    const { form } = this.props;
    const systemUserCode = form.getFieldValue('System_User_Code');
    const password = form.getFieldValue('System_User_Password');
    const uniqueCode = value;
    if (password) {
      Promise.all([authenticationService.login(systemUserCode, password, uniqueCode)]).then(result => {
        const returnCode = jsonQuery('[returnCode][0]', { data: result }).value;
        if (returnCode === MPS_RESULTS.ACCOUNT_EXPIRED) {
          this.setState({ isExpiredPage: true });
        }
        if (returnCode === MPS_RESULTS.ACCOUNT_COMPANYNAME_INCORRECT) {
          callback(<Mps_Tooltip title={this.state.message.COMPANY_CODE_INCORRECT} />);
        } else {
          callback();
        }
      });
    }
  };

  handleChangeLanguage = (e) => {
    const loginModel = this.state.loginModel;
    const chooseLanguage = e.target.id;
    loginModel.System_User_Language = chooseLanguage;
    const language = jsonQuery([chooseLanguage], { data: LANGUAGE_STATE }).value;
    this.setState({
      loginModel,
      language
    });
  };
  
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };
    if (this.state.isExpiredPage) {
      return <Mps_ErrorAccountExpired />;
    }
    const { language, message, loginModel } = this.state;

    return (
      <div className='mps-login-contain' >
        <Card bordered={true} style={{ width: 365, margin: '0px auto', padding: '20px 20px 0px 20px', borderRadius: 10 }}>
          <center>
            {/* <h3 style={{ marginBottom: 10 }}>{language.LOGINSCREEN}</h3> */}
            <img src={require('../../assets/Layout/logo.jpg')} style={{ width: 250, margin: '20px 10px 20px 0px' }} />
          </center>
          <Form {...formItemLayout} onSubmit={this.handleClick}>
            <Row>
              <Col  >

                <Form.Item hasFeedback >
                  {getFieldDecorator('System_User_Code', {
                    rules: [
                      {
                        required: true,
                        message: <Mps_Tooltip title={message.USERNAMENOTNULL} />
                      }
                    ]
                  })(<Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={language.USER_Id} ref={this.username}/>)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col   >
                <Form.Item hasFeedback>
                  {getFieldDecorator('System_User_Password', {
                    rules: [
                      {
                        required: true,
                        message: ' '
                      },
                      { validator: this.checkExist }
                    ],
                    validateTrigger: 'onBlur'
                  })(<Input.Password prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={language.PASSWORD} ref={this.password}/>)}
                </Form.Item>
              </Col>
            </Row>
            <Row  >
              <Col  className='c-hide' >
                <Form.Item hasFeedback>
                  {getFieldDecorator('System_UniqueCode', {
                    initialValue: loginModel.System_UniqueCode,
                    rules: [
                      {
                        required: true,
                        message: ' '
                      },
                      { validator: this.checkValidCompanyCode }
                    ],
                    validateTrigger: 'onBlur'
                  })(<Input  prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={language.COMPANY_CODE} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ textAlign: 'right' }} hidden={true}>
              <img onClick={this.handleChangeLanguage} id='vn' style={{ width: 40 }} src={require('../../assets/Layout/vietnam.png')} />
              <img onClick={this.handleChangeLanguage} id='en' style={{ width: 40, marginLeft: 10 }} src={require('../../assets/Layout/english.png')} />
            </Row>
            {/* <Row>
              <Col span={24} >
                <Form.Item >
                  <center>
                    <ReCaptcha ref={el => { this.captchaDemo = el; }}
                      style={{ width: '100%' }}
                      size='normal'
                      data-theme='dark'
                      render='explicit'
                      sitekey='6Lf89pAfAAAAAGCRBDVTGosALP9vhxKRbuC1aLxo'
                      onloadCallback={this.onLoadRecaptcha}
                      verifyCallback={this.verifyCallback}
                      hl='vi' />
                  </center>
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col span={12} style={{ paddingRight: 10, float: 'right' }}>
                <Button style={{ width: '100%', height: 33, fontSize: 15, marginTop: 5 }} ref={this.btn_submit}
                  type='primary' id='submit_login' onClick={this.handleClick} className='login-form-button' >
                  {language.LOGIN}
                </Button>
              </Col>
            </Row>
            <Row>
              <Link to='/forgotpw'>
                <Button className='mps-link-forgot-password' type='link' size='small'
                  style={{ marginTop: 10 }} >{language.FORGOT_PASSWORD}</Button>
              </Link>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

const WrappedLoginPage = Form.create({ name: 'LoginPage' })(LoginPage);
export { WrappedLoginPage as LoginPage };

export const MPS_MESSAGE_STATE = {
    'vn':
    {
        SHOWDATA: 'Tùy chọn thông tin hiển thị',
        PERMISSION: 'Bạn không có quyền truy cập',
        DATAISNOTNULL: 'Dữ liệu không được để trống',
        DATANOTFOUND: 'Dữ liệu không tìm thấy',
        DATAISEXISTS: 'Dữ liệu đã tồn tại',
        DATA_IS_NOT_EXISTS: 'Dữ liệu không tồn tại',
        DATASAVESUCCESS: 'Dữ liệu lưu thành công',
        DATASAVEUNSUCCESS: 'Dữ liệu lưu thất bại',
        DATA_DELETE_SUCCESS: 'Dữ liệu xóa thành công!',
        DATA_DELETE_FAILED: 'Xóa dữ liệu thất bại ',
        SENDMAIL_SUCCESS: 'Gửi mail thành công!',
        SENDMAIL_FAILED: 'Gửi mail thất bại',
        IMPORT_ERROR: 'Dữ liệu import không thành công!',
        IMPORT_SUCCESS: 'Dữ liệu import thành công!',
        DATAERROR: 'Dữ liệu bị lỗi',
        CHOOSE_ONLY_DATA_COPY: 'Vui lòng chỉ chọn một dòng dữ liệu để sao chép',
        CHOOSE_ONLY_DATA_EDIT: 'Vui lòng chỉ chọn một dòng dữ liệu để chỉnh sửa',
        CHOOSE_ONLY_DATA_VIEW: 'Vui lòng chỉ chọn một dòng dữ liệu để xem',
        CHOOSE_ONLY_DATA_PRINT: 'Vui lòng chỉ chọn một dòng dữ liệu để in',
        CHOOSE_DATA: 'Vui lòng chọn dòng dữ liệu ',
        CHOOSE_DATA_CANCEL: 'Vui lòng chọn dòng dữ liệu để hủy',
        CHOOSE_DATA_DELETE: 'Vui lòng chọn dòng dữ liệu để xóa',
        CHOOSE_ONLY_ONE_ROW: 'Vui lòng chỉ chọn một dòng dữ liệu',
        CHOOSE_DATA_PRINT: 'Vui lòng chọn dữ liệu để in',
        CHOOSE_DATA_EDIT: 'Vui lòng chọn dòng dữ liệu để sửa',
        CHOOSE_DATA_FEEDBACK: 'Vui lòng chọn dòng dữ liệu để phản hồi',
        CHOOSE_DATA_VIEW: 'Vui lòng chọn dòng dữ liệu để xem',
        CHOOSE_DATA_COPY: 'Vui lòng chọn dòng dữ liệu để sao chép',
        CASHBOOK_DELETE: 'Bạn có chắc chắn muốn hủy phiếu ?',
        CASHBOOK_DELETE_WARNING: 'Vui lòng chọn phiếu để huỷ ',
        CANCEL_WARNING: 'Bạn có chắc chắn muốn hủy dữ liệu?',
        DELETE_WARNING: 'Bạn có chắc chắn muốn xóa dữ liệu?',
        DELETE_CONFIRM: 'Bạn có chắc muốn xóa món ăn ?',
        DATAISNUMBER: 'Dữ liệu kiểu số',
        EMAILISVALID: 'Hộp mail chưa đúng',
        DO_NOT_HAVE_PERMISSION: 'Bạn không có quyền truy cập',
        NEW: 'Dữ liệu đã gởi về máy chủ',
        FINISHED: 'Dữ liệu đã xử lí hoàn tất',
        CANCEL: 'Dữ liệu xử lí bị lỗi',
        PROCESSING: 'Dữ liệu đang xử lí',
        CONFIRM_TITLE: 'Xác nhận',
        WARNING_TITLE: 'Cảnh báo',
        INFORM_TITLE: 'Thông báo',
        ERROR_TITLE: 'Thông báo lỗi',
        ERROR_CANNOT_EDIT_COMPLETED_TRANSACTION: 'Không thể chỉnh sửa phiếu đã hoàn thành!',
        ERROR_CANNOT_FEEDBACK_COMPLETED: 'Không thể phản hồi trạng thái đã hoàn thành!',
        ERROR_CANNOT_DELETE_COMPLETED_TRANSACTION: 'Không thể xóa phiếu đã hoàn thành!',
        ADD_PRODUCT_WARNING: 'Vui lòng nhập hoặc chọn sản phẩm!',
        WARNING_PRODUCT_LIST_EMPTY: 'Vui lòng nhập hoặc chọn ít nhất một sản phẩm!',
        FAILED_TO_FETCH: 'Không kết nối được máy chủ!',
        CHOOSE_SYSTEM_PERMISSION: ' Vui lòng chọn dữ liệu để phân quyền',
        CHOOSE_SYSTEM_PERMISSION_ONLY: ' Vui lòng chỉ chọn một dòng dữ liệu để phân quyền',
        UPDATE_FAIL: 'Cập nhật lỗi',
        UPDATE_SUCCESSFULLY: 'Cập nhật thành công!',
        UPDATE_WARNING: 'Bạn có chắc chắn muốn thay đổi dữ liệu?',
        PARTNER_NOT_ENOUGH_MONEY: 'Khách chưa thanh toán đủ tiền!',
        PIC_NOT_ENOUGH_MONEY: 'Nhân viên chưa trả đủ tiền!',
        DATAIMPORTERROR: 'Dữ liệu import không phù hợp',
        IMPORTSUCCESS: 'Dữ liệu được cập nhật: ',
        ERROR_CANNOT_PRINT_DRAFT_TRANSACTION: 'Phiếu chưa hoàn thành không thể in',
        SAVED_CAN_PRINT: 'Lưu thành công có thể in',
        ERROR_RUN_OUT_OFF_PRODUCT: 'Sản phẩm đã hết.',
        ERROR_RUN_OUT_OFF_PRODUCT_IN_STORAGE: 'Hết số lượng sản phẩm tồn kho.',
        ERROR_CANNOT_DELETE: 'Không thể xóa',
        ERROR_USED_SCHEDULE: 'Đang sử dụng dữ liệu ở Thời khóa biểu',
        ERROR_USED_CLASS: 'Đang sử dụng dữ liệu ở Thời khóa biểu',
        ERROR_USED_TEACHER: 'Đang sử dụng dữ liệu ở Giáo viên',
        ERROR_USED_ROOM: 'Đang sử dụng dữ liệu ở Phòng học',
        ERROR_USED_PARTNER: 'Đang sử dụng dữ liệu ở Đối tác',
        ERROR_USED_TRANSFER: 'Đang sử dụng dữ liệu ở Chuyển kho',
        ERROR_USED_TRANSACTION: 'Đang sử dụng dữ liệu ở Tồn kho',
        ERROR_USED_PHYSICAL: 'Đang sử dụng dữ liệu ở Thống kê',
        ERROR_USED_SALES: 'Đang sử dụng dữ liệu ở Bán hàng',
        ERROR_USED_CASHBOOK: 'Đang sử dụng dữ liệu ở Sổ quỹ',
        ERROR_USED_CONTACT_HISTORY: 'Đang sử dụng dữ liệu ở Lịch sử liên hệ',
        ERROR_USED_PROJECT_TIMESHEET: 'Đang sử dụng dữ liệu ở Lịch làm việc',
        ERROR_USED_SYSTEM_USER: 'Đang sử dụng dữ liệu ở Người dùng',
        ERROR_USED_PARENT_TASK: 'Đang được sử dụng thành công việc cha',
        ERROR_USED_LOG_TIME: 'Đang được sử dụng ở Chấm công',
        ERROR_403: 'Xin lỗi, bạn không được phép truy cập trang này.',
        ERROR_404: 'Xin lỗi, trang bạn đã truy cậpkhông tồn tại.',
        FILE_TYPE_WRONG:'Tập tin sai định dạng!',
        ERROR_CHANGE_CANDIDATE_STATUS: "Cập nhật trạng thái ứng viên không thành công",
        CHANGE_CANDIDATE_STATUS_SUCCESS: "Cập nhật trạng thái ứng viên thành công",
        CHANGE_CANDIDATE_STATUS_CONFIRM: "Bạn có chắc chắn muốn đổi trạng thái ứng viên?",
        CANT_CHANGE_DEFAULT_VALUE: "Không thể thay đổi giá trị mặc định",
        ADD_EMPLOYEE_FROM_CANDIDATE_SUCCESS: "Thêm nhân viên thành công",
        ADD_EMPLOYEE_FROM_CANDIDATE_CANNOT: "Không thể tạo nhân viên",
        ADD_EMPLOYEE_FROM_CANDIDATE_ERROR: "Thêm nhân viên lỗi",
        ADD_EMPLOYEE_FROM_CANDIDATE_WARNING: "Bạn có chắc chắn muốn tạo nhân viên?",
        ERROR_DOWLOAD: "Tải không thành công",
    },
    'en':
    {
        SHOWDATA: 'Choose data to show ',
        PERMISSION: 'You do not have permission',
        DATAISNOTNULL: 'Data cannot null',
        DATANOTFOUND: 'Data cannot find!',
        DATAISEXISTS: 'Data is existed!',
        DATA_IS_NOT_EXISTS: 'Data is not exist!',
        DATASAVESUCCESS: 'Data saved successful!',
        DATASAVEUNSUCCESS: 'Data save un-successful',
        DATA_DELETE_SUCCESS: 'Data deleted successful!',
        DATA_DELETE_FAILED: 'Delete data failed ! ',
        DATAERROR: 'Data Error',
        IMPORT_ERROR: 'Data import un-successful!',
        IMPORT_SUCCESS: 'Data imported successful!',
        CHOOSE_ONLY_DATA_COPY: 'Plesea choose only data copy',
        CHOOSE_ONLY_DATA_EDIT: 'Plesea choose only data edit',
        CHOOSE_ONLY_DATA_VIEW: 'Plesea choose only data view',
        CHOOSE_ONLY_DATA_PRINT: 'Plesea choose only data print',
        CHOOSE_DATA: 'Please choose row',
        CHOOSE_DATA_DELETE: 'Please choose row to delete',
        CHOOSE_DATA_CANCEL: 'Please choose row to cancel',
        CHOOSE_DATA_PRINT: 'Please choose data to print',
        CHOOSE_DATA_EDIT: 'Please choose row to edit',
        CHOOSE_DATA_FEEDBACK: 'Please choose row to feedback',
        CHOOSE_DATA_VIEW: 'Please choose row to view',
        CHOOSE_DATA_COPY: 'Please choose row to copy',
        CASHBOOK_DELETE_WARNING: 'Are you sure to cancel these records?',
        CANCEL_WARNING: 'Are you sure to cancel these records',
        DELETE_WARNING: 'Are you sure to delete these records',
        DELETE_CONFIRM: 'Are you sure ?',
        DATAISNUMBER: 'Data is digit',
        EMAILISVALID: 'Email is in-valid',
        DO_NOT_HAVE_PERMISSION: 'You do not have permission',
        NEW: 'Data is sent to server ',
        FINISHED: 'Data process successful',
        CANCEL: 'Data process error',
        PROCESSING: 'Data is processing',
        CONFIRM_TITLE: 'Confirm',
        WARNING_TITLE: 'Warning',
        INFORM_TITLE: 'Inform',
        ERROR_TITLE: 'Error',
        ADD_PRODUCT_WARNING: 'Please input or choose product!',
        WARNING_PRODUCT_LIST_EMPTY: 'Please choose at least 1 product!',
        ERROR_CANNOT_EDIT_COMPLETED_TRANSACTION: 'Cannot edit completed transaction !',
        ERROR_CANNOT_FEEDBACK_COMPLETED: 'Cannot feedback completed status !',
        ERROR_CANNOT_DELETE_COMPLETED_TRANSACTION: 'Cannot delete completed transaction !',
        FAILED_TO_FETCH: 'Cannot connet to server!',
        CHOOSE_SYSTEM_PERMISSION: ' Please choose data ',
        CHOOSE_SYSTEM_PERMISSION_ONLY: ' Please choose only data',
        UPDATE_FAIL: 'Update failed',
        UPDATE_SUCCESSFULLY: 'Update successfully!',
        UPDATE_WARNING: 'Are you sure to update these records',
        PARTNER_NOT_ENOUGH_MONEY: 'Customer has to pay the full amount to complete',
        PIC_NOT_ENOUGH_MONEY: 'Employee has to return the full amount to complete',
        ERROR_CANNOT_PRINT_DRAFT_TRANSACTION: 'Can not print draft transancation',
        ERROR_RUN_OUT_OFF_PRODUCT: 'Run out of product.',
        ERROR_RUN_OUT_OFF_PRODUCT_IN_STORAGE: 'Run out of product in storage.',
        ERROR_CANNOT_DELETE: 'Cannot delete',
        ERROR_USED_SCHEDULE: 'Data used in Schedule',
        ERROR_USED_CLASS: 'Data used in Class',
        ERROR_USED_TEACHER: 'Data used in Teacher',
        ERROR_USED_ROOM: 'Data used in Room',
        ERROR_USED_PARTNER: 'Data used in Partner',
        ERROR_USED_TRANSFER: 'Data used in Inventory_Transfer',
        ERROR_USED_TRANSACTION: 'Data used in Inventory_Transaction',
        ERROR_USED_PHYSICAL: 'Data used in Inventory_Physical',
        ERROR_USED_SALES: 'Data used in Sales_InvoiceMiniMart',
        ERROR_USED_CASHBOOK: 'Data used in CashBook',
        ERROR_USED_CONTACT_HISTORY: 'Data used in Partner_ContactHistory',
        ERROR_USED_PROJECT_TIMESHEET: 'Data used in Project_TimeSheet',
        ERROR_USED_SYSTEM_USER: 'Data used in System_User',
        ERROR_403: 'Sorry, you are not authorized to access this page.',
        ERROR_404: 'Sorry, the page you visited does not exist.',       
        FILE_TYPE_WRONG:'File was wrong format type!',
        ERROR_CHANGE_CANDIDATE_STATUS: "Can't update candidate status",
        CHANGE_CANDIDATE_STATUS_SUCCESS: "Candidate status updated",
        CHANGE_CANDIDATE_STATUS_CONFIRM: "Are you sure to change status of record?",
        CANT_CHANGE_DEFAULT_VALUE: "Can't change default value",
        ADD_EMPLOYEE_FROM_CANDIDATE_SUCCESS: "Add employee success",
        ADD_EMPLOYEE_FROM_CANDIDATE_ERROR: "Add employee error",
        ADD_EMPLOYEE_FROM_CANDIDATE_WARNING: "Are you sure to create employees?",
        ADD_EMPLOYEE_FROM_CANDIDATE_CANNOT: "Can't create employee from candidate",
        ERROR_DOWLOAD: "Download failed",
    }
};


import { Alert, Button, Input, Modal } from 'antd';
import jsonQuery from 'json-query';
import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import { Mps_MethodCommon } from '../../../commons';
import {
    Mps_Download
} from '../../Mps_Control';
import { Mps_ImportExample } from '../Mps_ImportExample/Mps_ImportExample';
import { LANGUAGE_STATE } from './Language/ln';
import { MESSAGE_STATE } from './Language/ms';
class Mps_ImportExcel extends Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language[0];
        const language = jsonQuery([chooseLanguage], { data: LANGUAGE_STATE }).value;
        const messageValid = jsonQuery([chooseLanguage], { data: MESSAGE_STATE }).value;
        const allowFileTypes =
            [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel'
            ];

        this.state = {
            language,
            messageValid,
            allowFileTypes,
            visible: false,
            message: null,
            isError: false,
        };
    }

    /**
    * @desc Handle sự kiện hiển thị màn hình View/Add/Copy/Edit
    * @param event
    *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
    *   object data - dữ liệu hiển thị
    * @return Nothing
    */
    handleShowForm = e => {
        e.preventDefault();
        this.setState({
            visible: true,
            dataSource: []
        });
    };
    /**
    * @desc Handle sự kiện xác nhận lưu thông tin
    * @param event
    *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
    *   object data - dữ liệu hiển thị
    * @return bool - kết quả: true = thành công/ false = thất bại
    */
    handleOk = e => {
        e.preventDefault();
        if (!!this.state.isError) {
            return;
        }

        this.props.onChange(this.state.dataSource);
        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            dataSource:[],
        });
    };

    validateFile = value => {
        const flag = this.state.allowFileTypes.indexOf(value.type);
        if (flag === -1) {
            return false;
        }
        return true;
    };

    getDataBeginRow = (file, beginRow) => {
        const data = [];
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                const workbook = XLSX.read(result, { type: 'binary' });
                const sheetNameList = workbook.SheetNames;
                const workSheet = workbook.Sheets[sheetNameList[0]];
                const headers = {};
                for (const w in workSheet) {
                    if (w[0] === '!') continue;
                    //parse out the column, row, and value
                    const row = parseInt(w.substring(1));
                    if (row == beginRow - 1) {
                        continue;
                    }
                    const col = w.substring(0, 1);
                    let value = workSheet[w].v;
                    //store header names
                    if (row === beginRow) {
                        headers[col] = value;
                        continue;
                    }
                    if (!data[row - 1]) {
                        data[row - 1] = {};
                    }
                    data[row - 1][headers[col]] = value;
                }
                //drop those first two rows which are empty
                data.shift();
                data.shift();
            } catch (e) {
            }
        };
        fileReader.readAsBinaryString(file);

        this.setState({
            dataSource: data
        }, function () { console.log('setState completed', this.state); }
        );
    };

    getData = (file) => {
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const data = [];
                const { result } = event.target;
                const workbook = XLSX.read(result, { type: 'binary' });
                for (const Sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(Sheet)) {
                        const rs = XLSX.utils.sheet_to_json(workbook.Sheets[Sheet]);
                        data.push(rs);
                    }
                }
                this.setState({
                    dataSource: data[0]
                });
            } catch (e) {
            }
        };
        fileReader.readAsBinaryString(file);
    };

    handleImportChange = event => {
        this.setState({
            dataSource: []
        });
        const { files } = event.target;
        if (files.length === 1) {
            // Process a file if we have exactly one
            if (this.validateFile(files[0]) === true) {
                this.getDataBeginRow(files[0], 2);
                this.setState({
                    isError: false,
                    message: ''
                });

            } else {
                this.setState({
                    isError: true,
                    message: this.state.messageValid.FILE_TYPE_WRONG
                });
            }

        }
    };
    handleAlertClose = (e) => {
        //TO DO
    };
    render() {
        const footer = this.props.headers === undefined || this.props.headers[0] === undefined|| this.props.headers[0].require === undefined ? 
            <Mps_Download value={headers} headers={this.props.headers ? 
                this.props.headers : []} language={this.state.language.FILE_EXAMPLE} label={this.props.name ? this.props.name : 'Example file'} />:
            <Button type='primary' className={Mps_MethodCommon.getDisplayTheme()}>
            <Mps_ImportExample value={headers} chooseLanguage={this.props.language} headers={this.props.headers ? 
                this.props.headers : []} language={this.state.language.FILE_EXAMPLE} label={this.props.name ? this.props.name : 'Example file'} />
            </Button>;
        const headers = [

        ];
        return (
            <span>
                <Button type='primary' onClick={this.handleShowForm}> {this.state.language.IMPORT}</Button>
                <Modal
                    title={this.props.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    footer={[
                        footer,
                        <Button key='submit' type='primary' className={Mps_MethodCommon.getDisplayTheme()} onClick={this.handleOk}>
                            {this.state.language.SAVE}
                        </Button>,
                        <Button key='back' type='primary' className={Mps_MethodCommon.getDisplayTheme()} onClick={this.handleCancel}>
                            {this.state.language.CANCEL}
                        </Button>,
                    ]}
                    width={500}>
                    {(this.state.isError === false) ? '' :
                        <Alert
                            message={this.state.message}
                            type='error'
                            closable
                            onClose={this.handleAlertClose}
                        />
                    }
                    <br />
                    <Input style={{ padding: 1 }} type='file' accept='.xlsx, .xls, .csv' onChange={this.handleImportChange} />

                </Modal>
            </span>

        );
    }
}

export { Mps_ImportExcel };

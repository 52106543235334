export const LANGUAGE_STATE = {
    'vn':
    {
        FILE_NAME: 'Dữ liệu lỗi',
        DOWNLOAD_ERROR_IMPORT: 'Vui lòng click vào đây để xem thêm chi tiết.',
        SHEET_NAME_ERROR: 'Chi tiết lỗi',
        NOTE: 'Ghi chú',
        NOTE_COLUMN: ['Ký hiệu màu', 'Tên lỗi' ],
        ERROR_NAME:[
            {value:'Dữ liệu đã tồn tại', color:'red'}, 
            {value:'Dữ liệu không tồn tại', color:'orange'},
            {value:'Cần nhập dữ liệu', color:'brown'}
        ],
        NO_VALUE: 'Cần nhập dữ liệu.'
    },
    'en':
    {
        FILE_NAME: 'Data error',
        DOWNLOAD_ERROR_IMPORT: 'Click here to view more detail.',
        SHEET_NAME_ERROR: 'Error detail',
        NOTE: 'Note',
        NOTE_COLUMN: ['Color', 'Error name'],
        ERROR_NAME: [
            {value:'Data exist', color:'red'}, 
            {value:'Data not exist', color:'orange'},
            {value:'Need value', color:'brown'}
        ],
        NO_VALUE: 'No value'
    }
};
export const MESSAGE_STATE ={
    'vn':
        {
          FILE_TYPE_WRONG:'Tập tin sai định dạng!'
        },
    'en':
        {
            FILE_TYPE_WRONG:'File was wrong format type!'
        }    
    }

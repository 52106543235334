import React, { Component } from 'react';
import {
    ResponsiveContainer, LineChart, Line, Text, Brush,
    XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import PropTypes from 'prop-types';

import '../../Mps_Chart/style.css';

class Mps_LineChart extends Component {
    render() {
        const {
            width, height, margin,
            xAxisDatakey, xAxisTick,
            tickFormatter, toolTipFormatter,
            data, dataKeys,
            colors, legend, brush
        } = this.props;
        const endIndex = this.props.endIndex ? this.props.endIndex : 20
        return (
            <ResponsiveContainer width='100%' height={height}>
                <LineChart
                    data={data}
                    width={width}
                    height={height}
                    margin={margin ? margin :
                        {
                            top: 0,
                            right: 0,
                            bottom: 30,
                            left: 35
                        }
                    }
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis interval={0} dataKey={xAxisDatakey}
                        tick={xAxisTick ? xAxisTick : true} padding={{ left: 50, right: 50 }}
                    />
                    <YAxis tickFormatter={tickFormatter ? tickFormatter : null} domain={[0, dataMax => (Math.floor(dataMax * 1.2)+1 )]} />
                    <Tooltip formatter={toolTipFormatter ? toolTipFormatter : null} />
                    {data.length > 0 && dataKeys.map((dataKey, index) => {
                        return <Line
                            key={`${dataKey}${index}`}
                            dataKey={dataKey}
                            stroke={colors[index % colors.length]}
                            strokeWidth={2}
                            type='monotone'
                        />
                    })}
                    {legend ? <Legend iconType='circle' /> : null}
                    {brush ? <Brush endIndex={endIndex} dataKey={xAxisDatakey} height={30} stroke='#8884d8' /> : null}
                </LineChart>
            </ResponsiveContainer>
        );
    }
}

Mps_LineChart.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number,
    height: PropTypes.number.isRequired,
    xAxisDatakey: PropTypes.string.isRequired,
    dataKeys: PropTypes.array.isRequired,
    colors: PropTypes.array.isRequired,
};

export default Mps_LineChart;
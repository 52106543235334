export function authHeader() {
    // return authorization header with jwt token
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {

        return 'Bearer ' + `${user}` ;
    } else {
        return {};
    }
}

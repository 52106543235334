import { MPS_MESSAGE_STATE, MPS_RESULTS } from '../commons';
import jsonQuery from 'json-query';
import React from 'react';
import { Modal, notification } from 'antd';
import jsonwebtoken from 'jsonwebtoken'
import moment from 'moment';
const paginationLimit = 10;
const paginationLimitMaximum = 100;
const dataFilterUnlimit = 1000000;

export const Mps_MethodCommon = {
    paginationLimit,
    paginationLimitMaximum,
    dataFilterUnlimit,
    returnMessageApi,
    handleShowConfirm,
    htmlDecode,
    checkFieldAvailable,
    openNotification,
    getDisplayTheme,
    openErrorNotification,
    openInforNotification,
    openSuccessNotification,
    openWarningNotification,
    openCustomNotification,
    getJsDateFromExcel,
    openNotificationImport,
    verifyUserToken,
    handleChangeDate
};

function checkFieldAvailable(displayDefault, fieldName) {
    if (!!displayDefault) {
        return displayDefault.includes(fieldName) ? '' : ' c-hide '
    }
    return '';

}
function htmlDecode(input) {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.innerText;
}

const confirm = Modal.confirm;
function returnMessageApi(value, chooselanguage) {
    var message = jsonQuery([chooselanguage[0]], { data: MPS_MESSAGE_STATE }).value;
    switch (value) {
        case "0":
            return message.DATAERROR;
        case "-1":
            return message.DATASAVEUNSUCCESS;
        case "1":
            return message.DATASAVESUCCESS;
        case "2":
            return message.DATAISEXISTS;
        case MPS_RESULTS.IMPORT_ERROR:
            return message.IMPORT_ERROR;
        case MPS_RESULTS.DATA_NOT_EXIST:
                return message.DATA_IS_NOT_EXISTS;
        case '':
            return message.FAILED_TO_FETCH;
        default:
            return '';
    }
}

/**
* @desc Handle sự kiện mở popup xác nhận
* @param 
*   message - Tiêu đề.
*   content - Nội dung
* @return null
*/
function handleShowConfirm(message, content) {
    confirm({
        title: message,
        content: content,
        onOk() {
            return new Promise((resolve, reject) => {
                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.log('Oops errors!'));
        },
        onCancel() { },
    });
}

/**
* @desc Handle sự kiện mở notify 
* @param 
*   type - Loại thông báo.
*   message - Tiêu đề.
*   description - Nội dung
* @return null
*/
function openNotification(type, message, description) {
    const args = {
        message: message,
        description: description,
        duration: 2,
    };
    notification[type](args);
}

/**
* @desc Handle sự kiện mở notify cảnh báo
* @param 
*   message - Tiêu đề.
*   description - Nội dung
* @return null
*/
function openWarningNotification(message, description) {
    const args = {
        message: message,
        description: description,
        duration: 2,
    };
    notification['warning'](args);
}

/**
* @desc Handle sự kiện mở notify thông báo lỗi
* @param 
*   message - Tiêu đề.
*   description - Nội dung
* @return null
*/
function openErrorNotification(message, description) {
    const args = {
        message: message,
        description: description,
        duration: 2,
    };
    notification['error'](args);
}

/**
* @desc Handle sự kiện mở notify thông báo
* @param
*   message - Tiêu đề.
*   description - Nội dung
* @return null
*/
function openInforNotification(message, description) {
    const args = {
        message: message,
        description: description,
        duration: 2,
    };
    notification['info'](args);
}

/**
* @desc Handle sự kiện mở notify thông báo thành công
* @param 
*   message - Tiêu đề.
*   description - Nội dung
* @return null
*/
function openSuccessNotification(message, description) {
    const args = {
        message: message,
        description: description,
        duration: 2,
    };
    notification['success'](args);
}

/**
* @desc Handle sự kiện mở notify tùy chỉnh
* @param 
*   message - Tiêu đề.
*   description - Nội dung
* @return null
*/
function openCustomNotification(type, message, description, duration) {
    const args = {
        message: message,
        description: description,
        duration: Number(duration),
    };
    notification[type](args);
}

function getDisplayTheme() {
    var displayTheme = JSON.parse(localStorage.getItem("mpsDisplayTheme"));
    if (displayTheme === null) {//Trả về theme mặc định nếu người dùng chưa thiết lập.
        return 'mps-theme-seagreen';
    }
    return displayTheme;
}

function getJsDateFromExcel(excelDateValue) {
    var date = new Date((excelDateValue - (25567 + 2)) * 86400 * 1000);
    var localTime = new Date(date.getTime() + (new Date()).getTimezoneOffset() * 60000);
    return localTime;
}

function openNotificationImport(validateData, messageRowSuccess, messageRowErr, message, description){
    let type = 'error';
    let desc;
    let duration = 2;
    if((validateData !== undefined && validateData !== null) && (validateData.successResults.length > 0 || validateData.dataErr.length > 0)){
        let desc1 = null, desc2 =  null;
        if(validateData.successResults.length > 0){
            desc1 = validateData.successResults.length + " " +  messageRowSuccess;
        }
        desc2 = '- ' + validateData.dataErr.length + " " +  messageRowErr;
        duration = 10;
        if(!!desc1){
            desc = <span><p>{desc1}</p><p>{desc2}</p><p><ins>{description}</ins></p></span>; 
        }else{
            desc = <span><p>{desc2}</p><p><ins>{description}</ins></p></span>;
        }
    }
    const args = {
        message,
        description: desc,
        duration,
    };
    notification[type](args);
}

async function verifyUserToken(userToken) {
    let user = {};
    if (userToken) {
        jsonwebtoken.verify(userToken.split(' ')[1], process.env.KEY, function (err, decode) {
            if (err) {
                user = {};
            }
            user = decode;
        });
    } else {
        user = {};
    }
    return user;
}

function handleChangeDate(dateNeedChange) {
    const [date1,date2] = dateNeedChange;
    const dateTemp1 = new Date(date1);
    const dateTemp2 = new Date(date2);
    const Temp = new Date(Date.now());
    dateTemp1.setHours(Temp.getHours());
    dateTemp1.setMinutes(Temp.getMinutes());
    dateTemp1.setSeconds(Temp.getSeconds());
    dateTemp2.setHours(Temp.getHours());
    dateTemp2.setMinutes(Temp.getMinutes());
    dateTemp2.setSeconds(Temp.getSeconds());
    const valueChange = [moment(dateTemp1),moment(dateTemp2)];
    if (date1 !== undefined && date2 !== undefined) {
        dateNeedChange = valueChange
        return dateNeedChange
    }
    return []
}

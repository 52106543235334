import React from 'react';
import jsonQuery from 'json-query';
import {
  Form,
  Select, Row, Col,
  Card
} from 'antd';
import {
  Mps_LayoutFormat, Mps_Tooltip
} from '../../Mps_Control';
import { authenticationService } from '../../../authenticate/Services';
import { LANGUAGE_STATE } from '../Language/ln';
import { System_User_Service } from '../Service/System_User.Service';


const { Option } = Select;
class System_User_Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    };
  }
  onChange = value => {
    this.props.onChange(value);
  };
  componentWillMount() {
    const search_Object = {
      search_Object: null,
      type: (!!this.props.type) ? this.props.type : null,
      Status: (!!this.props.status) ? this.props.status : null
    };
    Promise.all([System_User_Service.getDataFilter(search_Object)]).then(result => {
      const data = jsonQuery('[**]', { data: result[0].data }).value;
      this.setState({
        dataSource: data
      });
    });

  }

  render() {
    const chooseLanguage = (!!this.props.language) ? this.props.language : authenticationService.getCurrentLanguage();
    const language = jsonQuery([chooseLanguage], { data: LANGUAGE_STATE }).value;
    const gridStyle = this.props.style;
    return (
      <Card.Grid style={gridStyle}
        className={`mps-card-items ${this.props.className}`}>
        <Row>
          <Col span={Mps_LayoutFormat.ColCardLabel} >
            <Form.Item>
              {(!!this.props.label) ? this.props.label : language.SYSTEM_USER_NAME}
              {(this.props.requiredLabel === true) ? <span className='label-required'>*</span> : ''}
            </Form.Item>
          </Col>
          <Col span={Mps_LayoutFormat.ColCardInput}>
            <Form.Item
              help={
                (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
              }
              hasFeedback
            >
              {(!!this.props.getFieldDecorator) ?
                (this.props.getFieldDecorator((!!this.props.name) ? this.props.name : '', {
                  initialValue: this.props.value,
                  rules: [
                    {
                      required: (!!this.props.required) ? this.props.required : false,
                      message: this.props.message
                    }
                  ]
                })(
                  <Select allowClear disabled={(!!this.props.disabled) ? this.props.disabled : false}
                    placeholer={(!!this.props.placeholer) ? this.props.placeholer : ''}
                    onChange={this.onChange}
                  >
                    <Option value=''></Option>
                    {this.state.dataSource.map(select => <Option key={select._id} value={select._id}>{select.System_User_DisplayName}</Option>)}
                  </Select>
                )) :
                (<Select allowClear disabled={(!!this.props.disabled) ? this.props.disabled : false}
                  placeholer={(!!this.props.placeholer) ? this.props.placeholer : ''}
                  value={this.props.value}
                  onChange={this.onChange}
                >
                  <Option value=''></Option>
                  {this.state.dataSource.map(select=> <Option key={select._id} value={select._id}>{select.System_User_DisplayName}</Option>)}
                </Select>)
              }
            </Form.Item>
          </Col>
        </Row>
      </Card.Grid>
    );
  }
}

export { System_User_Select };

import React from 'react';
import { Mps_LabelFormat } from '../Mps_LabelFormat/Mps_LabelFormat';
import { Mps_Tooltip } from '../Mps_Tooltip/Mps_Tooltip';
import moment from 'moment';
import {
    DatePicker, Form
} from 'antd';

class Mps_InputDatePicker extends React.Component {

    constructor(props) {
        super(props);
    }

    onChange = value => {
        this.setState({
            value
        });
        if (!!this.props.form) {
            const changedValue = {};
            changedValue[!!this.props.name ? this.props.name : 'undefinded'] = value;
            this.props.form.setFieldsValue(changedValue);
        }
        this.props.onChange(value);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            })
            if (!!this.props.form) {
                const changedValue = {};
                changedValue[!!this.props.name ? this.props.name : 'undefinded'] = this.props.value;
                this.props.form.setFieldsValue(changedValue);
            }
        }
    }

    getFieldDecoratorName = name => {
        if (!!name) {
            return name;
        } else {
            return '';
        }
    };

    getClassName = className => {
        if (!!className) {
            return `${className} mps-ant-calendar-picker`;
        } else {
            return 'mps-ant-calendar-picker';
        }
    };

    getDisabled = disabled => {
        if (!!disabled) {
            return disabled;
        } else {
            return false;
        }
    };

    getAllowClear = allowClear => {
        if (!!allowClear) {
            return allowClear;
        } else {
            return false;
        }
    };

    getShowTime = showTime => {
        if (!!showTime) {
            return showTime;
        } else {
            return false;
        }
    };

    render() {
        const style = this.props.style ? this.props.style : { textAlign: 'center', width: '100%' };
        const disabled = this.getDisabled(this.props.disabled);
        const className = this.getClassName(this.props.className);
        const allowClear = this.getAllowClear(this.props.allowClear);
        const getFieldDecoratorName = this.getFieldDecoratorName(this.props.name);
        const showTime = this.getShowTime(this.props.showTime);
        const format = this.props.format ? this.props.format : Mps_LabelFormat.displayDateFormat;
        const value = !!this.props.value ? moment(this.props.value) : null;
        return (
            <Form.Item
                help={
                    (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
                }
                hasFeedback
            >
                {(!!this.props.getFieldDecorator) ?
                    (this.props.getFieldDecorator(getFieldDecoratorName, {
                        initialValue: value,
                        rules: [
                            {
                                required: (!!this.props.required) ? this.props.required : false,
                                message: this.props.message,
                            },
                        ],
                    })(
                        <DatePicker allowClear={allowClear}
                            showTime={showTime} style={style}
                            className={className} disabled={disabled}
                            onChange={this.onChange} format={format} />
                    )) :
                    (
                        <DatePicker allowClear={allowClear}
                            showTime={showTime} style={style}
                            className={className} disabled={disabled}
                            value={value}
                            defaultValue={value}
                            onChange={this.onChange} format={format} />
                    )
                }
            </Form.Item>
        );
    }
}
export { Mps_InputDatePicker };

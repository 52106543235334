import React from 'react';
import {
    Form, Input, Button, Tabs, Row, Col, Modal
    , Card
} from 'antd';
import jsonQuery from 'json-query';
import {
    MPS_MESSAGE_STATE, MPS_RESULTS
    , MPS_MODE
    , MPS_SYSTEM_PARAMETER_TYPE
    , MPS_SYSTEM_PARAMETER
    , Mps_MethodCommon,
    MPS_DISPLAY_AREA_STORAGE
} from '../../commons';
import { authenticationService } from '../../authenticate/Services';
//Import MPS common control here...
import {
    Mps_LayoutFormat
    , Mps_SelectParameterSystem, Mps_InputDatePicker
    , Mps_Tooltip, Mps_DisplayColumnResultTable, Mps_GenerateField
} from '../Mps_Control';
import { System_User_Select } from '../System_User/Controls/System_User_Select';
import { Sipco_WaterFilter_Service } from './Service/Sipco_WaterFilter.Service';
import { LANGUAGE_STATE } from './Language/ln';
import { GUIDE_LINE } from './Language/Guideline';
import { MESSAGE_STATE } from './Language/ms';

import {
    initModel, getDisplayFieldOnEditForm, setDisplayFieldOnEditForm
    , initFieldControl, initFielDisplayOnEditForm
} from './Models/Sipco_WaterFilter.Model';
import moment from 'moment';
import { Log_Service } from '../../authenticate/Helpers/Log.Service';
import { Sipco_WaterFilterHistory_Service } from '../Sipco_WaterFilter_History/Service/Sipco_WaterFilterHistory.Service';
import Mps_UploadPictureV2 from '../Mps_Control/Mps_UploadPicture/Mps_UploadPictureV2';
const { TabPane } = Tabs;

class Sipco_WaterFilterUpload extends React.Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([this.props.language[0]], { data: LANGUAGE_STATE }).value;
        const commonMessage = jsonQuery([this.props.language[0]], { data: MPS_MESSAGE_STATE }).value;
        const guideLine = jsonQuery([this.props.language[0]], { data: GUIDE_LINE }).value;
        const validMessage = jsonQuery([this.props.language[0]], { data: MESSAGE_STATE }).value;

        this.state = {
            language,
            commonMessage,
            guideLine,
            initModel,
            chooseLanguage,
            visible: false,
            validMessage,
            WaterFilter_Files: []
        };
    }

    /**
     * @desc Handle sự kiện hiển thị màn hình View/Add/Copy/Edit
     * @param event
     *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
     *   object data - dữ liệu hiển thị
     * @return Nothing
     */
    handleShowForm = e => {
        e.preventDefault();
        this.setState({
            visible: true,
        })

    };

    /**
    * @desc Handle sự kiện xác nhận lưu thông tin
    * @param event
    *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
    *   object data - dữ liệu hiển thị
    * @return bool - kết quả: true = thành công/ false = thất bại
    */
    handleSave = e => {
        e.preventDefault();
        this.handleOk('Save');
    };

    handleSaveNext = e => {
        e.preventDefault();
        this.handleOk('SaveNext');
    }



    handleOk = (e) => {
        this.props.form.validateFieldsAndScroll((err) => {
            if (!err) {
                const formData = new FormData();
                // Append images to form data
                this.state.WaterFilter_Files.forEach(file => {
                    formData.append('WaterFilter_New_Files', file.originFileObj);
                });
                // formData.append('Serial_Code', result[0].data.Serial_Code);
                return Sipco_WaterFilter_Service.uploadFiles(formData).then(response => {
                    //console.log(response)
                        if (!response.ok) {
                            if (response.status === 500) {
                                Mps_MethodCommon.openNotification('error',
                                Mps_MethodCommon.returnMessageApi(MPS_RESULTS.DATA_NOT_EXIST, this.state.chooseLanguage));     
                            }
                            if (response.status === 404) {
                                Mps_MethodCommon.openSuccessNotification('success',
                                Mps_MethodCommon.returnMessageApi(MPS_RESULTS.SUCCESS, this.state.chooseLanguage));  
                                this.setState({
                                    visible: false
                                });   
                            }
                        }
                   });
            }
        });
    };

    /**
    * @desc Handle sự kiện tắt màn hình
    * @param event
    *   Nothing
    * @return Nothing
    */
    handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.handleReset()
    };

    /**
    * @desc Handle sự kiện reset thông tin trên lưới
    * @param event
    *   Nothing
    * @return Nothing
    */
    handleReset = () => {
        this.props.form.resetFields();
    };

    /**
    * @desc Handle sự kiện trường thông tin trạng thái thay đổi giá trị.
    * @param event
    *   string value: giá trị mới
    * @return Nothing
    */
    handleUploadDocument = value => {
            const WaterFilter_Files = value;
            this.setState({
                WaterFilter_Files,
            });
    };

    render() {
        if (this.props.allowAccess === false) {
            return '';
        }
        const { language } = this.state;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };

        return (
            <span >
                {this.props.allowAccess === true ? <Button type="primary" onClick={this.handleShowForm}>{this.props.actionName}</Button> : ' '}
                <Modal
                    title={language.DOCUMENT_UPLOAD}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="saveClose" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleSave}>
                            {language.SAVEANDCLOSE}
                        </Button>,
                        <Button key="back" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleCancel}>
                            {language.CANCEL}
                        </Button>
                    ]}
                >
                    <Form {...formItemLayout} onSubmit={this.handleSave} id={Mps_MethodCommon.getDisplayTheme()}>
                        <Mps_UploadPictureV2 disabled={false}
                            language={language}
                            value={null}
                            onChange={this.handleUploadDocument}
                            multiple={true}
                           chooseLanguage={ this.props.language} />
                    </Form>
                </Modal>
            </span>
        );
    }
}
const WrappedSipco_WaterFilterUpload = Form.create({ name: 'Sipco_WaterFilterUpload' })(Sipco_WaterFilterUpload);
export { WrappedSipco_WaterFilterUpload as Sipco_WaterFilterUpload };

import { authHeader } from '../../../authenticate/Helpers';
import { API_URL } from '../../../conf.js';
import { isNullOrUndefined } from 'util';
import { authenticationService } from '../../../authenticate/Services';
import { Mps_MethodCommon } from '../../../commons';

export const System_Parameter_Service = {
    search,
    getById,
    create,
    update,
    importModel,
    deleteModel,
    getDataFilter,
    getDataFilterControl,
    getType
};

//Lấy thông tin chi tiết của System_Parameter theo điều kiện tìm kiếm được nhập.
function search(searchModel,searchOptions) {
    var value = {
        searchModel,
        searchOptions
    }
    let auth = authHeader();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(value)
    };

    return fetch(API_URL+`/System_Parameter/search`, requestOptions).then(handleResponse);       
}

//Lấy thông tin chi tiết của một System_Parameter theo Id.
function getById(_id) {
    let auth = authHeader();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(API_URL + `/System_Parameter/${_id}`, requestOptions).then(handleResponse);
}

//Lấy dữ liệu hiển thị cho autocomplete.
function getDataFilter(searchModel) {
    let auth = authHeader();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ searchModel: searchModel })

    };
    return fetch(API_URL + `/System_Parameter/getDataFilter`, requestOptions).then(handleResponse);
}

//Lấy dữ liệu hiển thị cho control.
function getDataFilterControl(searchModel, page = 1, limit = Mps_MethodCommon.paginationLimitMaximum, language = authenticationService.getCurrentLanguage()) {
    let auth = authHeader();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    var value = {
        searchModel,
        page,
        limit,
        language
    }
    const requestOptions = {
        method: 'POST',
        headers: headers, 
        body: JSON.stringify(value)

    };
    return fetch(API_URL + `/System_Parameter/getDataFilterControl`, requestOptions).then(handleResponse);
}

//Lấy dữ liệu hiển thị cho autocomplete.
function getType() {
    let auth = authHeader();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'POST',
        headers: headers,
    };
    return fetch(API_URL + `/System_Parameter/getType`, requestOptions).then(handleResponse);
}


//Tạo mới một System_Parameter
function create(model) {
    let auth = authHeader();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'POST',
        headers: headers,    
        body: JSON.stringify(model)
    };

    return fetch(API_URL + `/System_Parameter/create`, requestOptions).then(handleResponse);
}

//Cập nhật thông tin một System_Parameter
function update(model) {
    let auth = authHeader();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(model)
    };

    return fetch(API_URL+`/System_Parameter/update`, requestOptions).then(handleResponse);
}

//Xóa thông tin một/danh sách System_Parameter
function deleteModel(ids) {
    if (!isNullOrUndefined(ids)) {
        if (ids.length > 0) {
            const requestOptions = {
                method: 'POST',
                headers: { ...authHeader(), 'Content-Type': 'application/json' },
                body: JSON.stringify({ ids })
            };

            return fetch(API_URL + `/System_Parameter/delete`, requestOptions).then(handleResponse);
        }
    }
    else
        return false;
}

//Import data
function importModel(model) {
    let auth = authHeader();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ model: model })
    };
    return fetch(API_URL + `/System_Parameter/import`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
import React, { Component } from 'react';
import { Form, Row, Col, Card, Collapse, Input
  } from 'antd';
import jsonQuery from 'json-query';
import {Mps_LayoutFormat
    , Mps_InputRangePicker} from '../Mps_Control';
import {MPS_SYSTEM_PARAMETER_TYPE, Mps_MethodCommon} from '../../commons';
import {System_User_SelectMultiple} from '../System_User/Controls/System_User_SelectMultiple';
import {LANGUAGE_STATE} from './Language/ln';
// import {Sipco_UserFeedback_AutoComplete} from './Controls/Sipco_UserFeedback_AutoComplete';
import { Mps_SelectMultipleParameterSystem } from '../Mps_Control/Mps_SelectMultipleParameterSystem/Mps_SelectMultipleParameterSystem';
import logger from '../../commons/Mps_Logger';
import { Sipco_WaterFilter_AutoComplete } from '../Sipco_WaterFilter/Controls/Sipco_WaterFilter_AutoComplete';
const { Panel } = Collapse;
class Sipco_UserFeedbackSearch extends Component {
    /**
    * @desc Hàm khởi tạo component
    * @param
    *   props - properties của component.
    * @return Nothing
    */
    constructor(props){
        super(props);
        const chooseLanguage =this.props.language;
        const language = jsonQuery([chooseLanguage[0]], {data: LANGUAGE_STATE}).value;
        this.state = {
            language,
            chooseLanguage,
        };
    }
    /**
     * @desc Handle thông tin lỗi
     * @param
     *   Nothing: nothing
     * @return Nothing
     */
    componentDidCatch(error, info) {
        // log the error to loggly
        logger.push({ error, info });
    }
    /**
    * @desc Handle sự kiện textbox Code thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (Code được cập nhật giá trị mới).
    */
    handleCodeChange =value=> {
        const searchObjectModel = this.props.value;
        searchObjectModel.Serial_Code = value;
        /*demo mẫu logger*/
        logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện select Status thay đổi
    * @param
    *  String alue - Giá trị mới.
    * @return Nothing (Status được cập nhật giá trị mới).
    */
     handleSourceChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Source = value;
        /*demo mẫu logger*/
        logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện textbox Description thay đổi
    * @param
    *  Object e - sự kiện thay đổi.
    * @return Nothing (Description được cập nhật giá trị mới).
    */
     handleContentChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Content = e.target.value;
        /*demo mẫu logger*/
        logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    handleDescriptionChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Description = e.target.value;
        /*demo mẫu logger*/
        logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
   /**
    * @desc Handle sự kiện select Status thay đổi
    * @param
    *  String alue - Giá trị mới.
    * @return Nothing (Status được cập nhật giá trị mới).
    */
    handleStatusChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Status = value;
        /*demo mẫu logger*/
        logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
        //console.log(value)
    };
    /**
    * @desc Handle sự kiện select CreatedBy thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedBy được cập nhật giá trị mới).
    */
    handleCreatedByChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.CreatedBy = value;
        /*demo mẫu logger*/
        logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
    handleCreatedDateChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.CreatedDate = value;
        /*demo mẫu logger*/
        logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện select UpdatedBy thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (UpdatedBy được cập nhật giá trị mới).
    */
    handleUpdatedByChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.UpdatedBy = value;
        /*demo mẫu logger*/
        logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện select UpdatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (UpdatedDate được cập nhật giá trị mới).
    */
    handleUpdatedDateChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.UpdatedDate = value;
        /*demo mẫu logger*/
        logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };

    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 22 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 22 },
            },
          };
        const gridStyle = {
            width: '50%',
        };
        const searchModel = this.props.value;
        const {chooseLanguage, language} = this.state;
        const labelFieldName= language.SERIAL_CODE;
        const displaySearchConditions = this.props.displaySearchConditions;

        return(
            <Form  {...formItemLayout} >
            <Collapse
                defaultActiveKey={['1']}
                expandIconPosition={'right'}
                className = 'mps-search-header-collapse'
            >
                <Panel header= {<span className= 'mps-search-header-panel-title'>  {language.SEARCH_INFOR}</span>} key="1" >
                    <Card  style={{border: 'none'}} >
                        <Sipco_WaterFilter_AutoComplete placeholder={labelFieldName}
                            className = {Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Serial_Code')}
                            value = {searchModel.Serial_Code}
                            language={chooseLanguage}
                            style= {gridStyle}
                            onChange={this.handleCodeChange} />                     


                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Source')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item > {language.SOURCE} </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                <Form.Item>
                                        <Mps_SelectMultipleParameterSystem type={MPS_SYSTEM_PARAMETER_TYPE.Source}
                                                allowClear
                                                value={searchModel.Source} onChange={this.handleSourceChange} />
                                        </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Content')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.CONTENT}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                        <Input  onChange = {this.handleContentChange}
                                            value = {searchModel.Content}
                                            allowClear
                                            id= 'Content'
                                            name='Content'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>
                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Description')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.DESCRIPTION}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                        <Input  onChange = {this.handleDescriptionChange}
                                            value = {searchModel.Description}
                                            allowClear
                                            id= 'Description'
                                            name='Description'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Status')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item > {language.STATUS} </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Mps_SelectMultipleParameterSystem type = {MPS_SYSTEM_PARAMETER_TYPE.StatusFeedback}
                                         allowClear
                                        value = {searchModel.Status} onChange={this.handleStatusChange}/>
                                </Col>
                            </Row>
                        </Card.Grid>

                        <System_User_SelectMultiple
                            label ={language.CREATED_BY}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'CreatedBy')}`}
                            onChange ={this.handleCreatedByChange}
                            value = {searchModel.CreatedBy}
                            style= {gridStyle}/>
                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'CreatedDate')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item > {language.CREATED_DATE} </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Mps_InputRangePicker onChange = {this.handleCreatedDateChange}
                                    value = {searchModel.CreatedDate} />
                                </Col>
                            </Row>
                        </Card.Grid>

                        <System_User_SelectMultiple
                            label ={language.UPDATED_BY}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'UpdatedBy')}`}
                            onChange ={this.handleUpdatedByChange}
                            value = {searchModel.UpdatedBy}
                            style= {gridStyle}/>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'UpdatedDate')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item > {language.UPDATED_DATE} </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                <Mps_InputRangePicker onChange = {this.handleUpdatedDateChange}
                                    value = {searchModel.UpdatedDate} />
                                </Col>
                            </Row>
                        </Card.Grid>
                    </Card>
                </Panel>
            </Collapse>
         </Form>
        );
    }
}

const WrappedSipco_UserFeedbackSearch= Form.create({ name: 'Sipco_UserFeedbackSearch' })(Sipco_UserFeedbackSearch);
export { WrappedSipco_UserFeedbackSearch as Sipco_UserFeedbackSearch };

import React from 'react';
export const GUIDE_LINE ={
    'vn':
        {
            SIPCO_CPA_Code: <span>Mã trường thông tin sẽ được tạo tự động theo quy tắc: C-YY-MM-001
                                        <br/> Ví dụ: C-2002001</span>,
            SIPCO_CPA_Name: <span>Describe the meaning of "Name" </span>,
            SIPCO_CPA_Description: <span>Mô tả ý nghĩa của "Mô tả"</span>,
            Status: <span>Mô tả ý nghĩa của "Trạng thái"</span>,
        },
    'en':
        {
            SIPCO_CPA_Code: <span>The "Field code" will be automatically generated according to rules: C-YY-MM-001
                                        <br/> Ex: C-2002001</span>,
            SIPCO_CPA_Name: <span>Describe the meaning of "Name" </span>,
            SIPCO_CPA_Description: <span>Describe the meaning of "Description"</span>,
            Status: <span>Describe the meaning of "Status"</span>,
        }

}

const RESULTS = {
    SUCCESS: '1',
    ERROR: '-1',
    CLEAR: '0'
};

const MPS_RESULTS = {
    RUNTIME_ERRROR: '-2',
    ERROR: '-1',
    CLEAR: '0',
    SUCCESS: '1',
    DATA_EXIST: '2',
    PERMISSION: '3',
    DATA_USING: '4',
    LOGIC_ENDATE: '5',
    LOGIC_STARTDATE: '6',
    LOGIC_REPEATTIME_WITH_STARTDATE_ENDDATE: '7',
    CONFLICT_TIME_BOOKROOM: '8',
    ERROR_REPEAT_TYPE: '9',
    IMPORT_ERROR: '10',
    ACCOUNT_INCORECT: '11',
    ACCOUNT_COMPANYNAME_INCORRECT: '12',
    ACCOUNT_EXPIRED: '13',
    ACCOUNT_INACTIVE: '14',
    DATA_NOT_EXIST: '15',
    DATE_NOT_CORRECT: '16',
};

const MPS_TRANSACTION_TYPE = {
    Receipt: 1,
    Issue: 2,
    ReturnToSuppier: 3,
    CustomerReturn: 4,
    Sales_RestaurantInvoice: 7,
    Sales_RestaurantReturn: 8,
    TransferIssue: 9,
    TransferReceipt: 10,
    RestaurantInvoice: 11,
    Kitchen: 12,
    Product_Price: 6
};

const MPS_BALANCE = {
    Plus: 1,
    Minus: -1
};

const TRANSACTIONSTATUS = {
    new: '0',
    finished: '1',
    cancel: '2',
    processing: '3',
    data_exist: '4',
    permission: '5'
};

const ROOMTYPE = {
    Standard: 'Standard',
    VIP: 'VIP',
    Floor1: 'Floor1',
    Floor2: 'Floor2'
};

const CASH_BOOK_TYPE = {
    Receipt: 'Receipt',
    Payslip: 'Payslip'
};

const MPS_SCREEN_ACTION = {
    View: 1,
    Create: 2,
    Update: 3,
    Delete: 4,
    Cancel: 5,
    Print: 6,
    Export: 7,
    Import: 8,
    Permission: 9,
    Booking: 10,
    Order: 11,
    Sales: 12,
    Return: 13,
    Receipt: 14,
    Payslip: 15,
    BookRoom: 16,
    Receive: 17,
    Approve: 18,
    Feedback:20,
    UploadFile:21,
    ExportCPA:22,
};

const MPS_SCREEN_NO = {
    ModuleNameT_ScreenNameT:999,
    User : 1, 
    Role : 2, 
    Employee : 3, 
    System_Parameter : 4, 
    AlertSetting : 101, 
    CPA : 102, 
    UserFeedback : 105, 
    Sipco_Parameter:110,
    Sipco_WaterFilter:103,
    Sipco_WaterFilterHistory:104,
    Notify:106

};

//Các ứng dụng cho phép có route duyệt
const ROUTE_APPLICATION_AVAILABLE = {
    ProductPrice: 7,
    TransactionRestaurantOut: 10,
    TransactionRestaurantIn: 11,
    TransactionRestaurantKitchen: 12,
    TransactionSaleOut: 13,
    TransactionSaleIn: 14,
    InventoryIssue: 15,
    InventoryReceipt: 16,
    InventoryPhysical: 18,
    InventoryInquiry: 19,
    CashBook: 22,
    TransactionRestaurantBooking: 23,
    PurchaseOrders: 25,
    Contract_Article: 26,
    ContractTemplate: 27,
    Contract: 28,
    InventoryWarehouse: 30,
    InventoryTransferIssue: 37,
    InventoryTransferReceipt: 38,
};

const MPS_MODE = {
    Create: 1,
    Copy: 2,
    Update: 3,
    ReadOnly: 4
};

const MPS_SYSTEM_PARAMETER_TYPE = {
    ItemAttributes: '1001',
    ReceiptType: '1002',
    PaymentType: '1003',
    PaymentMethod: '1004',
    PartnerType: '1005',
    PartnerGroup: '1006',
    Department: '1007',
    Position: '1008',
    Status: '1009',
    Language: '1010',
    Gender: '1011',
    PurchaseOrderCategory: '1012',
    Currency: '1013',
    TransportationType: '1014',
    StatusDelivery: '1015',
    ContracType: '1016',
    RoomAndTableGroup: '1017',
    PhysicalStatus: '1018',
    InvoiceStatus: '1019',
    TransactionStatus: '1020',
    PartnerStatus: '1021',
    BranchStatus: '1022',
    EmployeeStatus: '1023',
    ProductPriceStatus: '1024',
    InquiryStatus: '1025',
    PointSetting: '1026',
    StudentType: '1027',
    StudentGroup: '1028',
    StudentStatus: '1029',
    TeacherType: '1030',
    TeacherGroup: '1031',
    KitchenStatus: '1032',
    KitchenOperationStatus: '1033',
    TeacherStatus: '1034',
    SubjectStatus: '1035',
    EducationRoomGroup: '1036',
    EducationRoomStatus: '1037',
    ClassStatus: '1038',
    ScheduleStatus: '1039',
    MeetingRoom_Status: '2020',
    MeetingRoom_Status_Process: '2021',
    LeaveCategory: '1045',
    LeaveType: '1040',
    TimeRange: '1041',
    RepeatType: '3021',
    RepeatDay: '4021',
    WorkDateType: '1044',
    TaskStatus: '1042',
    Action_Todo_Type: '3022',
    UserPermission: '1046',
    OverTimeReason: '1047',
    Todo_Status: '3033',
    ApplicationStatus: '1048',
    WarrantyStatus: '1049',                                                                                                                                                     
    WarrantyProductStatus: '1050',
    CandidateStatus: '1051',
    RecruitLevel : '4022' ,
    RecruitWorkType : '4023' ,
    MaritalStatus : '1053' ,
    Degree : '1054' ,
    CandidateSkill : '1055' ,
    News_Status : '1057' ,
    InterviewResult : '1058' ,
    ClassifyColor : '1065' ,
    Province : '1066' ,
    Source: '1067',
    StatusFeedback: '1068'
    
};

const MPS_SYSTEM_PARAMETER = {
    BranchStatus: {
        Working: '1022-001',
        Stop: '1022-002'
    },
    InquiryStatus: {
        Period: '1025-001',
        Date: '1025-002'
    },
    InterviewResult: {
        Pass: '1058-001',
        Failed: '1058-002'
    },
    Recruit_Level: {
        Intern: '4022-001',
        Junior: '4022-002',
        Staff: '4022-003',
        Manager: '4022-004'
    },
    Recruit_WorkType: {
        PartTime: '4023-001',
        FullTime: '4023-002',
        OtherTime: '4023-003'
    },
    ProductPriceStatus: {
        Draft: '1024-001',
        Complete: '1024-002'
    },
    PhysicalStatus: {
        New: '1018-000',
        Draft: '1018-001',
        Complete: '1018-002',
        Cancelled: '1018-003'
    },
    TransactionStatus: {
        New: '1020-000',
        Draft: '1020-001',
        Complete: '1020-002',
        Cancelled: '1020-003'
    },
    Status: {
        Active: '1009-001',
        Inactive: '1009-002'
    },
    InvoiceStatus: {
        New: '1019-000',
        Draft: '1019-001',
        Complete: '1019-002',
        Cancelled: '1019-003'
    },
    KitchenStatus: {
        Waiting: '1032-001',
        Completed: '1032-002',
        Cancelled: '1032-003'
    },
    KitchenOperationStatus: {
        Waiting: '1033-001',
        Processing: '1033-002',
        Completed: '1033-003'
    },
    Partner_Type: {
        Individual: '1005-001',
        Enterprise: '1005-002'
    },
    Partner_Group: {
        Customer: '1006-001',
        Supplier: '1006-002'
    },
    Partner_Status: {
        Cooperating: '1021-001',
        Stop: '1021-002'
    },
    StudentType: {
        Official: '1027-001',
        Temporary: '1027-002'
    },
    StudentGroup: {
        PrimarySchool: '1028-001',
        HighSchool: '1028-002',
        Collage: '1028-003'
    },
    StudentStatus: {
        New: '1029-001',
        Studying: '1029-002',
        Owe: '1029-003',
        Stop: '1029-004',
        Cancel: '1029-005'
    },
    TeacherType: {
        Official: '1030-001',
        Temporary: '1030-002'
    },
    TeacherGroup: {
        PrimarySchool: '1031-001',
        HighSchool: '1031-002',
        Collage: '1031-003'
    },
    TeacherStatus: {
        New: '1034-001',
        Official: '1034-002',
        Stop: '1034-003',
        Cancel: '1034-004'
    },
    SubjectStatus: {
        Active: '1035-001',
        Stop: '1035-002',
        Cancel: '1035-003'
    },
    EducationRoomGroup: {
        Moderate: '1036-001',
        Advance: '1036-002',
        Optimal: '1036-003',
    },
    EducationRoomStatus: {
        New: '1037-001',
        Using: '1037-002',
        Empty: '1037-003',
        Repairing: '1037-004',
        Cancel: '1037-005',
    },
    ClassStatus: {
        New: '1038-001',
        Active: '1038-002',
        Stop: '1038-003',
        Cancel: '1038-004',
        Finish: '1038-005',
    },
    ScheduleStatus: {
        New: '1039-001',
        Active: '1039-002',
        Compensate: '1039-003',
        Extra: '1039-004',
        Cancel: '1039-005',
        Finish: '1039-006',
    },
    Gender: {
        Male: '1011-001',
        Female: '1011-002'
    },
    Position: {
        Branch_Management: '1008-001',
        Cashier: '1008-002',
        WareHouse_PIC: '1008-003',
        Security: '1008-004'
    },
    CashBook_Payment_Method: {
        Transfer: '1004-001',
        Cash: '1004-002'
    },
    CashBook_Status: {
        Draft: '1019-001',
        Complete: '1019-002',
        Cancelled: '1019-003'
    },
    Receipt_Type: {
        BillsCollection: '1002-001'
    },
    Payment_Type: {
        BillPayment: '1003-001'
    },
    RoomAndTable_Group: {
        Vip: '1017-001',
        Normal: '1017-002',
        Other: '1017-003'
    },
    EmployeeStatus: {
        PartTime: '1023-001',
        FullTime: '1023-002',
        Probationary: '1023-003',
        Quit: '1023-004'
    },
    LeaveCategory: {
        AnnualLeave: '1045-001',
        BHXH_Leave: '1045-002',
        MarriedLeave: '1045-003',
        FuneralLeave: '1045-004',
        Prenatal_PostnatalLeave: '1045-005',
        MaternityLeave: '1045-006',
        UnpaidLeave: '1045-007',
        PrenatalCheckUpLeave: '1045-008',
        SickLeave: '1045-009',
        OtherLeave: '1045-010',
        CompensationLeave: '1045-011',
    },
    LeaveType: {
        PaidLeave: '1040-001',
        UnpaidLeave: '1040-002',
    },
    TimeRange: {
        Normal: '1041-001',
        Hours: '1041-002'
    },
    LanguageStatus: {
        En: '1010-001',
        Vn: '1010-002',
    },
    ContracType: {
        ContractBuy: '1016-001',
        ContractSell: '1016-002'
    },
    Log_Status: {
        new: 'new',
        done: 'done'
    },
    Log_Category: {
        search: 'search',
        create: 'create',
        update: 'update',
        delete: 'delete',
        login: 'login',
        import: 'import'
    },
    Log_Notification: {
        new: '0',
        finished: '1',
        cancel: '2',
        processing: '3',
        data_exist: '4',
        permission: '5'
    },
    PointSetting: {
        Payment_Point: '1026-001',
        Accumulate_No: '1026-002',
        Accumulate: '1026-003',
        Accumulate_Money: '1026-004',
        Accumulate_Point: '1026-005',
        Exchange_Point: '1026-006',
        Exchange_Money: '1026-007'
    },
    MeetingRoom_Status: {
        booked: '2020-001',
        notBooked: '2020-002'
    },
    TypeOfBookRoom: {
        normal: '3021-001',
        daily: '3021-002',
        monthly: '3021-003',
        annually: '3021-004',
        weekly: '3021-005',
    },
    Repeat_Day: {
        monday: '4021-006',
        tuesday: '4021-007',
        wednesday: '4021-008',
        thursday: '4021-009',
        friday: '4021-010',
        saturday: '4021-011',
        sunday: '4021-012'
    },
    Work_Date_Type: {
        workday: '1044-001',
        companyoffday: '1044-002',
        weekend: '1044-003',
        holiday: '1044-004'
    },
    TaskStatus: {
        New: '1042-001',
        Pending: '1042-002',
        Stuck: '1042-003',
        Done: '1042-004',
        Cancel: '1042-005',
    },
    Action_Todo_Type: {
        action_daily: '3022-002',
        action_weekly: '3022-003',
        action_monthly: '3022-003',
        action_annually: '3022-005',
    },
    UserPermission: {
        MasterLevel: '1046-001',
        BranchLevel: '1046-002',
        MemberLevel: '1046-003',
    },
    Todo_Status: {
        New: '3033-001',
        Pending: '3033-002',
        Done: '3033-003',
        Cancel: '3033-004'
    },
    OverTimeReason: {
        CompanyOrder: '1047-001',
        DoOwnTask: '1047-002'
    },
    ApplicationStatus: {
        New: '1048-001',
        Approve: '1048-002',
        Reject: '1048-003'
    },
    WarrantyStatus: {
        New: '1049-001',
        Handling: '1049-002',
        Done: '1049-003',
        Cancelled: '1049-004'
    },
    WarrantyProductStatus: {
        Warranty: '1050-001',
        Fix: '1050-002'
    },
    CandidateStatus: {
        ReceivedResume: '1051-001',
        Checking: '1051-002',
        Interview: '1051-003',
        JobOffer: '1051-004',
        Recruited: '1051-005',
    },
    ApplyPosition: {
        Intern: '1052-001',
        JuniorDev: '1052-002',
        SeniorDev: '1052-003',
        Tester: '1052-004',
        Manager: '1052-005',
    },
    MaritalStatus: {
        Single: '1053-001',
        Married: '1053-002',
        Separated: '1053-003',
        Divorced: '1053-004',
    },
    Degree: {
        Student: '1054-001',
        Bachelor: '1054-002',
        Master: '1054-003',
        Doctoral: '1054-004',
    },
    CandidateSkill: {
        CPlusPlus: '1055-001',
        Javascript: '1055-002',
        ReactJs: '1055-003',
        MongoDb: '1055-004',
        NodeJs: '1055-005',
    },
    News_Status: {
        New: '1057-001',
        Expired: '1057-002',
    },
    ClassifyColor : {
        DarkBlue: '1065-001',
        Green:'1065-002',
        Orange:'1065-003',
        Blue:'1065-004',
        Red:'1065-005',
    },
    Province : {
        AnGiang: 'An Giang',
        CanTho:'Can Tho',
        BenTre:'Ben Tre',
        CaMau:'Ca Mau',
        TraVinh:'Tra Vinh',
    },
    Source: {
        Web: '1067-001',
        SMS: '1067-002'
    },
    StatusFeedback: {
        New: '1068-001',
        Complete: '1068-002'
    }
};

const MPS_DISPLAY_AREA_KEY = {
    DisplaySortFields: 'DisplaySortFields',
    DisplaySearchConditions: 'DisplaySearchConditions',
    DisplaySearchResults: 'DisplaySearchResults',
    EditForm: 'EditForm',
    EditFormDetail: 'EditFormDetail',
};

const MPS_DISPLAY_AREA_STORAGE = {
    sortField: 'displaySortFields',
    sortType: 'changeSortType',
    searchField: 'displaySearchConditions',
    searchResult: 'displayColumnSearchResults',
    editField: 'displayFieldsOnEditForm',
    editResult: 'displayDefaultColumnOnEditForm'
};

const MPS_DISPLAY_DATA = {
    Many: '[**][docs]',
    One: '[docs][**][0]',
    DocsMany: '[docs][**]',
    ManyMany: '[**][docs][**]',
    ManyManyOne: '[**][docs][**][0]',
    ResultDataSource: 'result',
    ResultList: 'result[0].result'
};

const LOG_CATEGORY = {
    search: 'search',
    create: 'create',
    update: 'update',
    delete: 'delete',
    login: 'login',
    import: 'import'
};



const MPS_VALUE_SCOPE = {
    Max: 99999999999,
    Min: -99999999999
}

const DEFAULT_UNIQUECODE = '315893540';

module.exports = {
    MPS_RESULTS,
    TRANSACTIONSTATUS,
    ROOMTYPE,
    CASH_BOOK_TYPE,
    MPS_SCREEN_ACTION,
    MPS_SCREEN_NO,
    MPS_MODE,
    MPS_DISPLAY_AREA_STORAGE,
    MPS_TRANSACTION_TYPE,
    MPS_BALANCE,
    ROUTE_APPLICATION_AVAILABLE,
    MPS_SYSTEM_PARAMETER_TYPE,
    MPS_SYSTEM_PARAMETER,
    MPS_DISPLAY_AREA_KEY,
    MPS_DISPLAY_DATA,
    LOG_CATEGORY,
    MPS_VALUE_SCOPE,
    DEFAULT_UNIQUECODE
};

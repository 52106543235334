export const LANGUAGE_STATE ={
    'vn':
        {
            ADDRESS:'Địa chỉ',
            CITY:'Thành phố',
            DISTRICT:'Quận, Huyện',
            WARD:'Phường, Xã'
        },
    'en':
        {
            ADDRESS:'Address',
            CITY:'City',
            DISTRICT:'District',
            WARD:'Ward'
        }
}

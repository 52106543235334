import { isNullOrUndefined } from 'util';
import { authHeader } from '../../../../authenticate/Helpers';
import { API_URL } from '../../../../conf.js';
export const Address_Service = {
    getCity,
    getCitySelect,
    getDistrict,
    getWard,
    createCity,
    createDistrict,
    createWard
};
const linkAddress = 'https://thongtindoanhnghiep.co';
const proxyurl = 'https://cors.bridged.cc/';


function getCity() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    fetch(proxyurl + linkAddress + `/api/city`, requestOptions)
    .then(async response => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return Promise.resolve(data);

    })
    .catch(error => {
        console.error('There was an error!', error);
    });   
}


function getCitySelect(value) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(proxyurl + linkAddress + `/api/city/${value}/`, requestOptions).then(handleResponse);
}

function getDistrict(city) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(proxyurl + linkAddress + `/api/city/${city}/district/`, requestOptions).then(handleResponse);
}



function getWard(district) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(proxyurl + linkAddress + `/api/district/${district}/ward/`, requestOptions).then(handleResponse);
}
function createCity(city_list) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(city_list)
    };

    return fetch(API_URL + `/address/createCity`, requestOptions).then(handleResponse);
}
function createDistrict(district_list) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(district_list)
    };

    return fetch(API_URL + `/address/createDistrict`, requestOptions).then(handleResponse);
}
function createWard(ward_list) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(ward_list)
    };

    return fetch(API_URL + `/address/createWard`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

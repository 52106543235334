import { authHeader } from '../../../authenticate/Helpers';
import {API_URL} from '../../../conf.js';
import {Mps_MethodCommon} from '../../../commons';
import {authenticationService} from '../../../authenticate/Services';

export const Sipco_WaterFilter_Service = {
    search,
    search1,
    getById,
    create,
    update,
    deleteModel,
    getDataFilter,
    importModel,
    deleteWaterFilterFiles,
    uploadWaterFilterFiles,
    uploadFiles,
    checkFileExit,
    ViewByCPA,
    searchWaterFilterExpired,
    viewByProvince,
    viewByDistrict,
    viewByVillage,
    searchForDownload

};

const content = 'Content-Type';
const Type = 'application/json';
//Lấy thông tin chi tiết của Sipco_WaterFilter theo điều kiện tìm kiếm được nhập.
function search(searchModel,searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };

    return fetch(`${API_URL}/Sipco_WaterFilter/search`, requestOptions).then(handleResponse);
}
function searchForDownload(searchModel,searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };

    return fetch(`${API_URL}/Sipco_WaterFilter/searchCPAForDownload`, requestOptions).then(handleResponse);
}
//Lấy thông tin chi tiết của Sipco_WaterFilter sắp hết hạn.
function search1(searchModel,searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };

    return fetch(`${API_URL}/Sipco_WaterFilter/search1`, requestOptions).then(handleResponse);
}
function searchWaterFilterExpired(searchModel,searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };

    return fetch(`${API_URL}/Sipco_WaterFilter/searchWaterFilterExpired`, requestOptions).then(handleResponse);
}


//Lấy thông tin chi tiết của một Sipco_WaterFilter theo Id.
function getById(_id) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        method: 'GET',
        headers
    };
    return fetch(`${API_URL}/Sipco_WaterFilter/getById/${_id}`, requestOptions).then(handleResponse);
}

//Lấy dữ liệu hiển thị cho autocomplete.
function getDataFilter(searchModel,  page = 1, limit = Mps_MethodCommon.paginationLimitMaximum) {
    const value = {
        searchModel,
        page,
        limit,
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(`${API_URL}/Sipco_WaterFilter/getDataFilter`, requestOptions).then(handleResponse);
}

//Tạo mới một Sipco_WaterFilter
function create(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(`${API_URL}/Sipco_WaterFilter/create`, requestOptions).then(handleResponse);
}

//Import data
function importModel(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify({model})
    };
    return fetch(`${API_URL}/Sipco_WaterFilter/import`, requestOptions).then(handleResponse);
}

//Cập nhật thông tin một Sipco_WaterFilter
function update(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(`${API_URL}/Sipco_WaterFilter/update`, requestOptions).then(handleResponse);
}

//Xóa thông tin một/danh sách Sipco_WaterFilter
function deleteModel(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify({model})
    };
    return fetch(`${API_URL}/Sipco_WaterFilter/delete`, requestOptions).then(handleResponse);

}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                //authenticationService.logout();    
                window.location.reload(true);            
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function uploadWaterFilterFiles(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: model
    };
    return fetch(`${API_URL}` + `/Sipco_WaterFilter/uploadWaterFilterFiles`, requestOptions).then(handleResponse);
}
function uploadFiles(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: model
    };
    return fetch(`${API_URL}` + `/Sipco_WaterFilter/uploadFiles`, requestOptions);
}
function deleteWaterFilterFiles(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);

    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model),
    };
    return fetch(`${API_URL}` + `/Sipco_WaterFilter/deleteWaterFilterFiles`, requestOptions).then(handleResponse);
}

function getWaterFilterFileCover(_id) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify({ _id })
    };
    return fetch(`${API_URL}` + `/Sipco_WaterFilter/getWaterFilterFileCover`, requestOptions).then(handleResponse);
}

function checkFileExit(model) {
    const value = {model};
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(`${API_URL}` + `/Sipco_WaterFilter/checkFileExit`, requestOptions).then(handleResponse);
}

function ViewByCPA(searchModel, searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(`${API_URL}/Sipco_WaterFilter/ViewByCPA`, requestOptions).then(handleResponse);
}

    function viewByProvince(searchModel, searchOptions) {
        const value = {
            searchModel,
            searchOptions
        };
        const auth = authHeader();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', auth);
        const requestOptions = {
            headers,
            method: 'POST',
            body: JSON.stringify(value)
        };
        return fetch(`${API_URL}/Sipco_WaterFilter/viewByProvince`, requestOptions).then(handleResponse);
}

function viewByDistrict(searchModel, searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(`${API_URL}/Sipco_WaterFilter/viewByDistrict`, requestOptions).then(handleResponse);
}

function viewByVillage(searchModel, searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(`${API_URL}/Sipco_WaterFilter/viewByVillage`, requestOptions).then(handleResponse);
}

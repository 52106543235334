import React from 'react';
import { Upload, Icon, Modal } from 'antd';

function hexToBase64(str) {
  return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' ')));
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class Mps_UploadPicture extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      multiplePicture: this.props.multiplePicture === false ? false : true,
      previewImage: '',
      fileList: [
      ]
    };

  }
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const imagelist = this.props.value;
      this.setState({ fileList: imagelist });
    }

  }
  componentWillMount() {
    const imagelist = this.props.value;
    if (imagelist !== null && imagelist !== undefined && imagelist.length !== 0) {
      this.setState({ fileList: imagelist });
    }
  }


  handleChange = async (file) => {
    const acceptFileType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp'];
    if (acceptFileType.includes(file.file.type)) {
      if (!file.file.preview && file.file.originFileObj.name) {
        file.file.thumbUrl = await getBase64(file.file.originFileObj);
        file.file.response = null;
      }
      if (this.props.limitUpload) {
        file.fileList = file.fileList.slice(-this.props.limitUpload);
      }
      this.setState({ fileList: file.fileList });
      this.props.onChange(file.fileList);
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    this.setState({
      previewImage: file.preview || file.thumbUrl,
      previewVisible: true
    });
  };

  render() {
    const { fileList, multiplePicture } = this.state;
    const uploadButton = (
      <div>
        <Icon type='plus' />
        <div className='ant-upload-text'>Upload</div>
      </div>
    );
    const { previewVisible, previewImage } = this.state;
    return (

      <div className={`clearfix ${!!this.props.disabled ? 'hidden-delete' : ''}`}>
        <Upload beforeUpload={this.handleBeforeUpload}
          listType='picture-card'
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          disabled={this.props.disabled}
        >
          {multiplePicture === true || (typeof fileList === 'object') && fileList.length === 0 ? uploadButton : null}
        </Upload>
        <Modal style={{width :'auto !important'}} centered={true} visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt='example' style={{ width: '100%', maxWidth: '100%', objectFit: 'scale-down' }} src={previewImage} />
        </Modal>
      </div>

    );
  }
}

export { Mps_UploadPicture };

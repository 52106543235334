
import React from 'react';
import {Log_Service} from './Log.Service';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo);      
      Log_Service.create("App", errorInfo);
    }
  
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
              <Result
                status="warning"
                title="There are some problems with your operation."
                extra={<Link to="/"><Button type="primary">Quay về trang chủ</Button></Link>}
              />
            );
        }
    
        return this.props.children; 
    }
  }
export const LANGUAGE_STATE ={
    'vn':
        {
            PAGE_TITLE: 'Quản lý phản hồi người dùng',
            SIPCO_USERFEEDBACK_SEARCH: 'Quản lý phản hồi người dùng',
            SIPCO_USERFEEDBACK_NEW: 'Thêm mới thông tin',
            SIPCO_USERFEEDBACK_COPY: 'Sao chép thông tin',
            SIPCO_USERFEEDBACK_EDIT: 'Chỉnh sửa thông tin',
            SIPCO_USERFEEDBACK_VIEW: 'Xem thông tin',
            SIPCO_USERFEEDBACK_TITLE_GENERAL: 'Thông tin chung',
            SIPCO_USERFEEDBACK_TITLE_ADDITIONAL: 'Thông tin thêm',
            SERIAL_CODE: 'Mã số bình lọc nước',
            SOURCE: 'Loại',
            CONTENT:'SIPCO phản hồi ',         
            DESCRIPTION:'Khách hàng phản hồi',
            STATUS:'Trạng thái',
            CREATED_DATE: 'Ngày tạo',
            CREATED_BY: 'Người tạo',
            UPDATED_DATE: 'Ngày cập nhật',
            UPDATED_BY: 'Người cập nhật',
            SEARCH_INFOR:'Thông tin tìm kiếm',
            SAVE: 'Lưu',
            SAVEANDCLOSE:'Lưu và đóng',
            SAVEANDNEXT: 'Lưu và tiếp tục',
            CANCEL:'Hủy',
            CLOSE:'Đóng',
            SEARCH:'Tìm kiếm',
            NEW: 'Thêm mới',
            VIEW: 'Xem',
            EDIT: 'Chỉnh sửa',
            DELETE: 'Xóa',
            COPY:'Sao chép',
            IMPORT:'Nhập dữ liệu',
            DOWNLOADDATA:'Tải dữ liệu (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import mẫu',
            SIPCO_USERFEEDBACK_DATA_FILE: 'File data SIPCO_USERFEEDBACK',
            FEEDBACK:'Phản hồi', 
            

        },
    'en':
        {
            PAGE_TITLE: 'User Feedback management',
            SIPCO_USERFEEDBACK_SEARCH: 'User Feedback',
            SIPCO_USERFEEDBACK_NEW: 'Add new User Feedback information',
            SIPCO_USERFEEDBACK_COPY: 'Add User Feedback information',
            SIPCO_USERFEEDBACK_EDIT: 'Edit User Feedback information',
            SIPCO_USERFEEDBACK_VIEW: 'View User Feedback information',
            SIPCO_USERFEEDBACK_TITLE_GENERAL: 'General  information',
            SIPCO_USERFEEDBACK_TITLE_ADDITIONAL: 'Additional information',
            SERIAL_CODE: 'Household',
            SOURCE: 'Source',
            CONTENT:'SIPCO feedback',  
            DESCRIPTION:'Customer feedback',
            STATUS:'Status',
            CREATED_DATE: 'Created date',
            CREATED_BY: 'Created by',
            UPDATED_DATE: 'Updated date',
            UPDATED_BY: 'Updated by',
            SEARCH_INFOR:'Search Information',
            SAVE: 'Save',
            SAVEANDCLOSE:'Save and close',
            SAVEANDNEXT: 'Save and continue',
            CANCEL:'Cancel',
            CLOSE:'Close',
            SEARCH:'Search',
            NEW: 'New',
            EDIT: 'Edit',
            VIEW: 'View',
            DELETE: 'Delete',
            COPY:'Copy',
            IMPORT:'Import data',
            DOWNLOADDATA:'Download Data (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import example',
            SIPCO_USERFEEDBACK_DATA_FILE: 'File data SIPCO_USERFEEDBACK',
            FEEDBACK:'Feedback',

        }
    };

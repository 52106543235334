import { Mps_LabelFormat } from '../../Mps_Control/Mps_LabelFormat/Mps_LabelFormat';
import { MPS_DISPLAY_AREA_KEY, MPS_SCREEN_NO } from '../../../commons';
import React from 'react';
import { Icon, Tooltip } from 'antd';

export function initResultTable(language, defaultColumn) {

    return [
        {
            width: 130,
            title: language.SYSTEM_COMPANY_UNIQUE_CODE,
            dataIndex: 'System_UniqueCode',
            key: 'System_UniqueCode',
            className: defaultColumn.includes('System_UniqueCode') ? '' : 'c-hide'
        },
        {
            width: 200,
            title: language.SYSTEM_COMPANY_PARENT_CODE,
            dataIndex: 'System_Company_ParentObject[0].System_Company_Name',
            key: 'System_Company_Parent_Code',
            className: defaultColumn.includes('System_Company_Parent_Code') ? 'cell-ellipsis' : 'c-hide'
        },
        {
            width: 150,
            title: language.SYSTEM_COMPANY_CODE,
            dataIndex: 'System_Company_Code',
            key: 'System_Company_Code',
            className: defaultColumn.includes('System_Company_Code') ? '' : 'c-hide'
        },
        {
            width: 300,
            title: language.SYSTEM_COMPANY_NAME,
            dataIndex: 'System_Company_Name',
            key: 'System_Company_Name',
            className: defaultColumn.includes('System_Company_Name') ? '' : 'c-hide'
        },
        {
            width: 160,
            title: language.SYSTEM_COMPANY_TAX,
            dataIndex: 'System_Company_Tax',
            key: 'System_Company_Tax',
            className: defaultColumn.includes('System_Company_Tax') ? '' : 'c-hide'
        },
        {
            width: 160,
            title: language.SYSTEM_COMPANY_PHONE,
            dataIndex: 'System_Company_PhoneNumber',
            key: 'System_Company_PhoneNumber',
            className: defaultColumn.includes('System_Company_PhoneNumber') ? '' : 'c-hide'
        },
        {
            width: 160,
            title: language.SYSTEM_COMPANY_FAX,
            dataIndex: 'System_Company_Fax',
            key: 'System_Company_Fax',
            className: defaultColumn.includes('System_Company_Fax') ? '' : 'c-hide'
        },
        {
            width: 200,
            title: language.SYSTEM_COMPANY_EMAIL,
            dataIndex: 'System_Company_Email',
            key: 'System_Company_Email',
            className: defaultColumn.includes('System_Company_Email') ? '' : 'c-hide'
        },
        {
            width: 200,
            title: language.SYSTEM_COMPANY_BUSINESS,
            dataIndex: 'System_Company_BusinessObject.System_Company_Business_Name',
            key: 'System_Company_Business',
            className: defaultColumn.includes('System_Company_Business') ? 'cell-ellipsis' : 'c-hide'
        },
        {
            width: 200,
            title: language.SYSTEM_COMPANY_WEBSITE,
            dataIndex: 'System_Company_Website',
            key: 'System_Company_Website',
            className: defaultColumn.includes('System_Company_Website') ? '' : 'c-hide'
        },
        {
            width: 400,
            title: language.ADDRESS,
            dataIndex: 'Address',
            key: 'Address',
            className: defaultColumn.includes('Address') ? '' : 'c-hide'
        },
        {
            width: 200,
            title: language.CITY,
            dataIndex: 'City',
            key: 'City',
            className: defaultColumn.includes('City') ? '' : 'c-hide'
        },
        {
            width: 130,
            title: language.DISTRICT,
            dataIndex: 'District',
            key: 'District',
            className: defaultColumn.includes('District') ? '' : 'c-hide'
        },
        {
            width: 130,
            title: language.WARD,
            dataIndex: 'Ward',
            key: 'Ward',
            className: defaultColumn.includes('Ward') ? '' : 'c-hide'
        },
        {
            width: 200,
            title: language.SYSTEM_COMPANY_EXPIREDDATE,
            dataIndex: 'System_Company_ExpiredDate',
            key: 'System_Company_ExpiredDate',
            className: defaultColumn.includes('System_Company_ExpiredDate') ? 'display-date' : 'c-hide display-date',
            render: date => (
                Mps_LabelFormat.formatDateTime(date)
            )
        },
        {
            width: 160,
            title: language.SYSTEM_COMPANY_LOGO,
            dataIndex: 'System_Company_Logo',
            key: 'System_Company_Logo',
            className: defaultColumn.includes('System_Company_Logo') ? 'cell-ellipsis' : 'c-hide',
            render: System_Company_Logo => (
                <div>
                    {!!System_Company_Logo ? System_Company_Logo.map(tag => {
                        return (
                            <div key={tag.name} style={{ textAlign: 'center' }}>
                                <img width={50} height={50} src={tag.thumbUrl} />
                            </div>
                        );
                    }): ""}
                </div>
            ),
        },
        {
            width: 200,
            title: language.SYSTEM_COMPANY_LICENSE,
            dataIndex: 'System_Company_License',
            key: 'System_Company_License',
            className: defaultColumn.includes('System_Company_License') ? '' : 'c-hide',
            render: System_Company_License => (
                <div>
                    {!!System_Company_License ? System_Company_License.map(filelist => {
                        return (
                            <div key={filelist.name} style={{ textAlign: 'center' }}>
                                 <a className={'hidden-delete'} ref={filelist.thumbUrl} >{filelist.name} </a>
                            </div>
                        );
                    }): ""}
                </div>
            ),
        },
        {
            width: 150,
            title: language.STATUS,
            dataIndex: 'StatusObject.System_Parameter_Name',
            key: 'Status',
            className: defaultColumn.includes('Status') ? 'cell-ellipsis' : 'c-hide'
        },
        {
            width: 200,
            title: language.CREATED_BY,
            dataIndex: 'CreatedByObject.System_User_DisplayName',
            key: 'CreatedBy',
            className: defaultColumn.includes('CreatedBy') ? 'cell-ellipsis' : 'c-hide'
        },
        {
            width: 150,
            title: language.CREATED_DATE,
            dataIndex: 'CreatedDate',
            key: 'CreatedDate',
            className: defaultColumn.includes('CreatedDate') ? '' : 'c-hide ',
            render: date => (
                Mps_LabelFormat.formatDateTime(date)
            )
        },
        {
            width: 200,
            title: language.UPDATED_BY,
            dataIndex: 'UpdatedByObject.System_User_DisplayName',
            key: 'UpdatedBy',
            className: defaultColumn.includes('UpdatedBy') ? 'cell-ellipsis' : 'c-hide'
        },
        {
            width: 150,
            title: language.UPDATED_DATE,
            dataIndex: 'UpdatedDate',
            key: 'UpdatedDate',
            className: defaultColumn.includes('UpdatedDate') ? '' : 'c-hide ',
            render: date => (
                Mps_LabelFormat.formatDateTime(date)
            )
        },
    ];
}

export function initFieldsDisplayOnSearchForm(language, defaultColumn) {

    return [
        {
            width: 130,
            title: language.SYSTEM_COMPANY_UNIQUE_CODE,
            dataIndex: 'System_UniqueCode',
            key: 'System_UniqueCode',
        },
        {
            width: 200,
            title: language.SYSTEM_COMPANY_PARENT_CODE,
            dataIndex: 'System_Company_ParentObject[0].System_Company_Name',
            key: 'System_Company_Parent_Code',
        },
        {
            width: 150,
            title: language.SYSTEM_COMPANY_CODE,
            dataIndex: 'System_Company_Code',
            key: 'System_Company_Code',
        },
        {
            width: 200,
            title: language.SYSTEM_COMPANY_NAME,
            dataIndex: 'System_Company_Name',
            key: 'System_Company_Name',
        },
        {
            width: 160,
            title: language.SYSTEM_COMPANY_TAX,
            dataIndex: 'System_Company_Tax',
            key: 'System_Company_Tax',
        },
        {
            width: 160,
            title: language.SYSTEM_COMPANY_PHONE,
            dataIndex: 'System_Company_PhoneNumber',
            key: 'System_Company_PhoneNumber',
        },
        {
            width: 160,
            title: language.SYSTEM_COMPANY_FAX,
            dataIndex: 'System_Company_Fax',
            key: 'System_Company_Fax',
        },
        {
            width: 200,
            title: language.SYSTEM_COMPANY_EMAIL,
            dataIndex: 'System_Company_Email',
            key: 'System_Company_Email',
        },
        {
            width: 200,
            title: language.SYSTEM_COMPANY_WEBSITE,
            dataIndex: 'System_Company_Website',
            key: 'System_Company_Website',
        },
        {
            width: 400,
            title: language.ADDRESS,
            dataIndex: 'Address',
            key: 'Address',
        },
        {
            width: 200,
            title: language.CITY,
            dataIndex: 'City',
            key: 'City',
        },
        {
            width: 130,
            title: language.DISTRICT,
            dataIndex: 'District',
            key: 'District',
        },
        {
            width: 130,
            title: language.WARD,
            dataIndex: 'Ward',
            key: 'Ward',
        },
        {
            width: 200,
            title: language.SYSTEM_COMPANY_EXPIREDDATE,
            dataIndex: 'System_Company_ExpiredDate',
            key: 'System_Company_ExpiredDate',
            render: date => (
                Mps_LabelFormat.formatDateTime(date)
            )
        },
        {
            width: 150,
            title: language.STATUS,
            dataIndex: 'StatusObject.System_Parameter_Name',
            key: 'Status',
        },
        {
            width: 200,
            title: language.CREATED_BY,
            dataIndex: 'CreatedByObject.System_User_DisplayName',
            key: 'CreatedBy',
        },
        {
            width: 150,
            title: language.CREATED_DATE,
            dataIndex: 'CreatedDate',
            key: 'CreatedDate',
            render: date => (
                Mps_LabelFormat.formatDateTime(date)
            )
        },
        {
            width: 200,
            title: language.UPDATED_BY,
            dataIndex: 'UpdatedByObject.System_User_DisplayName',
            key: 'UpdatedBy',
        },
    ];
}
export function headers(initResultTableForHeader) {
    const columns = initResultTableForHeader;

    const displayDefaultColums = getDisplayColumnSearchResults();

    const headersExport = [];
    columns.forEach(element => {
        if (displayDefaultColums.includes(element.key)) {
            const colum = { label: '' };
            colum.label = element.title;
            colum.key = element.key;
            headersExport.push(colum);
        }
    });

    return headersExport;
}
export const searchModel = {
    System_Company_Code: '',
    System_UniqueCode: '',
    System_Company_Parent_Code: '',
    System_Company_Name: '',
    System_Company_Tax: '',
    System_Company_PhoneNumber: '',
    System_Company_Fax: '',
    System_Company_Email: '',
    System_Company_Website: '',
    Address: '',
    District: '',
    Ward: '',
    City: '',
    System_Company_ExpiredDate: [],
    Status: [],
    addressModel: {
        City: '',
        Address: '',
        District: '',
        Ward: ''
    },
    CreatedBy: [],
    CreatedDate: [],
    UpdatedBy: [],
    UpdatedDate: []
};

export const addressModel = {
    City: '',
    Address: '',
    District: '',
    Ward: ''
};

export const initModel = {
    _id: '',
    System_Company_Code: '',
    System_UniqueCode: '',
    System_Company_Parent_Code: '',
    System_Company_Name: '',
    System_Company_Tax: '',
    System_Company_PhoneNumber: '',
    System_Company_Fax: '',
    System_Company_Email: '',
    System_Company_Website: '',
    Address: '',
    District: '',
    Ward: '',
    City: '',
    System_Company_ExpiredDate: '',
    System_Company_License: [],
    System_Company_Logo: [
        {
            thumbUrl: ''
        }
    ],
    Status: '',
    CreatedBy: '',
    CreatedDate: '',
    UpdatedBy: '',
    UpdatedDate: ''
};

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySearchConditionsKey = MPS_SCREEN_NO.Company + MPS_DISPLAY_AREA_KEY.DisplaySearchConditions;
export function setDisplaySearchConditions(values) {
    localStorage.setItem(displaySearchConditionsKey, JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplaySearchConditions() {
    const column = JSON.parse(localStorage.getItem(displaySearchConditionsKey));
    if (column === null) {
        //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
        return ['System_Company_Code', 'System_Company_Name', 'Status'];
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySortFieldsKey = MPS_SCREEN_NO.System_Company + MPS_DISPLAY_AREA_KEY.DisplaySortFields;
export function setDisplaySortFields(values) {
    localStorage.setItem(displaySortFieldsKey, JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplaySortFields() {
    const column = JSON.parse(localStorage.getItem(displaySortFieldsKey));
    if (column === null) {
        //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
        return ['CreatedDate'];
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySearchResultsKey = MPS_SCREEN_NO.Company + MPS_DISPLAY_AREA_KEY.DisplaySearchResults;
export function setDisplayColumnSearchResults(values) {
    localStorage.setItem(displaySearchResultsKey, JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplayColumnSearchResults() {
    const column = JSON.parse(localStorage.getItem(displaySearchResultsKey));
    if (column === null) {
        //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
        return ['System_UniqueCode','System_Company_Code', 'System_Company_Name', 'System_Company_Tax', 'System_Company_PhoneNumber','Address','Status']
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình add/edit/view.
export function initFielDisplayOnEditForm(language) {
    return [
        {
            title: language.SYSTEM_COMPANY_UNIQUE_CODE,
            key: 'System_UniqueCode'
        },
        {
            title: language.SYSTEM_COMPANY_PARENT_CODE,
            key: 'System_Company_Parent_Code'
        },
        {
            title: language.SYSTEM_COMPANY_CODE,
            key: 'System_Company_Code'
        },
        {
            title: language.SYSTEM_COMPANY_NAME,
            key: 'System_Company_Name'
        },
        {
            title: language.SYSTEM_COMPANY_TAX,
            key: 'System_Company_Tax'
        },
        {
            title: language.SYSTEM_COMPANY_PHONE,
            key: 'System_Company_PhoneNumber'
        },
        {
            title: language.SYSTEM_COMPANY_FAX,
            key: 'System_Company_Fax'
        },
        {
            title: language.SYSTEM_COMPANY_EMAIL,
            key: 'System_Company_Email'
        },
        {
            title: language.SYSTEM_COMPANY_BUSINESS,
            key: 'System_Company_Business'
        },
        {
            title: language.SYSTEM_COMPANY_WEBSITE,
            key: 'System_Company_Website'
        },
        {
            title: language.ADDRESS,
            key: 'Address'
        },
        {
            title: language.CITY,
            key: 'City'
        },
        {
            title: language.DISTRICT,
            key: 'District'
        },
        {
            title: language.WARD,
            key: 'Ward'
        },
        {
            title: language.SYSTEM_COMPANY_EXPIREDDATE,
            key: 'System_Company_ExpiredDate'
        },
        {
            title: language.SYSTEM_COMPANY_LICENSE,
            key: 'System_Company_License'
        },
        {
            title: language.SYSTEM_COMPANY_LOGO,
            key: 'System_Company_Logo'
        },
        {
            title: language.STATUS,
            key: 'Status'
        },
        {
            title: language.CREATED_BY,
            key: 'CreatedBy'
        },
        {
            title: language.CREATED_DATE,
            key: 'CreatedDate'
        },
        {
            title: language.UPDATED_BY,
            key: 'UpdatedBy'
        },
        {
            title: language.UPDATED_DATE,
            key: 'UpdatedDate'
        }

    ];
}

export function headerExample(columnDisplay) {
    const columns = columnDisplay;
    const headersExport = [];
    const displayDefaultColums = getDisplayColumnSearchResults();
    columns.forEach(element => {
        if (displayDefaultColums.includes(element.key)) {
            const colum = {
                label: '',
                key: ''
            };
            colum.label = element.title;
            colum.key = element.key;
            headersExport.push(colum);
        }

    });
    return headersExport;
}

const displayEditFormKey = MPS_SCREEN_NO.Company + MPS_DISPLAY_AREA_KEY.EditForm;
export function setDisplayFieldOnEditForm(values) {
    localStorage.setItem(displayEditFormKey, JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình add/edit/view.
export function getDisplayFieldOnEditForm() {
    const column = JSON.parse(localStorage.getItem(displayEditFormKey));
    if (column === null) {
        //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
        return ['System_Company_Code', 'System_Company_Name', 'Status'];
    }
    return column;
}

//Thiết lập trường thông tin hiển thị.
export const initFieldControl = {
    System_UniqueCode: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    System_Company_Parent_Code: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    System_Company_Code: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: ''
    },
    System_Company_Name: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: ''
    },
    System_Company_Tax: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    System_Company_PhoneNumber: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    System_Company_Fax: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    System_Company_Email: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    System_Company_Website: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    Address: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    City: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    District: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    Ward: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    System_Company_ExpiredDate: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    System_Company_License: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    System_Company_Logo: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: ''
    },
    Status: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
        Message: ''
    },
    
};

export function initUserResultTable(language) {
    return [
        {
            width: 200,
            title: language.SYSTEM_USER_NAME,
            dataIndex: 'System_User_Code',
            key: 'System_User_Code',
        },
        {
            width: 200,
            title: language.SYSTEM_USER_DISPLAY_NAME,
            dataIndex: 'System_User_DisplayName',
            key: 'System_User_DisplayName',
        },
        {
            width: 200,
            title: language.SYSTEM_USER_EMPLOYEE,
            dataIndex: 'System_User_EmployeeObject.Hr_Employee_Name',
            key: 'System_User_Employee',
        },
        {
            width: 250,
            title: language.SYSTEM_USER_ROLES,
            dataIndex: 'System_User_Roles',
            key: 'System_User_Roles',
            render: roles => (
                (!!roles) ? roles.map(m => m.System_Role_Name).join(', ') : ''
            )

        }, 
        {
            width: 400,
            title: language.SYSTEM_USER_TOKEN_API,
            dataIndex: 'System_User_TokenAPI',
            key: 'System_User_TokenAPI',
            render: value => (
                <div>
                    {value}
                </div>
               
            )

        }, 
               
    ];
}

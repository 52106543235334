import React from 'react';
import { Form, Input, Button, Tabs, Row, Col, Modal, Card } from 'antd';
import jsonQuery from 'json-query';

import { MPS_DISPLAY_AREA_STORAGE, MPS_MESSAGE_STATE, Mps_MethodCommon, MPS_SYSTEM_PARAMETER_TYPE, MPS_SCREEN_NO } from '../../commons';
import {
    Mps_LayoutFormat
    , Mps_SelectParameterSystem, Mps_InputDatePicker
    , Mps_Tooltip, Mps_DisplayField, Mps_DisplayColumnResultTable
} from '../Mps_Control';
import { System_User_Select } from '../System_User/Controls/System_User_Select';
import { SIPCO_CPA_Service } from './Service/SIPCO_CPA.Service';
import { LANGUAGE_STATE } from './Language/ln';
import { GUIDE_LINE } from './Language/Guideline';
import { setDisplayFieldOnEditForm,
    getDisplayFieldOnEditForm, initFielDisplayOnEditForm, initModel, initFieldControl
} from './Models/SIPCO_CPA.Model';
import moment from 'moment';
import logger from '../../commons/Mps_Logger';
import Mps_UploadPictureV2  from '../Mps_Control/Mps_UploadPicture/Mps_UploadPictureV2';
import {API_URL} from '../../../src/conf.js';

const { TabPane } = Tabs;

const screenNo = MPS_SCREEN_NO.CPA;

class SIPCO_CPAView extends React.Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([this.props.language[0]], { data: LANGUAGE_STATE }).value;
        const commonMessage = jsonQuery([this.props.language[0]], { data: MPS_MESSAGE_STATE }).value;
        const guideLine = jsonQuery([this.props.language[0]], { data: GUIDE_LINE }).value;
        this.state = {
            language,
            commonMessage,
            guideLine,
            chooseLanguage,
            selectedModel: initModel,
            visible: false,
            src: ''
        };
    }
    /**
     * @desc Khởi tạo data khi load component
     * @param Nothing
     * @return Nothing
    */
    componentWillMount() {
        const selectedModel = this.props.value;
        this.setState({
            selectedModel,
        });

    }
        /**
    * @desc Handle thiết lập sắp xếp hiển thị field trên form.
    * @param
    *   array value: Danh sách Field được chọn hiển thị trên form.
    * @return Nothing (Cập nhật setting),
    */
         handleDisplayFieldOnEditFormChange = value => {
            setDisplayFieldOnEditForm(value);
            this.setState({
                displayDefaultFieldOnEditForm: value
            });
        };
    /**
     * @desc Handle thông tin lỗi
     * @param
     *   Nothing: nothing
     * @return Nothing
     */
    componentDidCatch(error, info) {
        // log the error to loggly
        //logger.push({ error, info });
    }
    /**
  * @desc Handle tùy chọn hiển thị
  * @param
  *   Nothing: nothing
  * @return Nothing
  */
 handleDisplayChange = (type, value) => {
    if (!value && type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
        this.setState({ isReset: this.state.isReset ? false : true });
    }
    if (type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
        if (type === MPS_DISPLAY_AREA_STORAGE.sortField) {
            const { searchOptions } = this.state;
            searchOptions.sort = value;
            this.setState({
                searchOptions
            });
            return ;
        }
        this.setState({ [type]: value });
    } else {
        const { searchOptions } = this.state;
        searchOptions.sortAscending = value;
        this.setState({
            sortAscending: value,
            searchOptions
        });
    }
};
    /**
   * @desc Handle sự kiện hiển thị màn hình View/Add/Copy/Edit
   * @param event
   *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
   *   object data - dữ liệu hiển thị
   * @return Nothing
   */
    handleShowForm = e => {
        e.preventDefault();
        var selectedModel = this.props.value;
        /*demo mẫu logger*/
        //logger.push({ selectedModel });
        if (selectedModel.length === 0 || selectedModel[0].Partner_Code === '') {
            Mps_MethodCommon.handleShowConfirm(this.state.commonMessage.CHOOSE_DATA_VIEW, '');
        } else if (selectedModel.length > 1 || selectedModel[0].Partner_Code === '') {
            Mps_MethodCommon.openWarningNotification(this.state.commonMessage.CHOOSE_ONLY_DATA_VIEW, '');
            return;           
        } else {
            Promise.all([SIPCO_CPA_Service.getById(selectedModel[0]._id)]).then((result) => {
                var data = jsonQuery('result[0]', { data: result }).value;
                /*demo mẫu logger*/
                //logger.push({ data });
                this.setState({
                    selectedModel: data,
                    visible: true,
                });
            }
            );
            Promise.all([SIPCO_CPA_Service.checkFileExit(selectedModel[0].SIPCO_CPA_Code)]).then((result) => {
                this.setState({
                    src: result[0].data,
                    visible: true,
                });
                }
                );
        }
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    handleReset = () => {
        this.props.form.resetFields();
    };

    handleStatusChange = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.Status = value;
        /*demo mẫu logger*/
        //logger.push({ selectedModel });
        this.setState({
            selectedModel
        });
    };

    render() {
        if (this.props.allowAccess === false) {
            return '';
        }

        const {language, guideLine, src} = this.state;
        const { getFieldDecorator } = this.props.form;
        const screenName = language.SIPCO_CPA_SCREEN_VIEW;
        const selectedModel = this.state.selectedModel;
        const displayDefaultFieldOnEditForm = getDisplayFieldOnEditForm();
        const displayFieldOnEditForm = initFielDisplayOnEditForm(language);
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const gridStyle = {
            width: '50%'
        };

        return (
            <span>
                {this.props.allowAccess === true ? <Button type="primary" onClick={this.handleShowForm}>{this.props.actionName}</Button> : ' '}
                <Modal
                    title={screenName}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleCancel}>
                            {language.CLOSE}
                        </Button>,
                    ]}
                    width={800}>
                    <Form {...formItemLayout} onSubmit={this.handleSave} id={Mps_MethodCommon.getDisplayTheme()}>
                        <Mps_DisplayColumnResultTable columns={displayFieldOnEditForm}
                            onDisplayFieldOnEditFormChange={this.handleDisplayFieldOnEditFormChange}
                            displayFieldOnEditForm={displayDefaultFieldOnEditForm}
                            language={this.state.chooseLanguage} />
                    <Tabs defaultActiveKey="1" >
                            <TabPane tab={language.SIPCO_CPA_SCREEN_TITLE_GENERAL} key="1">
                                <Card style={{ border: 'none' }} >

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_Code')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item>
                                                    {language.SIPCO_CPA_CODE}

                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('SIPCO_CPA_Code', {
                                                        initialValue: selectedModel.SIPCO_CPA_Code,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.SIPCO_CPA_Code.Required,
                                                            }
                                                        ],
                                                    })(<Input id="SIPCO_CPA_Code"  disabled ={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_Province')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_CPA_PROVINCE}
                                                </Form.Item>
                                            </Col>
                                           
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_SelectParameterSystem name='SIPCO_CPA_Province' id='SIPCO_CPA_Province' type={MPS_SYSTEM_PARAMETER_TYPE.Province}
                                                    disabled ={true} 
                                                    getFieldDecorator={getFieldDecorator}
                                                    required={initFieldControl.SIPCO_CPA_Province.Required}
                                                    help={guideLine.SIPCO_CPA_Province}
                                                    value={this.state.selectedModel.SIPCO_CPA_Province} onChange={this.handleProvinceChange} />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_Start')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_CPA_START}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('SIPCO_CPA_Start', {
                                                        initialValue: selectedModel.SIPCO_CPA_Start,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.SIPCO_CPA_Start.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled ={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_End')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_CPA_END}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('SIPCO_CPA_End', {
                                                        initialValue: selectedModel.SIPCO_CPA_End,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.SIPCO_CPA_End.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled ={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_Color')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_CPA_COLOR}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_SelectParameterSystem name='SIPCO_CPA_Color' id='SIPCO_CPA_Color' type={MPS_SYSTEM_PARAMETER_TYPE.ClassifyColor}
                                                    disabled ={true} 
                                                    getFieldDecorator={getFieldDecorator}
                                                    required={initFieldControl.SIPCO_CPA_Color.Required}
                                                    help={guideLine.SIPCO_CPA_Color}
                                                    value={this.state.selectedModel.SIPCO_CPA_Color} onChange={this.handleColorChange} />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_Description')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_CPA_DESCRIPTION}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('SIPCO_CPA_Description', {
                                                        initialValue: selectedModel.SIPCO_CPA_Description,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.SIPCO_CPA_Description.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled ={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Status')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.STATUS}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_SelectParameterSystem name='Status' id='Status' type={MPS_SYSTEM_PARAMETER_TYPE.Status}
                                                    disabled ={true} 
                                                    getFieldDecorator={getFieldDecorator}
                                                    required={initFieldControl.Status.Required}
                                                    value={this.state.selectedModel.Status} onChange={this.handleStatusChange} />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.CREATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedBy')}`} name="CreatedBy"
                                        help={guideLine.CreatedBy}
                                        value={this.state.selectedModel.CreatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.CREATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}
                                                    name="CreatedDate"
                                                    help={guideLine.CreatedDate}
                                                    style={{ width: '100%' }}
                                                    value={!!selectedModel.CreatedDate ? moment(selectedModel.CreatedDate) : null}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Start_Date')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.START_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Start_Date')}`}
                                                    name="CreatedDate"
                                                    help={guideLine.CreatedDate}
                                                    style={{ width: '100%' }}
                                                    value={!!this.state.selectedModel.Start_Date ? moment(this.state.selectedModel.Start_Date) : null}

                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'End_Date')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.END_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'End_Date')}`}
                                                    name="CreatedDate"
                                                    help={guideLine.CreatedDate}
                                                    style={{ width: '100%' }}
                                                    value={!!this.state.selectedModel.End_Date ? moment(this.state.selectedModel.End_Date) : null}

                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.UPDATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedBy')}`} name="UpdatedBy"
                                        help={guideLine.UpdatedBy}
                                        value={this.state.selectedModel.UpdatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.UPDATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}
                                                    name="UpdatedDate"
                                                    help={guideLine.UpdatedDate}
                                                    style={{ width: '100%' }}
                                                    value={!!selectedModel.UpdatedDate ? moment(selectedModel.UpdatedDate) : null}

                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                </Card>
                            </TabPane>
                            <TabPane tab={language.DOCUMENT_UPLOAD} key='2'>
                                <Mps_UploadPictureV2
                                language = {language}
                                isConvertUrl
                                images={this.state.selectedModel.CPA_Files}
                                disabled = {true}
                            />
                            {
                            this.state.src ?
                            <embed src={`${API_URL}/${src}`} width="100%" height="1080px"/>: ''
                            }
                            </TabPane>
                        </Tabs>
                    
                    
                    </Form>
                </Modal>
            </span>
        );
    }
}
const WrappedSIPCO_CPAView = Form.create({ name: 'SIPCO_CPAView' })(SIPCO_CPAView);
export { WrappedSIPCO_CPAView as SIPCO_CPAView };

import React from 'react';
import { Layout, Menu, Dropdown, Button, Icon, Badge, Row, Col, Avatar, Popover } from 'antd';
import { history } from '../Helpers';
import {  authenticationService } from '../Services';
import { Link } from 'react-router-dom';

function logout() {
    authenticationService.logout();
    history.push('/login')
}

const menu = (
    <Menu >
        <Menu.Item>
            <a href="/UserInfor">
                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
      Thông tin cá nhân 
      </a>
        </Menu.Item>
        <Menu.Item>
            <a href="/MiniMart_Report_ByBranch">
                <Icon type="dashboard" style={{ fontSize: 20, marginRight: 10 }} />Báo cáo
      </a>
        </Menu.Item>
        <Menu.Item>
            <a href="/Minimart">
                <Icon type="desktop" style={{ fontSize: 20, marginRight: 10 }} />Quản lý
      </a>
        </Menu.Item>
        <Menu.Item>
            <Link to='' onClick={logout}>
                <Icon type="logout" style={{ fontSize: 20, marginRight: 10 }} />Đăng xuất
            </Link>

        </Menu.Item>
    </Menu>
);

const mpsThemes = [
    {
        name: 'mps-theme-seagreen',
        backgroundColor: 'seagreen'
    },
    {
        name: 'mps-theme-purple',
        backgroundColor: 'purple'
    },
    {
        name: 'mps-theme-pink',
        backgroundColor: 'pink'
    },
    {
        name: 'mps-theme-red',
        backgroundColor: '#e91e63'
    },
    {
        name: 'mps-theme-orange',
        backgroundColor: 'orange'
    },
    {
        name: 'mps-theme-cyan',
        backgroundColor: 'cyan'
    },
    {
        name: 'mps-theme-green',
        backgroundColor: 'green'
    },
    {
        name: 'mps-theme-blue',
        backgroundColor: 'blue'
    },
    {
        name: 'mps-theme-purple',
        backgroundColor: 'purple'
    },
    {
        name: 'mps-theme-geekblue',
        backgroundColor: 'rgb(47, 84, 235)'
    },
    {
        name: 'mps-theme-magenta',
        backgroundColor: 'magenta'
    },
    {
        name: 'mps-theme-volcano',
        backgroundColor: 'rgb(250, 84, 28)'
    },
    {
        name: 'mps-theme-gold',
        backgroundColor: 'gold'
    },
    {
        name: 'mps-theme-lime',
        backgroundColor: 'lime'
    },
    {
        name: 'mps-theme-custom1',
        backgroundColor: '#f50'
    },
    {
        name: 'mps-theme-custom2',
        backgroundColor: '#2db7f5'
    },
    {
        name: 'mps-theme-custom3',
        backgroundColor: '#87d068'
    },
    {
        name: 'mps-theme-custom4',
        backgroundColor: '#108ee9'
    },
    //Add new 
    {
        name: 'mps-theme-395781',
        backgroundColor: '#395781'
    },
    {
        name: 'mps-theme-9F111B',
        backgroundColor: '#9F111B'
    },
    {
        name: 'mps-theme-443726',
        backgroundColor: '#443726'
    },
    {
        name: 'mps-theme-A73B00',
        backgroundColor: '#A73B00'
    },
    {
        name: 'mps-theme-FFB300',
        backgroundColor: '#FFB300'
    },
    {
        name: 'mps-theme-DA9E00',
        backgroundColor: '#DA9E00'
    },
    {
        name: 'mps-theme-838383',
        backgroundColor: '#838383'
    },
    {
        name: 'mps-theme-CD8C52',
        backgroundColor: '#CD8C52'
    },
    {
        name: 'mps-theme-152F70',
        backgroundColor: '#152F70'
    },
    {
        name: 'mps-theme-4D1D4D',
        backgroundColor: '#4D1D4D'
    },

]

export function setDisplayTheme(values) {
    localStorage.setItem('mpsDisplayTheme', JSON.stringify(values));
}
export function getDisplayTheme() {
    var displayTheme = JSON.parse(localStorage.getItem("mpsDisplayTheme"));
    if (displayTheme === null) {//Trả về theme mặc định nếu người dùng chưa thiết lập.
        return 'mps-theme-seagreen';
    }
    return displayTheme;
}


class Mps_HeaderTrans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mpsDisplayTheme: getDisplayTheme(),
        };
    }
    handleChangeTheme = (value) => {
        var name = value.target.name
        setDisplayTheme(name);
        window.location.reload();
    };
    handleFullScreen=()=>{
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
          }
    }

    handleCloseFullscreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }

    render() {
        const { Header } = Layout;
        const text = <span>Thay đổi chủ đề</span>;
        const subjectContent = (
            <div id='subject' >
                {mpsThemes.map((theme) =>
                    <Button key={Math.random()} name={theme.name} onClick={this.handleChangeTheme} style={{ backgroundColor: theme.backgroundColor }} ></Button>
                )}

            </div>
        );
        return (
            <Header className={"mps-header"} >
                <div>
                    <Row>
                        {/* Vùng hiển thị thông tin logo */}
                        <Col span={8}>
                           
                            <img width='40px' style={{ marginBottom: '10px' }} src={require("../Layout/Images/mps-logo12.png")} />
                        
                        </Col>
                        {/* Vùng hiển thị thông tin theme/contact... */}
                        <Col span={8} style={{ textAlign: "center" }}>
                            <Popover placement="bottom" title={text} content={subjectContent} trigger="click">
                                <span>
                                    <Icon type="cloud" style={{ marginLeft: 20, marginRight: 5, fontSize: '30px', color: 'white' }} /><span style={{ fontSize: '15px', color: 'white', fontWeight: 'bold' }}>Chủ đề</span>
                                </span>
                            </Popover>
                            {/* <Icon type="contacts" style={{ marginLeft: 20, marginRight: 5, fontSize: '30px', color: 'white' }} /><span style={{ fontSize: '15px', color: 'white', fontWeight: 'bold' }}>Liên hệ</span> */}
                            <Icon type="phone" style={{ marginLeft: 20, marginRight: 5, fontSize: '30px', color: 'white' }} /><span style={{ fontSize: '15px', color: 'white', fontWeight: 'bold' }}>Hotline: 1900636509</span>
                        </Col>
                        {/* Vùng hiển thị thông tin menu */}
                        <Col span={8} style={{ textAlign: "right", right: "0", position: "absolute", zIndex: "1" }}>
                            
                            {/* <Icon type="fullscreen"  style={{ fontSize: 30, color: 'white', marginLeft: '25px', marginRight: '5px' }}  onClick= {this.handleFullScreen}/>
                      */}
                            {/* <Kitchen_Notify /> */}
                            {/* <Badge count='10'>
                                <Icon title='Thông báo' type="notification" style={{ fontSize: '30px', color: 'white' }} />
                            </Badge> */}
                            <Dropdown overlay={menu} placement="bottomRight" >
                                <Icon style={{ fontSize: 30, color: 'white', marginLeft: '25px', marginRight: '5px' }} type="menu" />
                            </Dropdown>
                           
                        </Col>
                    </Row>
                </div>
            </Header>
        );
    }
}
export { Mps_HeaderTrans };
import React from 'react';
export const LANGUAGE_STATE = {
    'vn':
    {
        ERROR_403: 'Xin lỗi, bạn không được phép truy cập trang này.',
        ERROR_ACCOUNT_EXPIRED: <span>
            Tài khoản của quý khách hàng đã hết hạn sử dụng!
        </span>,
        ERROR_ACCOUNT_EXPIRED_MESAGE: 'Vui lòng liên hệ với chúng tôi để được hỗ trợ!',
        BACK_TO_HOME: 'Quay Về Trang Chủ',
        LOGIN_WITH_OTHER_ACCOUNT: 'Về trang đăng nhập',
        CONTACT_US: 'Liên hệ với chúng tôi'    
       
    },
    'en':
    {
        ERROR_403: 'Sorry, you are not authorized to access this page.',
        ERROR_ACCOUNT_EXPIRED: 'Sorry, your account is expired!',
        ERROR_ACCOUNT_EXPIRED_MESAGE: 'Please contact us to get support!',
        BACK_TO_HOME: 'Back To Home'  ,  
        LOGIN_WITH_OTHER_ACCOUNT: 'Back to login',   
        CONTACT_US: 'Contact us'       
    }
};


import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';
import { MPS_MESSAGE_STATE } from '../../../commons/Mps_Message.Common';

export default class NotFound extends React.Component {
    render() {
        return (
            <Result
                status="404"
                title="404"
                subTitle= {MPS_MESSAGE_STATE.en.ERROR_404}
                extra={<Link to="/"><Button type="primary">Back to Home</Button></Link>}
            />
        )
    }
}
import React, { Suspense, Component, lazy } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-google';
import { history } from '../Helpers';
import ErrorBoundary from '../Helpers/ErrorBoundary';
import '../Layout/Css/Mps-Layout-Common.css'
import '../Layout/Css/Mps-Theme-Blue.css'
import '../Layout/Css/Mps-Theme-Custom1.css'
import '../Layout/Css/Mps-Theme-Custom2.css'
import '../Layout/Css/Mps-Theme-Custom3.css'
import '../Layout/Css/Mps-Theme-Custom4.css'
import '../Layout/Css/Mps-Theme-Cyan.css'
import '../Layout/Css/Mps-Theme-Geekblue.css'
import '../Layout/Css/Mps-Theme-Gold.css'
import '../Layout/Css/Mps-Theme-Green.css'
import '../Layout/Css/Mps-Theme-Lime.css'
import '../Layout/Css/Mps-Theme-Magenta.css'
import '../Layout/Css/Mps-Theme-Orange.css'
import '../Layout/Css/Mps-Theme-Pink.css'
import '../Layout/Css/Mps-Theme-Red.css'
import '../Layout/Css/Mps-Theme-Seagreen.css'
import '../Layout/Css/Mps-Theme-Volcano.css'
import '../Layout/Css/Mps-Theme-395781.css'
import '../Layout/Css/Mps-Theme-9F111B.css'
import '../Layout/Css/Mps-Theme-443726.css'
import '../Layout/Css/Mps-Theme-A73B00.css'
import '../Layout/Css/Mps-Theme-FFB300.css'
import '../Layout/Css/Mps-Theme-DA9E00.css'
import '../Layout/Css/Mps-Theme-838383.css'
import '../Layout/Css/Mps-Theme-CD8C52.css'
import '../Layout/Css/Mps-Theme-152F70.css'
import '../Layout/Css/Mps-Theme-4D1D4D.css'
import '../Layout/Css/Mps-Theme-Purple.css'
import '../Layout/Css/Mps-Inventoty.css'
import '../Layout/Css/Mps-Restaurant.css'
import '../Layout/Css/Mps-Minimart.css'


import { PrivateRoute } from './Routes/PrivateRoute';
import NotFound from './Routes/NotFound';
import { moduleroutes } from './Routes/Module';
import { templateroutes } from './Routes/Module_Template';
import { sipcoroutes } from './Routes/SIPCO';
import { systemroutes } from './Routes/System';
import { loginRoute } from './Routes/Login';

import { humanresourceroutes } from './Routes/Human_Resource';


const LoginForm = withRouter(lazy(() => import('../LoginPage/LoginForm')));
const Dashboard = withRouter(lazy(() => import('../Dashboard/Dashboard')));

export function getDisplayTheme() {
    const displayTheme = JSON.parse(localStorage.getItem('mpsDisplayTheme'));
    if (displayTheme === null) {
        //Trả về theme mặc định nếu người dùng chưa thiết lập.
        return 'mps-theme-seagreen';
    }
    return displayTheme;
}

class App extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setTimeout(() => {
            loadReCaptcha();
        }, 500);
    }

    render() {
        return (
            <div id={getDisplayTheme()}>
                <Router history={history}>
                    <ErrorBoundary>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <PrivateRoute exact path='/' component={Dashboard} />
                                {loginRoute.map(m => (<Route key={m.path} exact path={m.path} component={withRouter(m.component)} />))}
                                {moduleroutes.map(m => (<PrivateRoute key={m.path} path={m.path} component={withRouter(m.component)} />))}
                                {templateroutes.map(m => (<Route key={m.path} path={m.path} component={withRouter(m.component)} />))}
                                {sipcoroutes.map(m => (<Route key={m.path} path={m.path} component={withRouter(m.component)} />))}
                                {systemroutes.map(m => (<PrivateRoute key={m.path} path={m.path} component={withRouter(m.component)} />))}
                                {humanresourceroutes.map(m => (<PrivateRoute key={m.path} path={m.path} component={withRouter(m.component)} />))}
                                <Route component={NotFound} />
                            </Switch>
                        </Suspense>
                    </ErrorBoundary>
                </Router>
            </div>

        );
    }

}
/*
function mapStateToProps(state) {

    const { alert } = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; */
export { App };
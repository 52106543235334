import React from 'react';

import { ForgotPassword } from '../../ForgotPasswordV2/ForgotPassword';
//const ForgotPassword = React.lazy(() => import('../../ForgotPasswordV2/ForgotPassword'));
const Login_Form = React.lazy(() => import('../../LoginPage/LoginForm'));


export const loginRoute = [
    { path: '/login', component: Login_Form },
    { path: '/forgotpw', component: ForgotPassword },
];


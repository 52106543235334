import React from 'react';


const System = React.lazy(() => import('../../../components/Shared/SystemArea'));
const HumanResource = React.lazy(() => import('../../../components/Shared/HumanResourceArea'));

export const moduleroutes = [
    { path: '/System', component: System },
    { path: '/HumanResource', component: HumanResource},
];

import React from 'react';
import jsonQuery from 'json-query';
import {
    Form,
    AutoComplete, Row, Col,
    Card
  } from 'antd';
import {Mps_LayoutFormat, Mps_Tooltip} from '../../Mps_Control';
import {Mps_ControlModuleAvailable} from '../../../commons';
import {LANGUAGE_STATE} from '../Language/ln';
import {SIPCO_CPA_Service} from '../Service/SIPCO_CPA.Service';

const { Option } = AutoComplete;

function renderOption(data) {
    return (
        <Option key={data.SIPCO_CPA_Code} text={data.SIPCO_CPA_Code}>
            <div className="global-search-item">
                <span>
                    {data.SIPCO_CPA_Code}
                </span>
                <span style={{ float: 'right' }}> {data.SIPCO_CPA_Name} </span>
            </div>
        </Option>
    );
  }

class SIPCO_CPA_AutoComplete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource:[]
        };
    }

    handleSearch = value => {
        const searchModel = {
            Value : value,
            Status :(!!this.props.status) ? this.props.status : null,
        };

        Promise.all([SIPCO_CPA_Service.getDataFilter(searchModel)]).then(result => {

            const data = jsonQuery('docs[**]', {data:result[0].result}).value;
            this.setState({
                dataSource:data,
            });
        });
    };

    onSelect =value=> {
        this.props.onChange(value);
    };

    onChange =value=> {
        this.props.onChange(value);
    };

    render() {
        const chooseLanguage = this.props.language;
        const language = jsonQuery([chooseLanguage[0]], {data: LANGUAGE_STATE}).value;
        const gridStyle = this.props.style;
        // if(!Mps_ControlModuleAvailable.SIPCO_CPA) {
        //     return '';
        // }
        return (

            <Card.Grid style={gridStyle}
                className={`mps-card-items ${this.props.className}`}>
                <Row>
                    <Col span={Mps_LayoutFormat.ColCardLabel} >
                        <Form.Item>
                            {(!!this.props.label)? this.props.label: language.SIPCO_CPA_CODE}
                        </Form.Item>
                    </Col>
                    <Col  span={Mps_LayoutFormat.ColCardInput}>
                        <Form.Item
                            help= {
                                (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> :''
                            }
                            hasFeedback
                        >
                        {(!!this.props.getFieldDecorator) ?
                                (this.props.getFieldDecorator((!!this.props.name) ? this.props.name : '', {
                                value : this.props.value ,
                                rules: [
                                        {
                                            required: (!!this.props.required) ? this.props.required : false,
                                            message: this.props.message,
                                        },
                                    ],
                                })(
                                    <AutoComplete
                                        value = {this.props.value}
                                        className="global-search"
                                        placeholder = {(!!this.props.placeholder)? this.props.placeholder: ''}
                                        allowClear={true}
                                        size="default"
                                        style={{ width: '100%' }}
                                        dataSource={this.state.dataSource.map(renderOption)}
                                        onSelect={this.onSelect}
                                        onChange ={this.onChange}
                                        onSearch={this.handleSearch}
                                        optionLabelProp="text"
                                        disabled = {(!!this.props.disabled)? this.props.disabled: false}
                                    />
                                )) :
                            (
                                <AutoComplete
                                    value = {this.props.value}
                                    className="global-search"
                                    placeholder = {(!!this.props.placeholder)? this.props.placeholder: ''}
                                    allowClear={true}
                                    size="default"
                                    style={{ width: '100%' }}
                                    dataSource={this.state.dataSource.map(renderOption)}
                                    onSelect={this.onSelect}
                                    onChange ={this.onChange}
                                    onSearch={this.handleSearch}
                                    optionLabelProp="text"
                                    disabled = {(!!this.props.disabled)? this.props.disabled: false}
                                />
                            )
                        }
                        </Form.Item>
                    </Col>
                </Row>
            </Card.Grid>

      );
    }
}

export {  SIPCO_CPA_AutoComplete };

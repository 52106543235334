import React from 'react';
import './Mps_TextEditor.css';
import 'medium-draft/lib/index.css';
import mediumDraftExporter from 'medium-draft/lib/exporter';
import {
  Editor, EditorState as EditorStateFunction, ContentState, RichUtils
} from 'draft-js';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';

export class Mps_TextEditor extends React.Component {

  constructor(props) {
    super(props);
    var editorState = EditorStateFunction.createEmpty();
    if (!!this.props.contentState) {
      const processedHTML = DraftPasteProcessor.processHTML(this.props.contentState);
      const contentState = ContentState.createFromBlockArray(processedHTML);
      //move focus to the end. 
      editorState = EditorStateFunction.createWithContent(contentState);
      editorState = EditorStateFunction.moveFocusToEnd(editorState);
    }

    this.state = {
      editorState: editorState,
    };

    this.focus = () => this.refs.editor.focus();

    this.onChange = (editorState) => {
      this.setState({ editorState });
    };

    this.onBlur = () => {
      let value = mediumDraftExporter(this.state.editorState.getCurrentContent());
      if(typeof this.props.onChange === 'function') this.props.onChange(value);
    }

    this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.onTab = (e) => this._onTab(e);
    this.toggleBlockType = (type) => this._toggleBlockType(type);
    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
  }

  _handleKeyCommand(command) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _onTab(e) {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  _toggleBlockType(blockType) {
    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType
      )
    );
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contentState !== this.props.contentState) {
      var editorState = this.state.editorState;
      if (!!editorState) {
        editorState = EditorStateFunction.createEmpty();
      }
      if (!!this.props.contentState) {
        const processedHTML = DraftPasteProcessor.processHTML(this.props.contentState);
        if (!!processedHTML.contentBlocks) {
          const contentState = ContentState.createFromBlockArray(processedHTML);
          //move focus to the end. 
          editorState = EditorStateFunction.createWithContent(contentState);
          editorState = EditorStateFunction.moveFocusToEnd(editorState);
        }
      }
      this.setState({
        editorState: editorState
      });
    }
  }

  render() {
    const { editorState } = this.state;
    let className = 'RichEditor-editor';

    return (
      <div className={!!this.props.disabled ? "RichEditor-root mps-texteditor-disabled" : "RichEditor-root"}>
        {(this.props.fontConfig === true) ?
          <div>
            <BlockStyleControls
              editorState={editorState}
              onToggle={this.toggleBlockType}
            />
            <InlineStyleControls
              editorState={editorState}
              onToggle={this.toggleInlineStyle}
            />
          </div>
          :
          null
        }
        <div className={className} onClick={this.focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onTab={this.onTab}
            placeholder=""
            ref="editor"
            spellCheck={true}
            readOnly={(!!this.props.readOnly) ? this.props.readOnly : false}
          />
        </div>
      </div>
    );
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = EditorStateFunction.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

import React from 'react';
import { Select, Form } from 'antd';
const { Option } = Select;
class Mps_InputVAT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: this.props.canNull === true ? [null, '0', '5', '10'] : ['0', '5', '10']
        }
    }

    onChange = (value) => {
        if (value === null) {
            this.props.onChange(value);
        } else this.props.onChange(Number(value));
    }

    componentDidMount() {
        if (typeof this.props.onChange === 'function') this.props.onChange(this.state.dataSource[0]);
    }

    render() {
        const value = this.props.value !==undefined ? (this.props.value === 0 ? '0' : this.props.value) : this.state.dataSource[0];
        return (
            <Form.Item>
                <Select disabled={(!!this.props.disabled) ? this.props.disabled : false}
                    className={this.props.className}
                    allowClear={true}
                    style={{ width: '100%', textAlign: 'right' }}
                    value={value}
                    onChange={this.onChange}
                >
                    {this.state.dataSource.map((select, index) =>
                        <Option key={index} value={select}>
                            {select !== null ? Number(select) + '%' : 'All'}
                        </Option>)}
                </Select>
            </Form.Item>

        );
    }
}
export { Mps_InputVAT };

import { Button, Col, Layout, Modal, Row, Spin, Table, Tabs } from 'antd';
import jsonQuery from 'json-query';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Mps_HeaderMaster, Mps_SubMenu, Mps_Footer, Mps_Breadcum } from '../../authenticate/Layout';
//Khai báo common component
import { menuService } from '../../authenticate/Services';
import {
    MESSAGE_STATE,
    MPS_MESSAGE_STATE, Mps_MethodCommon, MPS_MODE, MPS_SCREEN_NO, MPS_SCREEN_ACTION, MPS_RESULTS
} from '../../commons';
import {
    getPageSize, Mps_LabelFormat, Mps_ErrorPermission,
    Mps_Download, Mps_ImportExcel, Mps_ResultTable, Mps_DisplayColumnResultTable
} from '../Mps_Control';
//Khai báo thêm component trên page
import { LANGUAGE_STATE } from './Language/ln';
import {
    initResultTable, getDisplaySortFields, setDisplaySortFields
    , getDisplaySearchConditions, setDisplaySearchConditions, getDisplayColumnSearchResults
    , setDisplayColumnSearchResults, searchModel, headers, headerExample, getAllColumn, initCPATable, initProvinceTable, headersCPA, initDistrictTable, initWardTable
} from './Models/Sipco_WaterFilter.Model';
import { Sipco_WaterFilterAdd } from './Sipco_WaterFilterAdd';
import { Sipco_WaterFilter_ExportCPA } from './Sipco_WaterFilter_ExportCPA';
import { Sipco_WaterFilterEdit } from './Sipco_WaterFilterEdit';
import { Sipco_WaterFilterSearch } from './Sipco_WaterFilterSearch';
import { Sipco_WaterFilterUpload } from './Sipco_WaterFilterUpload';
import { Sipco_WaterFilterView } from './Sipco_WaterFilterView';
import { Sipco_WaterFilter_Service } from './Service/Sipco_WaterFilter.Service';
//import { Log_Service } from '../../authenticate/Helpers/Log.Service';
import { Mps_ImportError } from '../Mps_Control/Mps_ImportError/Mps_ImportError';
import './Language/btn-delete.css'

const screenNo = MPS_SCREEN_NO.Sipco_WaterFilter;
let permission = []
const confirm = Modal.confirm;
const { TabPane } = Tabs;

export default class Sipco_WaterFilterList extends Component {
    /**
    * @desc Hàm khởi tạo component
    * @param
    *   props - properties của component.
    * @return Nothing
    */
    constructor(props) {

        super(props);
        const chooseLanguage = menuService.getLanguage();
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
        const messageValid = jsonQuery([chooseLanguage[0]], { data: MESSAGE_STATE }).value;
        const commonMessage = jsonQuery([chooseLanguage[0]], { data: MPS_MESSAGE_STATE }).value;
        const displayColumnSearchResults = getAllColumn();
        const columnResultTable = initResultTable(language, displayColumnSearchResults)

        document.title = language.PAGE_TITLE;
        this.state = {
            chooseLanguage,
            language,
            messageValid,
            commonMessage,
            searchModel,
            dataSource: [],
            selectedModel: [],
            searchOptions: {
                page: 1,
                limit: getPageSize(),
                language: chooseLanguage,
                sort: getDisplaySortFields(),
                sortAscending: true
            },
            importModel: [],
            visible: false,
            mode: '',
            isLoading: false,
            alertMesage: {
                show: false,
                message: '',
                description: ''
            },
            dataCPA: [],
            dataProvince: [],
            dataDistrict: [],
            dataVillage: [],
            key: 1,
            headersExport: headers(columnResultTable),
            dataForDownload: [],
            page: 1
        };
    }

    callbackCPA = (d) => {
        this.setState({
            dataCPA: d
        }
        )
    }
    callbackProvince = (d) => {
        this.setState({
            dataProvince: d
        }
        )
    }
    //#region Handle event (Search, add, copy, edit, view, delete)
    /**
    * @desc Handle sự kiện tìm kiếm dữ liệu
    * @param
    * @return Nothing (Hiển thị dữ liệu được lọc theo điều kiện tìm kiếm),
    */

    componentWillMount() {
        permission = menuService.showPermission(screenNo);
        let pageSize = JSON.parse(localStorage.getItem('pageSize'))
        localStorage.setItem('pageSize',JSON.stringify((pageSize === null) ? pageSize = 10 : pageSize))
        // var searchObjectModel = [];
        // var searchOptions = this.state.searchOptions;
        // Promise.all([Sipco_WaterFilter_Service.viewByDistrict(searchObjectModel, searchOptions)]).then(res => {
        //     const dataSourceTotal = jsonQuery('[**]', { data: res }).value;
        //     this.setState({
        //         dataDistrict: dataSourceTotal,
        //         isLoading: false
        //     });
        // })
        // Promise.all([Sipco_WaterFilter_Service.viewByProvince(searchObjectModel, searchOptions)]).then(res => {
        //     const dataSourceTotal = jsonQuery('[**]', { data: res }).value;
        //     this.setState({
        //         dataProvince: dataSourceTotal,
        //         isLoading: false
        //     });
        // })
        // Promise.all([Sipco_WaterFilter_Service.ViewByCPA(searchObjectModel, searchOptions)]).then(res => {
        //     const dataSourceTotal = jsonQuery('[**]', { data: res }).value;
        //     this.setState({
        //         dataCPA: dataSourceTotal,
        //         isLoading: false
        //     });
        // })
        // Promise.all([Sipco_WaterFilter_Service.viewByVillage(searchObjectModel, searchOptions)]).then(res => {
        //     const dataSourceTotal = jsonQuery('[**]', { data: res }).value;
        //     this.setState({
        //         dataVillage: dataSourceTotal,
        //         isLoading: false
        //     });
        // })

    }
    componentWillUnmount () {
        localStorage.removeItem('page_Filter');
        localStorage.removeItem('page_CPA');
        localStorage.removeItem('page_Province');
        localStorage.removeItem('page_District');
        localStorage.removeItem('page_Village');
    }

    componentDidMount () {
        const searchOptions = this.state.searchOptions;
        const searchModel = this.state.searchModel;
        Promise.all([Sipco_WaterFilter_Service.search(searchModel, searchOptions)]).then((result) => {
            const data = jsonQuery('result', { data: result }).value;
            this.setState({
                dataSource: data,
                visible: false,
                isLoading: false,
            });
            const { language } = this.state;
            const displayColumnSearchResults = getDisplayColumnSearchResults(this.state.displayColumnSearchResults);
            const columnResultTable = initResultTable(language, displayColumnSearchResults)
            const tempdata = [...data]
            const data1 = JSON.parse(JSON.stringify(tempdata));
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
            this.handleDownloadExcel (tempDataForDownload)
            const dataForDownload = [{
                docs: tempDataForDownload
            }];
            this.setState({
                dataForDownload: dataForDownload,
                headersExport: headersCPA(columnResultTable),
            });   
        });
    }

    handleDownloadExcel (data) {
        data.forEach(d => {
            d.Serial_Code = (!!d.Serial_Code ? (d.Serial_Code) : '');
            d.Owner = (!!d.Owner ? (d.Owner) : '');
            d.Sipco_WaterFilter_Description = (!!d.Sipco_WaterFilter_Description ? (d.Sipco_WaterFilter_Description) : '');
            d.Province = (!!d.Province ? (d.Province) : '');
            d.District = (!!d.District ? (d.District) : '');
            d.Ward = (!!d.Ward ? (d.Ward) : '');
            d.Phone = (!!d.Phone ? ("'"+d.Phone) : '');
            d.DayLeft = this.handleDayLeft (d.Provide_Date);
            d.Provide_Date =!Mps_LabelFormat.formatDate(d.Provide_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Provide_Date));
            d.UpdatedBy = (!!d.UpdatedByObject ? d.UpdatedByObject.System_User_DisplayName : '');
            d.UpdatedDate = !Mps_LabelFormat.formatDate(d.UpdatedDate) ? '' : ("'"+Mps_LabelFormat.formatDate(d.UpdatedDate));
            d.CreatedBy = (!!d.CreatedByObject ? d.CreatedByObject.System_User_DisplayName : '');
            d.CreatedDate = "'"+ Mps_LabelFormat.formatDate(d.CreatedDate);
            d.Status = (!!d.StatusObject ? d.StatusObject.System_Parameter_Name : '');
            d.Monitoring_Date = !Mps_LabelFormat.formatDate(d.Monitoring_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Monitoring_Date));
            d.Filter_Replacement_Date = !Mps_LabelFormat.formatDate(d.Filter_Replacement_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Filter_Replacement_Date));
            d.Description_Of_Monitoring = (!!d.Description_Of_Monitoring ? (d.Description_Of_Monitoring) : '');
        });
        return data
    }

    handleSearchAfterAction = () => {
        this.setState({
            isLoading: true
        });
        var searchObjectModel = this.state.searchModel;
        searchObjectModel.Provide_Date = Mps_MethodCommon.handleChangeDate(searchObjectModel.Provide_Date)
        searchObjectModel.Filter_Replacement_Date = Mps_MethodCommon.handleChangeDate(searchObjectModel.Filter_Replacement_Date)
        searchObjectModel.Monitoring_Date = Mps_MethodCommon.handleChangeDate(searchObjectModel.Monitoring_Date)
        searchObjectModel.CreatedDate = Mps_MethodCommon.handleChangeDate(searchObjectModel.CreatedDate)
        searchObjectModel.UpdatedDate = Mps_MethodCommon.handleChangeDate(searchObjectModel.UpdatedDate)
        var searchOptions = this.state.searchOptions;
        searchOptions.page = 1
        const key = this.state.key
        if(key==1) {
            Promise.all([Sipco_WaterFilter_Service.search(searchObjectModel, searchOptions)]).then((result) => {
                var data = jsonQuery('result', { data: result }).value;
                const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
                const dataReturn = [{ docs: tempDataForDownload }]
                /*demo mẫu logger*/
                //Log_Service.create("Search", data);
                this.setState({
                    dataSource: data,
                    visible: false,
                    isLoading: false,
                    dataForDownload: dataReturn,
                    page: searchOptions.page,
                    selectedModel: [],

                });
                const { language } = this.state;
                const displayColumnSearchResults = getDisplayColumnSearchResults(this.state.displayColumnSearchResults);
                const columnResultTable = initResultTable(language, displayColumnSearchResults)
                const tempdata = [...data]
                const data1 = JSON.parse(JSON.stringify(tempdata));
                const tempDataForDownload1 = jsonQuery('[**]docs[**]', { data: data1 }).value;
                this.handleDownloadExcel (tempDataForDownload1)
                const dataForDownload = [{
                    docs: tempDataForDownload1
                }];
                this.setState({
                    dataForDownload: dataForDownload,
                    headersExport: headersCPA(columnResultTable),
                });
            }).catch(reason => {
                Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
                this.setState({
                    isLoading: false,
    
                });
            });
        }
        if(key==2) {
            Promise.all([Sipco_WaterFilter_Service.ViewByCPA([], searchOptions)]).then((result) => {
                const data = jsonQuery('[**]', { data: result }).value;
                const tempDataForDownload = jsonQuery('[**]docs[**]', { data: result }).value;
                const dataReturn = [{ docs: tempDataForDownload }]
                this.setState({
                    dataCPA: data,
                    visible: false,
                    isLoading: false,
                    dataForDownload: dataReturn,
                    page: searchOptions.page,
                    selectedModel: [],

                });
            }).catch(reason => {
                Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
                this.setState({
                    isLoading: false,
    
                });
            });
            
        }
        if(key==3) {
            Promise.all([Sipco_WaterFilter_Service.viewByProvince([], searchOptions)]).then((result) => {
                const data = jsonQuery('[**]', { data: result }).value;
                const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
                const dataReturn = [{ docs: tempDataForDownload }]
                /*demo mẫu logger*/
                //Log_Service.create("Search", data);
                this.setState({
                    dataProvince: data,
                    visible: false,
                    isLoading: false,
                    dataForDownload: dataReturn,
                    page: searchOptions.page,
                    selectedModel: [],

                });
            }).catch(reason => {
                Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
                this.setState({
                    isLoading: false,
    
                });
            });
        }
        if(key==4) {
            Promise.all([Sipco_WaterFilter_Service.viewByDistrict([], searchOptions)]).then((result) => {
                const data = jsonQuery('[**]', { data: result }).value;
                const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
                const dataReturn = [{ docs: tempDataForDownload }]
                /*demo mẫu logger*/
                //Log_Service.create("Search", data);
                this.setState({
                    dataDistrict: data,
                    visible: false,
                    isLoading: false,
                    dataForDownload: dataReturn,
                    page: searchOptions.page,
                    selectedModel: [],

                });
            }).catch(reason => {
                Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
                this.setState({
                    isLoading: false,
    
                });
            });
        }
        if(key==5) {
            Promise.all([Sipco_WaterFilter_Service.viewByVillage([], searchOptions)]).then((result) => {
                const data = jsonQuery('[**]', { data: result }).value;
                const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
                const dataReturn = [{ docs: tempDataForDownload }]
                /*demo mẫu logger*/
                //Log_Service.create("Search", data);
                this.setState({
                    dataVillage: data,
                    visible: false,
                    isLoading: false,
                    dataForDownload: dataReturn,
                    page: searchOptions.page,
                    selectedModel: [],

                });
            }).catch(reason => {
                Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
                this.setState({
                    isLoading: false,
    
                });
            });
        }
        // Promise.all([Sipco_WaterFilter_Service.search(searchObjectModel, searchOptions)]).then((result) => {
        //     var data = jsonQuery('result', { data: result }).value;
        //     const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
        //     const dataReturn = [{ docs: tempDataForDownload }]
        //     /*demo mẫu logger*/
        //     Log_Service.create("Search", data);
        //     this.setState({
        //         dataSource: data,
        //         visible: false,
        //         isLoading: false,
        //         dataForDownload: dataReturn
        //     });
        // }).catch(reason => {
        //     Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
        //     this.setState({
        //         isLoading: false,

        //     });
        // });

    };

    handleDayLeft (provideDay) {
        const Provide_Date =  new Date(provideDay)
        const endDate = new Date(Provide_Date.getFullYear()+3, Provide_Date.getMonth(), Provide_Date.getDate(),0,0,0);
        const year = new Date().getFullYear()
        const month = new Date().getMonth()
        const day = new Date().getDate()
        const now = new Date(year,month,day ,0,0,0)
        var Difference_In_Time = endDate.getTime() - now.getTime()
        var Difference_In_Days = Difference_In_Time /(1000 * 60 * 60 * 24);
        return Difference_In_Days
    }

    convertToDownload = (data, key) => {
        // if (!!data && data.length > 0) {
        if (key == 1) {
            if (!!data && data.length > 0) {
                data.forEach(d => {
                    d.Serial_Code = (!!d.Serial_Code ? (d.Serial_Code) : '');
                    d.Owner = (!!d.Owner ? (d.Owner) : '');
                    d.Sipco_WaterFilter_Description = (!!d.Sipco_WaterFilter_Description ? ("'"+d.Sipco_WaterFilter_Description) : '');
                    d.Province = (!!d.Province ? (d.Province) : '');
                    d.District = (!!d.District ? (d.District) : '');
                    d.Ward = (!!d.Ward ? (d.Ward) : '');
                    d.Phone = (!!d.Phone ? ("'"+d.Phone) : '');
                    d.DayLeft = this.handleDayLeft (d.Provide_Date);
                    d.Provide_Date =!Mps_LabelFormat.formatDate(d.Provide_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Provide_Date));
                    d.UpdatedBy = (!!d.UpdatedByObject ? d.UpdatedByObject.System_User_DisplayName : '');
                    d.UpdatedDate = !Mps_LabelFormat.formatDate(d.UpdatedDate) ? '' : ("'"+Mps_LabelFormat.formatDate(d.UpdatedDate));
                    d.CreatedBy = (!!d.CreatedByObject ? d.CreatedByObject.System_User_DisplayName : '');
                    d.CreatedDate = "'"+ Mps_LabelFormat.formatDate(d.CreatedDate);
                    d.Status = (!!d.StatusObject ? d.StatusObject.System_Parameter_Name : '');
                    d.Monitoring_Date = !Mps_LabelFormat.formatDate(d.Monitoring_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Monitoring_Date));
                    d.Filter_Replacement_Date = !Mps_LabelFormat.formatDate(d.Filter_Replacement_Date) ? '' : ("'"+Mps_LabelFormat.formatDate(d.Filter_Replacement_Date));
                    d.Description_Of_Monitoring = (!!d.Description_Of_Monitoring ? (d.Description_Of_Monitoring) : '');
                });
                const dataForDownload = [{
                    docs: data
                }];
                this.setState({ dataForDownload });
            } 
            // else {
            //     console.log("hello");
            //     const tempData = this.state.dataSource;
            //     const tempDataForDownload = jsonQuery('[**]docs[**]', { data: tempData }).value;
            //     const dataReturn = [{ docs: tempDataForDownload }]
            //     this.setState({ dataForDownload: dataReturn });
            //     this.convertToDownload(dataReturn, key);

            // }
        }
        if (key == 2) {
            if (!!data && data.length > 0) {
                data.forEach(d => {
                    d.SIPCO_CPA_Code = (!!d.SIPCO_CPA_Code ? (d.SIPCO_CPA_Code) : '');
                    d.Total = (!!d.Total ? (d.Total) : '');
                });
                const dataForDownload = [{
                    docs: data
                }];
                this.setState({ dataForDownload });
            } 
            // else {
            //     const tempData = JSON.parse(JSON.stringify(this.state.dataCPA));
            //     const tempDataForDownload = jsonQuery('[**]docs[**]', { data: tempData }).value;
            //     this.convertToDownload(tempDataForDownload, this.state.key);
            // }
        }

        if (key == 3) {
            if (!!data && data.length > 0) {
                data.forEach(d => {
                    d.SIPCO_CPA_Province = (!!d.SIPCO_CPA_Province ? (d.SIPCO_CPA_Province) : '');
                    d.Total = (!!d.Total ? (d.Total) : '');
                });
                const dataForDownload = [{
                    docs: data
                }];
                this.setState({ dataForDownload });
            } 
            // else {
            //     const tempData = JSON.parse(JSON.stringify(this.state.dataProvince));
            //     const tempDataForDownload = jsonQuery('[**]docs[**]', { data: tempData }).value;
            //     this.convertToDownload(tempDataForDownload, this.state.key);
            // }
        }

        if (key == 4) {
            if (!!data && data.length > 0) {
                data.forEach(d => {
                    d.District = (!!d.District ? (d.District) : '');
                    d.Total = (!!d.Total ? (d.Total) : '');
                });
                const dataForDownload = [{
                    docs: data
                }];
                this.setState({ dataForDownload });
            } 
            // else {
            //     const tempData = JSON.parse(JSON.stringify(this.state.dataDistrict));
            //     const tempDataForDownload = jsonQuery('[**]docs[**]', { data: tempData }).value;
            //     this.convertToDownload(tempDataForDownload, this.state.key);
            // }
        }
        if (key == 5) {
            if (!!data && data.length > 0) {
                data.forEach(d => {
                    d.Ward = (!!d.Ward ? (d.Ward) : '');
                    d.Total = (!!d.Total ? (d.Total) : '');
                });
                const dataForDownload = [{
                    docs: data
                }];
                this.setState({ dataForDownload });
            } 
            // else {
            //     const tempData = JSON.parse(JSON.stringify(this.state.dataVillage));
            //     const tempDataForDownload = jsonQuery('[**]docs[**]', { data: tempData }).value;
            //     this.convertToDownload(tempDataForDownload, this.state.key);
            // }
        }
        // }
        // else {
        //     const tempData = JSON.parse(JSON.stringify(this.state.dataSource));
        //     const tempDataForDownload = jsonQuery('[**]docs[**]', { data: tempData }).value;
        //     this.convertToDownload(tempDataForDownload,this.state.key);
        // }
    };
    /**
    * @desc Handle sự kiện xóa record trong table.
    * @param
    *   array selectedModel: Danh sách record cần xóa.
    * @return Kết quả thành công/thất bại.
    */


    handleDelete = () => {
        if (this.state.key == 1) {
            if (this.state.selectedModel.length === 0) {
                Mps_MethodCommon.handleShowConfirm(this.state.commonMessage.CHOOSE_DATA_DELETE, '');
            } else {
                this.handleShowConfirmDelete(this.state.commonMessage.DELETE_WARNING, '', this.state.selectedModel, this.handleSearchAfterAction, this.handleDeleteResult);
            }
        }
    };

    /**
    * @desc Handle sự kiện hiển thị thông báo
    * @param
    *   String message: tiêu đề thông báo.
    * @param
    *   String content: nội dung chi tiết câu thông báo
    * @return Nothing
    */

    handleShowConfirmDelete = (message, content, listDelete, handleSearchAfterAction) => {
        confirm({
            content,
            title: message,
            onOk: () => {
                const deleteFiles = [];
                listDelete.forEach(element => {
                    if (element.WaterFilter_Files) {
                        deleteFiles.push(...element.WaterFilter_Files);
                    }
                });
                return Promise.all([Sipco_WaterFilter_Service.deleteModel(listDelete)]).then((result) => {
                    if (result && result.returnCode === MPS_RESULTS.ERROR) throw new Error(result[0].returnCode);
                    this.setState({ selectedModel: [] });
                    handleSearchAfterAction();
                    return Sipco_WaterFilter_Service.deleteWaterFilterFiles({ WaterFilter_Files: deleteFiles });
                }).then(result => {
                    if (result && result.returnCode === MPS_RESULTS.ERROR) throw new Error(result[0].returnCode);

                    // this.handleSearchAfterAction();
                    this.setState({ selectedModel: [] });
                    handleSearchAfterAction();
                    Mps_MethodCommon.openSuccessNotification(this.state.commonMessage.INFORM_TITLE, this.state.commonMessage.DATA_DELETE_SUCCESS);
                }).catch(error => {
                    if (error.message === MPS_RESULTS.ERROR) {
                        return Mps_MethodCommon.openNotification('error', this.state.commonMessage.DATA_DELETE_SUCCESS, '');

                    }
                });
            }
        });
    };

    /**
    * @desc Handle sự kiện xóa import dữ liệu.
    * @param
    *   object value: Data được import.
    * @return Kết quả import thành công/thất bại.
    */


    handleImportChange = value => {
        function ExcelDateToJSDate(serial) {
            var utc_days = Math.floor(serial - 25569);
            var utc_value = utc_days * 86400;
            var date_info = new Date(utc_value * 1000);
            return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), 23, 59, 59);
        }
        for (let i = 0; i < value.length; i++) {
            const excelDate = value[i].Provide_Date;
            const jsDate = ExcelDateToJSDate(excelDate)
            value[i].Provide_Date = jsDate

            const excelDateM = value[i].Monitoring_Date;
            const jsDateM = ExcelDateToJSDate(excelDateM)
            value[i].Monitoring_Date = jsDateM

            const excelDateF = value[i].Filter_Replacement_Date;
            const jsDateF = ExcelDateToJSDate(excelDateF)
            value[i].Filter_Replacement_Date = jsDateF
        }

        if (value.length > 0) {
            this.setState({
                importModel: value,
                isLoading: true
            });
            Promise.all([Sipco_WaterFilter_Service.importModel(value)]).then((result) => {

                if (result[0].validationResult.data !== undefined && result[0].validationResult.data !== null) {
                    if (result[0].validationResult.data.dataErr.length > 0) {
                        const column = getAllColumn();
                        const description = <Mps_ImportError data={result[0].validationResult.data.dataErr} chooseLanguage={this.state.chooseLanguage} column={column}></Mps_ImportError>;
                        Mps_MethodCommon.openNotificationImport(result[0].validationResult.data, this.state.language.SUCCESS_IMPORT_ROW,
                            this.state.language.ERROR_IMPORT_ROW, this.state.commonMessage.ERROR_TITLE, description);
                            this.handleSearchAfterAction();
                            this.setState({
                                isLoading: false
                            });
                    } else if (result[0].returnCode === MPS_RESULTS.SUCCESS) {
                        Mps_MethodCommon.openSuccessNotification(this.state.commonMessage.SUCCESS_TITLE, Mps_MethodCommon.returnMessageApi(result[0].returnCode, this.state.chooseLanguage))
                        this.handleSearchAfterAction();
                        this.setState({
                            isLoading: false
                        });
                    }
                } else {
                    Mps_MethodCommon.openErrorNotification(this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(MPS_RESULTS.IMPORT_ERROR, this.state.chooseLanguage))
                    this.handleSearchAfterAction();
                    this.setState({
                        isLoading: false
                    });
                }
            });
        }
    };

    //#endregion

    //#region functions xử lý event trên componet Mps_ResultTable
    /**
    * @desc Handle sự kiện chọn record trên table.
    * @param
    *   Object selectedRow: records được chọn trên table.
    * @return Nothing (Cập nhật giá trị mới cho state selectedModel).
    */
    handleSelectRow = selectedRow => {
        const data = JSON.parse(JSON.stringify(selectedRow));
        this.convertToDownload(data, this.state.key);
        this.setState({
            detailModel: selectedRow[0],
            selectedModel: selectedRow
        });
    };

    /**
    * @desc Handle sự kiện page thay đổi.
    * @param
    *   Number page: trang thay đổi.
    *   Number pageSizes: Số record trên mỗi page
    * @return Nothing (Cập nhật giá trị mới cho state searchOptions, và hiển thị data trên table theo điều kiện tìm kiếm).
    */
    handlePagination = (page, pageSizes) => {
        const searchModel = this.state.searchModel;
        const searchOptions = this.state.searchOptions;
        searchOptions.page = page;
        searchOptions.limit = pageSizes;
        localStorage.setItem('page_Filter',JSON.stringify(page))
        /*demo mẫu logger*/
        //Log_Service.create("searchOptions", searchOptions);
        this.setState({
            searchOptions,
            isLoading: true,
            page
        });
        Promise.all([Sipco_WaterFilter_Service.search(searchModel, searchOptions)]).then(result => {
            const data = jsonQuery('result', { data: result }).value;
                // const dataReturn = [{docs: [...data]}]
            this.setState({
                dataSource: data,
                visible: false,
                isLoading: false
            });
            const { language } = this.state;
            const displayColumnSearchResults = getDisplayColumnSearchResults(this.state.displayColumnSearchResults);
            const columnResultTable = initResultTable(language, displayColumnSearchResults)
            const tempdata = [...data]
            const data1 = JSON.parse(JSON.stringify(tempdata));
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
            this.handleDownloadExcel (tempDataForDownload)
            const dataForDownload = [{
                docs: tempDataForDownload
            }];
            this.setState({
                dataForDownload: dataForDownload,
                headersExport: headersCPA(columnResultTable),
            });  
        });
    };  

    handlePagination_CPA = (page, pageSizes) => {
        const searchOptions = this.state.searchOptions;
        searchOptions.page = page;
        searchOptions.limit = pageSizes;
        localStorage.setItem('page_CPA',JSON.stringify(page))
        /*demo mẫu logger*/
        this.setState({
            searchOptions,
            isLoading: true,
            page
        });
        Promise.all([Sipco_WaterFilter_Service.ViewByCPA([], searchOptions)]).then(result => {
            const data = jsonQuery('[**]', { data: result }).value;
            const { language } = this.state;
            const columCPA = initCPATable(language)
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            this.setState({
                dataCPA: data,
                visible: false,
                isLoading: false,
                selectedModel: [],
                dataForDownload: dataReturn,
                headersExport: headersCPA(columCPA),
            });
        });
    };  
    handlePagination_Province = (page, pageSizes) => {
        const searchOptions = this.state.searchOptions;
        searchOptions.page = page;
        searchOptions.limit = pageSizes;
        localStorage.setItem('page_Province',JSON.stringify(page))
        /*demo mẫu logger*/
        this.setState({
            searchOptions,
            isLoading: true,
            page
        });
        Promise.all([Sipco_WaterFilter_Service.viewByProvince([], searchOptions)]).then(result => {
            const data = jsonQuery('[**]', { data: result }).value;
            const { language } = this.state;
            const columProvince = initProvinceTable(language);
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            this.setState({
                dataProvince: data,
                visible: false,
                isLoading: false,
                selectedModel: [],
                dataForDownload: dataReturn,
                headersExport: headersCPA(columProvince),
            });
        });
    };
    handlePagination_District = (page, pageSizes) => {
        const searchOptions = this.state.searchOptions;
        searchOptions.page = page;
        searchOptions.limit = pageSizes;
        localStorage.setItem('page_District',JSON.stringify(page))
        /*demo mẫu logger*/
        this.setState({
            searchOptions,
            isLoading: true,
            page
        });
        Promise.all([Sipco_WaterFilter_Service.viewByDistrict([], searchOptions)]).then(result => {
            const data = jsonQuery('[**]', { data: result }).value;
            const { language } = this.state;
            const columnDistrict = initDistrictTable(language);
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            // const dataReturn = [{docs: [...data]}]
            this.setState({
                dataDistrict: data,
                visible: false,
                isLoading: false,
                selectedModel: [],
                dataForDownload: dataReturn,
                headersExport: headersCPA(columnDistrict),
            });
        });
    };
    handlePagination_Village = (page, pageSizes) => {
        const searchOptions = this.state.searchOptions;
        searchOptions.page = page;
        searchOptions.limit = pageSizes;
        localStorage.setItem('page_Village',JSON.stringify(page))
        /*demo mẫu logger*/
        this.setState({
            searchOptions,
            isLoading: true,
            page
        });
        Promise.all([Sipco_WaterFilter_Service.viewByVillage([], searchOptions)]).then(result => {
            const data = jsonQuery('[**]', { data: result }).value;
            const { language } = this.state;
            const columnWard = initWardTable(language);
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            // const dataReturn = [{docs: [...data]}]
            this.setState({
                dataVillage: data,
                visible: false,
                isLoading: false,
                selectedModel: [],
                dataForDownload: dataReturn,
                headersExport: headersCPA(columnWard),
            });
        });
    };
    ChangeTab = (key) => {
        const pageSize_Filter = JSON.parse(localStorage.getItem('pageSize_Filter'))
        const pageSize_CPA = JSON.parse(localStorage.getItem('pageSize_CPA'))
        const pageSize_Province = JSON.parse(localStorage.getItem('pageSize_Province'))
        const pageSize_District = JSON.parse(localStorage.getItem('pageSize_District'))
        const pageSize_Village = JSON.parse(localStorage.getItem('pageSize_Village'))
        const pageSize = JSON.parse(localStorage.getItem('pageSize'))
        if (key == 1) {
            if (this.state.dataSource.length === 0 || pageSize_Filter !== pageSize) {
                const searchOptions = this.state.searchOptions;
                localStorage.setItem('pageSize_Filter', JSON.stringify(searchOptions.limit))
                const searchModel = this.state.searchModel;
                searchOptions.page = 1
                this.setState({
                    searchOptions,
                    isLoading: true,
                    page: 1
                });
                Promise.all([Sipco_WaterFilter_Service.search(searchModel, searchOptions)]).then(result => {
                    const data = jsonQuery('result', { data: result }).value; 
                    this.setState({
                        dataSource: data,
                        visible: false,
                        isLoading: false,
                        selectedModel: [],
                    });
                    const { language } = this.state;
                    const displayColumnSearchResults = getDisplayColumnSearchResults(this.state.displayColumnSearchResults);
                    const columnResultTable = initResultTable(language, displayColumnSearchResults)
                    const tempdata = [...data]
                    const data1 = JSON.parse(JSON.stringify(tempdata));
                    const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
                    this.handleDownloadExcel (tempDataForDownload)
                    const dataForDownload = [{
                        docs: tempDataForDownload
                    }];
                    this.setState({
                        dataForDownload: dataForDownload,
                        headersExport: headersCPA(columnResultTable),
                    });  
                });
            }else {
                const page_Filter = JSON.parse(localStorage.getItem('page_Filter'))
                this.setState({
                    page: (page_Filter === null) ? 1 : page_Filter,
                    selectedModel: [],
                });
            }
        }
        if (key == 2) {
            if (this.state.dataCPA.length === 0 || pageSize_CPA !== pageSize) {
                const searchOptions = this.state.searchOptions;
                localStorage.setItem('pageSize_CPA', JSON.stringify(searchOptions.limit))
                searchOptions.page = 1
                this.setState({
                    searchOptions,
                    isLoading: true,
                    page: 1
                });
                Promise.all([Sipco_WaterFilter_Service.ViewByCPA([], searchOptions)]).then(result => {
                    const data = jsonQuery('[**]', { data: result }).value;
                    const { language } = this.state;
                    const columCPA = initCPATable(language)
                    const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
                    const dataReturn = [{ docs: tempDataForDownload }]
                    this.setState({
                        dataCPA: data,
                        visible: false,
                        isLoading: false,
                        selectedModel: [],
                        dataForDownload: dataReturn,
                        key,
                        headersExport: headersCPA(columCPA),
                    });
                });
            }else {
                const page_CPA = JSON.parse(localStorage.getItem('page_CPA'))
                this.setState({
                    page: (page_CPA === null) ? 1 : page_CPA,
                    selectedModel: [],
                });
            }
        }
        if (key == 3) {
            if (this.state.dataProvince.length === 0 || pageSize_Province !== pageSize) {
                const searchOptions = this.state.searchOptions;
                localStorage.setItem('pageSize_Province', JSON.stringify(searchOptions.limit))
                searchOptions.page = 1
                this.setState({
                    searchOptions,
                    isLoading: true,
                    page: 1
                });
                Promise.all([Sipco_WaterFilter_Service.viewByProvince([], searchOptions)]).then(result => {
                    const data = jsonQuery('[**]', { data: result }).value;
                    const { language } = this.state;
                    const columProvince = initProvinceTable(language);
                    const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
                    const dataReturn = [{ docs: tempDataForDownload }]
                    this.setState({
                        dataProvince: data,
                        visible: false,
                        isLoading: false,
                        selectedModel: [],
                        dataForDownload: dataReturn,
                        key,
                        headersExport: headersCPA(columProvince),
                    });
                });
            }else {
                const page_Province = JSON.parse(localStorage.getItem('page_Province'))
                this.setState({
                    page: (page_Province === null) ? 1 : page_Province,
                    selectedModel: [],
                });
            }
        }
        if (key == 4) {
            if (this.state.dataDistrict.length === 0 || pageSize_District !== pageSize) {
                const searchOptions = this.state.searchOptions;
                localStorage.setItem('pageSize_District', JSON.stringify(searchOptions.limit))
                searchOptions.page = 1
                this.setState({
                    searchOptions,
                    isLoading: true,
                    page: 1
                });
                Promise.all([Sipco_WaterFilter_Service.viewByDistrict([], searchOptions)]).then(result => {
                    const data = jsonQuery('[**]', { data: result }).value;
                    const { language } = this.state;
                    const columnDistrict = initDistrictTable(language);
                    const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
                    const dataReturn = [{ docs: tempDataForDownload }]
                    // const dataReturn = [{docs: [...data]}]
                    this.setState({
                        dataDistrict: data,
                        visible: false,
                        isLoading: false,
                        selectedModel: [],
                        dataForDownload: dataReturn,
                        key,
                        headersExport: headersCPA(columnDistrict),
                    });
                });
            }else {
                const page_District = JSON.parse(localStorage.getItem('page_District'))
                this.setState({
                    page: (page_District === null) ? 1 : page_District,
                    selectedModel: [],
                });
            }
        }
        if (key == 5) {
            if (this.state.dataVillage.length === 0 || pageSize_Village !== pageSize) {
                const searchOptions = this.state.searchOptions;
                localStorage.setItem('pageSize_Village', JSON.stringify(searchOptions.limit))
                searchOptions.page = 1
                this.setState({
                    searchOptions,
                    isLoading: true,
                    page: 1
                });
                Promise.all([Sipco_WaterFilter_Service.viewByVillage([], searchOptions)]).then(result => {
                    const data = jsonQuery('[**]', { data: result }).value;
                    const { language } = this.state;
                    const columnWard = initWardTable(language);
                    const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
                    const dataReturn = [{ docs: tempDataForDownload }]
                    // const dataReturn = [{docs: [...data]}]
                    this.setState({
                        dataVillage: data,
                        visible: false,
                        isLoading: false,
                        selectedModel: [],
                        dataForDownload: dataReturn,
                        key,
                        headersExport: headersCPA(columnWard),
                    });
                });
            }else {
                const page_Village = JSON.parse(localStorage.getItem('page_Village'))
                this.setState({
                    page: (page_Village === null) ? 1 : page_Village,
                    selectedModel: [],
                });
            }
        }
        
    }

    /**
    * @desc Handle sự kiện thay đổi thiết lập số records hiển thị trên table.
    * @param
    *   Number currentPage: trang hiện tại.
    *   Number pageSizes: Số record sẽ hiển thị trên mỗi page
    * @return Nothing (Cập nhật pageSizes hiển thị theo thiết lập mới),
    */
    handleShowSizeChange = (currentPage, pageSizes) => {
        const searchOptions = this.state.searchOptions;
        searchOptions.page = currentPage;
        searchOptions.limit = pageSizes;
        const searchModel = this.state.searchModel
        /*demo mẫu logger*/
        //Log_Service.create("handleShowSizeChange", searchOptions);
        const key = this.state.key
        this.setState({
            searchOptions,
            page: currentPage
        });
        Promise.all([Sipco_WaterFilter_Service.search(searchModel, searchOptions)]).then(result => {
            const data = jsonQuery('result', { data: result }).value;
            this.setState({
                dataSource: data,
                visible: false,
                isLoading: false
            });
            const { language } = this.state;
            const displayColumnSearchResults = getDisplayColumnSearchResults(this.state.displayColumnSearchResults);
            const columnResultTable = initResultTable(language, displayColumnSearchResults)
            const tempdata = [...data]
            const data1 = JSON.parse(JSON.stringify(tempdata));
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
            this.handleDownloadExcel (tempDataForDownload)
            const dataForDownload = [{
                docs: tempDataForDownload
            }];
            this.setState({
                dataForDownload: dataForDownload,
                headersExport: headersCPA(columnResultTable),
            });  
        });   
    };

    handleShowSizeChange_CPA = (currentPage, pageSizes) => {
        const searchOptions = this.state.searchOptions;
        searchOptions.page = currentPage;
        searchOptions.limit = pageSizes;
        /*demo mẫu logger*/
        //Log_Service.create("handleShowSizeChange", searchOptions);
        const key = this.state.key
        this.setState({
            searchOptions,
            page: currentPage
        });
        Promise.all([Sipco_WaterFilter_Service.ViewByCPA([], searchOptions)]).then(result => {
            const data = jsonQuery('result', { data: result }).value;
            const { language } = this.state;
            const columCPA = initCPATable(language)
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            this.setState({
                dataCPA: data,
                visible: false,
                isLoading: false,
                selectedModel: [],
                dataForDownload: dataReturn,
                key,
                headersExport: headersCPA(columCPA),
            });
        });
    }
    handleShowSizeChange_Province = (currentPage, pageSizes) => {
        const searchOptions = this.state.searchOptions;
        searchOptions.page = currentPage;
        searchOptions.limit = pageSizes;
        /*demo mẫu logger*/
        //Log_Service.create("handleShowSizeChange", searchOptions);
        const key = this.state.key
        this.setState({
            searchOptions,
            page: currentPage
        });
        Promise.all([Sipco_WaterFilter_Service.viewByProvince([], searchOptions)]).then(result => {
            const data = jsonQuery('result', { data: result }).value;
            const { language } = this.state;
            const columProvince = initProvinceTable(language);
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            this.setState({
                dataProvince: data,
                visible: false,
                isLoading: false,
                selectedModel: [],
                dataForDownload: dataReturn,
                key,
                headersExport: headersCPA(columProvince),
            });
        });
    }
    handleShowSizeChange_District = (currentPage, pageSizes) => {
        const searchOptions = this.state.searchOptions;
        searchOptions.page = currentPage;
        searchOptions.limit = pageSizes;
        /*demo mẫu logger*/
        //Log_Service.create("handleShowSizeChange", searchOptions);
        const key = this.state.key
        this.setState({
            searchOptions,
            page: currentPage
        });
        Promise.all([Sipco_WaterFilter_Service.viewByDistrict([], searchOptions)]).then(result => {
            const data = jsonQuery('result', { data: result }).value;
            const { language } = this.state;
            const columnDistrict = initDistrictTable(language);
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            // const dataReturn = [{docs: [...data]}]
            this.setState({
                dataDistrict: data,
                visible: false,
                isLoading: false,
                selectedModel: [],
                dataForDownload: dataReturn,
                key,
                headersExport: headersCPA(columnDistrict),
            });
        });
    }
    handleShowSizeChange_Village = (currentPage, pageSizes) => {
        const searchOptions = this.state.searchOptions;
        searchOptions.page = currentPage;
        searchOptions.limit = pageSizes;
        /*demo mẫu logger*/
        //Log_Service.create("handleShowSizeChange", searchOptions);
        const key = this.state.key
        this.setState({
            searchOptions,
            page: currentPage
        });
        Promise.all([Sipco_WaterFilter_Service.viewByVillage([], searchOptions)]).then(result => {
            const data = jsonQuery('result', { data: result }).value;
            const { language } = this.state;
            const columnWard = initWardTable(language);
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            // const dataReturn = [{docs: [...data]}]
            this.setState({
                dataVillage: data,
                visible: false,
                isLoading: false,
                selectedModel: [],
                dataForDownload: dataReturn,
                key,
                headersExport: headersCPA(columnWard),
            });
        });
    }

    //#endregion

    //#region Handle sự kiện component Mps_DisplayColumnResultTable
    /**
    * @desc Handle sự kiện component Search thay đổi giá trị
    * @param
    *   Object value: Model điều kiện tìm kiếm.
    * @return Nothing (value State được cập nhật giá trị mới)
    */
    handleSearchChange = value => {
        this.setState({
            searchModel: value
        });

    }
    /**
       * @desc Handle thiết lập hiển thị Field điều kiện tìm kiếm.
       * @param
       *   array value: Danh sách Field được chọn hiển thị .
       * @return Nothing (Cập nhật setting),
       */
    handleDisplaySearchConditionsChange = value => {
        setDisplaySearchConditions(value);
        this.setState({
            displaySearchConditions: value
        });
    };

    //Handle thiết lập sắp xếp hiển thị kết quả tìm kiếm
    /**
    * @desc Handle thiết lập sắp xếp hiển thị record trên table kết quả tìm kiếm.
    * @param
    *   array value: Danh sách Field được chọn sort.
    * @return Nothing (Cập nhật setting),
    */
    handleDisplaySortFieldsChange = value => {
        setDisplaySortFields(value);
        const searchOptions = this.state.searchOptions;
        searchOptions.sort = value;
        this.setState({
            displaySortFields: value,
            searchOptions
        });
    };

    /**
    * @desc Handle thiết lập sắp xếp hiển thị record trên table kết quả tìm kiếm.
    * @param
    *   array value: Danh sách Field được chọn hiển thị trên table.
    * @return Nothing (Cập nhật setting),
    */
    handleDisplayColumnSearchResultsChange = value => {
        setDisplayColumnSearchResults(value);
        this.setState({
            displayColumnSearchResults: value
        });
    };

    /**
    * @desc Handle thiết lập kết quả hiển thị sắp xếp theo bảng chữ cái.
    * @param
    *   bool value: kiểu sắp xếp.
    * @return Nothing (Cập nhật setting: true (sắp xếp tăng), false: sắp xếp giảm),
    */
    handleSortTypeChange = value => {
        const searchOptions = this.state.searchOptions;
        searchOptions.sortAscending = value;
        this.setState({
            sortAscending: value,
            searchOptions
        });
    };
    //#endregion


    handleTabChange = (key) => {
        if (key == 1) {
            const { language } = this.state;
            const displayColumnSearchResults = getDisplayColumnSearchResults(this.state.displayColumnSearchResults);
            const columnResultTable = initResultTable(language, displayColumnSearchResults)
            const data = this.state.dataSource
            const tempdata = [...data]
            const data1 = JSON.parse(JSON.stringify(tempdata));
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
            this.handleDownloadExcel (tempDataForDownload)
            const dataReturn = [{ docs: tempDataForDownload }]
            this.setState({
                dataForDownload: dataReturn,
                key,
                headersExport: headers(columnResultTable),
            })

        }
        if (key == 2) {
            const { language } = this.state;
            const columCPA = initCPATable(language)
            const data = this.state.dataCPA
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            this.setState({
                dataForDownload: dataReturn,
                key,
                headersExport: headersCPA(columCPA),
            })
        }
        if (key == 3) {
            const { language } = this.state;
            const columProvince = initProvinceTable(language);
            const data = this.state.dataProvince
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            this.setState({
                dataForDownload: dataReturn,
                key,
                headersExport: headersCPA(columProvince),
            })
        }
        if (key == 4) {
            const { language } = this.state;
            const columnDistrict = initDistrictTable(language);
            const data = this.state.dataDistrict
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            this.setState({
                dataForDownload: dataReturn,
                key,
                headersExport: headersCPA(columnDistrict),
            })
        }
        if (key == 5) {
            const { language } = this.state;
            const columnWard = initWardTable(language);
            const data = this.state.dataVillage
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data }).value;
            const dataReturn = [{ docs: tempDataForDownload }]
            this.setState({
                dataForDownload: dataReturn,
                key,
                headersExport: headersCPA(columnWard),
            })
        }
    }


    render() {
        const { Content } = Layout;
        const { language, dataSource, key, page } = this.state;
        //#region kiểm tra quyền truy cập
        const actionView = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.View));
        // Hiển thị thông báo Nếu ko có quyền truy cập.
        if (!actionView) {
            //Hiển thị thông báo Nếu ko có quyền truy cập.
            return <Redirect to='/login'/>;
        }
        const actionCreate = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.Create));
        const actionUpdate = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.Update));
        const actionDelete = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.Delete));
        const actionExport = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.Export));
        const actionExportCPA = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.ExportCPA));
        const actionImport = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.Import));
        const actionUpload = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.UploadFile));
        //#region giá trị khởi tạo cho component Mps_DisplayColumnResultTable, Mps_ResultTable
        const displaySearchConditions = getDisplaySearchConditions(this.state.displaySearchConditions);
        const displaySortFields = getDisplaySortFields(displaySortFields);
        const displayColumnSearchResults = getDisplayColumnSearchResults(this.state.displayColumnSearchResults);
        const columCPA = initCPATable(language, displayColumnSearchResults)
        const columProvince = initProvinceTable(language)
        const columnResultTable = initResultTable(language, displayColumnSearchResults);
        const columnDistrict = initDistrictTable(language, displayColumnSearchResults);
        const columnWard = initWardTable(language, displayColumnSearchResults);
        //#endregion

        // const headersExport = headers(columnResultTable);
        let headersExport = this.state.headersExport
        const headerExportExample = headerExample(columnResultTable);

        return (
            <Layout>
                <Mps_HeaderMaster language={this.state.chooseLanguage} />
                <Layout>
                    <Mps_SubMenu module="CPATransaction" language={this.state.chooseLanguage} />
                    <Layout className='mps-content'>
                        <Mps_Breadcum module="CPATransaction" screen={language.SIPCO_WATERFILTER_SEARCH} language={this.state.chooseLanguage} />
                        <Content className='mps-sub-content' >
                            <Spin spinning={this.state.isLoading} delay={500}>
                                <Sipco_WaterFilterSearch
                                    value={this.state.searchModel}
                                    displaySearchConditions={displaySearchConditions}
                                    language={this.state.chooseLanguage}
                                    onChange={this.handleSearchChange} />

                                <Row>
                                    <Col>
                                        <div className='button-area'>
                                            {(actionView) ? <Button type="primary" onClick={this.handleSearchAfterAction}>{language.SEARCH}</Button> : ''}

                                            {(actionCreate && key == 1) ? <Sipco_WaterFilterAdd allowAccess={actionCreate} actionName={language.NEW} mode={MPS_MODE.Create}
                                                onhandleSave={this.handleSearchAfterAction} language={this.state.chooseLanguage}
                                                value={this.state.selectedModel} /> : ''}

                                            {(actionView && key == 1) ? <Sipco_WaterFilterView allowAccess={actionView} actionName={language.VIEW} mode={MPS_MODE.View}
                                                onhandleSave={this.handleSearchAfterAction} language={this.state.chooseLanguage} chooseKey = {key}
                                                value={this.state.selectedModel} /> : ''}

                                            {(actionCreate && key == 1) ? <Sipco_WaterFilterAdd allowAccess={actionCreate} actionName={language.COPY} mode={MPS_MODE.Copy}
                                                onhandleSave={this.handleSearchAfterAction} language={this.state.chooseLanguage}
                                                value={this.state.selectedModel} /> : ''}

                                            {(actionUpdate && key == 1) ? <Sipco_WaterFilterEdit allowAccess={actionUpdate} actionName={language.EDIT} mode={MPS_MODE.Update}
                                                onhandleSave={this.handleSearchAfterAction} language={this.state.chooseLanguage}
                                                value={this.state.selectedModel} /> : ''}
                                            {(actionDelete && key == 1) ? <span className = 'btn-delete'><Button type="primary" onClick={this.handleDelete}>{language.DELETE}</Button></span> : ' '}{' '}

                                            {(actionImport && key == 1) ? <Mps_ImportExcel
                                                title={this.state.language.IMPORT}
                                                headers={headerExportExample}
                                                name={language.PROJECT_EXAMPLE_FILE}
                                                ref="import_excel" message={[]} onChange={this.handleImportChange}
                                                language={this.state.chooseLanguage}>
                                            </Mps_ImportExcel> : ''}

                                            {/* {(actionExportCPA) ? <Sipco_WaterFilter_ExportCPA allowAccess={actionUpdate} actionName={language.EXPORT_CPA} mode={MPS_MODE.Update} language={this.state.chooseLanguage} /> : ''} */}

                                            {(actionExport) ? <Mps_Download value={this.state.dataForDownload}
                                                language={language.DOWNLOADDATA} name={language.SIPCO_WATERFILTER_DATA_FILE}
                                                headers={headersExport} /> : ''}
                                            {(actionUpload) ? <Sipco_WaterFilterUpload allowAccess={actionCreate} actionName={language.UPLOAD} mode={MPS_MODE.Create}
                                                onhandleSave={this.handleSearchAfterAction} language={this.state.chooseLanguage} /> : ''}

                                            <Mps_DisplayColumnResultTable columns={columnResultTable}
                                                onDisplaySearchConditionsChange={this.handleDisplaySearchConditionsChange}
                                                onDisplaySortFieldsChange={this.handleDisplaySortFieldsChange}
                                                onDisplayColumnSearchResultsChange={this.handleDisplayColumnSearchResultsChange}
                                                onSortTypeChange={this.handleSortTypeChange}
                                                displaySearchConditions={displaySearchConditions}
                                                displaySortFields={displaySortFields}
                                                displayColumnSearchResults={displayColumnSearchResults}
                                                sortAscending={this.state.searchOptions.sortAscending}
                                                language={this.state.chooseLanguage} />
                                        </div>
                                    </Col>
                                </Row>
                                <Tabs onTabClick = {this.ChangeTab} defaultActiveKey="1" onChange={this.handleTabChange}>
                                    <TabPane tab={language.SIPCO_WATERFILTER_TITLE_GENERAL} key="1" >
                                        <Mps_ResultTable onChange={this.handleSelectRow}
                                            onPagination={this.handlePagination}
                                            onShowSizeChange={this.handleShowSizeChange}
                                            messageValid={this.state.commonMessage}
                                            columns={columnResultTable}
                                            dataSource={dataSource}
                                            current = {page}
                                            rowKey = {'_id'}
                                            key = {key}
                                        />


                                    </TabPane>
                                    <TabPane tab={'CPA'} key="2" >
                                        {/* <Sipco_WaterFilter_CPA
                                            language={this.state.chooseLanguage}
                                            parentCallback={this.callbackCPA}
                                        /> */}
                                        <Mps_ResultTable onChange={this.handleSelectRow}
                                            onPagination={this.handlePagination_CPA}
                                            onShowSizeChange={this.handleShowSizeChange_CPA}
                                            messageValid={this.state.commonMessage}
                                            columns={columCPA}
                                            dataSource={this.state.dataCPA}
                                            current = {page}
                                            rowKey = {'_id'}
                                            key = {key}
                                        />
                                    </TabPane>
                                    <TabPane tab={language.PROVINCE} key="3">
                                        {/* <Sipco_WaterFilter_Province
                                            language={this.state.chooseLanguage}
                                            parentCallback={this.callbackProvince} /> */}
                                        <Mps_ResultTable onChange={this.handleSelectRow}
                                            onPagination={this.handlePagination_Province}
                                            onShowSizeChange={this.handleShowSizeChange_Province}
                                            messageValid={this.state.commonMessage}
                                            columns={columProvince}
                                            dataSource={this.state.dataProvince}
                                            rowKey = {'_id'}
                                            current = {page}
                                            key = {key}
                                        />
                                    </TabPane>
                                    <TabPane tab={language.DISTRICT} key="4">
                                        <Mps_ResultTable onChange={this.handleSelectRow}
                                            onPagination={this.handlePagination_District}
                                            onShowSizeChange={this.handleShowSizeChange_District}
                                            current={this.state.page}
                                            messageValid={this.state.commonMessage}
                                            columns={columnDistrict}
                                            dataSource={this.state.dataDistrict}
                                            rowKey = {'Serial_Code'}
                                            key = {key}
                                        />
                                    </TabPane>
                                    <TabPane tab={language.VILLAGE} key="5">
                                        <Mps_ResultTable onChange={this.handleSelectRow}
                                            onPagination={this.handlePagination_Village}
                                            onShowSizeChange={this.handleShowSizeChange_Village}
                                            current={this.state.page}
                                            messageValid={this.state.commonMessage}
                                            columns={columnWard}
                                            dataSource={this.state.dataVillage}
                                            rowKey = {'Serial_Code'}
                                            key = {key}
                                        />
                                    </TabPane>
                                </Tabs>

                            </Spin>
                        </Content>
                    </Layout>
                </Layout>
                <Mps_Footer />
            </Layout>
        );
    }
}

import React, { Component } from 'react';
import { Form, Row, Col, Card, Collapse, Input
  } from 'antd';
import jsonQuery from 'json-query';
import {Mps_LayoutFormat
    , Mps_InputRangePicker} from '../Mps_Control';
import {MPS_SYSTEM_PARAMETER_TYPE, Mps_MethodCommon} from '../../commons';
import {System_User_SelectMultiple} from '../System_User/Controls/System_User_SelectMultiple';
import {LANGUAGE_STATE} from './Language/ln';
import { Mps_SelectMultipleParameterSystem } from '../Mps_Control/Mps_SelectMultipleParameterSystem/Mps_SelectMultipleParameterSystem';
import logger from '../../commons/Mps_Logger';
import { fromPairs } from 'lodash';
import { Sipco_WaterFilter_AutoComplete } from './Controls/Sipco_WaterFilter_AutoComplete';
const { Panel } = Collapse;
class Sipco_WaterFilterSearch extends Component {
    /**
    * @desc Hàm khởi tạo component
    * @param
    *   props - properties của component.
    * @return Nothing
    */
    constructor(props){
        super(props);
        const chooseLanguage =this.props.language;
        const language = jsonQuery([chooseLanguage[0]], {data: LANGUAGE_STATE}).value;
        this.state = {
            language,
            chooseLanguage,
        };
    }
    /**
     * @desc Handle thông tin lỗi
     * @param
     *   Nothing: nothing
     * @return Nothing
     */
    componentDidCatch(error, info) {
        // log the error to loggly
        //logger.push({ error, info });
    }
   
    /**
    * @desc Handle sự kiện textbox Name thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (Name được cập nhật giá trị mới).
    */
    handleSerialCodeChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Serial_Code = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện textbox Description thay đổi
    * @param
    *  Object e - sự kiện thay đổi.
    * @return Nothing (Description được cập nhật giá trị mới).
    */
    handleDescriptionChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Sipco_WaterFilter_Description = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
   /**
    * @desc Handle sự kiện select Status thay đổi
    * @param
    *  String alue - Giá trị mới.
    * @return Nothing (Status được cập nhật giá trị mới).
    */
    handleStatusChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Status = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện textbox Name thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (Name được cập nhật giá trị mới).
    */
     
     handleDayLeftChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.DayLeft =parseInt(e.target.value)
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
     handlePhoneChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Phone = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
     /**
    * @desc Handle sự kiện textbox Name thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (Name được cập nhật giá trị mới).
    */
      handleTimesChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Number_Of_Times_To_Replace = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện textbox Name thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (Name được cập nhật giá trị mới).
    */
     handleOwnerChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Owner = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
     /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
      handleProvideDateChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Provide_Date = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    
     /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
      handleDescriptionMonitoringChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Description_Of_Monitoring = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
     /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
      handleMonitoringDateChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Monitoring_Date = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
     /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
      handleFilterReplacementDateChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Filter_Replacement_Date = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện select CreatedBy thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedBy được cập nhật giá trị mới).
    */
    handleCreatedByChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.CreatedBy = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
    handleCreatedDateChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.CreatedDate = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện select UpdatedBy thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (UpdatedBy được cập nhật giá trị mới).
    */
    handleUpdatedByChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.UpdatedBy = value;
        /*demo mẫu logger*/
        //ger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    /**
    * @desc Handle sự kiện select UpdatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (UpdatedDate được cập nhật giá trị mới).
    */
    handleUpdatedDateChange = value => {
        const searchObjectModel = this.props.value;
        searchObjectModel.UpdatedDate = value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
     /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
      handleProvinceChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Province = e.target.value;
        /*demo mẫu logger*/
       // logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
     /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
      handleDistrictChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.District = e.target.value;
        /*demo mẫu logger*/
       // logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
     /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
      handleWardChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Ward = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };
    handleVillageChange = e => {
        const searchObjectModel = this.props.value;
        searchObjectModel.Village = e.target.value;
        /*demo mẫu logger*/
        //logger.push({ searchObjectModel });
        this.props.onChange(searchObjectModel);
    };

    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 22 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 22 },
            },
          };
        const gridStyle = {
            width: '50%',
        };
        const searchModel = this.props.value;
        const {chooseLanguage, language} = this.state;
        const labelFieldName= language.SIPCO_WATERFILTER_CODE;
        const displaySearchConditions = this.props.displaySearchConditions;

        return(
            <Form  {...formItemLayout} >
            <Collapse
                defaultActiveKey={['1']}
                expandIconPosition={'right'}
                className = 'mps-search-header-collapse'
            >
                <Panel header= {<span className= 'mps-search-header-panel-title'>  {language.SEARCH_INFOR}</span>} key="1" >
                    <Card  style={{border: 'none'}} >

                    <Sipco_WaterFilter_AutoComplete placeholder={labelFieldName}
                            className = {Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Serial_Code')}
                            value = {searchModel.Serial_Code}
                            language={chooseLanguage}
                            style= {gridStyle}
                            onChange={this.handleSerialCodeChange} />

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Sipco_WaterFilter_Description')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.SIPCO_WATERFILTER_DESCRIPTION}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                        <Input  onChange = {this.handleDescriptionChange}
                                            value = {searchModel.Sipco_WaterFilter_Description}
                                            allowClear
                                            id= 'Sipco_WaterFilter_Description'
                                            name='Sipco_WaterFilter_Description'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Owner')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.OWNER}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                    <Input  onChange = {this.handleOwnerChange}
                                        value = {searchModel.Owner}
                                        allowClear
                                        id= 'Owner'
                                        name='Owner'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Phone')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.PHONE}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                    <Input  
                                        type='number'
                                        onChange = {this.handlePhoneChange}
                                        value = {searchModel.Phone}
                                        allowClear
                                        id= 'Phone'
                                        name='Phone'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>
                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Province')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.PROVINCE}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                    <Input  onChange = {this.handleProvinceChange}
                                        value = {searchModel.Province}
                                        allowClear
                                        id= 'Province'
                                        name='Province'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>
                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'District')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.DISTRICT}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                    <Input  onChange = {this.handleDistrictChange}
                                        value = {searchModel.District}
                                        allowClear
                                        id= 'District'
                                        name='District'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>
                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Ward')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.WARD}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                    <Input  onChange = {this.handleWardChange}
                                        value = {searchModel.Ward}
                                        allowClear
                                        id= 'Ward'
                                        name='Ward'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Village')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.VILLAGE}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                    <Input  onChange = {this.handleVillageChange}
                                        value = {searchModel.Village}
                                        allowClear
                                        id= 'Village'
                                        name='Village'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Provide_Date')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item > {language.PROVIDE_DATE} </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Mps_InputRangePicker onChange = {this.handleProvideDateChange}
                                    value = {searchModel.Provide_Date} />
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Filter_Replacement_Date')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item > {language.FILTER_REPLACEMENT_DATE} </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Mps_InputRangePicker onChange = {this.handleFilterReplacementDateChange}
                                    value = {searchModel.Filter_Replacement_Date} />
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Number_Of_Times_To_Replace')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.NUMBER_OF_TIMES_TO_REPLACE}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                    <Input  onChange = {this.handleTimesChange}
                                        value = {searchModel.Number_Of_Times_To_Replace}
                                        allowClear
                                        id= 'Number_Of_Times_To_Replace'
                                        name='Number_Of_Times_To_Replace'
                                        type = 'number'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Monitoring_Date')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item > {language.MONITORING_DATE} </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Mps_InputRangePicker onChange = {this.handleMonitoringDateChange}
                                    value = {searchModel.Monitoring_Date} />
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Description_Of_Monitoring')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.DESCRIPTION_OF_MONITORING}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                    <Input  onChange = {this.handleDescriptionMonitoringChange}
                                        value = {searchModel.Description_Of_Monitoring}
                                        allowClear
                                        id= 'Description_Of_Monitoring'
                                        name='Description_Of_Monitoring'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'Status')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item > {language.STATUS} </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Mps_SelectMultipleParameterSystem type = {MPS_SYSTEM_PARAMETER_TYPE.Status}
                                         allowClear
                                        value = {searchModel.Status} onChange={this.handleStatusChange}/>
                                </Col>
                            </Row>
                        </Card.Grid>

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'DayLeft')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item >
                                        {language.DAYLEFT}
                                    </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Form.Item>
                                    <Input
                                        type='number'  
                                        onChange = {this.handleDayLeftChange}
                                        value = {searchModel.DayLeft}
                                        allowClear
                                        id= 'DayLeft'
                                        name='DayLeft'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card.Grid>

                                <System_User_SelectMultiple
                                    label ={language.UPDATED_BY}
                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'UpdatedBy')}`}
                                    onChange ={this.handleUpdatedByChange}
                                    value = {searchModel.UpdatedBy}
                                    style= {gridStyle}
                                />                     

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'UpdatedDate')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item > {language.UPDATED_DATE} </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                <Mps_InputRangePicker onChange = {this.handleUpdatedDateChange}
                                    value = {searchModel.UpdatedDate} />
                                </Col>
                            </Row>
                        </Card.Grid>

                        <System_User_SelectMultiple
                            label ={language.CREATED_BY}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'CreatedBy')}`}
                            onChange ={this.handleCreatedByChange}
                            value = {searchModel.CreatedBy}
                            style= {gridStyle}
                        />

                        <Card.Grid style={gridStyle}
                            className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displaySearchConditions,'CreatedDate')}`}>
                            <Row>
                                <Col span={Mps_LayoutFormat.ColCardLabel} >
                                    <Form.Item > {language.CREATED_DATE} </Form.Item>
                                </Col>
                                <Col  span={Mps_LayoutFormat.ColCardInput}>
                                    <Mps_InputRangePicker onChange = {this.handleCreatedDateChange}
                                    value = {searchModel.CreatedDate} />
                                </Col>
                            </Row>
                        </Card.Grid>
                    </Card>
                </Panel>
            </Collapse>
         </Form>
        );
    }
}

const WrappedSipco_WaterFilterSearch= Form.create({ name: 'Sipco_WaterFilterSearch' })(Sipco_WaterFilterSearch);
export { WrappedSipco_WaterFilterSearch as Sipco_WaterFilterSearch };

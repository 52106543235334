import React from 'react';
import { Mps_LabelFormat } from '../Mps_LabelFormat/Mps_LabelFormat';
import { Mps_Tooltip } from '../Mps_Tooltip/Mps_Tooltip';
import {
    TimePicker, Form
} from 'antd';
class Mps_RangeTimePicker extends React.Component {

    constructor(props) {
        super(props);
    }

    handleTimeChange = (dates, dateStrings) => {
        this.props.onChange(dates);
    }

    render() {
        var startTime = (!!this.props.value) ? this.props.value[0] : '';
        var endTime = (!!this.props.value) ? this.props.value[1] : '';
        return (
                <TimePicker.RangePicker
                    />
        );
    }
}
export { Mps_RangeTimePicker };

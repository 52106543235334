import { authHeader } from '../../../authenticate/Helpers';
import { API_URL } from '../../../conf.js';
import { Mps_CommonConstant } from '../../Mps_Control/Mps_CommonConstant/Mps_CommonConstant';

export const System_Setting_Service = {
    getCurrentScreen,
    getModule,
    updateCurrentScreen
};
const content = 'Content-Type';
const Type = 'application/json';
//Lấy thông tin chi tiết của System_Setting theo điều kiện tìm kiếm được nhập.

//Lấy thông tin chi tiết của một System_Setting theo Id.
function getCurrentScreen() {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'GET'
    };
    return fetch(`${API_URL}` + `/System_Setting/getCurrentScreen`, requestOptions).then(handleResponse);
}

//Lấy dữ liệu hiển thị cho autocompconste.
function getModule(searchModul) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: "POST",
        body: JSON.stringify(searchModul),
    };
    return fetch(`${API_URL}` + `/System_Setting/getModule`, requestOptions).then(handleResponse);
}

function updateCurrentScreen(object) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append("Content-Type", Type);
    headers.append("Authorization", auth);
    const requestOptions = {
        headers,
        method: "POST",
        body: JSON.stringify({object}),
    };
    return fetch(`${API_URL}/System_Setting/updateCurrentScreen`, requestOptions).then(
        handleResponse
    );
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

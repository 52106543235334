import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { history } from '../Helpers';
import {Menu, Icon} from 'antd';
import jsonQuery from 'json-query';
import {menuService, authenticationService} from '../Services';
import {MPS_LANGUAGE_STATE} from '../../commons';

class MenuApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            menus:[]
        };  
        this.handleMenuClick = this.handleMenuClick.bind(this);   
    }

    componentWillMount(){
        Promise.all([menuService.show()]).then((result) => {
            this.setState({
                menus:result,
            });            
        });
    }
    handleMenuClick(item)  {
        console.log(item);
    }

    logout()
    {
        authenticationService.logout();
        history.push('/login');
    }
    render() {          
        
        const menuList1 = this.state.menus;
        var chooselanguage = this.props.language;   
        var language =  jsonQuery([chooselanguage[0]], {data: MPS_LANGUAGE_STATE}).value; 
        return (            

                <Menu
                    mode="horizontal"
                    style={{ lineHeight: '64px' }}      
                    onClick={this.handleMenuClick}   
                >
                    {
                        Object.keys(menuList1).map(function(key)
                        {
                            const navModel = menuList1[key];
                            const menulistv1 = navModel.map((navChild) =>
                                <Menu.Item key={navChild._id}  >                                   
                                   <Link  to={{pathname:`/${navChild.Module_Name}`}}>
                                        {jsonQuery(['[?]',navChild.Module_Name], {data:language}).value }
                                   </Link>
                                </Menu.Item>);
                            return  menulistv1;
                        })
                    }      

                </Menu>    
                       
          );
        }
      }

export {MenuApp};
      
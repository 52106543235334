import {Mps_LabelFormat} from '../../Mps_Control/Mps_LabelFormat/Mps_LabelFormat';
import {MPS_DISPLAY_AREA_KEY, MPS_SCREEN_NO} from '../../../commons';

export function initResultTable(language, defaultColumn) {
    const cell = 'cell-ellipsis';
    return  [
        {
            width: 150,
            title:language.MODULENAMET_SCREENT_CODE,
            dataIndex: 'ModuleNameT_ScreenNameT_Code',
            key: 'ModuleNameT_ScreenNameT_Code',
            className: defaultColumn.includes('ModuleNameT_ScreenNameT_Code') ? cell : 'c-hide'
        },
        {
            width: 200,
            title:language.MODULENAMET_SCREENT_NAME,
            dataIndex: 'ModuleNameT_ScreenNameT_Name',
            key: 'ModuleNameT_ScreenNameT_Name',
            className: defaultColumn.includes('ModuleNameT_ScreenNameT_Name')? cell : 'c-hide'
        },
        {
            width: 300,
            title:language.MODULENAMET_SCREENT_DESCRIPTION,
            dataIndex: 'ModuleNameT_ScreenNameT_Description',
            key: 'ModuleNameT_ScreenNameT_Description',
            className: defaultColumn.includes('ModuleNameT_ScreenNameT_Description')? cell : 'c-hide'
        },
        {
            width: 150,
            title:language.STATUS,
            dataIndex: 'StatusObject.System_Parameter_Name',
            key: 'Status',
            className: defaultColumn.includes('Status') ? cell : 'c-hide'
        },
        {
            width: 200,
            title:language.CREATED_BY,
            dataIndex: 'CreatedByObject.System_User_DisplayName',
            key: 'CreatedBy',
            className: defaultColumn.includes('CreatedBy') ? cell : 'c-hide'
        },
        {
            width: 150,
            title:language.CREATED_DATE,
            dataIndex: 'CreatedDate',
            key: 'CreatedDate',
            className: defaultColumn.includes('CreatedDate') ? 'display-date' : 'c-hide display-date',
            render: date => (
                Mps_LabelFormat.formatDateTime(date)
            ),
        },
        {
            width: 200,
            title:language.UPDATED_BY,
            dataIndex: 'UpdatedByObject.System_User_DisplayName',
            key: 'UpdatedBy',
            className: defaultColumn.includes('UpdatedBy') ? cell : 'c-hide'
        },
        {
            width: 150,
            title:language.UPDATED_DATE,
            dataIndex: 'UpdatedDate',
            key: 'UpdatedDate',
            className: defaultColumn.includes('UpdatedDate') ? 'display-date' : 'c-hide display-date',
            render: date => (
                Mps_LabelFormat.formatDateTime(date)
            ),
        },
    ];
}
export function initFieldsDisplayOnSearchForm(language) {

    return  [
        {
            width: 100,
            title:language.MODULENAMET_SCREENT_CODE,
            dataIndex: 'ModuleNameT_ScreenNameT_Code',
            key: 'ModuleNameT_ScreenNameT_Code',
        },
        {
            width: 100,
            title:language.MODULENAMET_SCREENT_NAME,
            dataIndex: 'ModuleNameT_ScreenNameT_Name',
            key: 'ModuleNameT_ScreenNameT_Name',
        },
        {
            width: 100,
            title:language.MODULENAMET_SCREENT_DESCRIPTION,
            dataIndex: 'ModuleNameT_ScreenNameT_Description',
            key: 'ModuleNameT_ScreenNameT_Description',
        },
        {
            width: 100,
            title:language.STATUS,
            dataIndex: 'StatusObject.System_Parameter_Name',
            key: 'Status',
        },
        {
            width: 100,
            title:language.CREATED_BY,
            dataIndex: 'CreatedByObject.System_User_DisplayName',
            key: 'CreatedBy',
        },
        {
            width: 100,
            title:language.CREATED_DATE,
            dataIndex: 'CreatedDate',
            key: 'CreatedDate',
        },
        {
            width: 100,
            title:language.UPDATED_BY,
            dataIndex: 'UpdatedByObject.System_User_DisplayName',
            key: 'UpdatedBy',
        },
        {
            width: 100,
            title:language.UPDATED_DATE,
            dataIndex: 'UpdatedDate',
            key: 'UpdatedDate',
        },
    ];
}
export function  headers (model){
    const columns = model;

    const displayDefaultColums = getDisplayColumnSearchResults();

    const headersExport = [];
    columns.forEach(element=>{
        if(displayDefaultColums.includes(element.key)){
            const colum = {
                label: element.title,
                key: element.key
            };
            headersExport.push(colum);
        }
    });
  return headersExport;
}
export function headerExample(columnDisplay) {
    const columns = columnDisplay;
    const headersExport = [];
    const displayDefaultColums = getDisplayColumnSearchResults();
    columns.forEach(element => {
        if (displayDefaultColums.includes(element.key)) {
            const colum = {
                label: '',
                key: ''
            };
            colum.label = element.title;
            colum.key = element.key;
            headersExport.push(colum);
        }

    });
    return headersExport;
}

export const searchModel={
    ModuleNameT_ScreenNameT_Code:'',
    ModuleNameT_ScreenNameT_Name:'',
    ModuleNameT_ScreenNameT_Description:'',
    CreatedBy:[],
    CreatedDate:[],
    UpdatedBy:[],
    UpdatedDate:[],
    Status:[]
};

export const initModel={
    _id:'',
    ModuleNameT_ScreenNameT_Code:'',
    ModuleNameT_ScreenNameT_Name:'',
    ModuleNameT_ScreenNameT_Description:'',
    CreatedBy:'',
    CreatedDate:'',
    UpdatedBy:'',
    UpdatedDate:'',
    Status:''
};

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySearchConditionsKey = MPS_SCREEN_NO.User + MPS_DISPLAY_AREA_KEY.DisplaySearchConditions;
export function setDisplaySearchConditions(values){
    localStorage.setItem(displaySearchConditionsKey,JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplaySearchConditions(){
    const column = JSON.parse(localStorage.getItem(displaySearchConditionsKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if(column === null){
        return ['ModuleNameT_ScreenNameT_Code', 'ModuleNameT_ScreenNameT_Name','Status'];
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySortFieldsKey = MPS_SCREEN_NO.User + MPS_DISPLAY_AREA_KEY.DisplaySortFields;
export function setDisplaySortFields(values){
    localStorage.setItem(displaySortFieldsKey,JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplaySortFields(){
    const column = JSON.parse(localStorage.getItem(displaySortFieldsKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if(column === null){
        return [ 'CreatedDate'];
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySearchResultsKey = MPS_SCREEN_NO.User + MPS_DISPLAY_AREA_KEY.DisplaySearchResults;
export function setDisplayColumnSearchResults(values){
    localStorage.setItem(displaySearchResultsKey,JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplayColumnSearchResults(){
    const column = JSON.parse(localStorage.getItem(displaySearchResultsKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if(column === null){
        return ['ModuleNameT_ScreenNameT_Code', 'ModuleNameT_ScreenNameT_Name','ModuleNameT_ScreenNameT_Description','Status'];
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình add/edit/view.
export function initFielDisplayOnEditForm(language) {

    return  [
        {
            title:language.MODULENAMET_SCREENT_CODE,
            key: 'ModuleNameT_ScreenNameT_Code',
        },
        {
            title:language.MODULENAMET_SCREENT_NAME,
            key: 'ModuleNameT_ScreenNameT_Name',
        },
        {
            title:language.MODULENAMET_SCREENT_DESCRIPTION,
            key: 'ModuleNameT_ScreenNameT_Description',
        },
        {
            title:language.STATUS,
            key: 'Status',
        },
        {
            title:language.CREATED_BY,
            key: 'CreatedBy',
        },
        {
            title:language.CREATED_DATE,
            key: 'CreatedDate',
        },
        {
            title:language.UPDATED_BY,
            key: 'UpdatedBy',
        },
        {
            title:language.UPDATED_DATE,
            key: 'UpdatedDate',
        },
    ];
}
const displayEditFormKey = MPS_SCREEN_NO.ModuleNameT_ScreenNameT + MPS_DISPLAY_AREA_KEY.EditForm;
export function setDisplayFieldOnEditForm(values){
    localStorage.setItem(displayEditFormKey,JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình add/edit/view.
export function getDisplayFieldOnEditForm(){
    const column = JSON.parse(localStorage.getItem(displayEditFormKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if(column === null){
        return ['ModuleNameT_ScreenNameT_Code', 'ModuleNameT_ScreenNameT_Name','ModuleNameT_ScreenNameT_Description','Status'];
    }
    return column;
}

//Thiết lập trường thông tin hiển thị.
export const initFieldControl = {
    ModuleNameT_ScreenNameT_Code:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    ModuleNameT_ScreenNameT_Name:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    Status:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
        Message:''
    },
    ModuleNameT_ScreenNameT_Description:{
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
    },
};

export const productLisst=[
    {
        _id:1,
        Product_Code:'A',
        Product_Name:'A',
        CreatedBy:'',
        CreatedDate:'',
        UpdatedBy:'',
        UpdatedDate:'',
        Status:'',
    },
    {
        _id:2,
        Product_Code:'A',
        Product_Name:'A',
        CreatedBy:'',
        CreatedDate:'',
        UpdatedBy:'',
        UpdatedDate:'',
        Status:'',
    }
];
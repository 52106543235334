import React from 'react';
import { InputNumber, Form } from 'antd';
import { Mps_Tooltip } from '../Mps_Tooltip/Mps_Tooltip';
import { isNumber } from 'lodash';

class Mps_InputQuantity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    onChange = value => {
        this.setState({ value });
    };

    onBlur = () => {
        if (!!this.props.form) {
            const changedValue = {};
            changedValue[!!this.props.name ? this.props.name : 'undefinded'] = this.state.value;
            this.props.form.setFieldsValue(changedValue);
        }
        this.props.onChange(this.state.value);
        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    };

    render() {
        return (
            <Form.Item
                help={
                    (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
                }
                hasFeedback
            >
                {
                    (!this.props.required) ?
                        <InputNumber
                            defaultValue={!!this.props.value ? this.props.value : null}
                            style={{ width: '100%', textAlign: 'right' }}
                            className={this.props.className}
                            min={isNumber(this.props.min) ? this.props.min : -9999999999}
                            max={isNumber(this.props.max) ? this.props.max : 9999999999}
                            value={this.state.value}
                            defaultValue={this.props.value}
                            disabled={this.props.disabled}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                        />
                        : (this.props.getFieldDecorator(this.props.name, {
                            initialValue: this.props.value,
                            rules: [
                                {
                                    required: this.props.required,
                                }
                            ],
                        })(
                            <InputNumber
                                placeholder={'0000'}
                                style={{ width: '100%', textAlign: 'right' }}
                                className={this.props.className}
                                min={isNumber(this.props.min) ? this.props.min : -9999999999}
                                max={isNumber(this.props.max) ? this.props.max : 9999999999}
                                disabled={this.props.disabled}
                                onChange={this.onChange}
                                onBlur={this.onBlur}
                            />
                        ))}
            </Form.Item>

        );
    }
}
export { Mps_InputQuantity };

import React from 'react';
import { Form, Input, Button, Tabs, Row, Col, Modal, Card } from 'antd';
import jsonQuery from 'json-query';

import {
    MPS_DISPLAY_AREA_STORAGE, MPS_RESULTS,
    MPS_MESSAGE_STATE, Mps_MethodCommon, MPS_SYSTEM_PARAMETER, MPS_SCREEN_NO
} from '../../commons/';
import {
    Mps_LayoutFormat
    , Mps_SelectParameterSystem, Mps_InputDatePicker
    , Mps_Tooltip, Mps_DisplayField, Mps_ResultTable, Mps_TextEditor
} from '../Mps_Control';
import { System_User_Select } from '../System_User/Controls/System_User_Select';
import { Sipco_UserFeedback_Service } from './Service/Sipco_UserFeedback.Service';
import { LANGUAGE_STATE } from './Language/ln';
import { GUIDE_LINE } from './Language/Guideline';
import {
    setDisplayFieldOnEditForm,
    getDisplayFieldOnEditForm, initFielDisplayOnEditForm, initModel, initFieldControl, initResultTable, getDisplayColumnSearchResults
} from './Models/Sipco_UserFeedback.Model';
import moment from 'moment';
import { Log_Service } from '../../authenticate/Helpers/Log.Service';
const { TabPane } = Tabs;
const { TextArea } = Input;

const screenNo = MPS_SCREEN_NO.UserFeedback;

class Sipco_UserFeedback extends React.Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([this.props.language[0]], { data: LANGUAGE_STATE }).value;
        const commonMessage = jsonQuery([this.props.language[0]], { data: MPS_MESSAGE_STATE }).value;
        const guideLine = jsonQuery([this.props.language[0]], { data: GUIDE_LINE }).value;
        this.state = {
            language,
            commonMessage,
            guideLine,
            chooseLanguage,
            initModel,
            visible: false,
            Content: ''
        };
    }
    /**
     * @desc Khởi tạo data khi load component
     * @param Nothing
     * @return Nothing
    */
    componentWillMount() {
        const selectedModel = this.props.value;
        this.setState({
            selectedModel,
        });

    }
    /**
* @desc Handle thiết lập sắp xếp hiển thị field trên form.
* @param
*   array value: Danh sách Field được chọn hiển thị trên form.
* @return Nothing (Cập nhật setting),
*/
    handleDisplayFieldOnEditFormChange = value => {
        setDisplayFieldOnEditForm(value);
        this.setState({
            displayDefaultFieldOnEditForm: value
        });
    };
    /**
     * @desc Handle thông tin lỗi
     * @param
     *   Nothing: nothing
     * @return Nothing
     */
    /*componentDidCatch(error, info) {
        // log the error to loggly
        logger.push({ error, info });
    }*/

    handleShowForm = (e) => {
        e.preventDefault();
        const selectedModel = this.props.value;
        if (selectedModel.length === 0) {
            Mps_MethodCommon.openWarningNotification(this.state.commonMessage.CHOOSE_DATA_FEEDBACK, '');
        } else {
            const completeModel = selectedModel.filter(s => s.Status === MPS_SYSTEM_PARAMETER.StatusFeedback.Complete);

            if (completeModel.length !== 0) {
                Mps_MethodCommon.openErrorNotification(this.state.commonMessage.ERROR_TITLE, this.state.commonMessage.ERROR_CANNOT_FEEDBACK_COMPLETED);
            } else {
                this.setState({
                    visible: true,
                });
            }

        }
    };

    handleContentChange = (e) => {
        const Content = e.target.value
        this.setState({
            Content
        })
    }

    /**
    * @desc Handle sự kiện xác nhận lưu thông tin
    * @param event
    *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
    *   object data - dữ liệu hiển thị
    * @return bool - kết quả: true = thành công/ false = thất bại
    */
    handleOk = e => {
        e.preventDefault();
        const data = this.props.value;
        const count = data.length
        let datas = []
        for (let i = 0; i < count; i++) {
            const objectModel = {
                _id: data[i]._id,
                Serial_Code: data[i].Serial_Code,
                Description: data[i].Description,
                Source: data[i].Source,
                Content: this.state.Content,
                Status: MPS_SYSTEM_PARAMETER.StatusFeedback.Complete,
                UpdatedDate: Date.now()
            }
            datas = datas.concat(objectModel)
        }
        Promise.all([Sipco_UserFeedback_Service.updateMany(datas)]).then((result) => {
            this.setState({
                Content: '',
                visible: false
            });
            this.props.onhandleSave();
            this.handleReset()
        });



    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.handleReset()
    };

    handleReset = () => {
        this.props.form.resetFields();
    };

    handleStatusChange = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.Status = value;
        /*demo mẫu logger*/
        //Log_Service.create("handleStatusChange", value);
        this.setState({
            selectedModel
        });
    };
    handleSourceChange = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.Source = value;
        /*demo mẫu logger*/
        //Log_Service.create("handleSourceChange", value);
        this.setState({
            selectedModel
        });
    };
    /**
   * @desc Handle tùy chọn hiển thị
   * @param
   *   Nothing: nothing
   * @return Nothing
   */
    handleDisplayChange = (type, value) => {
        if (!value && type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
            this.setState({ isReset: this.state.isReset ? false : true });
        }
        if (type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
            if (type === MPS_DISPLAY_AREA_STORAGE.sortField) {
                const { searchOptions } = this.state;
                searchOptions.sort = value;
                this.setState({
                    searchOptions
                });
                return;
            }
            this.setState({ [type]: value });
        } else {
            const { searchOptions } = this.state;
            searchOptions.sortAscending = value;
            /*demo mẫu logger*/
            //Log_Service.create("handleDisplayChange", searchOptions);
            this.setState({
                sortAscending: value,
                searchOptions
            });
        }
    };
    render() {
        if (this.props.allowAccess === false) {
            return '';
        }

        const { language, guideLine } = this.state;
        const { getFieldDecorator } = this.props.form;
        const screenName = language.SIPCO_USERFEEDBACK_SCREEN_EDIT;
        // const selectedModel = this.state.selectedModel;
        const displayDefaultFieldOnEditForm = getDisplayFieldOnEditForm();
        const displayFieldOnEditForm = initFielDisplayOnEditForm(language);

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const gridStyle = {
            width: '50%'
        };
        return (
            <span>
                {this.props.allowAccess === true ? <Button type="primary" onClick={this.handleShowForm}>{this.props.actionName}</Button> : ' '}
                <Modal
                    title={language.SIPCO_USERFEEDBACK_TITLE_GENERAL}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="submit" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleOk}>
                            {language.FEEDBACK}
                        </Button>,
                        <Button key="back" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleCancel}>
                            {language.CANCEL}
                        </Button>,
                    ]}
                    width={800}
                    style={{ height: '800px' }}>
                    <Form {...formItemLayout} onSubmit={this.handleSave} id={Mps_MethodCommon.getDisplayTheme()}>
                        <Mps_DisplayField
                            style={{ position: 'absolute', right: '15px', 'zIndex': '1' }}
                            columns={[{ fields: displayFieldOnEditForm, type: 'displayFieldOnEditForm', data: displayDefaultFieldOnEditForm }
                            ]}
                            screenNo={screenNo}
                            onDisplayChange={this.handleDisplayChange}
                            language={this.state.chooseLanguage} />

                        <Tabs defaultActiveKey="1">
                            <TabPane tab={language.FEEDBACK} key="1">
                                <Form.Item>
                                    {language.CONTENT}
                                </Form.Item>
                                <TextArea rows={4}
                                    value={this.state.Content}
                                    id="Content"
                                    onChange={this.handleContentChange}
                                />

                            </TabPane>
                        </Tabs>
                    </Form>
                </Modal>
            </span>
        );
    }
}
const WrappedSipco_UserFeedback = Form.create({ name: 'Sipco_UserFeedback' })(Sipco_UserFeedback);
export { WrappedSipco_UserFeedback as Sipco_UserFeedback };

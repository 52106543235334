export const LANGUAGE_STATE ={
    'vn':
        {
            PAGE_TITLE_PROFILE:'Thông tin người dùng',
            PAGE_TITLE: 'Quản lý người dùng ',
            SYSTEM_USER_SCREEN_SEARCH: 'Quản lý người dùng ',
            SYSTEM_USER_SCREEN_NEW: 'Thêm tài khoản người dùng',
            SYSTEM_USER_SCREEN_COPY: 'Sao chép thông tin người dùng',
            SYSTEM_USER_SCREEN_EDIT: 'Chỉnh sửa thông tin người dùng',
            SYSTEM_USER_SCREEN_VIEW: 'Xem thông tin người dùng',
            SYSTEM_USER_SCREEN_TITLE_GENERAL: 'Thông tin chung',
            SYSTEM_USER_SCREEN_TITLE_ADDITIONAL: 'Thông tin thêm',
            SYSTEM_USER_SCREEN_CHANGE_PASSWORD:'Thay đổi mật khẩu',
            SYSTEM_USER_CODE: 'Mã đăng nhập',
            SYSTEM_USER_NAME: 'Tên đăng nhập',
            SYSTEM_USER_DISPLAY_NAME: 'Tên hiển thị',
            SYSTEM_USER_EMAIL: 'Email',
            SYSTEM_USER_PHONE_NUMBER: 'Số điện thoại',
            SYSTEM_USER_CREATED_DATE: 'Ngày tạo',
            SYSTEM_USER_CURRENT_PASSWORD: 'Mật khẩu hiện tại',
            SYSTEM_USER_NEW_PASSWORD: 'Mật khẩu mới',
            SYSTEM_USER_PASSWORD: 'Mật khẩu',
            SYSTEM_USER_CONFIRM_PASSWORD: 'Nhập lại mật khẩu',
            SYSTEM_USER_PERMISSION:'Quản lý cấp',
            SYSTEM_USER_PERMISSION_CPA:'Quyền quản lý CPA',
            SYSTEM_USER_ROLES:'Vai trò',
            SYSTEM_USER_LANGUAGE:'Ngôn ngữ',
            SYSTEM_USER_EMPLOYEE:'Nhân viên',
            SYSTEM_USER_INFO:'Thông tin người dùng',
            STATUS:'Trạng thái',
            CREATED_DATE: 'Ngày tạo',
            CREATED_BY: 'Người tạo',
            UPDATED_DATE: 'Ngày cập nhật',
            UPDATED_BY: 'Người cập nhật',
            HR_EMPLOYEE: 'Nhân viên',
            HR_EMPLOYEE_CODE: 'Mã nhân viên',
            HR_EMPLOYEE_NAME: 'Tên nhân viên',
            HR_EMPLOYEE_DESCRIPTION:'Mô tả',
            HR_EMPLOYEE_BRANCH: 'Chi nhánh',
            HR_EMPLOYEE_POSITION: 'Chức vụ',
            HR_EMPLOYEE_GENDER:'Giới tính',
            HR_EMPLOYEE_BIRTHDAY:'Ngày sinh',
            HR_EMPLOYEE_JOINED_DATE:'Ngày nhận việc',
            HR_EMPLOYEE_IDENTIFICATION: 'CMND',
            HR_EMPLOYEE_IDENTIFICATION_DATE_ISSUE: 'Ngày cấp',
            HR_EMPLOYEE_IDENTIFICATION_PLACE_ISSUE: 'Nơi cấp',
            HR_EMPLOYEE_PHONE_NUMBER:'Số điện thoại',
            HR_EMPLOYEE_EMAIL:'Email',
            ADDRESS:'Địa chỉ',
            CITY:'Thành phố',
            DISTRICT:'Quận/huyện',
            WARD:'Phường/xã',
            UPDATE:'Cập nhật',
            SAVE: 'Lưu',
            SAVEANDCLOSE:'Lưu và đóng',
            SAVEANDNEXT: 'Lưu và tiếp tục',
            CANCEL:'Hủy',
            CLOSE:'Đóng',
            VIEW:'Xem',
            SEARCH:'Tìm kiếm',
            RESET: 'làm mới',
            NEW: 'Thêm mới',
            EDIT: 'Chỉnh sửa',
            DELETE: 'Xóa',
            COPY:'Sao chép',
            DOWNLOADDATA:'Tải dữ liệu về (CSV)',
            FAILED_TO_FETCH:'Không kết nối được máy chủ!',
            SEARCH_INFO:'Thông tin tìm kiếm',
            PERMISSTION: 'Phân quyền',
            SYSTEM_USER_EXAMPLE_FILE:'System user example file',
            CHANGE_PASSWORD: 'Thay đổi mật khẩu',
            SIPCO_CPA_CODE: 'Mã CPA',
            NOTE: 'Ghi chú',
            IMPORT:'Nhập dữ liệu'
        },
    'en':
        {
            PAGE_TITLE_PROFILE:'User account information',
            PAGE_TITLE: 'User management',
            SYSTEM_USER_SCREEN_SEARCH: 'User Management',
            SYSTEM_USER_SCREEN_NEW: 'Add new user account',
            SYSTEM_USER_SCREEN_COPY: 'Copy user account information',
            SYSTEM_USER_SCREEN_EDIT: 'Update user account information',
            SYSTEM_USER_SCREEN_VIEW: 'View user account information',
            SYSTEM_USER_SCREEN_TITLE_GENERAL: 'General  information',
            SYSTEM_USER_SCREEN_TITLE_ADDITIONAL: 'Additional information',
            SYSTEM_USER_SCREEN_CHANGE_PASSWORD:'Change password',
            SYSTEM_USER_CODE: 'Account code',
            SYSTEM_USER_NAME: 'Account name',
            SYSTEM_USER_DISPLAY_NAME: 'Display name',
            SYSTEM_USER_EMAIL: 'Email',
            SYSTEM_USER_PHONE_NUMBER: 'Phone number',
            SYSTEM_USER_CREATED_DATE: 'Created date',
            SYSTEM_USER_CURRENT_PASSWORD: 'Current password',
            SYSTEM_USER_NEW_PASSWORD: 'New password',
            SYSTEM_USER_PASSWORD: 'Password',
            SYSTEM_USER_CONFIRM_PASSWORD: 'Confirm password',
            SYSTEM_USER_PERMISSION:'Manage level',
            SYSTEM_USER_PERMISSION_CPA:'Manage CPA',
            SYSTEM_USER_ROLES:'Roles',
            SYSTEM_USER_LANGUAGE:'Language',
            SYSTEM_USER_EMPLOYEE:'Employee',
            SYSTEM_USER_INFO:'User infor',
            STATUS:'Status',
            RESET: 'reset',
            CREATED_DATE: 'Created date',
            CREATED_BY: 'Created by',
            UPDATED_DATE: 'Updated date',
            UPDATED_BY: 'Updated by',
            HR_EMPLOYEE: 'Employee',
            HR_EMPLOYEE_CODE: 'Employee code',
            HR_EMPLOYEE_NAME: 'Employee name',
            HR_EMPLOYEE_DESCRIPTION:'Description',
            HR_EMPLOYEE_BRANCH: 'Branch',
            HR_EMPLOYEE_POSITION: 'Position',
            HR_EMPLOYEE_GENDER:'Gender',
            HR_EMPLOYEE_BIRTHDAY:'Birthday',
            HR_EMPLOYEE_JOINED_DATE:'Joinned date',
            HR_EMPLOYEE_IDENTIFICATION: 'Identification',
            HR_EMPLOYEE_IDENTIFICATION_DATE_ISSUE: 'Date of issue',
            HR_EMPLOYEE_IDENTIFICATION_PLACE_ISSUE: 'Place of issue',
            HR_EMPLOYEE_PHONE_NUMBER:'Phone number',
            HR_EMPLOYEE_EMAIL:'Email',
            ADDRESS:'Address',
            CITY:'City',
            DISTRICT:'District',
            WARD:'Wards',
            PERMISSTION: 'Permisstion',
            UPDATE:'Update',
            SAVE: 'Save',
            SAVEANDCLOSE:'Save and close',
            SAVEANDNEXT: 'Save and continue',
            CANCEL:'Cancel',
            CLOSE:'Close',
            VIEW:'View',
            SEARCH:'Search',
            NEW: 'New',
            EDIT: 'Edit',
            DELETE: 'Delete',
            COPY:'Copy',
            DOWNLOADDATA:'Download Data (CSV)',
            FAILED_TO_FETCH:'Cannot connet to server!',
            SEARCH_INFO:'Search info',
            SYSTEM_USER_EXAMPLE_FILE:'System user example file',
            CHANGE_PASSWORD: 'Change password',
            SIPCO_CPA_CODE: 'CPA Code',
            NOTE: 'Notes',
            IMPORT:'Import data'
        }
    };

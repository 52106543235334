import React, { Component } from 'react';
import jsonQuery from 'json-query';
import {MPS_LANGUAGE_STATE} from '../../commons';
import { Breadcrumb } from 'antd';
class Mps_Breadcum extends Component {
    render() {   
        var chooselanguage = this.props.language;   
        var language =  jsonQuery([chooselanguage[0]], {data: MPS_LANGUAGE_STATE}).value; 
        
        return (            
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item> {jsonQuery(['[?]',this.props.module], {data:language}).value }</Breadcrumb.Item>
                    <Breadcrumb.Item>{this.props.screen} </Breadcrumb.Item>
                </Breadcrumb>
            );
        }
      }
export {Mps_Breadcum};
      
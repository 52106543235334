import {MPS_MESSAGE_STATE} from '../../../commons';
import jsonQuery from 'json-query';
import { Modal } from 'antd';
const paginationLimit = 10;

export const Mps_CommonConstant = {
    paginationLimit,
    returnMessageApi,
    handleShowConfirm
};
const confirm = Modal.confirm;
function returnMessageApi(value,chooselanguage) {
    var message = jsonQuery([chooselanguage[0]], {data: MPS_MESSAGE_STATE}).value; 
    switch (value){
        case "0":
            return message.DATAERROR;         
        case "-1":
            return message.DATASAVEUNSUCCESS;           
        case "1": 
            return message.DATASAVESUCCESS;
        case "2":
            return message.DATAISEXISTS;
    }
}

/**
    * @desc Handle sự kiện mở popup xác nhận
    * @param 
    *   message - Tiêu đề.
    *   content - Nội dung
    * @return null
    */
function handleShowConfirm (message,content) {
    confirm({
    title: message,
    content: content,
    onOk() {
        return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log('Oops errors!'));
    },
    onCancel() {},
    });
}
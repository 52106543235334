export const LANGUAGE_STATE = {
    'vn':
    {
        MONDAY: 'Thứ hai',
        TUESDAY:'Thứ ba',
        WEDNESDAY:'Thứ tư',
        THURSDAY:'Thứ năm',
        FRIDAY:'Thứ sáu',
        SATURDAY:'Thứ bảy',
        SUNDAY:'Chủ nhật',
    },
    'en':
    {
        MONDAY: 'Monday',
        TUESDAY:'Tuesday',
        WEDNESDAY:'Wednesday',
        THURSDAY:'Thursday',
        FRIDAY:'Friday',
        SATURDAY:'Saturday',
        SUNDAY:'Sunday',
    }
};


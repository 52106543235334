import React from 'react';
import { Form, Input, Button, Tabs, Row, Col, Modal, Card } from 'antd';
import jsonQuery from 'json-query';

import { MPS_DISPLAY_AREA_STORAGE, MPS_MESSAGE_STATE, Mps_MethodCommon, MPS_SYSTEM_PARAMETER_TYPE, MPS_SCREEN_NO } from '../../commons';
import {
    Mps_LayoutFormat
    , Mps_SelectParameterSystem, Mps_InputDatePicker
    , Mps_Tooltip, Mps_DisplayField, Mps_DisplayColumnResultTable
} from '../Mps_Control';
import { System_User_Select } from '../System_User/Controls/System_User_Select';
import { Sipco_WaterFilter_Service } from '../Sipco_WaterFilter/Service/Sipco_WaterFilter.Service';
import { LANGUAGE_STATE } from '../Sipco_WaterFilter/Language/ln';
import { GUIDE_LINE } from '../Sipco_WaterFilter/Language/Guideline';
import { setDisplayFieldOnEditForm,
    getDisplayFieldOnEditForm, initFielDisplayOnEditForm, initModel, initFieldControl
} from '../Sipco_WaterFilter/Models/Sipco_WaterFilter.Model';
import Mps_UploadPictureV2  from '../Mps_Control/Mps_UploadPicture/Mps_UploadPictureV2';
import moment from 'moment';
import logger from '../../commons/Mps_Logger';
import {API_URL} from '../../conf.js';

const fs = require('fs')
const { TabPane } = Tabs;

const screenNo = MPS_SCREEN_NO.Sipco_WaterFilterHistory;

class Sipco_WaterFilterView_Notifical extends React.Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([this.props.language[0]], { data: LANGUAGE_STATE }).value;
        const commonMessage = jsonQuery([this.props.language[0]], { data: MPS_MESSAGE_STATE }).value;
        const guideLine = jsonQuery([this.props.language[0]], { data: GUIDE_LINE }).value;
        this.state = {
            language,
            commonMessage,
            guideLine,
            chooseLanguage,
            selectedModel: initModel,
            visible: false,
            src: ''
        };
    }
    /**
     * @desc Khởi tạo data khi load component
     * @param Nothing
     * @return Nothing
    */
    componentWillMount() {        
        const selectedModel = this.props.value;
        this.setState({
            selectedModel,
        });

    }

        /**
    * @desc Handle thiết lập sắp xếp hiển thị field trên form.
    * @param
    *   array value: Danh sách Field được chọn hiển thị trên form.
    * @return Nothing (Cập nhật setting),
    */
         handleDisplayFieldOnEditFormChange = value => {
            setDisplayFieldOnEditForm(value);
            this.setState({
                displayDefaultFieldOnEditForm: value
            });
        };
    /**
     * @desc Handle thông tin lỗi
     * @param
     *   Nothing: nothing
     * @return Nothing
     */
    componentDidCatch(error, info) {
        // log the error to loggly
        //logger.push({ error, info });
    }
    /**
  * @desc Handle tùy chọn hiển thị
  * @param
  *   Nothing: nothing
  * @return Nothing
  */
 handleDisplayChange = (type, value) => {
    if (!value && type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
        this.setState({ isReset: this.state.isReset ? false : true });
    }
    if (type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
        if (type === MPS_DISPLAY_AREA_STORAGE.sortField) {
            const { searchOptions } = this.state;
            searchOptions.sort = value;
            this.setState({
                searchOptions
            });
            return ;
        }
        this.setState({ [type]: value });
    } else {
        const { searchOptions } = this.state;
        searchOptions.sortAscending = value;
        this.setState({
            sortAscending: value,
            searchOptions
        });
    }
};
    /**
   * @desc Handle sự kiện hiển thị màn hình View/Add/Copy/Edit
   * @param event
   *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
   *   object data - dữ liệu hiển thị
   * @return Nothing
   */
    handleShowForm = e => {
        e.preventDefault();
        //if (this.props.chooseKey === 1) {
            var selectedModel = this.props.value;
            /*demo mẫu logger*/
            //logger.push({ selectedModel });
            if (selectedModel.length === 0 || selectedModel[0].Partner_Code === '') {
                    Mps_MethodCommon.handleShowConfirm(this.state.commonMessage.CHOOSE_DATA_VIEW, '');
            } else if (selectedModel.length > 1 || selectedModel[0].Partner_Code === '') {
                Mps_MethodCommon.openWarningNotification(this.state.commonMessage.CHOOSE_ONLY_DATA_VIEW, '');
                return;
            } else {
                Promise.all([Sipco_WaterFilter_Service.getById(selectedModel[0]._id)]).then((result) => {
                    var data = jsonQuery('result[0]', { data: result }).value;
                    /*demo mẫu logger*/
                    //logger.push({ data });
                    this.setState({
                        selectedModel: data,
                        visible: true,
                    });
                }
                );
                Promise.all([Sipco_WaterFilter_Service.checkFileExit(selectedModel[0].Serial_Code)]).then((result) => {
                    this.setState({
                        src: result[0].data,
                        visible: true,
                    });
                    }
                    );
                
            }
        //}
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    handleReset = () => {
        this.props.form.resetFields();
    };

    handleStatusChange = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.Status = value;
        /*demo mẫu logger*/
        //logger.push({ selectedModel });
        this.setState({
            selectedModel
        });
    };

    render() {
        if (this.props.allowAccess === false) {
            return '';
        }

        const {language, guideLine, src} = this.state;
        const { getFieldDecorator } = this.props.form;
        const screenName = language.SIPCO_WATERFILTER_VIEW;
        const selectedModel = this.state.selectedModel;
        const displayDefaultFieldOnEditForm = getDisplayFieldOnEditForm();
        const displayFieldOnEditForm = initFielDisplayOnEditForm(language);
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const gridStyle = {
            width: '50%'
        };

        return (
            <span>
                {this.props.allowAccess === true ? <Button type="primary" onClick={this.handleShowForm}>{this.props.actionName}</Button> : ' '}
                <Modal
                    title={screenName}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleCancel}>
                            {language.CLOSE}
                        </Button>,
                    ]}
                    width={800}>
                    <Form {...formItemLayout} onSubmit={this.handleSave} id={Mps_MethodCommon.getDisplayTheme()}>
                        <Mps_DisplayColumnResultTable columns={displayFieldOnEditForm}
                            onDisplayFieldOnEditFormChange={this.handleDisplayFieldOnEditFormChange}
                            displayFieldOnEditForm={displayDefaultFieldOnEditForm}
                            language={this.state.chooseLanguage} />
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={language.SIPCO_WATERFILTER_TITLE_GENERAL} key="1">
                                <Card style={{ border: 'none' }} >
                                    
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Serial_Code')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SERIAL_CODE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={
                                                        <Mps_Tooltip title={guideLine.Serial_Code} />
                                                    }
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Serial_Code', {
                                                        initialValue: selectedModel.Serial_Code,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Serial_Code.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Owner')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.OWNER}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Owner', {
                                                        initialValue: selectedModel.Owner,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Owner.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Phone')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.PHONE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Phone', {
                                                        initialValue: selectedModel.Phone,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Phone.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Province')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.PROVINCE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Province', {
                                                        initialValue: selectedModel.Province,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Province.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'District')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.DISTRICT}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('District', {
                                                        initialValue: selectedModel.District,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.District.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Ward')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.WARD}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Ward', {
                                                        initialValue: selectedModel.Ward,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Ward.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Village')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.VILLAGE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Village', {
                                                        initialValue: selectedModel.Village,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Village.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Provide_Date')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.PROVIDE_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Provide_Date')}`}
                                                    name="Provide_Date"
                                                    help={guideLine.Provide_Date}
                                                    style={{width:'100%'}}
                                                    value={!!selectedModel.Provide_Date ? moment(selectedModel.Provide_Date) : null}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Sipco_WaterFilter_Description')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_WATERFILTER_DESCRIPTION}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Sipco_WaterFilter_Description', {
                                                        initialValue: selectedModel.Sipco_WaterFilter_Description,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Sipco_WaterFilter_Description.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Status')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.STATUS}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_SelectParameterSystem disabled={true} name='Status' type={MPS_SYSTEM_PARAMETER_TYPE.Status}
                                                    getFieldDecorator={getFieldDecorator}
                                                    required={initFieldControl.Status.Required}
                                                    value={this.state.selectedModel.Status} onChange={this.handleStatusChange} />

                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.CREATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedBy')}`} name="CreatedBy"
                                        help={guideLine.CreatedBy}
                                        value={this.state.selectedModel.CreatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.CREATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}
                                                    name="CreatedDate"
                                                    help={guideLine.CreatedDate}
                                                    style={{width:'100%'}}
                                                    value={!!selectedModel.CreatedDate ? moment(selectedModel.CreatedDate) : null}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.FILTER_REPLACEMENT_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}
                                                    name="UpdatedDate"
                                                    style={{width:'100%'}}
                                                    value={!!selectedModel.Filter_Replacement_Date ? moment(selectedModel.Filter_Replacement_Date) : null}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Status')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.NUMBER_OF_TIMES_TO_REPLACE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Number_Of_Times_To_Replace', {
                                                        initialValue: selectedModel.Number_Of_Times_To_Replace,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Number_Of_Times_To_Replace.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.UPDATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedBy')}`} name="CreatedBy"
                                        help={guideLine.UpdatedBy}
                                        value={this.state.selectedModel.UpdatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.UPDATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}
                                                    name="UpdatedDate"
                                                    help={guideLine.UpdatedDate}
                                                    style={{width:'100%'}}
                                                    value={!!selectedModel.UpdatedDate ? moment(selectedModel.UpdatedDate) : null}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Monitoring_Date')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.MONITORING_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Monitoring_Date')}`}
                                                    name="Monitoring_Date"
                                                    style={{width:'100%'}}
                                                    onChange={this.handleSurveyDateChange}
                                                    value={!!selectedModel.Monitoring_Date ? moment(selectedModel.Monitoring_Date) : null}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Description_Of_Monitoring')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.DESCRIPTION_OF_MONITORING}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Description_Of_Monitoring', {
                                                        initialValue: selectedModel.Description_Of_Monitoring,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Description_Of_Monitoring.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true}/>)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                </Card>

                            </TabPane>
                            <TabPane tab={language.DOCUMENT_UPLOAD} key='2'>
                                <Mps_UploadPictureV2
                                language = {language}
                                isConvertUrl
                                images={this.state.selectedModel.WaterFilter_Files}
                                disabled = {true}
                            />
                            {
                            !!this.state.src ?
                            <embed src={`${API_URL}/${src}`} width="100%" height="1080px"/>: ''
                            }
                            </TabPane>
                        </Tabs>
                    </Form>
                </Modal>
            </span>
        );
    }
}
const WrappedSipco_WaterFilterView_Notifical = Form.create({ name: 'Sipco_WaterFilterView_Notifical' })(Sipco_WaterFilterView_Notifical);
export { WrappedSipco_WaterFilterView_Notifical as Sipco_WaterFilterView_Notifical };

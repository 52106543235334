import React from 'react';
import { Form, Input, Button, Tabs, Row, Col, Modal, Card } from 'antd';
import jsonQuery from 'json-query';

import { MPS_DISPLAY_AREA_STORAGE, MPS_MESSAGE_STATE, Mps_MethodCommon, MPS_SYSTEM_PARAMETER_TYPE, MPS_SCREEN_NO } from '../../commons/';
import {
    Mps_LayoutFormat
    , Mps_SelectParameterSystem, Mps_InputDatePicker
    , Mps_Tooltip, Mps_DisplayField
} from '../Mps_Control';
import { System_User_Select } from '../System_User/Controls/System_User_Select';
import { ModuleNameT_ScreenNameT_Service } from './Service/ModuleNameT_ScreenNameT.Service';
import { LANGUAGE_STATE } from './Language/ln';
import { GUIDE_LINE } from './Language/Guideline';
import { setDisplayFieldOnEditForm,
    getDisplayFieldOnEditForm, initFielDisplayOnEditForm, initModel, initFieldControl
} from './Models/ModuleNameT_ScreenNameT.Model';
import moment from 'moment';
import logger from '../../commons/Mps_Logger';
const { TabPane } = Tabs;

const screenNo = MPS_SCREEN_NO.ModuleName_ScreenNameT;

class ModuleNameT_ScreenNameTView extends React.Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([this.props.language[0]], { data: LANGUAGE_STATE }).value;
        const commonMessage = jsonQuery([this.props.language[0]], { data: MPS_MESSAGE_STATE }).value;
        const guideLine = jsonQuery([this.props.language[0]], { data: GUIDE_LINE }).value;
        this.state = {
            language,
            commonMessage,
            guideLine,
            chooseLanguage,
            selectedModel: initModel,
            visible: false,
        };
    }
    /**
     * @desc Khởi tạo data khi load component
     * @param Nothing
     * @return Nothing
    */
    componentWillMount() {
        const selectedModel = this.props.value;
        this.setState({
            selectedModel,
        });

    }
        /**
    * @desc Handle thiết lập sắp xếp hiển thị field trên form.
    * @param
    *   array value: Danh sách Field được chọn hiển thị trên form.
    * @return Nothing (Cập nhật setting),
    */
         handleDisplayFieldOnEditFormChange = value => {
            setDisplayFieldOnEditForm(value);
            this.setState({
                displayDefaultFieldOnEditForm: value
            });
        };
    /**
     * @desc Handle thông tin lỗi
     * @param
     *   Nothing: nothing
     * @return Nothing
     */
    componentDidCatch(error, info) {
        // log the error to loggly
        logger.push({ error, info });
    }
    /**
  * @desc Handle tùy chọn hiển thị
  * @param
  *   Nothing: nothing
  * @return Nothing
  */
 handleDisplayChange = (type, value) => {
    if (!value && type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
        this.setState({ isReset: this.state.isReset ? false : true });
    }
    if (type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
        if (type === MPS_DISPLAY_AREA_STORAGE.sortField) {
            const { searchOptions } = this.state;
            searchOptions.sort = value;
            this.setState({
                searchOptions
            });
            return ;
        }
        this.setState({ [type]: value });
    } else {
        const { searchOptions } = this.state;
        searchOptions.sortAscending = value;
        this.setState({
            sortAscending: value,
            searchOptions
        });
    }
};
    /**
   * @desc Handle sự kiện hiển thị màn hình View/Add/Copy/Edit
   * @param event
   *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
   *   object data - dữ liệu hiển thị
   * @return Nothing
   */
    handleShowForm = e => {
        e.preventDefault();
        var selectedModel = this.props.value;
        /*demo mẫu logger*/
        logger.push({ selectedModel });
        if (selectedModel.length === 0 || selectedModel[0].Partner_Code === "") {
            Mps_MethodCommon.handleShowConfirm(this.state.commonMessage.CHOOSE_DATA_VIEW, "");
        } else {
            Promise.all([ModuleNameT_ScreenNameT_Service.getById(selectedModel[0]._id)]).then((result) => {
                var data = jsonQuery('result[0]', { data: result }).value;
                /*demo mẫu logger*/
                logger.push({ data });
                this.setState({
                    selectedModel: data,
                    visible: true,
                });
            }
            );
        }
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    handleReset = () => {
        this.props.form.resetFields();
    };

    handleStatusChange = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.Status = value;
        /*demo mẫu logger*/
        logger.push({ selectedModel });
        this.setState({
            selectedModel
        });
    };

    render() {
        if (this.props.allowAccess === false) {
            return '';
        }

        const {language, guideLine} = this.state;
        const { getFieldDecorator } = this.props.form;
        const screenName = language.MODULENAMET_SCREENT_SCREEN_VIEW;
        const selectedModel = this.state.selectedModel;
        const displayDefaultFieldOnEditForm = getDisplayFieldOnEditForm();
        const displayFieldOnEditForm = initFielDisplayOnEditForm(language);
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const gridStyle = {
            width: '50%'
        };

        return (
            <span>
                {this.props.allowAccess === true ? <Button type="primary" onClick={this.handleShowForm}>{this.props.actionName}</Button> : ' '}
                <Modal
                    title={screenName}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleCancel}>
                            {language.CANCEL}
                        </Button>,
                    ]}
                    width={800}>
                    <Form {...formItemLayout} onSubmit={this.handleSave} id={Mps_MethodCommon.getDisplayTheme()}>
                        <Mps_DisplayField
                            style={{ position: 'absolute', right: '15px', 'zIndex': '1' }}
                            columns={[{ fields: displayFieldOnEditForm, type: 'displayFieldOnEditForm', data: displayDefaultFieldOnEditForm }
                            ]}
                            screenNo={screenNo}
                            onDisplayChange={this.handleDisplayChange}
                            language={this.state.chooseLanguage} />

                        <Tabs defaultActiveKey="1">
                            <TabPane tab={language.MODULENAMET_SCREENT_SCREEN_TITLE_GENERAL} key="1">
                                <Card style={{ border: 'none' }} >
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'ModuleNameT_ScreenNameT_Code')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item>
                                                    {language.MODULENAMET_SCREENT_CODE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={
                                                        <Mps_Tooltip title={guideLine.ModuleNameT_ScreenNameT_Code} />
                                                    }
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('ModuleNameT_ScreenNameT_Code', {
                                                        initialValue: selectedModel.ModuleNameT_ScreenNameT_Code,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.ModuleNameT_ScreenNameT_Code.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                    {getFieldDecorator('_id', {
                                                        initialValue: selectedModel._id,
                                                        rules: [],
                                                    })(<Input type='hidden' />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'ModuleNameT_ScreenNameT_Name')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.MODULENAMET_SCREENT_NAME}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={
                                                        <Mps_Tooltip title={guideLine.ModuleNameT_ScreenNameT_Name} />
                                                    }
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('ModuleNameT_ScreenNameT_Name', {
                                                        initialValue: selectedModel.ModuleNameT_ScreenNameT_Name,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.ModuleNameT_ScreenNameT_Name.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'ModuleNameT_ScreenNameT_Description')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.MODULENAMET_SCREENT_DESCRIPTION}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={
                                                        <Mps_Tooltip title={guideLine.ModuleNameT_ScreenNameT_Description} />
                                                    }
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('ModuleNameT_ScreenNameT_Description', {
                                                        initialValue: selectedModel.ModuleNameT_ScreenNameT_Description,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.ModuleNameT_ScreenNameT_Description.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Status')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.STATUS}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_SelectParameterSystem disabled={true} name='Status' type={MPS_SYSTEM_PARAMETER_TYPE.Status}
                                                    getFieldDecorator={getFieldDecorator}
                                                    required={initFieldControl.Status.Required}
                                                    help={guideLine.Status}
                                                    value={this.state.selectedModel.Status} onChange={this.handleStatusChange} />

                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.CREATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedBy')}`} name="CreatedBy"
                                        help={guideLine.CreatedBy}
                                        value={this.state.selectedModel.CreatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.CREATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}
                                                    name="CreatedDate"
                                                    help={guideLine.CreatedDate}
                                                    style={{width:'100%'}}
                                                    value={moment(Date.now())}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.UPDATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedBy')}`} name="CreatedBy"
                                        help={guideLine.UpdatedBy}
                                        value={this.state.selectedModel.UpdatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.UPDATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}
                                                    name="UpdatedDate"
                                                    help={guideLine.UpdatedDate}
                                                    style={{width:'100%'}}
                                                    value={moment(Date.now())}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                </Card>

                            </TabPane>
                        </Tabs>
                    </Form>
                </Modal>
            </span>
        );
    }
}
const WrappedModuleNameT_ScreenNameTView = Form.create({ name: 'ModuleNameT_ScreenNameTView' })(ModuleNameT_ScreenNameTView);
export { WrappedModuleNameT_ScreenNameTView as ModuleNameT_ScreenNameTView };

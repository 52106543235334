export const LANGUAGE_STATE ={
    'vn':
        {
            YOUR_NOTIFY : "Thông báo của bạn",
           TEXT_MESS:"Bạn chưa hoàn thành xong các công việc của ngày: ",
           NO_NOTIFY:"Hiện tại bạn chưa có thông báo",
           NOTIFICAL:"Bình lọc nước sắp hết hạn",
           DETAIL: "Xem chi tiết",
           SYSTEM_USER_INFO: 'Thông tin người dùng',
           CATALOGUE: 'Danh mục'

        },
    'en':
    {
        YOUR_NOTIFY : "Your notifical",
            TEXT_MESS:"You have'nt completed tasks of date: ",
            NO_NOTIFY:"You don't have any notify",
            NOTIFICAL:"Household expiration soon",
           DETAIL: "View detail",
           SYSTEM_USER_INFO: 'User info',
           CATALOGUE: 'Catalogue'

        }
    };

import React from 'react';
import { Drawer, Button, Form, Checkbox, Row, Col, Icon, Tooltip, Select } from 'antd';
import jsonQuery from 'json-query';
import { Mps_MethodCommon } from '../../../commons';
import { LANGUAGE_STATE } from './Language/ln';
const { Option } = Select;
class Mps_DisplayColumnResultTable extends React.Component {

  constructor(props) {
    super(props);
    const chooseLanguage = this.props.language[0];
    const language = jsonQuery([chooseLanguage], { data: LANGUAGE_STATE }).value;
    this.state = {
      language,
      visible: false,
      checkAll: false,
    };
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleDisplaySearchConditionsChange = values => {
    this.props.onDisplaySearchConditionsChange(values);
  };

  handleDisplaySortFieldsChange = values => {
    this.props.onDisplaySortFieldsChange(values);
  };

  handleDisplayColumnSearchResultsChange = values => {
    this.props.onDisplayColumnSearchResultsChange(values);
  };

  handleSortTypeChange = (e) => {
    const sortAscending = !this.props.sortAscending;
    this.props.onSortTypeChange(sortAscending);
  };

  handleDisplayFieldOnEditFormChange = values => {
    this.props.onDisplayFieldOnEditFormChange(values);
  };

  render() {
    const language = this.state.language;
    const { columns, searchConditionColumns, displaySearchConditions,
      displaySortFields, displayColumnSearchResults, sortAscending } = this.props;


    return (
      <span className={' mps-display-options ' + this.props.className} style={this.props.style}>
        <Tooltip id={Mps_MethodCommon.getDisplayTheme()} title={language.TITLE} placement='leftBottom'  >
          <Icon type='setting' onClick={this.showDrawer}
            style={{ float: 'right', fontSize: '25px', marginTop: 10, marginRight: 10 }} />
        </Tooltip>
        <Drawer
          title={language.TITLE}
          width={420} 
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}>

          {(!this.props.displaySortFields) ? '' :
            (
              <div className='mps-display-sort-field'>
                <Form.Item >
                  <span style={{ fontWeight: 'bold', fontSize: 15 }}>
                    {language.TITLE_DISPLAY_SORT_RESULT_AREA}
                    <Icon type={(sortAscending) ? 'sort-ascending' : 'sort-descending'}
                      onClick={this.handleSortTypeChange}
                      style={{ fontSize: 20, fontWeight: 'bold' }} />
                  </span>
                  <Select
                    style={{ width: '100%' }}
                    mode='multiple'
                    allowClear={true}
                    defaultValue={displaySortFields}
                    onChange={this.handleDisplaySortFieldsChange}
                  >
                    {columns.map(select => <Option key={select.key} value={select.key}>{select.title}</Option>)}
                  </Select>
                </Form.Item>
              </div>
            )
          }
          {
            (!this.props.displaySearchConditions) ? '' :
              (
                <div className='mps-display-search-field'>
                  <span style={{ fontWeight: 'bold', fontSize: 15 }} >{language.TITLE_SEARCH_CONDITION_AREA}</span>
                  <Checkbox.Group style={{ width: '100%', marginTop: '5px' }} onChange={this.handleDisplaySearchConditionsChange}
                    value={displaySearchConditions}>
                    {
                      (!this.props.searchConditionColumns) ?
                        <Row>
                          {columns.map((column) =>
                            <Col key={column.key + Math.random()} span={12}>
                              <Checkbox key={column.key} value={column.key}>{column.title}</Checkbox>
                            </Col>
                          )}
                        </Row> :
                        <Row>
                          {searchConditionColumns.map((s) =>
                            <Col key={s.key + Math.random()} span={12}>
                              <Checkbox key={s.key} value={s.key}>{s.title}</Checkbox>
                            </Col>
                          )}
                        </Row>
                    }
                  </Checkbox.Group>
                </div>
              )
          }
          {(!this.props.displayColumnSearchResults) ? '' :
            (
              <div className='mps-display-column-result' >
                <span style={{ fontWeight: 'bold', fontSize: 15 }}>{language.TITLE_SEARCH_RESULT_AREA}</span>
                <Checkbox.Group style={{ width: '100%', margin: '5px 0 10px 0' }} onChange={this.handleDisplayColumnSearchResultsChange}
                  value={displayColumnSearchResults}>
                  <Row>
                    {columns.map(column =>
                      <Col key={column.key + Math.random()} span={12}>
                        <Checkbox key={column.key} value={column.key}>{column.title}</Checkbox>
                      </Col>
                    )}
                  </Row>
                </Checkbox.Group>
              </div>
            )}

          {(!this.props.displayFieldOnEditForm) ? '' :
            (
              <div className='mps-display-column-result' >
                <span style={{ fontWeight: 'bold', fontSize: 15 }}>{language.TITLE_EDIT_FORM}</span>
                <Checkbox.Group style={{ width: '100%', margin: '5px 0 10px 0' }} onChange={this.handleDisplayFieldOnEditFormChange}
                  value={this.props.displayFieldOnEditForm}>
                  <Row>
                    {columns.map(column =>
                      <Col key={column.key + Math.random()} span={12}>
                        <Checkbox key={column.key} value={column.key}>{column.title}</Checkbox>
                      </Col>
                    )}
                  </Row>
                </Checkbox.Group>
              </div>
            )}

          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }} >

            <Button style={{ marginRight: 8 }} className={Mps_MethodCommon.getDisplayTheme()} onClick={this.onClose} type='primary'>
              {language.CLOSE}
            </Button>
          </div>
        </Drawer>
      </span>
    );
  }
}
export { Mps_DisplayColumnResultTable };


import React, { Component } from 'react';
import {
	ResponsiveContainer, BarChart, Bar,
	XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import PropTypes from 'prop-types';

import '../../Mps_Chart/style.css';

const COLORS = [
	'#6EC3C9', '#8273B0', '#FF33FF', '#66FF66', '#009966', '#003333',
	'#E54646', '#94AAD6', '#635BA2', '#67BF7F', '#AF4A92', '#00B2BF',
	'#0000AA', '#000022'
];

class Mps_BarChart extends Component {
	render() {
		const { width, height, data, margin, xAxisDatakey, dataKeys, tickFormatter, xAxisTick, toolTipFormatter, hasLegend = true } = this.props;
		return (
			<ResponsiveContainer width='100%' height={height}>
				<BarChart
					width={width}
					height={height}
					data={data}
					margin={margin ? margin :
						{
							top: 0,
							right: 0,
							bottom: 30,
							left: 35
						}}
				>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis interval={0} dataKey={xAxisDatakey} tick={xAxisTick ? xAxisTick : true} />
					<YAxis tickFormatter={tickFormatter ? tickFormatter : null} />
					<Tooltip formatter={toolTipFormatter ? toolTipFormatter : null} />
					{hasLegend && <Legend verticalAlign='bottom' height={0} />}
					{data.length > 0 && dataKeys.map((dataKey, index) => {
						return <Bar is key={`${dataKey}${index}`} dataKey={dataKey} fill={COLORS[index % COLORS.length]} />;
					})}
				</BarChart>
			</ResponsiveContainer>
		);
	}
}

Mps_BarChart.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number,
	height: PropTypes.number.isRequired,
	xAxisDatakey: PropTypes.string.isRequired,
	dataKeys: PropTypes.array.isRequired,
};

export default Mps_BarChart;

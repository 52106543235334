import React from 'react';
import jsonQuery from 'json-query';
import {
  Select, Form
} from 'antd';
import { MPS_SYSTEM_PARAMETER } from "../../../commons"
import { Mps_Tooltip } from '../Mps_Tooltip/Mps_Tooltip';
import { System_Parameter_Service } from '../../System_Parameter/Service/System_Parameter.Service';

class Mps_SelectParameterSystem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
  }

  componentDidUpdate(prevProps) {
    const searchModel = {}
    if (prevProps.type !== this.props.type) {
      searchModel.System_Parameter_Type = this.props.type;
      searchModel.Status = (!!this.props.status) ? this.props.status : null;
      Promise.all([System_Parameter_Service.getDataFilterControl(searchModel)]).then((result) => {
        var data = jsonQuery('docs[**]', { data: result[0].result }).value;
        this.setState({
          dataSource: data,
        });
      });
    }
  }

  componentDidMount() {
    const searchModel = {}
    searchModel.System_Parameter_Type = this.props.type;
    searchModel.Status = (!!this.props.status) ? this.props.status : null;
    Promise.all([System_Parameter_Service.getDataFilterControl(searchModel)]).then((result) => {
      var data = jsonQuery('docs[**]', { data: result[0].result }).value;
      this.setState({
        dataSource: data,
      });
    });
  }

  onChange = (value) => {
    this.props.onChange(value);
  }

  render() {
    const { Option } = Select;
    const dataSource = jsonQuery('[**]', { data: this.state.dataSource }).value;

    return (
      <div>
        <Form.Item
          help={
            (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
          }
          hasFeedback
        >
          {
            (!this.props.getFieldDecorator) ?
              <Select disabled={(!!this.props.disabled) ? this.props.disabled : false}
                allowClear={this.props.allowClear}
                placeholer={(!!this.props.placeholer) ? this.props.placeholer : ''}
                value={this.props.value}
                onChange={this.onChange}
                style={(!!this.props.style) ? this.props.style : {}}>

                {dataSource.map((select) => {
                  if (this.props.isLargeThan_aDay === true) {
                    {
                      if (select.System_Parameter_Code !== "3021-002" && select.System_Parameter_Code !== "3021-005") {

                        return <Option key={select.System_Parameter_Code}
                          value={select.System_Parameter_Code}>{select.System_Parameter_Name}</Option>
                      }
                    }
                  } else {
                    return <Option key={select.System_Parameter_Code}
                      value={select.System_Parameter_Code}>{select.System_Parameter_Name}</Option>
                  }
                }
                )}


              </Select>
              : (this.props.getFieldDecorator(this.props.name, {
                initialValue: this.props.value,
                rules: [
                  {
                    required: this.props.required,
                  }
                ],
              })(
                <Select disabled={(!!this.props.disabled) ? this.props.disabled : false}
                  allowClear={this.props.allowClear}
                  placeholer={(!!this.props.placeholer) ? this.props.placeholer : ''}
                  onChange={this.onChange}
                  style={(!!this.props.style) ? this.props.style : {}}>

                  {dataSource.map((select) => {
                    if (this.props.isLargeThan_aDay === true) {
                      {
                        if (select.System_Parameter_Code !== MPS_SYSTEM_PARAMETER.TypeOfBookRoom.daily && 
                            select.System_Parameter_Code !== MPS_SYSTEM_PARAMETER.TypeOfBookRoom.weekly) {
                              return <Option key={select.System_Parameter_Code}
                                value={select.System_Parameter_Code}>{select.System_Parameter_Name}</Option>
                        }
                      }
                    } else if(this.props.is_todo_LargeThan_aDay ===true){
                        if ( select.System_Parameter_Code !== MPS_SYSTEM_PARAMETER.Action_Todo_Type.action_weekly && 
                             select.System_Parameter_Code !== MPS_SYSTEM_PARAMETER.Action_Todo_Type.action_daily) {
                               return <Option key={select.System_Parameter_Code}
                                  value={select.System_Parameter_Code}>{select.System_Parameter_Name}</Option>
                        } 
                    }else{
                      return <Option key={select.System_Parameter_Code}
                        value={select.System_Parameter_Code}>{select.System_Parameter_Name}</Option>
                    }
                  }
                  )}


                </Select>
              ))}
        </Form.Item>
      </div>
    );
  }
}

export { Mps_SelectParameterSystem };

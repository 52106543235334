import React from 'react';
import jsonQuery from 'json-query';
import {
  Form,
  Row, Col,
  Card, Select
} from 'antd';
import {
  Mps_LayoutFormat, Mps_Tooltip
} from '../../Mps_Control';
import { authenticationService } from '../../../authenticate/Services';
import { LANGUAGE_STATE } from '../Language/ln';
import { System_User_Service } from '../Service/System_User.Service';
const { Option } = Select;

class System_User_SelectMultiple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    };
  }

  //Xử lý trong trường hợp cần gửi System_User_Code vì trong tìm kiếm mặc định đang để _id
  onChange = (value, data) => {
    if (this.props.dataSearch === 'System_User_Code') {
      let searchOption = [];
      data.forEach(element => {
        searchOption.push(element.props.code)
      });
      this.props.onChange(searchOption);
    } else this.props.onChange(value);
  }

  componentDidMount() {
    const searchModel = {
      Status: ''
    };
    searchModel.Status = (!!this.props.status) ? this.props.status : null;
    Promise.all([System_User_Service.getDataFilter(searchModel)]).then(result => {
      const data = jsonQuery('[**]', { data: result[0].data }).value;
      this.setState({
        dataSource: data
      });
    });
  }

  handleOnFocus = () => {
    const searchModel = {
      Status: ''
    };
    searchModel.Status = (!!this.props.status) ? this.props.status : null;
    Promise.all([System_User_Service.getDataFilter(searchModel)]).then(result => {
      const data = jsonQuery('[**]', { data: result[0].data }).value;
      this.setState({
        dataSource: data
      });
    });
  }

  render() {
    const chooseLanguage = (!!this.props.language) ? this.props.language : authenticationService.getCurrentLanguage();
    const language = jsonQuery([chooseLanguage], { data: LANGUAGE_STATE }).value;
    // const gridStyle = this.props.style;

    return (
      <Card.Grid style={(!!this.props.style) ? this.props.style :{ width: '50%', height: '100%' }}
        className={`mps-card-items  ${this.props.className}`}>
        <Row>
          <Col span={Mps_LayoutFormat.ColCardLabel} >
            <Form.Item>
              {(!!this.props.label) ? this.props.label : language.SYSTEM_USER_NAME}
            </Form.Item>
          </Col>
          <Col span={Mps_LayoutFormat.ColCardInput}>
            <Form.Item
              help={
                (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
              }
              hasFeedback
            >
              <Select
                disabled={(!!this.props.disabled) ? this.props.disabled : false}
                mode='multiple'
                style={{ width: '100%' }}
                allowClear={this.props.allowClear}
                onChange={this.onChange}
                filterOption={(input, option) =>
                  (option.props.data.System_User_DisplayName.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  || (option.props.data.System_User_Code.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                optionFilterProp='data'
                optionLabelProp='text'
                showSearch
                allowClear
                value={this.props.value}
                onFocus={this.handleOnFocus}
              >
                {this.state.dataSource.map(select =>
                  <Option key={select._id} data={select} value={select._id} code={select.System_User_Code} text={select.System_User_DisplayName}>
                    <div className='global-search-item'>
                      <span>
                        {select.System_User_Code}
                      </span>
                      <span style={{ float: 'right' }}> {select.System_User_DisplayName} </span>
                    </div>
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card.Grid>
    );
  }
}

export { System_User_SelectMultiple };

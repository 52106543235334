export const LANGUAGE_STATE ={
    'vn':
        {
            PAGE_TITLE: 'Quản lý [tên màn hình]',
            MODULENAMET_SCREENT_SCREEN_SEARCH: 'Quản lý [tên màn hình]',
            MODULENAMET_SCREENT_SCREEN_NEW: 'Thêm mới [tên màn hình]',
            MODULENAMET_SCREENT_SCREEN_COPY: 'Sao chép thông tin [tên màn hình]',
            MODULENAMET_SCREENT_SCREEN_EDIT: 'Chỉnh sửa thông tin [tên màn hình]',
            MODULENAMET_SCREENT_SCREEN_VIEW: 'Xem thông tin [tên màn hình]',
            MODULENAMET_SCREENT_SCREEN_TITLE_GENERAL: 'Thông tin chung',
            MODULENAMET_SCREENT_SCREEN_TITLE_ADDITIONAL: 'Thông tin thêm',
            MODULENAMET_SCREENT_CODE: 'Mã tên trường',
            MODULENAMET_SCREENT_NAME: 'Tên tên trường',
            MODULENAMET_SCREENT_DESCRIPTION:'Mô tả',
            STATUS:'Trạng thái',
            CREATED_DATE: 'Ngày tạo',
            CREATED_BY: 'Người tạo',
            UPDATED_DATE: 'Ngày cập nhật',
            UPDATED_BY: 'Người cập nhật',
            SEARCH_INFOR:'Thông tin tìm kiếm',
            SAVE: 'Lưu',
            SAVEANDCLOSE:'Lưu và đóng',
            SAVEANDNEXT: 'Lưu và tiếp tục',
            CANCEL:'Hủy',
            CLOSE:'Đóng',
            SEARCH:'Tìm kiếm',
            NEW: 'Thêm mới',
            VIEW: 'Xem',
            EDIT: 'Chỉnh sửa',
            DELETE: 'Xóa',
            COPY:'Sao chép',
            IMPORT:'Nhập dữ liệu',
            DOWNLOADDATA:'Tải dữ liệu (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import mẫu',
            MODULENAMET_SCREENT_DATA_FILE: 'File data MODULENAMET_SCREENT',
        },
    'en':
        {
            PAGE_TITLE: '[Screen name] management',
            MODULENAMET_SCREENT_SCREEN_SEARCH: '[Screen name] management',
            MODULENAMET_SCREENT_SCREEN_NEW: 'Add new [Screen name] information',
            MODULENAMET_SCREENT_SCREEN_COPY: 'Add new [Screen name] information',
            MODULENAMET_SCREENT_SCREEN_EDIT: 'Edit [Screen name] information',
            MODULENAMET_SCREENT_SCREEN_VIEW: 'View [Screen name] information',
            MODULENAMET_SCREENT_SCREEN_TITLE_GENERAL: 'General  information',
            MODULENAMET_SCREENT_SCREEN_TITLE_ADDITIONAL: 'Additional information',
            MODULENAMET_SCREENT_CODE: 'Field code',
            MODULENAMET_SCREENT_NAME: 'Field name',
            MODULENAMET_SCREENT_DESCRIPTION:'Description',
            STATUS:'Status',
            CREATED_DATE: 'Created date',
            CREATED_BY: 'Created by',
            UPDATED_DATE: 'Updated date',
            UPDATED_BY: 'Updated by',
            SEARCH_INFOR:'Search Information',
            SAVE: 'Save',
            SAVEANDCLOSE:'Save and close',
            SAVEANDNEXT: 'Save and continue',
            CANCEL:'Cancel',
            CLOSE:'Close',
            SEARCH:'Search',
            NEW: 'New',
            EDIT: 'Edit',
            VIEW: 'View',
            DELETE: 'Delete',
            COPY:'Copy',
            IMPORT:'Import data',
            DOWNLOADDATA:'Download Data (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import example',
            MODULENAMET_SCREENT_DATA_FILE: 'File data MODULENAMET_SCREENT',
        }
    };

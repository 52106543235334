import { Mps_LabelFormat } from '../../Mps_Control/Mps_LabelFormat/Mps_LabelFormat';
import { MPS_DISPLAY_AREA_KEY, MPS_SCREEN_NO, MPS_SYSTEM_PARAMETER_TYPE } from '../../../commons';
import React from 'react';

export function initResultTable(language, defaultColumn) {
    const cell = 'cell-ellipsis';
    return [

        {
            width: 200,
            title: language.SERIAL_CODE,
            dataIndex: 'Serial_Code',
            key: 'Serial_Code',
            className: defaultColumn.includes('Serial_Code') ? cell : 'c-hide'
        },
        {
            width: 200,
            title: language.OWNER,
            dataIndex: 'Owner',
            key: 'Owner',
            className: defaultColumn.includes('Owner') ? cell : 'c-hide'
        },
        {
            width: 200,
            title: language.PHONE,
            dataIndex: 'Phone',
            key: 'Phone',
            align: 'right',
            className: defaultColumn.includes('Phone') ? cell : 'c-hide'
        },
        {
            width: 200,
            title: language.PROVINCE,
            dataIndex: 'Province',
            key: 'Province',
            className: defaultColumn.includes('Province') ? cell : 'c-hide'
        },
        {
            width: 200,
            title: language.DISTRICT,
            dataIndex: 'District',
            key: 'District',
            className: defaultColumn.includes('District') ? cell : 'c-hide'
        },
        {
            width: 200,
            title: language.WARD,
            dataIndex: 'Ward',
            key: 'Ward',
            className: defaultColumn.includes('Ward') ? cell : 'c-hide'
        },
        {
            width: 200,
            title: language.VILLAGE,
            dataIndex: 'Village',
            key: 'Village',
            className: defaultColumn.includes('Village') ? cell : 'c-hide'
        },
        {
            width: 150,
            title: language.PROVIDE_DATE,
            dataIndex: 'Provide_Date',
            key: 'Provide_Date',
            className: defaultColumn.includes('Provide_Date') ? cell : 'c-hide',
            align:'center',
            render: date => (
                Mps_LabelFormat.formatDate(date)
            ),
        },
        {
            width: 180,
            title: language.DAYLEFT,
            dataIndex: 'Provide_Date',
            key: 'DayLeft',
            align: 'right',
            className: defaultColumn.includes('DayLeft') ? cell : 'c-hide',
            render: date => {
            const Provide_Date =  new Date(date)
            const endDate = new Date(Provide_Date.getFullYear()+3, Provide_Date.getMonth(), Provide_Date.getDate(),0,0,0);
            const year = new Date().getFullYear()
            const month = new Date().getMonth()
            const day = new Date().getDate()
            const now = new Date(year,month,day ,0,0,0)
            var Difference_In_Time = endDate.getTime() - now.getTime()
            var Difference_In_Days = Difference_In_Time /(1000 * 60 * 60 * 24);
            if(Difference_In_Days < 0){
                // return (`${language.WATERFILTER_EPIRED} ${Math.abs(Difference_In_Days)} ${language.DAY}`)
                return   <div style={{overflow:'hidden', fontWeight:'bold'}} dangerouslySetInnerHTML={{ __html: ( `${language.WATERFILTER_EPIRED} ${Math.abs(Difference_In_Days)} ${language.DAY}` ) }} />
            }
                return Difference_In_Days
            }
        },
        {
            width: 300,
            title: language.SIPCO_WATERFILTER_DESCRIPTION,
            dataIndex: 'Sipco_WaterFilter_Description',
            key: 'Sipco_WaterFilter_Description',
            className: defaultColumn.includes('Sipco_WaterFilter_Description') ? cell : 'c-hide'
        },
        {
            width: 150,
            title: language.STATUS,
            dataIndex: 'StatusObject.System_Parameter_Name',
            key: 'Status',
            className: defaultColumn.includes('Status') ? cell : 'c-hide'
        },
        {
            width: 200,
            title: language.CREATED_BY,
            dataIndex: 'CreatedByObject.System_User_DisplayName',
            key: 'CreatedBy',
            className: defaultColumn.includes('CreatedBy') ? cell : 'c-hide'
        },
        {
            width: 150,
            title: language.CREATED_DATE,
            dataIndex: 'CreatedDate',
            key: 'CreatedDate',
            className: defaultColumn.includes('CreatedDate') ? 'display-date' : 'c-hide display-date',
            render: date => (
                Mps_LabelFormat.formatDate(date)
            ),
        },
        {
            width: 150,
            title: language.MONITORING_DATE,
            dataIndex: 'Monitoring_Date',
            key: 'Monitoring_Date',
            className: defaultColumn.includes('Monitoring_Date') ? 'display-date' : 'c-hide display-date',
            render: date => (
                Mps_LabelFormat.formatDate(date)
            ),
        },
        {
            width: 300,
            title: language.DESCRIPTION_OF_MONITORING,
            dataIndex: 'Description_Of_Monitoring',
            key: 'Description_Of_Monitoring',
            className: defaultColumn.includes('Description_Of_Monitoring') ? cell : 'c-hide'
        },
        {
            width: 150,
            title: language.FILTER_REPLACEMENT_DATE,
            dataIndex: 'Filter_Replacement_Date',
            key: 'Filter_Replacement_Date',
            className: defaultColumn.includes('Filter_Replacement_Date') ? 'display-date' : 'c-hide display-date',
            render: date => (
                Mps_LabelFormat.formatDate(date)
            ),
        },
        {
            width: 200,
            title: language.NUMBER_OF_TIMES_TO_REPLACE,
            dataIndex: 'Number_Of_Times_To_Replace',
            key: 'Number_Of_Times_To_Replace',
            align: 'right',
            className: defaultColumn.includes('Number_Of_Times_To_Replace') ? cell : 'c-hide'
        },
        {
            width: 200,
            title: language.UPDATED_BY,
            dataIndex: 'UpdatedByObject.System_User_DisplayName',
            key: 'UpdatedBy',
            className: defaultColumn.includes('UpdatedBy') ? cell : 'c-hide'
        },
        {
            width: 150,
            title: language.UPDATED_DATE,
            dataIndex: 'UpdatedDate',
            key: 'UpdatedDate',
            className: defaultColumn.includes('UpdatedDate') ? 'display-date' : 'c-hide display-date',
            render: date => (
                Mps_LabelFormat.formatDate(date)
            ),
        },
    ];
}
export function initCPATable(language) {
    return [

        {
            title: language.CPA_CODE,
            dataIndex: '_id',
            key: '_id',
        },
        {
            title: language.SUM_SERIAL_PER_CPA,
            dataIndex: 'total',
            key: 'total',
            align: 'right',
        },
        
    ];
}
export function initProvinceTable(language) {
    return [

        {
            title: language.PROVINCE,
            dataIndex: '_id',
            key: '_id',
        },
        {
            title: language.SUM_CPA,
            dataIndex: 'total',
            key: 'total',
            align: 'right',
        },
        
    ];
}
export function initDistrictTable(language) {
    return [
        {
            title: language.PROVINCE,
            dataIndex: 'Province',
            key: 'Province',
        },

        {
            title: language.DISTRICT,
            dataIndex: 'District',
            key: 'District',
        },
        {
            title: language.SUM_SERIAL_PER_DISTRICT,
            dataIndex: 'total',
            key: 'total',
            align: 'right',
        },
        
    ];
}
export function initWardTable(language) {
    return [
        {
            title: language.PROVINCE,
            dataIndex: 'Province',
            key: 'Province',
        },

        {
            title: language.DISTRICT,
            dataIndex: 'District',
            key: 'District',
        },
        {
            title: language.WARD,
            dataIndex: 'Ward',
            key: 'Ward',
        },
        {
            title: language.VILLAGE,
            dataIndex: 'Village',
            key: 'Village',
        },
        {
            title: language.SUM_WATERFILTER_PER_WARD,
            dataIndex: 'total',
            key: 'total',
            align: 'right',
        },
        
    ];
}

export function initFieldsDisplayOnSearchForm(language) {

    return [

        {
            width: 100,
            title: language.SERIAL_CODE,
            dataIndex: 'Serial_Code',
            key: 'Serial_Code',
        },
        {
            width: 100,
            title: language.OWNER,
            dataIndex: 'Owner',
            key: 'Owner',
        },
        {
            width: 100,
            title: language.PHONE,
            dataIndex: 'Phone',
            key: 'Phone',
        },
        {
            width: 100,
            title: language.PROVINCE,
            dataIndex: 'Province',
            key: 'Province',
        },
        {
            width: 100,
            title: language.DISTRICT,
            dataIndex: 'District',
            key: 'District',
        },
        {
            width: 100,
            title: language.WARD,
            dataIndex: 'Ward',
            key: 'Ward',
        },
        {
            width: 100,
            title: language.VILLAGE,
            dataIndex: 'Village',
            key: 'Village',
        },
        {
            width: 100,
            title: language.PROVIDE_DATE,
            dataIndex: 'Provide_Date',
            key: 'Provide_Date',
        },
       
        {
            width: 100,
            title: language.SIPCO_WATERFILTER_DESCRIPTION,
            dataIndex: 'Sipco_WaterFilter_Description',
            key: 'Sipco_WaterFilter_Description',
        },
        {
            width: 100,
            title: language.STATUS,
            dataIndex: 'StatusObject.System_Parameter_Name',
            key: 'Status',
        },
        {
            width: 100,
            title: language.CREATED_BY,
            dataIndex: 'CreatedByObject.System_User_DisplayName',
            key: 'CreatedBy',
        },
        {
            width: 100,
            title: language.CREATED_DATE,
            dataIndex: 'CreatedDate',
            key: 'CreatedDate',
        },
        {
            width: 100,
            title: language.UPDATED_BY,
            dataIndex: 'UpdatedByObject.System_User_DisplayName',
            key: 'UpdatedBy',
        },
        {
            width: 100,
            title: language.UPDATED_DATE,
            dataIndex: 'UpdatedDate',
            key: 'UpdatedDate',
        },
    ];
}
export function headers(model) {
    const columns = model;

    const displayDefaultColums = getDisplayColumnSearchResults();

    const headersExport = [];
    columns.forEach(element => {
        if (displayDefaultColums.includes(element.key)) {
            const colum = {
                label: element.title,
                key: element.key
            };
            headersExport.push(colum);
        }
    });
    return headersExport;
}
export function headersCPA(model) {
    const columns = model;
    const headersExport = [];
    columns.forEach(element => {
            const colum = {
                label: element.title,
                key: element.key
            };
            headersExport.push(colum);
    });
    return headersExport;
}

export function headerExample(columnDisplay) {
    const columns = columnDisplay;
    const headersExport = [];
    const col = ['CreatedBy', 'UpdatedBy', 'CreatedDate', 'UpdatedDate','DayLeft'];
    const onlyOnceCol = [];
    const requireCol = ['Owner', 'Phone', 'Provide_Date','Status','Province', 'Ward', 'District', 'Village' ];
    const requireOnlyOnceCol = ['Serial_Code'];
    const parameter = [
        {key: 'Status', type : MPS_SYSTEM_PARAMETER_TYPE.Status},
    ];
    columns.forEach(element => {
        if (!col.includes(element.key)) {
            const colum = {
                label: null,
                key: null,
                type: null,
                require: 0
            };
            const par = parameter.find(par => par.key === element.key);
            if(par !== undefined){
                colum.type = par.type;
            }
            if(onlyOnceCol.includes(element.key)){
                colum.require = 1;
            }
            if(requireCol.includes(element.key)){
                colum.require = 2;
            }
            if(requireOnlyOnceCol.includes(element.key)){
                colum.require = 3;
            }
            colum.label = element.title;
            colum.key = element.key;
            headersExport.push(colum);
        }

    });
    return headersExport;
}

export const searchModel = {
    Serial_Code: '',
    Owner: '',
    Phone: '',
    Province: '',
    District: '',
    Ward: '',
    Village:'',
    Provide_Date: [],
    Filter_Replacement_Date: [],
    Monitoring_Date: [],
    WaterFilter_File: [],
    Sipco_WaterFilter_Description: '',
    CreatedBy: [],
    CreatedDate: [],
    UpdatedBy: [],
    UpdatedDate: [],
    Status: [],
    Notifical: [],

};

export const initModel = {
    _id: '',
    Serial_Code: '',
    Owner: '',
    Province: '',
    District: '',
    Ward: '',
    Village:'',
    Phone: '',
    Provide_Date: '',
    Monitoring_Date: '',
    WaterFilter_File: [],
    WaterFilter_Files: [],
    Sipco_WaterFilter_Description: '',
    CreatedBy: '',
    CreatedDate: '',
    Filter_Replacement_Date: '',
    Number_Of_Times_To_Replace: '',
    UpdatedBy: '',
    UpdatedDate: '',
    Status: ''
};

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySearchConditionsKey = MPS_SCREEN_NO.Sipco_WaterFilter + MPS_DISPLAY_AREA_KEY.DisplaySearchConditions;
export function setDisplaySearchConditions(values) {
    localStorage.setItem(displaySearchConditionsKey, JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplaySearchConditions() {
    const column = JSON.parse(localStorage.getItem(displaySearchConditionsKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if (column === null) {
        return [ 'Serial_Code', 'Owner', 'Phone', 'Province', 'District', 'Ward', 'Village', 'Provide_Date'];
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySortFieldsKey = MPS_SCREEN_NO.Sipco_WaterFilter + MPS_DISPLAY_AREA_KEY.DisplaySortFields;
export function setDisplaySortFields(values) {
    localStorage.setItem(displaySortFieldsKey, JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplaySortFields() {
    const column = JSON.parse(localStorage.getItem(displaySortFieldsKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if (column === null) {
        return ['Serial_Code',];
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình search.
const displaySearchResultsKey = MPS_SCREEN_NO.Sipco_WaterFilter + MPS_DISPLAY_AREA_KEY.DisplaySearchResults;
export function setDisplayColumnSearchResults(values) {
    localStorage.setItem(displaySearchResultsKey, JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình search.
export function getDisplayColumnSearchResults() {
    const column = JSON.parse(localStorage.getItem(displaySearchResultsKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if (column === null) {
        return [ 'Serial_Code', 'Owner', 'Phone', 'Province', 'District', 'Ward', 'Village', 'Provide_Date','CreatedBy','CreatedDate',
        'UpdatedBy','UpdatedDate',
    ];
    }
    return column;
}

//Lưu thông tin các trường thông tin được hiển thị mặc định trên màn hình add/edit/view.
export function initFielDisplayOnEditForm(language) {

    return [
        {
            title: language.SERIAL_CODE,
            key: 'Serial_Code',
        },
        {
            title: language.OWNER,
            key: 'Owner',
        },
        {
            title: language.PHONE,
            key: 'Phone',
        },
        {
            title: language.PROVINCE,
            key: 'Province',
        },
        {
            title: language.DISTRICT,
            key: 'District',
        },
        {
            title: language.WARD,
            key: 'Ward',
        },
        {
            title: language.VILLAGE,
            key: 'Village',
        },
        {
            title: language.PROVIDE_DATE,
            key: 'Provide_Date',
        },
        {
            title: language.SIPCO_WATERFILTER_DESCRIPTION,
            key: 'Sipco_WaterFilter_Description',
        },
        {
            title: language.STATUS,
            key: 'Status',
        },
        {
            title: language.CREATED_BY,
            key: 'CreatedBy',
        },
        {
            title: language.CREATED_DATE,
            key: 'CreatedDate',
        },
        {
            title: language.FILTER_REPLACEMENT_DATE,
            key: 'Filter_Replacement_Date',
        },
        {
            title: language.NUMBER_OF_TIMES_TO_REPLACE,
            key: 'Number_Of_Times_To_Replace',
        },
        {
            title: language.UPDATED_BY,
            key: 'UpdatedBy',
        },
        {
            title: language.UPDATED_DATE,
            key: 'UpdatedDate',
        },
        {
            title: language.MONITORING_DATE,
            key: 'Monitoring_Date',
        },
        {
            title: language.DESCRIPTION_OF_MONITORING,
            key: 'Description_Of_Monitoring',
        },
    ];
}
const displayEditFormKey = MPS_SCREEN_NO.Sipco_WaterFilter + MPS_DISPLAY_AREA_KEY.EditForm;
export function setDisplayFieldOnEditForm(values) {
    localStorage.setItem(displayEditFormKey, JSON.stringify(values));
}
//Lấy danh sách các trường thông tin được hiển thị mặc định trên màn hình add/edit/view.
export function getDisplayFieldOnEditForm() {
    const column = JSON.parse(localStorage.getItem(displayEditFormKey));
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    if (column === null) {
        return [ 'Serial_Code', 'Owner', 'Phone', 'Provide_Date','Province', 'District', 'Ward','Village',
        'Sipco_WaterFilter_Description', 'Status','Description_Of_Monitoring','Monitoring_Date',
        'Filter_Replacement_Date', 'Number_Of_Times_To_Replace','CreatedBy','CreatedDate',
        'UpdatedBy','UpdateDate', 
    ];
    }
    return column;
}
export function getAllColumn() {
    //Trả về danh sách các cột mặc định nếu người dùng chưa thiết lập.
    return [
        'Serial_Code', 'Owner', 'Phone', 'Provide_Date', 'Province', 'District', 'Ward','Village',
        'Sipco_WaterFilter_Description', 'Status','Description_Of_Monitoring','Monitoring_Date',
        'Filter_Replacement_Date', 'Number_Of_Times_To_Replace','CreatedBy','CreatedDate',
        'UpdatedBy','UpdateDate',
    ];
}

//Thiết lập trường thông tin hiển thị.
export const initFieldControl = {

    Serial_Code: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    Owner: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    Phone: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
    },
    Provide_Date: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    Status: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
        Message: ''
    },
    Sipco_WaterFilter_Description: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
    },
    Filter_Replacement_Date: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
    },
    Number_Of_Times_To_Replace: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
    },
    Monitoring_Date: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
    },
    Description_Of_Monitoring: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: false,
        Pattern: '',
    },
    Province: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    District: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    Ward: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
    Village: {
        // true: hiển thị ; false: không hiển thị
        Available: true,
        // true: bắt buộc nhập ; false: không bắt buộc nhập
        Required: true,
        Pattern: '',
    },
};


export const LANGUAGE_STATE ={
    'vn':
        {
            PAGE_TITLE: 'Quản lý CPA',
            SIPCO_CPA_SCREEN_SEARCH: 'Quản lý CPA',
            SIPCO_CPA_SCREEN_NEW: 'Thêm mới CPA',
            SIPCO_CPA_SCREEN_COPY: 'Sao chép thông tin CPA',
            SIPCO_CPA_SCREEN_EDIT: 'Chỉnh sửa thông tin CPA',
            SIPCO_CPA_SCREEN_VIEW: 'Xem thông tin CPA',
            SIPCO_CPA_SCREEN_TITLE_GENERAL: 'Thông tin chung',
            SIPCO_CPA_SCREEN_TITLE_ADDITIONAL: 'Thông tin thêm',
            SIPCO_CPA_CODE: 'Mã CPA',
            SIPCO_CPA_NAME: 'Tên CPA',
            SIPCO_CPA_START: 'Series đầu mã CPA',
            SIPCO_CPA_END: 'Series cuối mã CPA',
            SIPCO_CPA_COLOR: 'Phân màu',
            SIPCO_CPA_PROVINCE: 'Tỉnh thành',
            SIPCO_CPA_DESCRIPTION:'Mô tả',
            STATUS:'Trạng thái',
            CREATED_DATE: 'Ngày tạo',
            CREATED_BY: 'Người tạo',
            UPDATED_DATE: 'Ngày cập nhật',
            UPDATED_BY: 'Người cập nhật',
            SEARCH_INFOR:'Thông tin tìm kiếm',
            SAVE: 'Lưu',
            SAVEANDCLOSE:'Lưu và đóng',
            SAVEANDNEXT: 'Lưu và tiếp tục',
            CANCEL:'Hủy',
            CLOSE:'Đóng',
            SEARCH:'Tìm kiếm',
            NEW: 'Thêm mới',
            VIEW: 'Xem',
            EDIT: 'Chỉnh sửa',
            DELETE: 'Xóa',
            COPY:'Sao chép',
            IMPORT:'Nhập dữ liệu',
            DOWNLOADDATA:'Tải dữ liệu (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import mẫu',
            SIPCO_CPA_DATA_FILE: 'File data SIPCO_CPA',
            END_DATE: 'Ngày kết thúc cấp phát',
            START_DATE: 'Ngày bắt đầu cấp phát',
            ERROR_IMPORT_ROW: 'sản phẩm import không thành công.',
            SUCCESS_IMPORT_ROW: 'sản phẩm import thành công.',
            DOCUMENT_UPLOAD: 'Tải dữ liệu',
            ADDDOCUMENT: 'Thêm tài liệu'

        },
    'en':
        {
            PAGE_TITLE: 'CPA management',
            SIPCO_CPA_SCREEN_SEARCH: 'CPA Management',
            SIPCO_CPA_SCREEN_NEW: 'Add new CPA information',
            SIPCO_CPA_SCREEN_COPY: 'Copy CPA information',
            SIPCO_CPA_SCREEN_EDIT: 'Edit CPA information',
            SIPCO_CPA_SCREEN_VIEW: 'View CPA information',
            SIPCO_CPA_SCREEN_TITLE_GENERAL: 'General  information',
            SIPCO_CPA_SCREEN_TITLE_ADDITIONAL: 'Additional information',
            SIPCO_CPA_CODE: 'CPA code',
            SIPCO_CPA_DESCRIPTION:'Description',
            STATUS:'Status',
            SIPCO_CPA_PROVINCE: 'Province',
            SIPCO_CPA_START: 'CPA start',
            SIPCO_CPA_COLOR: 'CPA color',
            SIPCO_CPA_END: 'CPA end',
            CREATED_DATE: 'Created date',
            CREATED_BY: 'Created by',
            UPDATED_DATE: 'Updated date',
            UPDATED_BY: 'Updated by',
            SEARCH_INFOR:'Search Information',
            SAVE: 'Save',
            SAVEANDCLOSE:'Save and close',
            SAVEANDNEXT: 'Save and continue',
            CANCEL:'Cancel',
            CLOSE:'Close',
            SEARCH:'Search',
            NEW: 'New',
            EDIT: 'Edit',
            VIEW: 'View',
            DELETE: 'Delete',
            COPY:'Copy',
            IMPORT:'Import data',
            DOWNLOADDATA:'Download Data (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import example',
            SIPCO_CPA_DATA_FILE: 'File data SIPCO_CPA',
            START_DATE: 'Distribution start date',
            END_DATE: 'Distribution end date',
            ERROR_IMPORT_ROW: 'row error.',
            SUCCESS_IMPORT_ROW: 'row success.',
            DOCUMENT_UPLOAD: 'Upload document ',
            ADDDOCUMENT: 'Additional document'

        }
    };

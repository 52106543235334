const Mps_ControlModuleAvailable = {
    ModuleNameT_ScreenNameT: true,
    // true: hiển thị ; false: không hiển thị
    User: true,
    Employee: true,
    Products: true,
    ProductCategory: true,
    ProductPrice: true,
    ProductUnit: true,
    ProductGroup: true,
    Partner: true,
    Partner_ContactHistory: true,
    Partner_PointSetting: true,
    Partner_RewardPoint: true,
    Hr_Branch: true,
    PurchaseOrders: true,
    Contract_Article: true,
    ContractTemplate: true,
    Contract: true,
    InventoryWarehouse: true,
    RoomAndTable: true,
    Sales_RestaurantInvoice: true,
    Project_Roles: true,
    Project_Tasks: true,
    Project_Manager: true,
    Project_TimeSheet: true,
    Contract_DocumentTemplate: true,
    Document_Content:true,
    Document_Content_Group:true,
    Project_Calendar: true,
    Approve_Manager: true,
    Todo_Template:true,
    Todo:true,
    Education: true,
    Project_Gantt: true,
    MeetingRoom:true,
    MeetingRoom_Category:true,
    MeetingRoom_Floor:true,
    MeetingRoom_Device:true,
    MeetingRoom_Device_Category:true,
    Hr_LeaveApplication:true,
    Hr_OverTime:true,
    Hr_WorkSchedule:true,
    BusinessTrip: true,
    Warranty: true,
    Warranty_Manager: true,
    Company: true,
    Recruit_JobDescription: true,
    Recruit_PostManager: true,
    Recruit_Candidate: true,
    Recruit_InterviewResult: true,
    Wiki: true,
    Sipco_WaterFilterArea:true
};

module.exports = {
    Mps_ControlModuleAvailable
};

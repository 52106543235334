import { Button, Col, Layout, Modal, Row, Spin } from 'antd';
import jsonQuery from 'json-query';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Mps_HeaderMaster, Mps_SubMenu, Mps_Footer, Mps_Breadcum } from '../../authenticate/Layout';
//Khai báo common component
import { menuService } from '../../authenticate/Services';
import {
    MESSAGE_STATE,
    MPS_MESSAGE_STATE, Mps_MethodCommon, MPS_MODE, MPS_SCREEN_NO, MPS_SCREEN_ACTION, MPS_RESULTS
} from '../../commons';
import {
    getPageSize, Mps_LabelFormat, Mps_ErrorPermission,
    Mps_Download, Mps_ImportExcel, Mps_ResultTable, Mps_DisplayColumnResultTable
} from '../Mps_Control';
//Khai báo thêm component trên page
import { LANGUAGE_STATE } from './Language/ln';
import {
    initResultTable, getDisplaySortFields, setDisplaySortFields
    , getDisplaySearchConditions, setDisplaySearchConditions, getDisplayColumnSearchResults
    , setDisplayColumnSearchResults, searchModel, headers, headerExample
} from './Models/Sipco_WaterFilterHistory.Model';
import { Sipco_WaterFilterHistorySearch } from './Sipco_WaterFilterHistorySearch';
import { Sipco_WaterFilterHistoryView } from './Sipco_WaterFilterHistoryView';
import { Sipco_WaterFilterHistory_Service } from './Service/Sipco_WaterFilterHistory.Service';
//import { Log_Service } from '../../authenticate/Helpers/Log.Service';
const screenNo = MPS_SCREEN_NO.Sipco_WaterFilterHistory;
let permission = []
const confirm = Modal.confirm;
export default class Sipco_WaterFilterHistoryList extends Component {
    /**
    * @desc Hàm khởi tạo component
    * @param
    *   props - properties của component.
    * @return Nothing
    */
    constructor(props) {

        super(props);
        const chooseLanguage = menuService.getLanguage();
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
        const messageValid = jsonQuery([chooseLanguage[0]], { data: MESSAGE_STATE }).value;
        const commonMessage = jsonQuery([chooseLanguage[0]], { data: MPS_MESSAGE_STATE }).value;
        document.title = language.PAGE_TITLE;
        this.state = {
            chooseLanguage,
            language,
            messageValid,
            commonMessage,
            searchModel,
            dataSource: [],
            selectedModel: [],
            searchOptions: {
                page: 1,
                limit: getPageSize(),
                language: chooseLanguage,
                sort: getDisplaySortFields(),
                sortAscending: true
            },
            importModel: [],
            visible: false,
            mode: '',
            isLoading: false,
            alertMesage: {
                show: false,
                message: '',
                description: ''
            }
        };
    }

    //#region Handle event (Search, add, copy, edit, view, delete)
    /**
    * @desc Handle sự kiện tìm kiếm dữ liệu
    * @param
    * @return Nothing (Hiển thị dữ liệu được lọc theo điều kiện tìm kiếm),
    */
     componentWillMount() {
        permission = menuService.showPermission(screenNo);
    }
    handleSearchAfterAction = () => {
        this.setState({
            isLoading: true
        });
        var searchObjectModel = this.state.searchModel;
        searchObjectModel.Provide_Date = Mps_MethodCommon.handleChangeDate(searchObjectModel.Provide_Date)
        searchObjectModel.CreatedDate = Mps_MethodCommon.handleChangeDate (searchObjectModel.CreatedDate)
        searchObjectModel.UpdatedDate = Mps_MethodCommon.handleChangeDate (searchObjectModel.UpdatedDate)
        var searchOptions = this.state.searchOptions;
        searchOptions.page = 1
        Promise.all([Sipco_WaterFilterHistory_Service.search(searchObjectModel, searchOptions)]).then((result) => {
            var data = jsonQuery('result', { data: result }).value;
            /*demo mẫu logger*/
            //Log_Service.create("Search", data);
            this.setState({
                dataSource: data,
                visible: false,
                isLoading: false,
                selectedModel: []
            });
            const tempdata = [...data]
            const data1 = JSON.parse(JSON.stringify(tempdata));
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
            this.handleDownloadExcel (tempDataForDownload)
            const dataForDownload = [{
                docs: tempDataForDownload
            }];
            this.setState({
                dataForDownload: dataForDownload,
            });
        }).catch(reason => {
            Mps_MethodCommon.openNotification('error', this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(reason.message, this.state.chooseLanguage));
            this.setState({
                isLoading: false,

            });
        });
    };

    handleDownloadExcel (data) {
        data.forEach(d => {
            d.Serial_Code = (!!d.Serial_Code ? (`'` + d.Serial_Code) : '');
            d.Owner = (!!d.Owner ? (`'` + d.Owner) : '');
            d.Phone = (!!d.Phone ? (`'` + d.Phone) : '');
            d.Province = (!!d.Province ? (`'` + d.Province) : '');
            d.District = (!!d.District ? (`'` + d.District) : '');
            d.Ward = (!!d.Ward ? (`'` + d.Ward) : '');
            d.Sipco_WaterFilter_Description = (!!d.Sipco_WaterFilter_Description ? (`'` + d.Sipco_WaterFilter_Description) : '');
            d.Provide_Date = !Mps_LabelFormat.formatDate(d.Provide_Date) ? '' : (`'` + Mps_LabelFormat.formatDate(d.Provide_Date));
            d.UpdatedBy = (!!d.UpdatedByObject ? d.UpdatedByObject : '');
            d.UpdatedDate = !Mps_LabelFormat.formatDate(d.UpdatedDate) ? '' : (`'` + Mps_LabelFormat.formatDate(d.UpdatedDate));
            d.CreatedBy = (!!d.CreatedByObject ? d.CreatedByObject : '');
            d.CreatedDate = `'` + Mps_LabelFormat.formatDate(d.CreatedDate);
            d.Status = (!!d.StatusObject ? d.StatusObject : '');
        });
        return data
    }

    convertToDownload = (data) => {
        if (!!data && data.length > 0) {
            data.forEach(d => {
                d.Serial_Code = (!!d.Serial_Code ? (`'` + d.Serial_Code) : '');
                d.Owner = (!!d.Owner ? (`'` + d.Owner) : '');
                d.Phone = (!!d.Phone ? (`'` + d.Phone) : '');
                d.Province = (!!d.Province ? (`'` + d.Province) : '');
                d.District = (!!d.District ? (`'` + d.District) : '');
                d.Ward = (!!d.Ward ? (`'` + d.Ward) : '');
                d.Sipco_WaterFilter_Description = (!!d.Sipco_WaterFilter_Description ? (`'` + d.Sipco_WaterFilter_Description) : '');
                d.Provide_Date = !Mps_LabelFormat.formatDate(d.Provide_Date) ? '' : (`'` + Mps_LabelFormat.formatDate(d.Provide_Date));
                d.UpdatedBy = (!!d.UpdatedByObject ? d.UpdatedByObject : '');
                d.UpdatedDate = !Mps_LabelFormat.formatDate(d.UpdatedDate) ? '' : (`'` + Mps_LabelFormat.formatDate(d.UpdatedDate));
                d.CreatedBy = (!!d.CreatedByObject ? d.CreatedByObject : '');
                d.CreatedDate = `'` + Mps_LabelFormat.formatDate(d.CreatedDate);
                d.Status = (!!d.StatusObject ? d.StatusObject : '');
            });
            const dataForDownload = [{
                docs: data
            }];
            this.setState({ dataForDownload });
        }
        // else {
        //     const tempData = JSON.parse(JSON.stringify(this.state.dataSource));
        //     const tempDataForDownload = jsonQuery('[**]docs[**]', { data: tempData }).value;
        //     this.convertToDownload(tempDataForDownload);
        // }
    };
    /**
    * @desc Handle sự kiện xóa record trong table.
    * @param
    *   array selectedModel: Danh sách record cần xóa.
    * @return Kết quả thành công/thất bại.
    */
    handleDelete = () => {
        if (this.state.selectedModel.length === 0) {
            Mps_MethodCommon.handleShowConfirm(this.state.commonMessage.CHOOSE_DATA_DELETE, '');
        } else {
            const listDelete = jsonQuery('[_id]', { data: this.state.selectedModel }).value;
            this.handleShowConfirmDelete(this.state.commonMessage.DELETE_WARNING, '', listDelete, this.handleSearchAfterAction, this.handleDeleteResult);
        }
    };

    /**
    * @desc Handle sự kiện hiển thị thông báo
    * @param
    *   String message: tiêu đề thông báo.
    * @param
    *   String content: nội dung chi tiết câu thông báo
    * @return Nothing
    */
    handleShowConfirmDelete = (message, content, listDelete, handleSearchAfterAction, handleDeleteResult) => {
        confirm({
            content,
            title: message,
            onOk() {
                return Promise.all([Sipco_WaterFilterHistory_Service.deleteModel(listDelete)]).then((result) => {
                    if (result[0].returnCode === MPS_RESULTS.SUCCESS) {
                        handleDeleteResult(true);
                        handleSearchAfterAction();
                    } else {
                        handleDeleteResult(false);
                        handleSearchAfterAction();
                    }
                });
            },
            onCancel() {
                //
            },
        });
    };
    handleDeleteResult = result => {
        if (result === true) {
            Mps_MethodCommon.openSuccessNotification(this.state.commonMessage.INFORM_TITLE, this.state.commonMessage.DATA_DELETE_SUCCESS)
            this.setState({ selectedModel: [] });
        } else {
            Mps_MethodCommon.openErrorNotification(this.state.commonMessage.ERROR_TITLE, this.state.commonMessage.DATA_DELETE_FAILED)
            this.setState({ selectedModel: [] });
        }
    };
    /**
    * @desc Handle sự kiện xóa import dữ liệu.
    * @param
    *   object value: Data được import.
    * @return Kết quả import thành công/thất bại.
    */
    handleImportChange = (value) => {
        /*demo mẫu logger*/
        //Log_Service.create("Import", value);
        this.setState({
            importModel: value
        });
        Promise.all([Sipco_WaterFilterHistory_Service.importModel(value)]).then((result) => {
            if (!!result && result[0].returnCode === MPS_RESULTS.SUCCESS) {
                Mps_MethodCommon.openSuccessNotification(this.state.commonMessage.INFORM_TITLE, Mps_MethodCommon.returnMessageApi(result[0].returnCode, this.state.chooseLanguage))
                this.handleSearchAfterAction();
            } else {
                Mps_MethodCommon.openErrorNotification(this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(result[0].returnCode, this.state.chooseLanguage))
            }

        });

    };

    //#endregion

    //#region functions xử lý event trên componet Mps_ResultTable
    /**
    * @desc Handle sự kiện chọn record trên table.
    * @param
    *   Object selectedRow: records được chọn trên table.
    * @return Nothing (Cập nhật giá trị mới cho state selectedModel).
    */
    handleSelectRow = selectedRow => {
        const data = JSON.parse(JSON.stringify(selectedRow));
        this.convertToDownload(data);
        this.setState({ 
            detailModel: selectedRow[0],
            selectedModel: selectedRow 
        });
    };

    /**
    * @desc Handle sự kiện page thay đổi.
    * @param
    *   Number page: trang thay đổi.
    *   Number pageSizes: Số record trên mỗi page
    * @return Nothing (Cập nhật giá trị mới cho state searchOptions, và hiển thị data trên table theo điều kiện tìm kiếm).
    */
    handlePagination = (page, pageSizes) => {
        const model = this.state.searchModel;
        const searchOptions = this.state.searchOptions;
        searchOptions.page = page;
        searchOptions.limit = pageSizes;
        /*demo mẫu logger*/
        //Log_Service.create("searchOptions", searchOptions);
        this.setState({
            searchOptions,
            isLoading: true
        });
        Promise.all([Sipco_WaterFilterHistory_Service.search(model, searchOptions)]).then((result) => {
            var data = jsonQuery('result', { data: result }).value;
            /*demo mẫu logger*/
            //Log_Service.create("search", data);
            this.setState({
                dataSource: data,
                visible: false,
                isLoading: false,
            });
            const tempdata = [...data]
            const data1 = JSON.parse(JSON.stringify(tempdata));
            const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
            this.handleDownloadExcel (tempDataForDownload)
            const dataForDownload = [{
                docs: tempDataForDownload
            }];
            this.setState({
                dataForDownload: dataForDownload,
            }); 
        });
    };

    /**
    * @desc Handle sự kiện thay đổi thiết lập số records hiển thị trên table.
    * @param
    *   Number currentPage: trang hiện tại.
    *   Number pageSizes: Số record sẽ hiển thị trên mỗi page
    * @return Nothing (Cập nhật pageSizes hiển thị theo thiết lập mới),
    */
    handleShowSizeChange = (currentPage, pageSizes) => {
        const searchOptions = this.state.searchOptions;
        searchOptions.page = currentPage;
        searchOptions.limit = pageSizes;
        /*demo mẫu logger*/
        //Log_Service.create("handleShowSizeChange", searchOptions);
        this.setState({
            searchOptions,
        });
        this.handleSearchAfterAction();
        const data = this.state.dataSource
        const tempdata = [...data]
        const data1 = JSON.parse(JSON.stringify(tempdata));
        const tempDataForDownload = jsonQuery('[**]docs[**]', { data: data1 }).value;
        this.handleDownloadExcel (tempDataForDownload)
        const dataForDownload = [{
            docs: tempDataForDownload
        }];
        this.setState({
            dataForDownload: dataForDownload,
        }); 
    };

    //#endregion

    //#region Handle sự kiện component Mps_DisplayColumnResultTable
    /**
    * @desc Handle sự kiện component Search thay đổi giá trị
    * @param
    *   Object value: Model điều kiện tìm kiếm.
    * @return Nothing (value State được cập nhật giá trị mới)
    */
    handleSearchChange = value => {
        this.setState({
            searchModel: value
        });

    }
    /**
       * @desc Handle thiết lập hiển thị Field điều kiện tìm kiếm.
       * @param
       *   array value: Danh sách Field được chọn hiển thị .
       * @return Nothing (Cập nhật setting),
       */
    handleDisplaySearchConditionsChange = value => {
        setDisplaySearchConditions(value);
        this.setState({
            displaySearchConditions: value
        });
    };

    //Handle thiết lập sắp xếp hiển thị kết quả tìm kiếm
    /**
    * @desc Handle thiết lập sắp xếp hiển thị record trên table kết quả tìm kiếm.
    * @param
    *   array value: Danh sách Field được chọn sort.
    * @return Nothing (Cập nhật setting),
    */
    handleDisplaySortFieldsChange = value => {
        setDisplaySortFields(value);
        const searchOptions = this.state.searchOptions;
        searchOptions.sort = value;
        this.setState({
            displaySortFields: value,
            searchOptions
        });
    };

    /**
    * @desc Handle thiết lập sắp xếp hiển thị record trên table kết quả tìm kiếm.
    * @param
    *   array value: Danh sách Field được chọn hiển thị trên table.
    * @return Nothing (Cập nhật setting),
    */
    handleDisplayColumnSearchResultsChange = value => {
        setDisplayColumnSearchResults(value);
        this.setState({
            displayColumnSearchResults: value
        });
    };

    /**
    * @desc Handle thiết lập kết quả hiển thị sắp xếp theo bảng chữ cái.
    * @param
    *   bool value: kiểu sắp xếp.
    * @return Nothing (Cập nhật setting: true (sắp xếp tăng), false: sắp xếp giảm),
    */
    handleSortTypeChange = value => {
        const searchOptions = this.state.searchOptions;
        searchOptions.sortAscending = value;
        this.setState({
            sortAscending: value,
            searchOptions
        });
    };
    //#endregion

    render() {
        const { Content } = Layout;
        const { language } = this.state;
        //#region kiểm tra quyền truy cập
        const actionView = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.View));
        //Hiển thị thông báo Nếu ko có quyền truy cập.
        if (!actionView) {
            //Hiển thị thông báo Nếu ko có quyền truy cập.
            return <Redirect to='/login' />;
        }
        const actionCreate = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.Create));
        const actionUpdate = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.Update));
        const actionDelete = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.Delete));
        const actionExport = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.Export));
        const actionImport = (!!permission.find(m => m.System_Permission_Action === MPS_SCREEN_ACTION.Import));
        //#region giá trị khởi tạo cho component Mps_DisplayColumnResultTable, Mps_ResultTable
        const displaySearchConditions = getDisplaySearchConditions(this.state.displaySearchConditions);
        const displaySortFields = getDisplaySortFields(displaySortFields);
        const displayColumnSearchResults = getDisplayColumnSearchResults(this.state.displayColumnSearchResults);
        const columnResultTable = initResultTable(language, displayColumnSearchResults);
        //#endregion

        const headersExport = headers(columnResultTable);
        const headerExportExample = headerExample(columnResultTable);

        return (
            <Layout>
                <Mps_HeaderMaster language={this.state.chooseLanguage} />
                <Layout>
                    <Mps_SubMenu module="CPATransaction" language={this.state.chooseLanguage} />
                    <Layout className='mps-content'>
                        <Mps_Breadcum module="CPATransaction" screen={language.SIPCO_WATERFILTERHISTORY_SEARCH} language={this.state.chooseLanguage} />
                        <Content className='mps-sub-content' >
                            <Spin spinning={this.state.isLoading} delay={500}>
                                <Sipco_WaterFilterHistorySearch
                                    value={this.state.searchModel}
                                    displaySearchConditions={displaySearchConditions}
                                    language={this.state.chooseLanguage}
                                    onChange={this.handleSearchChange} />

                                <Row>
                                    <Col>
                                        <div className='button-area'>
                                            {(actionView) ? <Button type="primary" onClick={this.handleSearchAfterAction}>{language.SEARCH}</Button> : ''}

                                         

                                            {(actionView) ? <Sipco_WaterFilterHistoryView allowAccess={actionView} actionName={language.VIEW} mode={MPS_MODE.ReadOnly}
                                                onhandleSave={this.handleSearchAfterAction} language={this.state.chooseLanguage}
                                                value={this.state.selectedModel} /> : ''}
                                         
                                          

                                            {(actionExport) ? <Mps_Download value={this.state.dataForDownload}
                                                language={language.DOWNLOADDATA} name={language.SIPCO_WATERFILTERHISTORY_DATA_FILE}
                                                headers={headersExport} /> : ''}

                                            <Mps_DisplayColumnResultTable columns={columnResultTable}
                                                onDisplaySearchConditionsChange={this.handleDisplaySearchConditionsChange}
                                                onDisplaySortFieldsChange={this.handleDisplaySortFieldsChange}
                                                onDisplayColumnSearchResultsChange={this.handleDisplayColumnSearchResultsChange}
                                                onSortTypeChange={this.handleSortTypeChange}
                                                displaySearchConditions={displaySearchConditions}
                                                displaySortFields={displaySortFields}
                                                displayColumnSearchResults={displayColumnSearchResults}
                                                sortAscending={this.state.searchOptions.sortAscending}
                                                language={this.state.chooseLanguage} />
                                        </div>
                                    </Col>
                                </Row>
                                <Mps_ResultTable onChange={this.handleSelectRow}
                                    onPagination={this.handlePagination}
                                    current={this.state.searchOptions.page}
                                    onShowSizeChange={this.handleShowSizeChange}
                                    messageValid={this.state.commonMessage}
                                    columns={columnResultTable}
                                    dataSource={this.state.dataSource} />
                            </Spin>
                        </Content>
                    </Layout>
                </Layout>
                <Mps_Footer />
            </Layout>
        );
    }
}

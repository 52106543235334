import React from 'react';
import { Mps_LabelFormat } from '../Mps_LabelFormat/Mps_LabelFormat';
import { Mps_Tooltip } from '../Mps_Tooltip/Mps_Tooltip';
import moment from 'moment';
import {
    TimePicker, Form
} from 'antd';

class Mps_InputTimePicker extends React.Component {

    constructor(props) {
        super(props);
    }

    onChange = value => {
        this.setState({
            value
        });
        this.props.onChange(value);
    };

    getFieldDecoratorName = name => {
        if (!!name) {
            return name;
        } else {
            return '';
        }
    };

    getClassName = className => {
        if (!!className) {
            return `${className} mps-ant-calendar-picker`;
        } else {
            return 'mps-ant-calendar-picker';
        }
    };

    getDisabled = disabled => {
        if (!!disabled) {
            return disabled;
        } else {
            return false;
        }
    };

    render() {
        const style = { textAlign: 'center', width: '100%' };
        const disabled = this.getDisabled(this.props.disabled);
        const className = this.getClassName(this.props.className);
        const getFieldDecoratorName = this.getFieldDecoratorName(this.props.name);
        return (
            <Form.Item
                help={
                    (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
                }
                hasFeedback
            >
                {(!!this.props.getFieldDecorator) ?
                    (this.props.getFieldDecorator(getFieldDecoratorName, {
                        initialValue: (!!this.props.value) ? moment(this.props.value, Mps_LabelFormat.displayTimeHourFormat) : null,
                        rules: [
                            {
                                required: (!!this.props.required) ? this.props.required : false,
                                message: this.props.message,
                            },
                        ],
                    })(
                        <TimePicker
                            style={style}
                            className={className} disabled={disabled}
                            onChange={this.onChange}
                            format={(!!this.props.format) ? this.props.format : Mps_LabelFormat.displayTimeHourFormat} />
                    )) :
                    (
                        <TimePicker
                            style={style}
                            className={className} disabled={disabled}
                            value={(!!this.props.value) ? moment(this.props.value, (!!this.props.format) ? this.props.format : Mps_LabelFormat.displayTimeHourFormat) : null}
                            onChange={this.onChange}
                            format={(!!this.props.format) ? this.props.format : Mps_LabelFormat.displayTimeHourFormat} />
                    )
                }
            </Form.Item>
        );
    }
}
export { Mps_InputTimePicker };

export const LANGUAGE_STATE ={
    'vn':
        {
            PAGE_TITLE: 'Quản lý bình lọc nước',
            SIPCO_WATERFILTERHISTORY_SEARCH: 'Lịch sử thay lõi lọc',
            SIPCO_WATERFILTERHISTORY_NEW: 'Thêm mới bình lọc nước',
            SIPCO_WATERFILTERHISTORY_COPY: 'Sao chép thông tin bình lọc nước',
            SIPCO_WATERFILTERHISTORY_EDIT: 'Chỉnh sửa thông tin bình lọc nước',
            SIPCO_WATERFILTERHISTORY_VIEW: 'Xem thông tin lịch sử thay lõi lọc',
            SIPCO_WATERFILTERHISTORY_TITLE_GENERAL: 'Thông tin chung',
            SIPCO_WATERFILTERHISTORY_TITLE_ADDITIONAL: 'Thông tin thêm',
            SERIAL_CODE: 'Mã số bình lọc nước',
            OWNER:'Tên hộ nhận BLN',
            ADDRESS: 'Địa chỉ',
            PHONE:'Số điện thoại',
            PROVIDE_DATE:'Ngày cấp',
            DOCUMENT_UPLOAD:'Tài liệu',
            SIPCO_WATERFILTERHISTORY_DESCRIPTION:'Mô tả',
            STATUS:'Trạng thái',
            CREATED_DATE: 'Ngày tạo',
            CREATED_BY: 'Người tạo',
            UPDATED_DATE: 'Ngày cập nhật',
            UPDATED_BY: 'Người cập nhật',
            SEARCH_INFOR:'Thông tin tìm kiếm',
            SAVE: 'Lưu',
            SAVEANDCLOSE:'Lưu và đóng',
            SAVEANDNEXT: 'Lưu và tiếp tục',
            CANCEL:'Hủy',
            CLOSE:'Đóng',
            SEARCH:'Tìm kiếm',
            NEW: 'Thêm mới',
            VIEW: 'Xem',
            EDIT: 'Chỉnh sửa',
            DELETE: 'Xóa',
            COPY:'Sao chép',
            IMPORT:'Nhập dữ liệu',
            DOWNLOADDATA:'Tải dữ liệu (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import mẫu',
            SIPCO_WATERFILTERHISTORY_DATA_FILE: 'File data SIPCO_WATERFILTERHISTORY',
            PROVINCE: 'Tỉnh',
            DISTRICT: 'Huyện',
            WARD: 'Xã'
        },
    'en':
        {
            PAGE_TITLE: 'Household History management',
            SIPCO_WATERFILTERHISTORY_SEARCH: 'Household History',
            SIPCO_WATERFILTERHISTORY_NEW: 'Add new Household information',
            SIPCO_WATERFILTERHISTORY_COPY: 'Add Household information',
            SIPCO_WATERFILTERHISTORY_EDIT: 'Edit Household information',
            SIPCO_WATERFILTERHISTORY_VIEW: 'View Household information',
            SIPCO_WATERFILTERHISTORY_TITLE_GENERAL: 'General  information',
            SIPCO_WATERFILTERHISTORY_TITLE_ADDITIONAL: 'Additional information',
            SERIAL_CODE: 'Household',
            OWNER:'Name of Household',
            ADDRESS: 'Address',
            PHONE:'Phone',
            PROVIDE_DATE:'Distribution date',
            DOCUMENT_UPLOAD:'Document Upload',
            SIPCO_WATERFILTERHISTORY_DESCRIPTION:'Description',
            STATUS:'Status',
            CREATED_DATE: 'Created date',
            CREATED_BY: 'Created by',
            UPDATED_DATE: 'Updated date',
            UPDATED_BY: 'Updated by',
            SEARCH_INFOR:'Search Information',
            SAVE: 'Save',
            SAVEANDCLOSE:'Save and close',
            SAVEANDNEXT: 'Save and continue',
            CANCEL:'Cancel',
            CLOSE:'Close',
            SEARCH:'Search',
            NEW: 'New',
            EDIT: 'Edit',
            VIEW: 'View',
            DELETE: 'Delete',
            COPY:'Copy',
            IMPORT:'Import data',
            DOWNLOADDATA:'Download Data (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import example',
            SIPCO_WATERFILTERHISTORY_DATA_FILE: 'File data SIPCO_WATERFILTERHISTORY',
            PROVINCE: 'Province',
            DISTRICT: 'District',
            WARD: 'Ward'
            
        }
    };

import React from 'react';

import SIPCO_CPAList from '../../../components/SIPCO_CPA/SIPCO_CPAList';
import Sipco_WaterFilterList from '../../../components/Sipco_WaterFilter/Sipco_WaterFilterList';
import Sipco_WaterFilterHistoryList from '../../../components/Sipco_WaterFilter_History/Sipco_WaterFilterHistoryList';
import Sipco_ParameterList from '../../../components/Sipco_Parameter/Sipco_ParameterList';
import Sipco_UserFeedbackList from '../../../components/Sipco_UserFeedback/Sipco_UserFeedbackList';
import { Notifical } from '../../../components/Notifical/Notifical';
export const sipcoroutes = [
    { path: '/CPAManagement', component: SIPCO_CPAList },
    { path: '/CPA', component: SIPCO_CPAList },
    
    { path: '/CPATransaction', component: Sipco_WaterFilterList },
    { path: '/WaterFilter', component: Sipco_WaterFilterList },
    { path: '/WaterFilterHistory', component: Sipco_WaterFilterHistoryList },
    { path: '/AlertSetting', component: Sipco_ParameterList },
    { path: '/UserFeedback', component: Sipco_UserFeedbackList },
    { path: '/Notify', component: Notifical}
]
export const LANGUAGE_STATE ={
    'vn':
        {
            CAPACITY_EXCEEDED: 'Kích thước tệp vượt quá giới hạn cho phép và không thể lưu được.'
        },
    'en':
        {
            CAPACITY_EXCEEDED: 'The file size exceeds the limit allowed and cannot be saved.'

        }
    };
import React from 'react';
import {
    Form, Input, Button, Tabs, Row, Col, Modal
    , Card
} from 'antd';
import jsonQuery from 'json-query';
import {
    MPS_MESSAGE_STATE, MPS_RESULTS
    , MPS_MODE
    , MPS_SYSTEM_PARAMETER_TYPE
    , MPS_SYSTEM_PARAMETER
    , Mps_MethodCommon,
    MPS_DISPLAY_AREA_STORAGE
} from '../../commons';
import { authenticationService } from '../../authenticate/Services';
//Import MPS common control here...
import {
    Mps_LayoutFormat
    , Mps_SelectParameterSystem, Mps_InputDatePicker
    , Mps_Tooltip, Mps_DisplayColumnResultTable, Mps_GenerateField
} from '../Mps_Control';
import { System_User_Select } from '../System_User/Controls/System_User_Select';
import { Sipco_UserFeedback_Service } from './Service/Sipco_UserFeedback.Service';
import { LANGUAGE_STATE } from './Language/ln';
import { GUIDE_LINE } from './Language/Guideline';
import {
    initModel, getDisplayFieldOnEditForm, setDisplayFieldOnEditForm
    , initFieldControl, initFielDisplayOnEditForm
} from './Models/Sipco_UserFeedback.Model';
import moment from 'moment';
//import { Log_Service } from '../../authenticate/Helpers/Log.Service';

const { TabPane } = Tabs;

class Sipco_UserFeedbackAdd extends React.Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([this.props.language[0]], { data: LANGUAGE_STATE }).value;
        const commonMessage = jsonQuery([this.props.language[0]], { data: MPS_MESSAGE_STATE }).value;
        const guideLine = jsonQuery([this.props.language[0]], { data: GUIDE_LINE }).value;
        this.state = {
            language,
            commonMessage,
            guideLine,
            initModel,
            chooseLanguage,
            visible: false,
            
        };
    }
    /**
     * @desc Khởi tạo data khi load component
     * @param Nothing
     * @return Nothing
    */
    componentWillMount() {
        const selectedModel = this.props.value;
        //Xử lí thêm field
        this.setState({
            selectedModel,
        });
        
    }
    /**
    * @desc Handle thiết lập sắp xếp hiển thị field trên form.
    * @param
    *   array value: Danh sách Field được chọn hiển thị trên form.
    * @return Nothing (Cập nhật setting),
    */
    handleDisplayFieldOnEditFormChange = value => {
        setDisplayFieldOnEditForm(value);
        this.setState({
            displayDefaultFieldOnEditForm: value
        });
    };

    /**
     * @desc Handle sự kiện hiển thị màn hình View/Add/Copy/Edit
     * @param event
     *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
     *   object data - dữ liệu hiển thị
     * @return Nothing
     */
    handleShowForm = e => {
        e.preventDefault();
        const mode = this.props.mode;
        let selectedModel = {};
        const userInfo = authenticationService.getUserInfor();
        const user = jsonQuery('[**]User[0]', { data: userInfo }).value;
        const userId = user._id;

        if (mode === MPS_MODE.Create) {
            selectedModel = { ...initModel };
            selectedModel.Status = MPS_SYSTEM_PARAMETER.StatusFeedback.New;
            selectedModel.CreatedBy = userId;
            this.setState({
                selectedModel,
                mode,
                visible: true,
            });
        } else {
            selectedModel = this.props.value;
            if (selectedModel.length === 0) {
                Mps_MethodCommon.openWarningNotification(this.state.commonMessage.CHOOSE_DATA_COPY, '');
                return;
            } else if (selectedModel.length > 1) {
                Mps_MethodCommon.openWarningNotification(this.state.commonMessage.CHOOSE_ONLY_DATA_COPY, '');
                return;
            } else {
                Promise.all([Sipco_UserFeedback_Service.getById(selectedModel[0]._id)]).then(result => {
                    const data = jsonQuery('result[0]', { data: result }).value;
                    data.CreatedBy = userId;
                    this.setState({
                        selectedModel: data,
                        visible: true,
                    });
                });
            }
        }
    };

    /**
    * @desc Handle sự kiện xác nhận lưu thông tin
    * @param event
    *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
    *   object data - dữ liệu hiển thị
    * @return bool - kết quả: true = thành công/ false = thất bại
    */
    handleSave = e => {
        e.preventDefault();
        this.handleOk('Save');
    };

    handleSaveNext = e => {
        e.preventDefault();
        this.handleOk('SaveNext');
    }

    handleOk = action => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const objectModel = {
                    _id: values._id,
                    Serial_Code: values.Serial_Code.toUpperCase(),
                    Source: values.Source,
                    Content: values.Content,
                    Description: values.Description,
                    Status: this.state.selectedModel.Status,
                    CreatedDate: Date.now(), 
                    UpdatedDate: ''
                }
                /*demo mẫu logger*/
                //Log_Service.create("handleOk", objectModel);
                Promise.all([Sipco_UserFeedback_Service.create(objectModel)]).then((result) => {
                    if (!!result && result[0].returnCode === MPS_RESULTS.SUCCESS) {
                        Mps_MethodCommon.openSuccessNotification(this.state.commonMessage.INFORM_TITLE, Mps_MethodCommon.returnMessageApi(result[0].returnCode, this.state.chooseLanguage));

                        if (action === 'Save') {
                            this.setState({
                                selectedModel: { ...initModel },
                                visible: false
                            });
                            this.handleReset();
                            this.props.onhandleSave();
                        } else {
                            this.handleReset();
                            const userInfo = authenticationService.getUserInfor();
                            const user = jsonQuery('[**]User[0]', { data: userInfo }).value;

                            const newInitModel = { ...initModel };
                            newInitModel.Status = this.state.selectedModel.Status;
                            newInitModel.CreatedBy = user._id;
                            this.setState({ selectedModel: { ...newInitModel }, });
                            this.props.onhandleSave();
                        }

                    } else {
                        Mps_MethodCommon.openErrorNotification(this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(result[0].returnCode, this.state.chooseLanguage))
                    }
                    /*demo mẫu logger*/
                    //Log_Service.create("handleOk", result);

                });
            }
        });
    }

    /**
    * @desc Handle sự kiện tắt màn hình
    * @param event
    *   Nothing
    * @return Nothing
    */
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    /**
    * @desc Handle sự kiện reset thông tin trên lưới
    * @param event
    *   Nothing
    * @return Nothing
    */
    handleReset = () => {
        this.props.form.resetFields();
    };

    /**
    * @desc Handle sự kiện trường thông tin trạng thái thay đổi giá trị.
    * @param event
    *   string value: giá trị mới
    * @return Nothing
    */
    handleStatusChange = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.Status = value;
        this.setState({
            selectedModel
        });
    };

    handleSourceChange = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.Source = value;
        this.setState({
            selectedModel
        });
    };
    /**
   * @desc Handle tùy chọn hiển thị
   * @param
   *   Nothing: nothing
   * @return Nothing
   */
    handleDisplayChange = (type, value) => {
        if (!value && type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
            this.setState({ isReset: this.state.isReset ? false : true });
        }
        if (type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
            if (type === MPS_DISPLAY_AREA_STORAGE.sortField) {
                const { searchOptions } = this.state;
                searchOptions.sort = value;
                this.setState({
                    searchOptions
                });
                return;
            }
            this.setState({ [type]: value });
        } else {
            const { searchOptions } = this.state;
            searchOptions.sortAscending = value;
            this.setState({
                sortAscending: value,
                searchOptions
            });
        }
    };
    render() {
        if (this.props.allowAccess === false) {
            return '';
        }
        const mode = this.props.mode;
        const { language, guideLine } = this.state;
        const { getFieldDecorator } = this.props.form;
        let screenName = '';
        if (mode === MPS_MODE.Copy) {
            screenName = language.SIPCO_USERFEEDBACK_COPY;
        }
        if (mode === MPS_MODE.Create) {
            screenName = language.SIPCO_USERFEEDBACK_NEW;
        }
        const selectedModel = this.state.selectedModel;
        const displayDefaultFieldOnEditForm = getDisplayFieldOnEditForm();
        const displayFieldOnEditForm = initFielDisplayOnEditForm(language);
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };

        const gridStyle = {
            width: '50%',
        };

        return (
            <span >
                {this.props.allowAccess === true ? <Button type="primary" onClick={this.handleShowForm}>{this.props.actionName}</Button> : ' '}
                <Modal
                    title={screenName}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="saveClose" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleSave}>
                            {language.SAVEANDCLOSE}
                        </Button>,
                        <Button key="saveNext" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleSaveNext}>
                            {language.SAVEANDNEXT}
                        </Button>,
                        <Button key="back" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleCancel}>
                            {language.CANCEL}
                        </Button>
                    ]}
                    >
                    <Form {...formItemLayout} onSubmit={this.handleSave} id={Mps_MethodCommon.getDisplayTheme()}>
                        <Mps_DisplayColumnResultTable columns={displayFieldOnEditForm}
                            onDisplayFieldOnEditFormChange={this.handleDisplayFieldOnEditFormChange}
                            displayFieldOnEditForm={displayDefaultFieldOnEditForm}
                            language={this.state.chooseLanguage} />

                        <Tabs defaultActiveKey="1" >
                            <TabPane tab={language.SIPCO_USERFEEDBACK_TITLE_GENERAL} key="1">
                                <Card style={{ border: 'none' }} >

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Serial_Code')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item>
                                                    {language.SERIAL_CODE}

                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                          
                                          
                                           help={
                                                        <Mps_Tooltip title={guideLine.Serial_Code} />
                                                    }
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Serial_Code', {
                                                        initialValue: mode === MPS_MODE.Copy ? '' : selectedModel.Serial_Code,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Serial_Code.Required,
                                                            }
                                                        ],
                                                    })(<Input id="Serial_Code" />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Source')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SOURCE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_SelectParameterSystem name='Source' id='Source' type={MPS_SYSTEM_PARAMETER_TYPE.Source}

                                                    getFieldDecorator={getFieldDecorator}
                                                    required={initFieldControl.Source.Required}
                                                    help={guideLine.Source}
                                                    value={this.state.selectedModel.Source} onChange={this.handleSourceChange} />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Content')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.CONTENT}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={
                                                        <Mps_Tooltip title={guideLine.Content} />
                                                    }
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Content', {
                                                        initialValue: selectedModel.Content,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Content.Required,
                                                            }
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Description')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.DESCRIPTION}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={
                                                        <Mps_Tooltip title={guideLine.Description} />
                                                    }
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('Description', {
                                                        initialValue: selectedModel.Description,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.Description.Required,
                                                            }
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Status')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.STATUS}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_SelectParameterSystem name='Status' id='Status' type={MPS_SYSTEM_PARAMETER_TYPE.StatusFeedback}

                                                    getFieldDecorator={getFieldDecorator}
                                                    required={initFieldControl.Status.Required}
                                                    help={guideLine.Status}
                                                    value={this.state.selectedModel.Status} onChange={this.handleStatusChange} />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.CREATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedBy')}`} name="CreatedBy"
                                        help={guideLine.CreatedBy}
                                        value={this.state.selectedModel.CreatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.CREATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}
                                                    name="CreatedDate"
                                                    help={guideLine.CreatedDate}
                                                    style={{ width: '100%' }}
                                                    value={moment(Date.now())}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.UPDATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedBy')}`} name="UpdatedBy"
                                        help={guideLine.UpdatedBy}
                                        value={this.state.selectedModel.UpdatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.UPDATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}
                                                    name="UpdatedDate"
                                                    help={guideLine.UpdatedDate}
                                                    style={{ width: '100%' }}
                                                    value={this.state.selectedModel.UpdatedDate}
                                                    mutiple={false}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                </Card>
                            </TabPane>
                        </Tabs>
                    </Form>
                </Modal>
            </span>
        );
    }
}
const WrappedSipco_UserFeedbackAdd = Form.create({ name: 'Sipco_UserFeedbackAdd' })(Sipco_UserFeedbackAdd);
export { WrappedSipco_UserFeedbackAdd as Sipco_UserFeedbackAdd };

import React, { useState, useEffect } from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { API_URL } from '../../../conf';
import { Mps_MethodCommon,MPS_MESSAGE_STATE} from '../../../commons'
import jsonQuery from 'json-query';
import { LANGUAGE_STATE } from './Language/ln';
import { menuService } from '../../../authenticate/Services';

const chooseLanguage = menuService.getLanguage();
        const commonMessage = jsonQuery([chooseLanguage[0]], { data: MPS_MESSAGE_STATE }).value;
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
const convertImagesURL = (images = []) => {
  return images.map(image => {
    return {
      ...image,
      [image.url ? 'url' : 'thumbUrl']: image.url ? `${API_URL}/${image.url}` : image.thumbUrl,
      uid: image.uid || image.url
    };
  });
};


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function Mps_UploadPictureV2(props) {
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  const [images, setImages] = useState([]);
  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const upLoadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{props.title || props.language.ADDDOCUMENT}</div>
    </div>
  );

  let total = 0
  const handleChange = ({ file, fileList }) => {
    const acceptedFileType = ['application/pdf','image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp','application/x-zip-compressed'];
    let totalSize = fileList.map(image =>  total + image.size).reduce((prev, curr) => prev + curr, 0)
    if (acceptedFileType.includes(file.type) && totalSize < 6000000) {
      if (!file.preview && file.originFileObj?.name) {
        getBase64(file.originFileObj).then(result => {
          file.preview = result;
        });
        file.response = null;
        file.status = 'done';
      }
      
      setImages(fileList);
      if (props.onAddImage) {
        return props.onAddImage(fileList);
      }

      props.onChange(fileList);

    }
    else Mps_MethodCommon.openWarningNotification(commonMessage.WARNING_TITLE, language.CAPACITY_EXCEEDED)
  } 

  const handleRemove = (file) => {
    if (props.onRemove) {
      props.onRemove(file);
    }
    return true;
  };

  const handlePreview = file => {
    setPreviewImage(file.url || file.preview || file.thumbUrl);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
    
  };

  const customRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  useEffect(() => {
    if (props.isConvertUrl) {
      setImages(convertImagesURL(props.images) || []);
    } else {
      setImages(props.images || []);
    }
  }, [props.isConvertUrl, props.images]);
  return (

<>
      <Upload
        listType="picture-card"
        fileList={images}
        multiple={true}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
        customRequest={customRequest}
        disabled={props.disabled}

      >
      {/* {props.multiple ? total > 500000  ? null : upLoadButton : images.length >= 1 ? null : upLoadButton}  */}
      {props.multiple ? upLoadButton : images.length >= 1 ? null : upLoadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        footer={null}
        title={previewTitle}
        onCancel={handleCancel}
      >
      <embed src={previewImage} width="100%" height="1080px"/>
      </Modal>
    </> 
  );
}

export default Mps_UploadPictureV2;

import React, { Component } from "react";
import * as XLSX from "xlsx";
import { Button, Icon,Alert } from "antd";

class Mps_UpdatExcel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }
    importExcel = (file) => {
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                var data = [];
                const { result } = event.target;
                const workbook = XLSX.read(result, { type: "binary" });
                for (const Sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(Sheet)) {
                        var rs = XLSX.utils.sheet_to_json(workbook.Sheets[Sheet]);
                        data.push(rs)
                    }
                }
                this.setState({
                    data: data
                })
                console.log("data import", data);
                this.props.onChange(data);

            } catch (e) {
            }
        };
        fileReader.readAsBinaryString(file);
    }

    onImportExcel = event => {
        const { files } = event.target;
        if (files.length === 1) {
            // Process a file if we have exactly one
            this.importExcel(files[0]);
        }
    }

    render() {
        let messageError = (
            this.props.message.map(rs => {
                return (<code>{rs}<br /></code>)
            })
        );
        return (
            <div>
                {this.props.message.length == 0 ? (
                    <div></div>
                )
                    : (
                        <Alert
                            description={messageError}
                            type="error"
                            showIcon
                        />
                    )}
                <Button>
                    <Icon type="upload" />
                    <input type="file" accept=".xlsx, .xls" onChange={this.onImportExcel} />
                </Button>
            </div>

        );
    }
}

export { Mps_UpdatExcel };

const MPS_GUIDELINE_INVENTORY_TRANSACTION ={
    'vn':
        {   
            INVENTORY_TRANSACTION_CODE: 'Mã giao dịch là duy nhất!'
        },
    'en':
        {
            INVENTORY_TRANSACTION_CODE: 'Transaction code is unique!'
        }    
}


const MPS_GUIDELINE_MODULE_NAME_SCREEN_NAME_TRAN ={
    'vn':
        {   
            MODULE_NAME_SCREEN_NAME_TRAN_CODE: 'Mã giao dịch là duy nhất!'
        },
    'en':
        {
            MODULE_NAME_SCREEN_NAME_TRAN_CODE: 'Transaction code is unique!'
        }    
}

module.exports = {
    MPS_GUIDELINE_INVENTORY_TRANSACTION :MPS_GUIDELINE_INVENTORY_TRANSACTION,
    MPS_GUIDELINE_MODULE_NAME_SCREEN_NAME_TRAN: MPS_GUIDELINE_MODULE_NAME_SCREEN_NAME_TRAN
};
import React, { Component } from 'react';
import CKEditor from 'ckeditor4-react';

export class Mps_RichTextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.contentState,
        };
    }

    componentWillMount() {
        this.setState({
            data: this.props.contentState,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contentState !== this.props.contentState) {
            this.setState({
                data: this.props.contentState,
            });
        }
    }

    onEditorChange = event => {
        this.props.readOnly !== true && this.props.onChange(event.editor.getData());
        this.setState({
            data: event.editor.getData(),
        });
    };

    render() {
        const readOnly = this.props.readOnly ? this.props.readOnly : false;
        const dataContent = this.state.data;
        return (
            <div className='Mps_RichTextEditor'>
                {this.props.disabled === true ?
                    <CKEditor
                        config={{
                            enterMode: 2,
                            toolbar: [],
                            resize_minWidth: '100%',
                            resize_maxHeight: 600,
                            //filebrowserBrowseUrl: '/browser/browse.php',
                            //filebrowserUploadUrl: '/uploader/upload.php',
                            pasteFromWordRemoveStyles: false,
                            pasteFromWordNumberedHeadingToList: true,
                            pasteFromWordPromptCleanup: true,
                        }}
                        data={dataContent}
                        readOnly={readOnly}
                        onChange={this.onEditorChange}
                    /> :
                    <CKEditor
                        config={{
                            enterMode: 2,
                            resize_minWidth: '100%',
                            resize_maxHeight: 600,
                            //filebrowserBrowseUrl: '/browser/browse.php',
                            //filebrowserUploadUrl: '/uploader/upload.php',
                            pasteFromWordRemoveStyles: false,
                            pasteFromWordNumberedHeadingToList: true,
                            pasteFromWordPromptCleanup: true,
                        }}
                        data={dataContent}
                        readOnly={readOnly}
                        onChange={this.onEditorChange}
                    />
                }
            </div>
        );
    }
}




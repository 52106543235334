import React from 'react';
import { Icon } from 'antd';
export const MPS_LANGUAGE_STATE = {
    'vn':
    {
        //#Start module
        System: <span className='mps-menu-icon'><Icon type="setting" /><span>Hệ thống</span></span>,
        HumanResource: <span className='mps-menu-icon'><Icon type="team" /><span>Nhân sự</span></span>,
        CPAManagement: <span className='mps-menu-icon'><Icon type="file-text" /><span>Quản lý mã CPA</span></span>,
        CPATransaction: <span className='mps-menu-icon'><Icon type="file-text" /><span>Quản lý bình lọc nước</span></span>,
        UserInfor: 'Thông tin nhân viên',
        ChangePassword: <span className='mps-sub-menu-icon'><Icon type="key" /><span>Thay đổi mật khẩu</span></span>,
        Logout: <span className='mps-sub-menu-icon'><Icon type="logout" /><span>Thoát tài khoản</span></span>,
        CompanyInfor: <span className='mps-sub-menu-icon'><Icon type="home" /><span>Thông tin doanh nghiệp</span></span>,
        User: <span className='mps-sub-menu-icon'><Icon type="user" /><span>Người dùng</span></span>,
        Role: <span className='mps-sub-menu-icon'><Icon type="usergroup-add" /><span>Phân quyền</span></span>,
        Employee: <span className='mps-sub-menu-icon'><Icon type="user" /><span>Nhân viên</span></span>,
        System_Parameter: <span className='mps-sub-menu-icon'><Icon type="gold" /><span>Thông số hệ thống</span></span>,
        Branch: <span className='mps-sub-menu-icon'><Icon type="shop" /><span>Chi nhánh</span></span>,
       
        //SIPCO
        AlertSetting: <span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Thiết lập cảnh báo</span></span>,
        CPA: <span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Quản lý mã CPA</span></span>,
        WaterFilter: <span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Quản lý bình lọc nước</span></span>,
        WaterFilterHistory: <span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Lịch sử thay lõi lọc</span></span>,
        UserFeedback: <span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Phản hồi thông tin</span></span>,
        Sipco_Parameter:<span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Thông số hệ thống</span></span>,
        Sipco_WaterFilter:<span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Quản lí bình lọc nước </span></span>,
        Sipco_WaterFilterHistory:<span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Lịch sử bình lọc nước</span></span>,
        Notify:<span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Thông báo</span></span>,
        //#End screen
        SYSTEM_USER_INFO: 'Thông tin người dùng',
        SYSTEM_MAIN_FUNCTION: 'Chức năng chính'
    },
    'en':
    {
        //#Start module
        System: <span className='mps-menu-icon'><Icon type="setting" /><span>System</span></span>,
        HumanResource: <span className='mps-menu-icon'><Icon type="team" /><span>Human Resource</span></span>,
        CPAManagement: <span className='mps-menu-icon'><Icon type="file-text" /><span>CPA Management</span></span>,
        CPATransaction: <span className='mps-menu-icon'><Icon type="file-text" /><span>Household Management</span></span>,
        UserInfor: 'Thông tin nhân viên',
        ChangePassword: <span className='mps-sub-menu-icon'><Icon type="key" /><span>Change Password</span></span>,
        Logout: <span className='mps-sub-menu-icon'><Icon type="logout" /><span>Logout</span></span>,
        CompanyInfor: <span className='mps-sub-menu-icon'><Icon type="home" /><span>Company Information</span></span>,
        User: <span className='mps-sub-menu-icon'><Icon type="user" /><span>User Management</span></span>,
        Role: <span className='mps-sub-menu-icon'><Icon type="usergroup-add" /><span>Permission Management</span></span>,
        Employee: <span className='mps-sub-menu-icon'><Icon type="user" /><span>Nhân viên</span></span>,
        System_Parameter: <span className='mps-sub-menu-icon'><Icon type="gold" /><span>System Parameter</span></span>,
        Branch: <span className='mps-sub-menu-icon'><Icon type="shop" /><span>Branch</span></span>,
       
        //SIPCO
        AlertSetting: <span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Alert Setting</span></span>,
        CPA: <span className='mps-sub-menu-icon'><Icon type="file-text" /><span>CPA Management</span></span>,
        WaterFilter: <span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Household</span></span>,
        WaterFilterHistory: <span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Household History</span></span>,
        UserFeedback: <span className='mps-sub-menu-icon'><Icon type="file-text" /><span>User Feedback</span></span>,
        //#End screen

        System_Setting: <span className='mps-sub-menu-icon'><Icon type="setting" /><span>System Setting</span></span>,
        Sipco_Parameter:<span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Parameter Management</span></span>,
        Sipco_WaterFilter:<span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Household Management </span></span>,
        Sipco_WaterFilterHistory:<span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Household History</span></span>,
        Notify:<span className='mps-sub-menu-icon'><Icon type="file-text" /><span>Notify</span></span>,
        SYSTEM_USER_INFO: 'User profile',
        SYSTEM_MAIN_FUNCTION: 'Main function'
    }
}

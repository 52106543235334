import React, { Component } from 'react';
import { Mps_HeaderInfor } from './Mps_HeaderInfor';
import Mps_LineChart from '../Mps_Chart/Mps_LineChart/Mps_LineChart';
import { Mps_ReportByList } from './Mps_ReportByList';

class Mps_ReportLineAndList extends Component {
    render() {
        const {
            reportType,
            headerLabel,
            changeDate,
            lineChartDataStatus,
            tickFormatter,
            xAxisTickLineChart,
            dataKeys,
            toolTipFormatter,
            colors,
            dataSource,
            columns,
        } = this.props;

        const propsLineChart = {
            data:lineChartDataStatus,
            height:500,
            tickFormatter,
            xAxisDatakey:'Date_Time',
            xAxisTick:xAxisTickLineChart,
            dataKeys,
            toolTipFormatter,
            legend:true,
            brush:true,
            colors,
        }
        
        if(lineChartDataStatus.length < 20) {
            propsLineChart.endIndex = lineChartDataStatus.length-1
        }

        return (
            <div className='mps-layout-report'>
                <Mps_HeaderInfor label={headerLabel} changeDate={changeDate} />
                {reportType.typeDisplay === 1 ?
                    (<Mps_LineChart {...propsLineChart}/>)
                    :
                    (<Mps_ReportByList
                        dataSource={dataSource}
                        title=""
                        columns={columns} 
                    />)
                }
            </div>
        );
    }
}

export { Mps_ReportLineAndList };

import React, { Component } from 'react';
import { Table } from 'antd';

function sumProperty(arr, type) {
    return arr.reduce((total, obj) => {
        if (typeof obj[type] === 'string') {
            return total + Number(obj[type]);
        }
        return total + obj[type];
    }, 0);
}

class Mps_ReportByList extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const dataSource = this.props.dataSource;
        this.setState({ dataSource });
    }

    render() {
        const columns = this.props.columns;
        const overScroll = (sumProperty(columns.filter(x => x.className === '' || x.className === 'cell-ellipsis' ||
            x.className === 'cell-ellipsis display-date' || x.className === 'display-date'), 'width'));
        return (
            <div>
                <Table
                    columns={this.props.columns}
                    dataSource={this.props.dataSource}
                    pagination={false}
                    bordered
                    scroll={{ x: overScroll, y: ((!!this.props.y) ? this.props.y : 0) }} columns={columns}
                    rowKey={this.props.rowKey}
                />
            </div>
        );
    }
}

export { Mps_ReportByList };

import { authHeader } from '../../authenticate/Helpers';
import {API_LOG_URL} from '../../conf.js';
import {TOKEN} from '../../conf.js'
import { isNullOrUndefined } from 'util';
import {Mps_CommonConstant} from '../../components/Mps_Control/Mps_CommonConstant/Mps_CommonConstant';
export const Log_Service = {
    get,
    getDataFilter,   
    create   
};

function getDataFilter(search_Object)
{
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },     
        body: JSON.stringify(search_Object)
    };
    //console.log("requestOptions",requestOptions);
    return fetch(API_LOG_URL+`/log/getDataFilter`, requestOptions).then(handleResponse);       
}

function get(objectSearch,page) {
    const limit = Mps_CommonConstant.paginationLimit;
    
    var value = {
        objectSearch,
        page,
        limit
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },     
        body: JSON.stringify(value)
    };
    return fetch(API_LOG_URL+`/logUI/search`, requestOptions).then(handleResponse);       
}


function create(screen, action, type = null,content = null) {
        const user = JSON.parse(localStorage.getItem('user'));
        let auth = 'Bearer ' + TOKEN;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', auth);
        var value = {
            Token:TOKEN,
            userToken:user,
            Screen: screen,
            Action:action,
            Type:type,
            Content:content       
        }
        const requestOptions = {
            method: 'POST',
            headers:headers ,   
            body: JSON.stringify(value)
        };
        fetch(API_LOG_URL+`/logUI/create`, requestOptions)
        .then(async response => {
            const data = await response.json();
    
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
    
        })
        .catch(error => {
            console.error('There was an error!', error);
        });   
    
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
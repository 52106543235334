import React from 'react';
import jsonQuery from 'json-query';
import {
  Select, Form
} from 'antd';
import { Mps_Tooltip } from '../Mps_Tooltip/Mps_Tooltip';
import { System_Parameter_Service } from '../../System_Parameter/Service/System_Parameter.Service';


class Mps_SelectMultipleParameterSystem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
  }

  componentDidUpdate(prevProps) {
    const searchModel = {};
    if (prevProps.type !== this.props.type) {
      searchModel.System_Parameter_Type = this.props.type;
      Promise.all([System_Parameter_Service.getDataFilterControl(searchModel)]).then(result => {
        const data = jsonQuery('docs[**]', { data: result[0].result }).value;
        this.setState({
          dataSource: data,
        });
      });
    }
  }

  componentWillMount() {
    const searchModel = { Value: '' };
    searchModel.System_Parameter_Type = this.props.type;
    Promise.all([System_Parameter_Service.getDataFilterControl(searchModel)]).then(result => {
      const data = jsonQuery('docs[**]', { data: result[0].result }).value;
      this.setState({
        dataSource: data,
      });
    });
  }

  onChange = value => {
    this.props.onChange(value);
  };

  render() {
    const { Option } = Select;
    const dataSource = jsonQuery('[**]', { data: this.state.dataSource }).value;

    return (
      <div>
        <Form.Item
          help={
            (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
          }
          hasFeedback
        >
          {
            (!this.props.getFieldDecorator) ?
              <Select disabled={this.props.disabled}
                allowClear={this.props.allowClear}
                placeholer={this.props.placeholer}
                value={this.props.value}
                mode='multiple'
                showSearch
                optionFilterProp='data'
                optionLabelProp='text'
                filterOption={(input, option) =>
                  (option.props.data.System_Parameter_Code.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  || (option.props.data.System_Parameter_Name.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                onChange={this.onChange}>
                {dataSource.map(select =>
                  <Option key={select.System_Parameter_Code} text={select.System_Parameter_Name} data={select} value={select.System_Parameter_Code}>
                    {select.System_Parameter_Name}
                  </Option>
                )}
              </Select>
              : (this.props.getFieldDecorator(this.props.name, {
                initialValue: this.props.value,
                rules: [
                  {
                    required: this.props.required,
                  }
                ],
              })(
                <Select disabled={this.props.disabled}
                  allowClear={this.props.allowClear}
                  placeholer={this.props.placeholer}
                  onChange={this.onChange}
                  optionFilterProp='data'
                  optionLabelProp='text'
                  filterOption={(input, option) =>
                    (option.props.data.System_Parameter_Code.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                    || (option.props.data.System_Parameter_Name.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                  showSearch
                  mode='multiple'>
                  {dataSource.map(select =>
                    <Option key={select.System_Parameter_Code} text={select.System_Parameter_Name} data={select} value={select.System_Parameter_Code}>
                      {select.System_Parameter_Name}
                    </Option>
                  )}
                </Select>
              ))}
        </Form.Item>
      </div>
    );
  }
}

export { Mps_SelectMultipleParameterSystem };

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../../Services/Authentication.Service';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => {
    return authenticationService.checkInfor() === true ? <Component {...props} /> : <Redirect to='/login' />;
  }}
  />;
};

export const LANGUAGE_STATE ={
    'vn':
        {
            PAGE_TITLE: 'Quản lý bình lọc nước',
            SIPCO_WATERFILTER_SEARCH: 'Quản lý bình lọc nước',
            SIPCO_WATERFILTER_NEW: 'Thêm mới bình lọc nước',
            SIPCO_WATERFILTER_COPY: 'Sao chép thông tin bình lọc nước',
            SIPCO_WATERFILTER_EDIT: 'Chỉnh sửa thông tin bình lọc nước',
            SIPCO_WATERFILTER_VIEW: 'Xem thông tin bình lọc nước',
            SIPCO_WATERFILTER_TITLE_GENERAL: 'Thông tin chung',
            SIPCO_WATERFILTER_TITLE_ADDITIONAL: 'Thông tin thêm',
            CPA_CODE: 'Mã CPA',
            SERIAL_CODE: 'Mã số bình lọc nước',
            OWNER:'Tên hộ nhận BLN',
            PROVINCE: 'Tỉnh',
            DISTRICT: 'Huyện',
            WARD: 'Xã',
            VILLAGE: 'Thôn/Ấp',
            PHONE:'Số điện thoại',
            PROVIDE_DATE:'Ngày cấp',
            SIPCO_WATERFILTER_DESCRIPTION:'Mô tả',
            DOCUMENT_UPLOAD:'Tài liệu',
            STATUS:'Trạng thái',
            CREATED_DATE: 'Ngày tạo',
            CREATED_BY: 'Người tạo',
            UPDATED_DATE: 'Ngày cập nhật',
            UPDATED_BY: 'Người cập nhật',
            SEARCH_INFOR:'Thông tin tìm kiếm',
            SAVE: 'Lưu',
            SAVEANDCLOSE:'Lưu và đóng',
            SAVEANDNEXT: 'Lưu và tiếp tục',
            CANCEL:'Hủy',
            CLOSE:'Đóng',
            SEARCH:'Tìm kiếm',
            NEW: 'Thêm mới',
            VIEW: 'Xem',
            EDIT: 'Chỉnh sửa',
            DELETE: 'Xóa',
            COPY:'Sao chép',
            IMPORT:'Nhập dữ liệu',
            DOWNLOADDATA:'Tải dữ liệu (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import mẫu',
            MODULENAMET_SCREENT_DATA_FILE: 'File data MODULENAMET_SCREENT',
            MONITORING_DATE: 'Ngày giám sát',
            DESCRIPTION_OF_MONITORING: 'Mô tả giám sát',
            FILTER_REPLACEMENT_DATE: 'Ngày thay lõi',
            NUMBER_OF_TIMES_TO_REPLACE: 'Lần thay lõi',
            DAYLEFT :'Ngày còn lại',
            WATERFILTER_EPIRATION_SOON: 'Bình lọc nước sắp hết hạn',
            WATERFILTER_EPIRED: 'Bình lọc nước đã hết hạn',
            TOTAL_WATERFILTER_EPIRATION_SOON: 'Tổng bình lọc nước sắp hết hạn',
            TOTAL_WATERFILTER_EPIRED: 'Tổng bình lọc nước đã hết hạn'
        },
    'en':
        {
            PAGE_TITLE: 'Household management',
            SIPCO_WATERFILTER_SEARCH: 'Household management',
            SIPCO_WATERFILTER_NEW: 'Add new Household information',
            SIPCO_WATERFILTER_COPY: 'Add new Household information',
            SIPCO_WATERFILTER_EDIT: 'Edit Household information',
            SIPCO_WATERFILTER_VIEW: 'View Household information',
            SIPCO_WATERFILTER_TITLE_GENERAL: 'General  information',
            SIPCO_WATERFILTER_TITLE_ADDITIONAL: 'Additional information',
            CPA_CODE: 'CPA No',
            SERIAL_CODE: 'Household Series No.',
            OWNER:'Name of Household',
            
            PHONE:'Phone',
            PROVINCE: 'Province',
            DISTRICT: 'District',
            WARD: 'Ward',
            VILLAGE: 'Village',
            PROVIDE_DATE:'Distribution date',
            SIPCO_WATERFILTER_DESCRIPTION:'Description',
            DOCUMENT_UPLOAD:'Document Upload',
            STATUS:'Status',
            CREATED_DATE: 'Created date',
            CREATED_BY: 'Created by',
            UPDATED_DATE: 'Updated date',
            UPDATED_BY: 'Updated by',
            SEARCH_INFOR:'Search Information',
            SAVE: 'Save',
            SAVEANDCLOSE:'Save and close',
            SAVEANDNEXT: 'Save and continue',
            CANCEL:'Cancel',
            CLOSE:'Close',
            SEARCH:'Search',
            NEW: 'New',
            EDIT: 'Edit',
            VIEW: 'View',
            DELETE: 'Delete',
            COPY:'Copy',
            IMPORT:'Import data',
            DOWNLOADDATA:'Download Data (CSV)',
            PROJECT_EXAMPLE_FILE: 'File import example',
            MODULENAMET_SCREENT_DATA_FILE: 'File data MODULENAMET_SCREENT',
            MONITORING_DATE: 'Monitoring date',
            DESCRIPTION_OF_MONITORING: 'Description of Monitoring',
            FILTER_REPLACEMENT_DATE: 'Filter replacement date',
            NUMBER_OF_TIMES_TO_REPLACE: 'Number of times to replace',
            DAYLEFT :'Day left',
            WATERFILTER_EPIRATION_SOON: 'Household expiration soon',
            WATERFILTER_EPIRED: 'Household expired',
            TOTAL_WATERFILTER_EPIRATION_SOON: 'Total Household expiration soon',
            TOTAL_WATERFILTER_EPIRED: 'Total Household expired',
            HETHAN: 'Notify',
            SIPCO_WATERFILTER_DATA_FILE: 'File data SIPCO_NOTIFY',
        }
    };

export const LANGUAGE_STATE = {
    'vn':
    {
        LOGINSCREEN: 'COMPANY NAME',
        LANGUAGE: 'Ngôn ngữ',
        USER_Id: 'Tên đăng nhập',
        USER_NAME: 'Tên đăng nhập',
        PASSWORD: 'Nhập mật khẩu mới',
        LOGIN: 'Đăng nhập',
        COMPANY_CODE: 'Mã công ty',
        SCREEN_NAME: 'COMPANY NAME',
        FORGOT_PASSWORD: 'QUÊN MẬT KHẨU',
        SIGNIN: "Đăng nhập",
        SIGNUP: "Đăng ký",
        USER_EMAIL: "Email",
        CONTINUTE: "Tiếp tục",
        EMAIL_CODE: "Code nhận từ email",
        REPASSWORD: "Nhập lại mật khẩu mới",
        EMAIL_NOT_MATCH: "Email không khớp vói tài khoản",
        CODE_EMAIL_NOT_MATCH: "Mã code nhập không đúng!!!",
        REPASSWORD_SUCCESS: "Lấy lại mật khẩu thành công",
        REPASSWORD_ERROR:"Không thể đổi mật khẩu",
        CODE_EMAIL_EXPIRED:"Mã của bạn đã hết hạn",
    },
    'en':
    {
        CODE_EMAIL_EXPIRED:"Your code expired",
        REPASSWORD_ERROR:"Can't not change password",
        REPASSWORD_SUCCESS: "Reset password successfully",
        CODE_EMAIL_NOT_MATCH: "Code not match!!!",
        EMAIL_NOT_MATCH: "Email not match with account",
        REPASSWORD: "Retype new password",
        EMAIL_CODE: "Code from email",
        CONTINUTE: "Continue",
        USER_EMAIL: "Email",
        SIGNUP: "Sign up",
        SIGNIN: "Sign in",
        LOGINSCREEN: 'COMPANY NAME',
        LANGUAGE: 'Language',
        USER_Id: 'User name',
        USER_NAME: 'User name',
        PASSWORD: 'New password',
        LOGIN: 'Login',
        COMPANY_CODE: 'Company code',
        FORGOT_PASSWORD: 'FORGOT PASSWORD'
    }
};


import React from 'react';
import { Form, Input, Button, Tabs, Row, Col, Modal, Card } from 'antd';
import jsonQuery from 'json-query';

import {
    MPS_DISPLAY_AREA_STORAGE, MPS_RESULTS,
    MPS_MESSAGE_STATE, Mps_MethodCommon, MPS_SYSTEM_PARAMETER_TYPE, MPS_SCREEN_NO
} from '../../commons';
import {
    Mps_LayoutFormat
    , Mps_SelectParameterSystem, Mps_InputDatePicker
    , Mps_Tooltip, Mps_DisplayField, Mps_DisplayColumnResultTable
} from '../Mps_Control';
import { System_User_Select } from '../System_User/Controls/System_User_Select';
import { SIPCO_CPA_Service } from './Service/SIPCO_CPA.Service';
import { LANGUAGE_STATE } from './Language/ln';
import { GUIDE_LINE } from './Language/Guideline';
import { MESSAGE_STATE } from './Language/ms';

import {
    setDisplayFieldOnEditForm,
    getDisplayFieldOnEditForm, initFielDisplayOnEditForm, initModel, initFieldControl
} from './Models/SIPCO_CPA.Model';
import moment from 'moment';
import { Log_Service } from '../../authenticate/Helpers/Log.Service';
import Mps_UploadPictureV2 from '../Mps_Control/Mps_UploadPicture/Mps_UploadPictureV2';

const { TabPane } = Tabs;

const screenNo = MPS_SCREEN_NO.CPA;

class SIPCO_CPAEdit extends React.Component {
    constructor(props) {
        super(props);
        const chooseLanguage = this.props.language;
        const language = jsonQuery([this.props.language[0]], { data: LANGUAGE_STATE }).value;
        const commonMessage = jsonQuery([this.props.language[0]], { data: MPS_MESSAGE_STATE }).value;
        const guideLine = jsonQuery([this.props.language[0]], { data: GUIDE_LINE }).value;
        const messageState = jsonQuery([this.props.language[0]], { data: MESSAGE_STATE }).value;
        this.state = {
            messageState,
            language,
            commonMessage,
            guideLine,
            chooseLanguage,
            initModel,
            visible: false,
            CPAOriginalFiles: [],
            removedFiles: [],
            addedFiles: [],
        };
    }
    /**
     * @desc Khởi tạo data khi load component
     * @param Nothing
     * @return Nothing
    */
    componentWillMount() {
        const selectedModel = this.props.value;

        this.setState({
            selectedModel,
        });

    }
    /**
* @desc Handle thiết lập sắp xếp hiển thị field trên form.
* @param
*   array value: Danh sách Field được chọn hiển thị trên form.
* @return Nothing (Cập nhật setting),
*/
    handleDisplayFieldOnEditFormChange = value => {
        setDisplayFieldOnEditForm(value);
        this.setState({
            displayDefaultFieldOnEditForm: value
        });
    };
    /**
     * @desc Handle thông tin lỗi
     * @param
     *   Nothing: nothing
     * @return Nothing
     */
    /*componentDidCatch(error, info) {
        // log the error to loggly
        logger.push({ error, info });
    }*/
    // handleShowForm = (e) => {
    //     e.preventDefault();

    //     const selectedModel = this.props.value;
    //     if (selectedModel.length === 0 || selectedModel[0].SIPCO_CPA_Code === '') {
    //         Mps_MethodCommon.handleShowConfirm(this.state.commonMessage.CHOOSE_DATA_EDIT, '');
    //     } else if (selectedModel.length > 1 || selectedModel[0].SIPCO_CPA_Code === '') {
    //         Mps_MethodCommon.openWarningNotification(this.state.commonMessage.CHOOSE_ONLY_DATA_EDIT, '');
    //         return;
    //     } else {
    //         Promise.all([SIPCO_CPA_Service.getById(selectedModel[0]._id)]).then((result) => {
    //             var data = jsonQuery('result[0]', { data: result }).value;
    //             /*demo mẫu logger*/
    //             Log_Service.create("selectedModel", data);
    //             this.setState({
    //                 selectedModel: data,
    //                 visible: true,
    //             });
    //         });

    //     }
    // };

    handleShowForm = e => {
        e.preventDefault();
        const selectedModel = this.props.value;
        if (selectedModel.length === 0 || selectedModel[0].Serial_Code === '') {
          Mps_MethodCommon.openWarningNotification(this.state.commonMessage.CHOOSE_DATA_EDIT, '');
        } else {
          if (selectedModel.length === 1) {
            Promise.all([SIPCO_CPA_Service.getById(selectedModel[0]._id)]).then(result => {
              const data = jsonQuery('result[0]', { data: result }).value;
              if (data) {
                this.setState({
                  selectedModel: data,
                  CPAOriginalFiles: data.CPA_Files ? [...data.CPA_Files] : [],
                  visible: true
                });
              } else {
                Mps_MethodCommon.openErrorNotification(this.state.commonMessage.DATANOTFOUND, '');
              }
            });
          } else {
            Mps_MethodCommon.openWarningNotification(this.state.commonMessage.CHOOSE_ONLY_DATA_EDIT, '');
            return;
          }
        }
      };


    /**
    * @desc Handle sự kiện xác nhận lưu thông tin
    * @param event
    *   string mode - chế độ hiển thị (View,Copy,Add, Edit).
    *   object data - dữ liệu hiển thị
    * @return bool - kết quả: true = thành công/ false = thất bại
    */
    // handleOk = e => {
    //     e.preventDefault();
    //     this.props.form.validateFieldsAndScroll((err, values) => {
    //         if (!err) {
    //             const objectModel = {
    //                 _id: this.state.selectedModel._id,
    //                 SIPCO_CPA_Code: values.SIPCO_CPA_Code,
    //                 // SIPCO_CPA_Name: values.SIPCO_CPA_Name,
    //                 SIPCO_CPA_Province: values.SIPCO_CPA_Province,
    //                 SIPCO_CPA_Color: values.SIPCO_CPA_Color,
    //                 SIPCO_CPA_Start: values.SIPCO_CPA_Start,
    //                 SIPCO_CPA_End: values.SIPCO_CPA_End,
    //                 SIPCO_CPA_Description: values.SIPCO_CPA_Description,
    //                 Status: this.state.selectedModel.Status,
    //                 Start_Date: this.state.selectedModel.Start_Date,
    //                 End_Date: this.state.selectedModel.End_Date,
    //                 UpdatedDate: Date.now()
    //             }

    //             const WrongDate = (moment(objectModel.End_Date).isBefore(moment(objectModel.Start_Date)))
    //             if (WrongDate) {
    //                 Mps_MethodCommon.openWarningNotification(this.state.commonMessage.DATAERROR, this.state.messageState.END_DATE_WRONG);
    //             }
    //             if (!WrongDate) {
    //                 Promise.all([SIPCO_CPA_Service.update(objectModel)]).then((result) => {
    //                     if (!!result && result[0].returnCode === MPS_RESULTS.SUCCESS) {
    //                         Mps_MethodCommon.openSuccessNotification(this.state.commonMessage.INFORM_TITLE, Mps_MethodCommon.returnMessageApi(result[0].returnCode, this.state.chooseLanguage))

    //                         this.setState({
    //                             selectedModel: initModel,
    //                             visible: false
    //                         });
    //                         this.handleReset();
    //                         this.props.onhandleSave();
    //                     } else {
    //                         Mps_MethodCommon.openErrorNotification(this.state.commonMessage.ERROR_TITLE, Mps_MethodCommon.returnMessageApi(result[0].returnCode, this.state.chooseLanguage))
    //                     }
    //                     /*demo mẫu logger*/
    //                     Log_Service.create("handleOk", result);

    //                 });
    //             }
    //         }
    //     });
    // };



    handleOk = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            //console.log(values)
          if (!err) {
            const objectModel = {
                _id: this.state.selectedModel._id,
                    SIPCO_CPA_Code: values.SIPCO_CPA_Code,
                    SIPCO_CPA_Province: values.SIPCO_CPA_Province,
                    SIPCO_CPA_Color: values.SIPCO_CPA_Color,
                    SIPCO_CPA_Start: values.SIPCO_CPA_Start,
                    SIPCO_CPA_End: values.SIPCO_CPA_End,
                    SIPCO_CPA_Description: values.SIPCO_CPA_Description,
                    Status: this.state.selectedModel.Status,
                    Start_Date: this.state.selectedModel.Start_Date,
                    End_Date: this.state.selectedModel.End_Date,
                    UpdatedDate: Date.now(),
                    SIPCO_File: this.state.CPA_File
            };
            //Log_Service.create('Update', objectModel);
            Promise.all([SIPCO_CPA_Service.update(objectModel)]).then(result => {
    
              if (result && result[0].returnCode === MPS_RESULTS.DATA_EXIST) throw new Error(MPS_RESULTS.DATA_EXIST);
    
              if (result && result[0].returnCode === MPS_RESULTS.ERROR) throw new Error(MPS_RESULTS.ERROR);

              if (result && result[0].returnCode === MPS_RESULTS.LOGIC_ENDATE) {
                throw Mps_MethodCommon.openErrorNotification(this.state.messageState.END_DATE_WRONG,
                    Mps_MethodCommon.returnMessageApi(MPS_RESULTS.LOGIC_ENDATE, this.state.chooseLanguage));
                }

              const formData = new FormData();
              // Append images to form data
              this.state.selectedModel.CPA_Files && this.state.selectedModel.CPA_Files.forEach(file => {
                formData.append('CPA_Files', JSON.stringify(file));
              });
              // Append new images to form data
              this.state.addedFiles.forEach(file => {
                formData.append('CPA_New_Files', file.originFileObj);
              });
              // Append removed images to form data
              this.state.removedFiles.forEach(file => {
                formData.append('CPA_Removed_Files', file.name);
              });
              formData.append('SIPCO_CPA_Code', result[0].data.SIPCO_CPA_Code);
              //Log_Service.create('handleOk', result);

              return SIPCO_CPA_Service.uploadCPAFiles(formData);
    
            }).then(result => {
    
              if (result && result.returnCode === MPS_RESULTS.ERROR) throw new Error(MPS_RESULTS.ERROR);
    
              this.setState({
                selectedModel: { ...initModel, CPA_Files: [] },
                addedFiles: [],
              });
              // Delete product image
              return SIPCO_CPA_Service.deleteCPAFiles({ CPA_Files: this.state.removedFiles });
    
            }).then(result => {
    
              if (result && result.returnCode === MPS_RESULTS.ERROR) throw new Error(MPS_RESULTS.ERROR);
    
              this.setState({ removedFiles: [], visible: false });
              this.handleReset();
              this.props.onhandleSave();
    
              Mps_MethodCommon.openSuccessNotification(
                this.state.commonMessage.INFORM_TITLE,
                Mps_MethodCommon.returnMessageApi(result.returnCode, this.state.chooseLanguage)
              );
            }).catch(error => {
                if (error === undefined) {
                    return false
                }
              if (error.message === MPS_RESULTS.DATA_EXIST) {
                return Mps_MethodCommon.openErrorNotification(this.state.commonMessage.ERROR_TITLE,
                  Mps_MethodCommon.returnMessageApi(MPS_RESULTS.DATA_EXIST, this.state.chooseLanguage));
              }
              if (error.message === MPS_RESULTS.ERROR) {
                return Mps_MethodCommon.openNotification('error', this.state.validMessage.ERROR_PRODCUCT_IS_EXIST, '');
              }
            });
          }
        });
      };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.handleReset()
    };

    handleReset = () => {
        this.props.form.resetFields();
    };

    handleStatusChange = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.Status = value;
        /*demo mẫu logger*/
        //Log_Service.create("handleStatusChange", value);
        this.setState({
            selectedModel
        });
    };
    handleColorChange = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.SIPCO_CPA_Color = value;
        this.setState({
            selectedModel
        });
    };
    handleProvinceChange = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.SIPCO_CPA_Province = value;
        this.setState({
            selectedModel
        });
    };
    /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
    handleEndDateChange = value => {
        const selectedModel = this.state.selectedModel;
        if (value !== null) {
            const dateTime_in = value._d
            const dateTime = new Date(dateTime_in.getFullYear(), dateTime_in.getMonth(), dateTime_in.getDate(), 0, 0, 0);
            const dateTime_after = dateTime.setHours(dateTime.getHours() + 8)
            const pick_time = new Date(dateTime_after);
            value._d = pick_time
        } else {
            value = ""
        }
        selectedModel.End_Date = value;
        this.setState({
            selectedModel
        });
    };

    /**;
    * @desc Handle sự kiện select CreatedDate thay đổi
    * @param
    *  String value - Giá trị mới.
    * @return Nothing (CreatedDate được cập nhật giá trị mới).
    */
    handleStartDateChange = value => {
        const selectedModel = this.state.selectedModel;
        if (value !== null) {
            const dateTime_in = value._d
            const dateTime = new Date(dateTime_in.getFullYear(), dateTime_in.getMonth(), dateTime_in.getDate(), 0, 0, 0);
            const dateTime_after = dateTime.setHours(dateTime.getHours() + 8)
            const pick_time = new Date(dateTime_after);
            value._d = pick_time
        } else {
            value = ""
        }
        selectedModel.Start_Date = value;
        this.setState({
            selectedModel
        });
    };

    handleUploadFile = value => {
        const selectedModel = this.state.selectedModel;
        selectedModel.CPA_File = value;
        this.setState({ selectedModel });
      };

    handleAddFile = value => {
        this.setState({ addedFiles: [...value] });
      };
    handleRemoveFile = value => {
        const { CPAOriginalFiles, removedFiles } = this.state;
        const index = CPAOriginalFiles.findIndex(item => item.name === value.name);
    
        if (index === -1) return;
    
        removedFiles.push(value);
        this.setState({ removedFiles });
        //console.log(removedFiles)
    };

    /**
   * @desc Handle tùy chọn hiển thị
   * @param
   *   Nothing: nothing
   * @return Nothing
   */
    handleDisplayChange = (type, value) => {
        if (!value && type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
            this.setState({ isReset: this.state.isReset ? false : true });
        }
        if (type !== MPS_DISPLAY_AREA_STORAGE.sortType) {
            if (type === MPS_DISPLAY_AREA_STORAGE.sortField) {
                const { searchOptions } = this.state;
                searchOptions.sort = value;
                this.setState({
                    searchOptions
                });
                return;
            }
            this.setState({ [type]: value });
        } else {
            const { searchOptions } = this.state;
            searchOptions.sortAscending = value;
            /*demo mẫu logger*/
            //Log_Service.create("handleDisplayChange", searchOptions);
            this.setState({
                sortAscending: value,
                searchOptions
            });
        }
    };
    render() {
        if (this.props.allowAccess === false) {
            return '';
        }

        const { language, guideLine } = this.state;
        const { getFieldDecorator } = this.props.form;
        const screenName = language.SIPCO_CPA_SCREEN_EDIT;
        const selectedModel = this.state.selectedModel;
        const displayDefaultFieldOnEditForm = getDisplayFieldOnEditForm();
        const displayFieldOnEditForm = initFielDisplayOnEditForm(language);

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const gridStyle = {
            width: '50%'
        };
        return (
            <span>
                {this.props.allowAccess === true ? <Button type="primary" onClick={this.handleShowForm}>{this.props.actionName}</Button> : ' '}
                <Modal
                    title={screenName}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="submit" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleOk}>
                            {language.SAVE}
                        </Button>,
                        <Button key="back" className={Mps_MethodCommon.getDisplayTheme()} type="primary" onClick={this.handleCancel}>
                            {language.CANCEL}
                        </Button>,
                    ]}
                    width={800}>
                    <Form {...formItemLayout} onSubmit={this.handleSave} id={Mps_MethodCommon.getDisplayTheme()}>

                        <Mps_DisplayColumnResultTable columns={displayFieldOnEditForm}
                            onDisplayFieldOnEditFormChange={this.handleDisplayFieldOnEditFormChange}
                            displayFieldOnEditForm={displayDefaultFieldOnEditForm}
                            language={this.state.chooseLanguage} />

                        <Tabs defaultActiveKey="1">
                            <TabPane tab={language.SIPCO_CPA_SCREEN_TITLE_GENERAL} key="1">
                                <Card style={{ border: 'none' }} >
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_Code')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item>
                                                    {language.SIPCO_CPA_CODE}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('SIPCO_CPA_Code', {
                                                        initialValue: selectedModel.SIPCO_CPA_Code,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.SIPCO_CPA_Code.Required,
                                                            }
                                                        ],
                                                    })(<Input disabled={true} />)}
                                                    {getFieldDecorator('_id', {
                                                        initialValue: selectedModel._id,
                                                        rules: [],
                                                    })(<Input type='hidden' />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_Province')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_CPA_PROVINCE}
                                                </Form.Item>
                                            </Col>

                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_SelectParameterSystem name='SIPCO_CPA_Province' id='SIPCO_CPA_Province' type={MPS_SYSTEM_PARAMETER_TYPE.Province}

                                                    getFieldDecorator={getFieldDecorator}
                                                    required={initFieldControl.SIPCO_CPA_Province.Required}
                                                    help={guideLine.SIPCO_CPA_Province}
                                                    value={this.state.selectedModel.SIPCO_CPA_Province} onChange={this.handleProvinceChange} />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_Start')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_CPA_START}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('SIPCO_CPA_Start', {
                                                        initialValue: selectedModel.SIPCO_CPA_Start,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.SIPCO_CPA_Start.Required,
                                                            }
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_End')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_CPA_END}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('SIPCO_CPA_End', {
                                                        initialValue: selectedModel.SIPCO_CPA_End,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.SIPCO_CPA_End.Required,
                                                            }
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_Color')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_CPA_COLOR}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_SelectParameterSystem name='SIPCO_CPA_Color' id='SIPCO_CPA_Color' type={MPS_SYSTEM_PARAMETER_TYPE.ClassifyColor}

                                                    getFieldDecorator={getFieldDecorator}
                                                    required={initFieldControl.SIPCO_CPA_Color.Required}
                                                    help={guideLine.SIPCO_CPA_Color}
                                                    value={this.state.selectedModel.SIPCO_CPA_Color} onChange={this.handleColorChange} />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'SIPCO_CPA_Description')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.SIPCO_CPA_DESCRIPTION}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Form.Item
                                                    help={null}
                                                    hasFeedback
                                                >
                                                    {getFieldDecorator('SIPCO_CPA_Description', {
                                                        initialValue: selectedModel.SIPCO_CPA_Description,
                                                        rules: [
                                                            {
                                                                required: initFieldControl.SIPCO_CPA_Description.Required,
                                                            }
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Status')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item >
                                                    {language.STATUS}
                                                </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_SelectParameterSystem name='Status' type={MPS_SYSTEM_PARAMETER_TYPE.Status}
                                                    getFieldDecorator={getFieldDecorator}
                                                    required={initFieldControl.Status.Required}
                                                    value={this.state.selectedModel.Status} onChange={this.handleStatusChange} />

                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.CREATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedBy')}`} name="CreatedBy"
                                        help={guideLine.CreatedBy}
                                        value={this.state.selectedModel.CreatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.CREATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'CreatedDate')}`}
                                                    name="CreatedDate"
                                                    help={guideLine.CreatedDate}
                                                    style={{ width: '100%' }}
                                                    value={!!selectedModel.CreatedDate ? moment(selectedModel.CreatedDate) : null}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Start_Date')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.START_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={false}
                                                    onChange={this.handleStartDateChange}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'Start_Date')}`}
                                                    name="Start_Date"
                                                    help={guideLine.CreatedDate}
                                                    style={{ width: '100%' }}
                                                    value={!!selectedModel.Start_Date ? moment(selectedModel.Start_Date) : null}
                                                    allowClear = {true}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'End_Date')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.END_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={false}
                                                    onChange={this.handleEndDateChange}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'End_Date')}`}
                                                    name="End_Date"
                                                    help={guideLine.CreatedDate}
                                                    style={{ width: '100%' }}
                                                    value={!!selectedModel.End_Date ? moment(selectedModel.End_Date) : null}
                                                    allowClear = {true}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                    <System_User_Select disabled={true}
                                        style={gridStyle}
                                        label={language.UPDATED_BY}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedBy')}`} name="UpdatedBy"
                                        help={guideLine.UpdatedBy}
                                        value={this.state.selectedModel.UpdatedBy} />

                                    <Card.Grid style={gridStyle}
                                        className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}>
                                        <Row>
                                            <Col span={Mps_LayoutFormat.ColCardLabel} >
                                                <Form.Item> {language.UPDATED_DATE} </Form.Item>
                                            </Col>
                                            <Col span={Mps_LayoutFormat.ColCardInput}>
                                                <Mps_InputDatePicker disabled={true}
                                                    className={`mps-card-items ${Mps_MethodCommon.checkFieldAvailable(displayDefaultFieldOnEditForm, 'UpdatedDate')}`}
                                                    name="UpdatedDate"
                                                    help={guideLine.UpdatedDate}
                                                    style={{ width: '100%' }}
                                                    value={moment(Date.now())}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                </Card>

                            </TabPane>
                            <TabPane tab={language.DOCUMENT_UPLOAD} key='2'>
                            <Mps_UploadPictureV2
                                language = {language}
                                isConvertUrl
                                images={this.state.selectedModel.CPA_Files}
                                onChange={this.handleUploadFile}
                                onRemove={this.handleRemoveFile} 
                                onAddImage={this.handleAddFile}
                                />
                            </TabPane>
                        </Tabs>
                    </Form>
                </Modal>
            </span>
        );
    }
}
const WrappedSIPCO_CPAEdit = Form.create({ name: 'SIPCO_CPAEdit' })(SIPCO_CPAEdit);
export { WrappedSIPCO_CPAEdit as SIPCO_CPAEdit };

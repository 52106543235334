import React from 'react';
import jsonQuery from 'json-query';
import {
    Form, Row, Col,
    Select, Card,
} from 'antd';
import { Mps_LayoutFormat, Mps_Tooltip } from '../index';
import { LANGUAGE_STATE } from './ln';
const { Option } = Select;

class Mps_SelectMultipleDay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
        };
    }

    onChange = value => {
        value.sort();
        this.props.onChange(value);
    };

    onSelect = value => {
        value.sort();
        this.props.onChange(value);
    };

    getName = name => {
        if (!!name) {
            return name;
        } else {
            return '';
        }
    };

    getDisabled = disabled => {
        if (!!disabled) {
            return disabled;
        } else {
            return false;
        }
    };

    getPlaceHolder = placeholder => {
        if (!!placeholder) {
            return placeholder;
        } else {
            return '';
        }
    };

    render() {
        const chooseLanguage = this.props.language;
        const language = jsonQuery([chooseLanguage[0]], { data: LANGUAGE_STATE }).value;
        const gridStyle = this.props.style;
        const getFieldDecoratorName = this.getName(this.props.name);

        return (
            <Card.Grid style={gridStyle}
                className={`mps-card-items ${this.props.className}`}>
                <Row>
                    <Col span={Mps_LayoutFormat.ColCardLabel} >
                        <Form.Item>
                            {(!!this.props.label) ? this.props.label : language.CLASS}
                        </Form.Item>
                    </Col>
                    <Col span={Mps_LayoutFormat.ColCardInput}>
                        <Form.Item
                            help={
                                (!!this.props.help) ? <Mps_Tooltip title={this.props.help} /> : ''
                            }
                            hasFeedback
                        >
                            {(!!this.props.getFieldDecorator) ?
                                (this.props.getFieldDecorator(getFieldDecoratorName, {
                                    initialValue: this.props.value ? this.props.value : '',
                                    rules: [
                                        {
                                            required: (!!this.props.required) ? this.props.required : false,
                                            message: this.props.message,
                                        },
                                    ],
                                })(
                                    <Select disabled={(!!this.props.disabled) ? this.props.disabled : false}
                                        mode="multiple"
                                        onChange={this.onChange}
                                    >
                                        <Option key={0}>{language.SUNDAY}</Option>
                                        <Option key={1}>{language.MONDAY}</Option>
                                        <Option key={2}>{language.TUESDAY}</Option>
                                        <Option key={3}>{language.WEDNESDAY}</Option>
                                        <Option key={4}>{language.THURSDAY}</Option>
                                        <Option key={5}>{language.FRIDAY}</Option>
                                        <Option key={6}>{language.SATURDAY}</Option>
                                    </Select>
                                )) : (
                                    <Select disabled={(!!this.props.disabled) ? this.props.disabled : false}
                                        mode="multiple"
                                        value={this.props.value}
                                        onChange={this.onChange}
                                    >
                                        <Option key={0}>{language.SUNDAY}</Option>
                                        <Option key={1}>{language.MONDAY}</Option>
                                        <Option key={2}>{language.TUESDAY}</Option>
                                        <Option key={3}>{language.WEDNESDAY}</Option>
                                        <Option key={4}>{language.THURSDAY}</Option>
                                        <Option key={5}>{language.FRIDAY}</Option>
                                        <Option key={6}>{language.SATURDAY}</Option>
                                    </Select>
                                )}
                        </Form.Item>
                    </Col>
                </Row>
            </Card.Grid>
        );
    }
}

export { Mps_SelectMultipleDay };


export const LANGUAGE_STATE = {
    'vn':
    {
        FILE_NAME: 'Dữ liệu chi tiết',
        DOWNLOAD_ERROR_IMPORT: 'Vui lòng click vào đây để xem thêm chi tiết.',
        SHEET_NAME: 'Chi tiết dữ liệu',
        NOTE: 'Ghi chú',
        NOTE_COLUMN: [{
            columns: [
                {title: 'Màu', width: {wpx: 100}},
                {title: 'Mô tả', width: {wpx: 100}},
            ],
            data: [
                [{value: '' , style: 'brown'},{value: 'Duy nhất'}],
                [{value: '' , style: 'orange'},{value: 'Cần nhập'}],
                [{value: '' , style: 'red'},{value: 'Duy nhất + Cần nhập'}],
                ['']
            ]
        }],
        REQUIRE: ['\n', '\n- Duy nhất', '\n- Cần nhập', '\n- Duy nhất\n- Cần nhập'],
        COLOR: ['white', 'brown', 'orange', 'red'],
        CODE: 'Mã ',
    },
    'en':
    {
        FILE_NAME: 'Data detail',
        DOWNLOAD_ERROR_IMPORT: 'Click here to view more detail.',
        SHEET_NAME: 'Data detail',
        NOTE: 'Note',
        NOTE_COLUMN: [{
            columns: [
                {title: 'Color', width: {wpx: 100}},
                {title: 'Description', width: {wpx: 100}},
            ],
            data: [
                [{value: '' , style: 'brown'},{value: 'Only once'}],
                [{value: '' , style: 'orange'},{value: 'Require'}],
                [{value: '' , style: 'red'},{value: 'Only once + Require'}],
                ['']
            ]
        }],
        REQUIRE: ['\n', '\n- Only once', '\n- Require', '\n- Only once\n- Require'],
        COLOR: ['white', 'brown', 'orange', 'red'],
        CODE: ' Code',
    }
};